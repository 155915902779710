@media ( min-width: 1025px ) {
    #peche_Homelist {
        margin: 0 0 0 50px !important;
    }   
  }
  
@media ( max-width: 1024px ) {
    #peche_Homelist {
        margin: 0 13px 150px 13px !important;
    }
}

.list-title.in-rows {
    @media ( max-width: 1024px ) {
        margin-bottom: 13px !important;
        h3 { font-size: 1.1rem; }
        .list-infoIcon { height: 19px !important; margin-top: -3px !important; }
    }
}

.list-title {
    span.icon_box {
        display: inline-block;
        position: relative;
        width: 37px;
        @media ( max-width: 1025px ) { width: 23px }
    }
    
    span.dot {
        border-radius: 50%;
        background-color: red;
        display: inline-block;
        height: 13px;
        margin: 0 7px 3px 3px;
        width: 13px;

        @media ( max-width: 1025px ) {
            margin: 0 7px -1px 3px;
        }
    }
    
    @keyframes blinking {  
        from { opacity: 1; }
        to { opacity: 0.2; }
    }

    span.live {
        animation: blinking 2s cubic-bezier( 0, 0, 1, 1 ) infinite alternate;  
    }
}
  
.GBSlider {
    // position: relative;
    // top: 77px;
    margin-top: 80px;
    transition: all .5s;

    &.in-rows {
        margin-top: 0;
        margin-bottom: 26px;
    }

    /* hover on image : changing because play button is removed */
    .carousel-img {
        transition: filter 0.5s, transform 0.5s;
    }

    .carousel-img button {
        opacity: 0;
        transition: opacity 1s;
    }

    .carousel-img:hover {
        cursor: pointer;
        filter: brightness( 1.2 );
        // transform: scale( 1.2 );
    }

    .carousel-img.with-btn:hover { cursor: initial; }

    .carousel.carousel-slider {
        overflow: visible;
    }
    
    .carousel .control-dots {
        transform: translateY( 50px );
        z-index: 1;

        .dot { height: 13px; width: 13px; }
        .dot:hover { background-color: #FBA00B; }
        // .dot:focus { outline: -webkit-focus-ring-color auto 1px; }
        .dot:focus { outline: none; }
        .dot.selected { background-color: #FBA00B; }
    }
}

@media ( min-width: 1025px ) {
    .GBSlider {
        // height: 300px;
        margin: 0 50px 0 80px;
        box-shadow: 10px 20px 15px -10px #000000BF;

        &.in-rows { margin: 0 13px 68px 13px; }

        .slider-wrapper.axis-horizontal {
            // height: 300px !important;
            border: 5px inset transparent;
            border-radius: 10px;
        }

        &:hover {
            // margin: 0 20px 0 50px;
            .slider-wrapper.axis-horizontal {
                // height: 300px !important;
                border: 5px inset #FBA00B;
            }
        }

        .carousel .control-dots {
            transform: translateY( 50px );
        }
    }   
}

@media ( max-width: 1025px ) {
    .GBSlider.in-rows.with-dots { margin-bottom: 55px; }   
}

/* MQ */
@include maxMediaQueries(750px) {
    .list-title {
      padding-left: 20px;
      margin-bottom: 13px;
    }
    .left-controls {
      height: 180px;
      width: 10%;
    }
    .right-controls {
      height: 180px;
      width: 10%;
    }
    #content {
      padding: 30px 0 30px 0;
    }
  } 
