.hide { display: none; }

@media ( min-width: 1025px ) {
    #nav-container-mobile { display: none; }   
}

#nav-container-mobile {
    color: #AEAEAE;
    font-size: 16px;
    @media ( max-width: 767px ) { font-size: 12px; }

    #nav-container-mobile-up {
        // background: radial-gradient(circle at 50% 0, #0038385e, #00000047 70.71%),
        //             radial-gradient(circle at 6.7% 75%, #002420, #000000 70.71%),
        //             radial-gradient(circle at 93.3% 75%, #00211e, #003838 70.71%) #000000;
        background: #111111e6;
        color: #AEAEAE;
        position: fixed;
        // position: absolute;
        top: 0;
        width: 100%;
        z-index: 4; 

        .nav-container-mobile-up-right {
            align-items: center;
            display: flex;
            padding-right: 13px;

            .nav_item {
                display: inline-block;
                margin: 7px 26px 7px 0;
                position: relative;
                z-index: 2;
        
                .notif-context-menu  {
                    background-color: #000000;
                    border-radius: 5px;
                    top: 40px;
                    color: #eee;
                    right: -50px;
                    min-width: 300px;
                    padding: 26px 13px;
                    position: absolute;
                    z-index: 1;
        
                    .notif-context-menu-title {
                        font-size: 1.5em;
                        padding: 13px 0;
                        text-align: center;
                        width: 100%;
                    }
        
                    .notif-context-menu-item {
                        align-items: center;
                        border-top: 1px solid #aeaeae55;
                        display: flex;
                        padding: 26px 0;
        
                        &.linked { cursor: pointer; }
        
                        .notif-context-menu-info {
                            color: #eee;
                            padding-left: 26px;
                        }
                        .tile-img-small { width: 120px; }
                    }
        
                    .notif-context-menu-close {
                        button {
                            border: 0px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;
                            font-size: 20px;
                            position: absolute;
                            padding: 24px;
                            right: 0;
                            top: 0;
                            z-index: 9;
                            background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                            background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                            background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                            background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                            background-position: 50% -50%;	
                        }
                    }
                }

                .profile-context-menu  {
                    background-color: #000000;
                    border: 1px solid #AEAEAE44;
                    border-radius: 5px;
                    top: 43px;
                    color: #eee;
                    right: 37px;
                    min-width: 150px;
                    padding: 13px;
                    position: absolute;
                    z-index: 1;

                    .profile-context-menu-item {
                        align-items: center;
                        display: flex;
                        padding: 7px 0;

                        &.linked { cursor: pointer; }

                        .notif-context-menu-info {
                            color: #eee;
                            padding-left: 26px;
                        }
                        .tile-img-small { width: 120px; }
                    }
                }
            }
        
            span.icon_box { display: inline-block; position: relative; text-align: center; width: 60px; }
        
            span.dot {
                border-radius: 50%;
                background-color: #ff0000;
                display: inline-block;
                height: 11px;
                position: absolute;
                right: 14px;
                top: -3px;
                width: 11px;
            }

            img.nav_icon { height: 30px; }
        }

        img.nav_icon {
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            height: 30px;
            @media ( max-width: 767px ) { height: 20px; }
        }

        a {
            color: inherit;
            display: block;

            &.selected {
                color: #FBA00B;

                img.nav_icon {
                    filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                }
            }
        }

        #nav-container-mobile-up-profile {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 7px 10px;

            #nav_profile-mobile {
                font-size: 30px;
                @media ( max-width: 767px ) { font-size: 20px; }
            }
        }

        #nav-container-mobile-up-btns {
            align-items: center;
            display: flex;
            font-size: 24px;
            @media ( max-width: 767px ) { font-size: 16px; }
            justify-content: space-around;
            padding: 26px;
            @media ( max-width: 767px ) { padding: 26px 13px; }

            a {
                align-items: center;
                background-color: #333333;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                padding: 7px 0;
                width: 150px;
                @media ( max-width: 767px ) { width: 90px; }

                span.icon_box {
                    padding-right: 26px;
                    @media ( max-width: 767px ) { padding-right: 7px; }
                }
            }
        }
    }

    .hidden-nav {
        display: none !important;
    }

    #nav-container-mobile-down {
        align-items: center;
        // background: radial-gradient(circle at 50% 0, #0038385e, #00000047 70.71%),
        //             radial-gradient(circle at 6.7% 75%, #002420, #000000 70.71%),
        //             radial-gradient(circle at 93.3% 75%, #00211e, #003838 70.71%) #000000;
        background: #111;
        bottom: 0;
        color: #AEAEAE;
        display: flex;
        justify-content: space-around;
        height: 60px;
        padding: 13px 26px 13px 26px;
        position: fixed;
        width: 100%;
        z-index: 4;

        img.nav_icon {
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            height: 30px;
            @media ( max-width: 767px ) { height: 20px; }
        }

        a {
            color: inherit;
            display: block;

            &.selected {
                color: #FBA00B;

                img.nav_icon {
                    filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                }
            }
    
            span {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
    
                &.icon_box {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 7px;
                    width: 100%;
                }

                &.dot {
                    border-radius: 50%;
                    background-color: red;
                    display: inline-block;
                    height: 13px;
                    margin: 7px 0 10px 0;
                    @media ( max-width: 767px ) { margin: 3px 0 4px 0; }
                    width: 13px;
                }
                
                @keyframes blinking {  
                    from { opacity: 1; }
                    to { opacity: 0.2; }
                }
            
                &.live {
                    animation: blinking 2s cubic-bezier( 0, 0, 1, 1 ) infinite alternate;  
                }
            }
        }
    }
    
    #nav-container-mobile-up-profile .user-avatar {
        border-radius: 50%;
        height: 60px;
        margin-right: 50px;
        width: 60px;
    
        @media ( max-width: 767px ) { margin-right: 0; }
        @media ( max-width: 425px ) { height: 40px; width: 40px;}
    }
    
    #nav_logo-mobile img{
        max-width: 100px;
        height: auto;
        @media ( max-width: 425px ) { max-width: 80px; height: auto; }
    }

    .scrollingMenu {
        animation: scrollingMenu 0.5s;
        bottom: 60px;
        position: fixed;
        right: 0;
        width: 100%;
        z-index: 11;
        // radial-gradient(circle at 50% 0, #0038385e, #00000047 70.71%), radial-gradient(circle at 6.7% 75%, #002420, #000000 70.71%), radial-gradient(circle at 93.3% 75%, #00211e, #003838 70.71%) #000000
        @media ( min-width: 767px ) { bottom: 55px; }

        a {
            color: inherit;
            display: block;

            &.selected {
                color: #FBA00B;

                img.nav_icon {
                    filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                }

                .nav_item_text { color: #FBA00B; }
            }
        }
    }
    
    .menuElement {
        background: rgb(14, 13, 13);
        display: flex;
        padding: 20px 26px;

        &:hover {
            background: rgb(37, 36, 36);
        }

        .icon_box { width: 30px; }
        
        img.nav_icon {
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            height: 30px;
            @media ( max-width: 767px ) { height: 20px; }
        }
    }
    
    .nav_item_text {
        color: white;
        font-size: 18px;
        padding-left: 20px;

        @media ( max-width: 425px ) { font-size: 16px; }

    }
    
    .dot {
        border-radius: 50%;
        background-color: red;
        display: inline-block;
        height: 13px;
        margin: 7px 0 10px 0;
        @media ( max-width: 767px ) { margin: 3px 0 4px 0; }
        width: 13px;
    }
    
    .live {
        animation: blinking 2s cubic-bezier( 0, 0, 1, 1 ) infinite alternate;  
    }
}

.icon_box_more {
    margin-bottom: 6px;
    height: 22px !important;
    @media ( min-width: 767px ) { height: 32px !important; }
}

@keyframes scrollingMenu {
    from{
        bottom: -600px;
    }
    to{
        bottom: 60px;
    }
}
