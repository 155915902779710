.video-js .vjs-control-bar {
	position: fixed !important;
}
.video-js .btn{
	font-size: 1.2vw !important;
}
.video-js .vjs-tech{
	position: fixed !important;
}
.video-js .vjs-modal-dialog {
	z-index: 2000 !important;
}
.react-confirm-alert-overlay {
	z-index: 9999 !important;
	background: rgba(0,0,0,0.8);
}

.responsive-image {
	position: relative;
	width: 600px;
	max-width: 100%;
}
.responsive-image__image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  .btnPopup{
	  position:relative;
	  top: -80px;
  }
  @media only screen and (max-device-width:480px) {
	.responsive-image {
		width: 300px;
	}
	.btnPopup{
		top: -38px;
	}
}


.player-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%; 
	overflow: hidden;  
}
  
.react-player {
	/* Make video to at least 100% wide and tall */
	min-width: 100%; 
	min-height: 100%; 
	
	/* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: auto;
	height: auto;
	
	/* Center the video */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.z3PlayOverlay {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: #000;
    display: none;
    transition: .2s;
    z-index: 99999;
}
.overlayGB {
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.overlayGB-visible {
	visibility: visible;
	opacity: 1;
	transition: opacity 1s linear;  
}

.overlayGB-hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 1s, opacity 1s linear;
}

/* LOADER */
.loader {
	display: inline-block;
	margin: 10vh;
	position: relative;
	width: 200px;
	height: 200px;
	z-index: 1;
}

@media ( max-width: 767px ) { .loader { margin: 2vh 10vh 0 10vh; } }

.loader:hover {
	cursor: pointer;
}

.loader div.loader-part {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 160px;
	height: 160px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #FBA00B transparent transparent transparent;
	z-index: 1;
}

.loader div.loader-part:nth-child(1) { animation-delay: -0.45s; }
.loader div.loader-part:nth-child(2) { animation-delay: -0.3s; }
.loader div.loader-part:nth-child(3) { animation-delay: -0.15s; }

.loader div.loader-text {
	color: #FFF;
	box-sizing: border-box;
	font-size: 6rem;
	left: 45%;
	margin: 0;
	position: absolute;
	text-align: center;
	top: 48%;
	transform: translate( -50%, -50% );
	z-index: 2;
}

.loader div.loader-play {
	color: #FBA00B;
	box-sizing: border-box;
	font-size: 6rem;
	left: 45%;
	margin: 0;
	opacity: 0.5;
	position: absolute;
	text-align: center;
	top: 48%;
	transform: translate( -50%, -50% );
	z-index: 1;	
}

@keyframes loading {
	0% { transform: rotate( 0deg ); }
	100% { transform: rotate( 360deg ); }
}

.btnRet{
	font-size: 1.6rem !important;
	padding: 0.4rem 1.5rem;
	border: 1px solid transparent !important;
}
.btnRet:hover{
	border: 1px solid #fba00b !important;
}

$font-stack: 'Helvetica Neue',Helvetica,Arial,sans-serif;

.video-js .vjs-big-play-button {
    font-size: 6em;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    margin-left: -45px;
    margin-top: -45px;
    border: none;
    background-color: rgba(43,51,63,.7);
    line-height: 90px;
    border-radius: 50%;
    border: none;
    z-index: 2;
}

.video-js .vjs-control-bar {
    z-index: 2;
}

.video-js .vjs-tech{
	position: fixed !important;
}

.s3bubble-meta-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483647;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container {
    margin: 2%;
    width: 40%;
    padding: 0;
    text-align: left
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container:-webkit-full-screen {
	display:block !important;
	z-index:2147483647;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5 {
    font-family: 'Helvetica', sans-serif;
    font-size: 1.3vw;
    font-weight: 400;
    text-shadow: 0 1px 4px rgb(0, 0, 0);    
    color: #fff;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    float: left;
    width: 100%;
}
.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5:-webkit-full-screen{
	display : block !important
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h1, .s3bubble-meta-overlay .s3bubble-meta-overlay-container h1:-webkit-full-screen {
    font-family: 'Open Sans', sans-serif;
    font-size: 4vw;
    font-weight: 700;
    color: #fff;
    text-shadow: 0 1px 4px rgb(0, 0, 0);
    margin: 1vw 0;
    padding: 0;
    float: left;
    width: 100%;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container p, .s3bubble-meta-overlay .s3bubble-meta-overlay-container p:-webkit-full-screen {
    font-family: 'Helvetica', sans-serif;
    text-shadow: 0 1px 4px rgb(0, 0, 0);
    font-weight: normal;
    font-weight: 200;
    font-size: 1.8vw;
    line-height: 1.3em;
    color: #fff;
    width: 100%;
    margin: 0;
    padding: 0;
}

.s3bubble-meta-overlay-visible,.s3bubble-meta-overlay-visible:-webkit-full-screen {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.s3bubble-meta-overlay-hidden, .s3bubble-meta-overlay-hidden:-webkit-full-screen {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.popupFinPlay-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0
}

.s3bubble-meta-overlay-container-mobile {
	text-align: center;
	color:#fff;
	padding-top:10px;
}

.s3bubble-meta-overlay-container-mobile h5 {
	padding-top:10px;
}

.s3bubble-meta-overlay.next-overlay {
    display: flex;
    width: 100%; 
}

@media ( max-width: 767px ) {
    .s3bubble-meta-overlay.next-overlay {
        flex-direction: column;
    }
}

.s3bubble-meta-overlay:hover { cursor: pointer; } 

.notFullScreen .s3bubble-meta-overlay {
    // border: 2px solid #001212;
    transition: border 0.5s;

    .fullscreen_icon {
        bottom: 7px;
        // color: #FFF;
        font-size: 36px;
        opacity: 0;
        position: absolute;
        right: 7px;
        transition: opacity 0.5s;
        z-index: 2;
    }

    // &:hover { border: 2px solid #fba00b; border-radius: 10px; }
    &:hover .fullscreen_icon { opacity: 0.8; }

    &.refresh {
        // background-color: #fff !important;
        // opacity: 0.6;
        opacity: 0.4;

        .play_icon {
            // color: #111;
            color: #eee;
            font-size: 96px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate( -50%, -50% );
            z-index: 2;
        }
    }
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container-video {
    margin: 2%;
    width: 52%;
    padding: 0 10%;
    text-align: left
}

.s3bubble-meta-overlay figure {
    border: 5px solid #fba00b;
}

.s3bubble-meta-overlay figure h1.short-title {
    font-size: 1em !important;       
}

.s3bubble-meta-overlay figure h1.long-title {
    font-size: 0.8em !important;
}

.s3bubble-meta-overlay figure h1.longer-title {
    font-size: 0.5em !important;
}

.s3bubble-meta-overlay figure div.title-info {
    display: none;
}

.s3bubble-meta-overlay figure:hover h1.short-title {
    display: none !important;
}

.s3bubble-meta-overlay figure:hover h1.long-title {
    display: none !important;
}

.s3bubble-meta-overlay figure:hover h1.longer-title {
    display: none !important;
}

.s3bubble-meta-overlay figure img {
    transition: all .5s;
}

.s3bubble-meta-overlay figure:hover img {
    opacity: 1;
    filter: brightness(0.8);
}

.s3bubble-meta-overlay  figure:hover div.title-info {
    margin: 15%;
    display: block;
}

.s3bubble-meta-overlay figure:hover div.title-info .title-info-title {
    font-size: 1em !important;
    font-weight: bold; 
    margin-top: -26px;
    text-shadow: 1px 1px #111;    
}

.s3bubble-meta-overlay figure:hover div.title-info .title-info-details {
    font-size: 0.7em !important;
    text-shadow: 1px 1px #111;
    text-transform: none;
}

#advertisement_pub {
    bottom: 7px;
    color: #fff;
    position: absolute;
    left: 7px;
    width: 100%;
    z-index: 2;  

    h3 {
        font-size: 2rem;
        padding: 13px;
        text-align: center;
    }

    a.go_offer {
        margin: 0 auto;
        width: 500px;

        @media ( max-width: 767px ) { width: 80%; }

        &:hover { background-color: #d6880a !important; color: #fff !important; }
    }

    &.hide {
        display: none;
    }
}

#advertisement_pub_count {
    align-items: center;
    bottom: 26px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
    z-index: 2;  

    h3 {
        font-size: 2rem;
        margin-top: 13px;
        padding: 0 13px;
    }

    &.hide {
        display: none;
    }
}

#advertisement_pub_progress {
    height: 7px;
    bottom: 7px;
    left: 50%;
    position: absolute;
    transform: translateX( -50% );
    width: 500px;
    z-index: 2;

    @media ( max-width: 767px ) { width: 80%; }
    &.hide { display: none; }
    #progressBar { background-color: #fba00b; height: 100%; }
}

@media ( min-width: 1200px ) and ( max-width: 1400px ) {
    #advertisement_pub h3 { font-size: 1.8rem; }
}

@media ( min-width: 992px ) and ( max-width: 1200px ) {
    #advertisement_pub h3 { font-size: 1.4rem; }

	#advertisement_pub_count {
        h3 {
            font-size: 1.3rem;
            padding: 0 7px;
        }
    }
}

@media ( min-width: 768px ) and ( max-width: 992px ) {
    #advertisement_pub h3 { font-size: 1.2rem; }

	#advertisement_pub_count {
        h3 {
            font-size: 1rem;
            padding: 0 7px;
        }

        a.go_offer {
            font-size: 1.4rem;
            width: 80%;
        }
    }
}

@media ( max-width: 767px ) {
    #advertisement_pub h3 { font-size: 1rem; padding: 0; }

    #advertisement_pub a.btn { width: 80%; margin-top: 13px; }

	#advertisement_pub_count {
        h3 { font-size: 1rem; padding: 3px; margin-bottom: 0 !important; }
        a.go_offer { font-size: 1rem; width: 80%; }
    }
}

#z3pOverlay.playlistPlayer .s3bubble-meta-overlay {
    background-color: transparent;
}

#z3pOverlay.playlistPlayer.isFullScreen .fullscreen_icon {
    display: none;
}

#advertisement_pub_count {
    a.go_offer {
        margin: 0 auto;
        width: 500px;

        @media ( max-width: 767px ) { width: 80%; }

        &:hover { background-color: #d6880a !important; color: #fff !important; }
    }
}

.free-movie-container {
    align-items: center;
    background-color: #fba00b;
    color: #fff;
    font-size: 14px;
    justify-content: space-around;
    padding: 13px 0;
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    z-index: 10;

    @media ( max-width: 767px ) { flex-direction: column; }

    .btn {
        background-color: #fff !important;
        border: 2px solid #fff;
        border-radius: 5px;
        color: #000 !important;
        width: 150px;

        &:hover { border: 2px solid #000 !important; }
    }
}