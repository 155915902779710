/* tiles CSS transition and transform effects */
// .GBListItem{
// 	position: relative;
// 	top:82px;
// }

// .list-container {
//   max-width: 100%;
//   padding: 0px;
//   position: relative;
// }

// .list-title {
//   padding-left: 40px;
//   font-weight: bold;
//   color:#C1C1C1;
//   font-family: 'balboa', sans-serif;
// }

// .listRow {
//   display: flex;
//   width: 100%;
//   transition: 0.3s transform;
//   &:hover {
//     transform: translate3d(-120px, 0, 0);
//   }
// }

// @include listingTiles;
// .long-title {
//   font-size: 0.9em;

//   @media ( max-width: 1200px ) { font-size: 0.8em; }
//   @media ( max-width: 1024px ) { font-size: 0.5em; }
// }

// .longer-title {
//   font-size: 0.75em;
//   @media ( max-width: 1200px ) { font-size: 0.65em; }
//   @media ( max-width: 1024px ) { font-size: 0.35em; }
// }

// /* netflix-like carousel effects */

// html {
//   overflow: scroll;
//   overflow-x: hidden;
// }

// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent;
// }

// .left-controls {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 4%;
//   height: 280px;
//   z-index: 40;
//   background: $netflixBlack;
//   opacity: 0.5;
//   cursor: pointer;
//   text-align: center;
//   display: flex;
//   color: #fff;
//   justify-content: center;
// }

// .right-controls {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 4%;
//   height: 280px;
//   z-index: 40;
//   background: $netflixBlack;
//   opacity: 0.5;
//   display: flex;
//   cursor: pointer;
//   text-align: center;
//   justify-content: center;
//   color: #fff;
// }

// .module-section {
//   max-width: 100%;
//   overflow: hidden;
//   position: relative;
//   top:-40px;
// }

// #content {
//   width: 600em;
//   list-style-type: none;
//   padding: 50px 0 50px 0;
// }

// /* MQ */
// @include maxMediaQueries(1400px) {
//   .tile {
//     width: $tileWidth * 0.95;
//     height: $tileHeight * 0.95;
//     &:hover {
//       transform: scale(1.7);
//     }
//     &:hover ~ .tile {
//       transform: translate3d(190px, 0, 0);
//     }
//   }
//   .listRow {
//     &:hover {
//       transform: translate3d(-110px, 0, 0);
//     }
//   }
// }

// @include maxMediaQueries(750px) {
//   .playbtn-container {
//     margin: 18px 0px 5px 0px;
//   }
//   .tile {
//     width: $tileWidth * 0.8;
//     height: $tileHeight * 0.8;
//     &:hover {
//       transform: scale(1.4);
//     }
//     &:hover ~ .tile {
//       transform: translate3d(90px, 0, 0);
//     }
//   }
//   .tile-title {
//     font-size: 0.8em;
//   }
//   .listRow {
//     &:hover {
//       transform: translate3d(-70px, 0, 0);
//     }
//   }
// }
// @include maxMediaQueries(445px) {
//   .playbtn-container {
//     margin: 15px 0px 0px 0px;
//   }
//   .tile {
//     width: $tileWidth * 0.75;
//     height: $tileHeight * 0.75;
//     &:hover {
//       transform: scale(1.3);
//     }
//     &:hover ~ .tile {
//       transform: translate3d(65px, 0, 0);
//     }
//   }
//   .tile-title {
//     font-size: 0.7em;
//   }
//   .listRow {
//     &:hover {
//       transform: translate3d(-60px, 0, 0);
//     }
//   }
// }

#playlist_title {
  color: #fff;
  font-size: 30px;
  // margin: 12vh 0 0 13px;
  padding: 50px 0 0 26px;
  text-align: center;
  width: 75vw;
}

#playlist_info_container {
  display: flex;
  margin: 0 0 0 13px;
  
  #playlist_info {
    color: #fff;
    flex-basis: 75%;
    font-size: 20px;
    text-align: center;
    padding-top: 7px;
  }

  #btns_choice { 
    display: flex;
    padding: 7px;

    .btns_choice_ico {
      height: 20px;
    }

    #chasse_choice .btns_choice_ico {
      margin-right: 13px;
    }

    #peche_choice .btns_choice_ico {
      margin-left: 13px;
    }

    #chasse_choice, #peche_choice {
      align-items: center;
      background-color:rgb(95, 93, 93);
      border-radius: 13px;
      color: #111;
      display: flex;
      justify-content: center;
      height: 40px;
      opacity: 0.6;
      width: 140px;
      transition: opacity 0.5s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }

    #chasse_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-right: -13px;
      opacity: 1;
      z-index: 2;
    }
    
    #peche_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-left: -13px;
      opacity: 1;
      z-index: 2;
    }

    .selected .btns_choice_ico {
      filter: invert( 100% );
    }
  }
}

@media ( min-width: 992px ) and ( max-width: 1200px ) {
  #playlist_info_container #playlist_info { font-size: 18px; }
}

@media ( max-width: 767px ) {
  // #z3pOverlay.playlistPlayer {
  //   top: 12% !important;
  //   left: 12% !important;
  //   height: 25% !important;
  //   width: 75% !important;
  //   z-index: 2;
  // }

  #playlist_title {
    // margin: 40vh 0 0 26px;
    margin: 26px;
    text-align: initial;
  }

  #playlist_info_container {
    flex-direction: column;
    padding-right: 13px;

    #playlist_info {
      text-align: start;
    }

    #btns_choice {
      justify-content: center;
    }
  } 
}

@media ( orientation: landscape ) and ( max-width: 850px ) {
  // #z3pOverlay.playlistPlayer {
  //   top: 15% !important;
  //   left: 15% !important;
  //   height: 50% !important;
  //   width: 75% !important;
  //   z-index: 2;
  // }

  #playlist_title {
    margin: 60vh 0 0 26px;
    text-align: initial;
  } 
}

// #playlist_title {
//       margin: 150px 50px 0 80px;
//   }

// @media ( max-width: 1023px ) {
//   #z3pOverlay.playlistPlayer {
//     top: 360px !important;
//   }
// }

// @media ( max-width: 767px ) {
//   #z3pOverlay.playlistPlayer {
//     top: 170px !important;
//   }
// }

@media ( min-width: 768px ) {
  #playlist_title {
      margin: 50px;
  }   
}

@media ( min-width: 1024px ) {
  #playlist_title {
      margin: 0 50px 0 80px;
  }   
}

// @media ( max-width: 1024px ) {
//   #playlist_title {
//       margin: 0 13px;
//   }
// }

// MovieDetails
.playlist_movie_details {
  color: #fff;
  flex-basis: 40%;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px; 

  img { border-radius: 10px; width: 100%; }

  .playlist_movie_intro {
      align-self: flex-start;
      padding: 5vh 0 2vh 5vw;
  }

  .playlist_movie_title {
      align-self: flex-start;
      padding: 3vh 0 1vh 5vw;
      font-size: 30px;
  }

  .playlist_movie_synopsis {
      align-self: flex-start;
      padding: 0 5vw 0 5vw;
      font-size: 18px;
      text-align: justify;
  }
}

@media ( min-width: 1200px ) {
  .playlist_movie_details {
      margin-left: 4vw;
      width: 36vw;
    
      img { max-width: 30vw; }
      .playlist_movie_title { font-size: 24px; }
  }
}

@media ( min-width: 992px ) and ( max-width: 1200px ) {
  .playlist_movie_details {
      margin-left: 4vw;
      width: 36vw;
    
      img { max-width: 30vw; }
      .playlist_movie_title { font-size: 20px; }
      .playlist_movie_synopsis {
          padding: 0 2vw 0 5vw;
          font-size: 16px;
      }
  }
}

@media ( min-width: 768px ) and ( max-width: 992px ) {
  .playlist_movie_details {
      margin-left: 4vw;
      width: 36vw;
    
      img { max-width: 30vw; }
      .playlist_movie_title { font-size: 18px; }
      .playlist_movie_synopsis {
          padding: 0 2vw 0 5vw;
          font-size: 16px;
      }
  }
}

@media ( max-width: 767px ) {
  .playlist_movie_details {
      width: 100vw;
  }
}

.playlist_movie_categories {
  align-self: flex-start;
  font-size: 18px;
  padding: 26px 5vw 0 5vw;

  @media ( min-width: 1025px ) { .catSpanLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
  
  .catSpan {
      border: 1px solid #aeaeae;
      border-radius: 20px;
      color: #aeaeae;
      display: inline-block;
      margin-right: 13px;
      margin-bottom: 7px;
      padding: 0 13px;
      
      &.catSpan-mobile { display: none; }
      &.catSpan-web { display: inline-block; }

      @media ( max-width: 767px ) {
          &.catSpan-mobile { display: inline-block; }
          &.catSpan-web { display: none; }
      }
  }
}

@media ( min-width: 992px ) and ( max-width: 1200px ) {
  .playlist_movie_categories {
      padding: 26px 2vw 0 5vw;
      font-size: 16px;
  }
}

@media ( min-width: 768px ) and ( max-width: 992px ) {
  .playlist_movie_categories {
      padding: 26px 2vw 0 5vw;
      font-size: 16px;
  }
}

.flex-container {
  display: flex;  
}

.player-container-relative {
  position: relative;
  flex-basis: 60%;

  #z3pOverlay { position: relative; }
  .z3PlayOverlay { background-color: transparent !important; }
}

@media ( max-width: 767px ) {
  .flex-container {
      flex-direction: column-reverse;

      .playlist_movie_details {
          flex-basis: auto;
      }

      .player-container-relative {
          flex-basis: auto;
          width: 100vw;
          height: 50vh;

          #z3pOverlay { top: 5% !important; height: 100% !important; width: 100% !important; }
      }
  }
  
}