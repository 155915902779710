footer {
    height: 100px;
    margin-top: 300px;
    @media ( max-width: 1024px ) { height: 160px; }
    @media ( max-width: 800px ) { height: 170px; margin-top: 280px; }
    p {
        padding-top: 12px;
        font-size: 0.7em !important;
	}

	.navbar-desabo{
		cursor: pointer;
		color: rgba(177,163,116,.57) !important;
	}

    nav.navbar { justify-content: flex-end; }
}

#advertisement{
    color: rgb(255,255,255);
    font-size: 14px;
    text-align: center;
    position: fixed;
    background-color: rgba(0,0,0,0.87);
    display: block;
    width: 100%;
    float: none;
    bottom: 0;
    z-index: 88888;
    border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 1px;
	padding-bottom:5px;
}

#advertisement .btn {
	font-size: x-large !important;
}

#advertisement_playlist {
    align-items: center;
    background-color: rgba( 0, 0, 0, 0.87 );
    border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 1px;
    bottom: 0;
    color: rgb(255,255,255);
    display: flex;
    float: none;
    font-size: 14px;
    justify-content: space-around;
    min-height: 12vh;
    padding: 0 10vw 5px 10vw;
    position: fixed;
    width: 100%;
    z-index: 88888;
}

#advertisement_guest, #advertisement_guest_mobile, #advertisement_discarded {
    align-items: center;
    // background-color: rgba( 0, 0, 0, 0.87 );
    background-color: #fba00b;
    // border: 1px solid rgba(0, 0, 0, 0.12);
	// border-radius: 1px;
    color: #fff;
    font-size: 14px;
    justify-content: space-around;
    // min-height: 12vh;
    // opacity: 0.9;
    // padding: 0 10vw 5px 10vw;
    padding: 13px 0;
    position: fixed;
    display: flex;
    // float: none;
    bottom: 0;
    width: 100%;
    z-index: 10;

    @media ( max-width: 767px ) { flex-direction: column; }

    .btn {
        background-color: #fff !important;
        border: 2px solid #fff;
        border-radius: 5px;
        color: #000 !important;
        // font-size: 1rem !important;
        // margin-top: -13px !important;
        width: 150px;

        &:hover { border: 2px solid #000 !important; }

        // &.btnLogin {
        //     background-color: transparent !important;
        //     color: #ffffff !important;
        //     border: none !important;
        // }

        // &.btnOffer:hover {
        //     background-color: #fba00b !important;
        //     color: #ffffff !important;
        // }
    }
}

#advertisement_discarded .btn { width: 280px; }

// #advertisement_discarded {
//     align-items: center;
//     background-color: rgba( 0, 0, 0, 0.87 );
//     border: 1px solid rgba(0, 0, 0, 0.12);
// 	border-radius: 1px;
//     color: rgb(255,255,255);
//     font-size: 14px;
//     justify-content: space-around;
//     min-height: 12vh;
//     opacity: 0.9;
//     padding: 0 10vw 5px 10vw;
//     position: fixed;
//     display: flex;
//     float: none;
//     bottom: 0;
//     width: 100%;
//     z-index: 2;

//     @media ( max-width: 767px ) { flex-direction: column; }

//     .btn {
//         font-size: 1.4em !important;
//         margin-top: -13px !important;
//         width: 280px;

//         &.btnReactive:hover {
//             background-color: #fba00b !important;
//             color: #ffffff !important;
//         }
//     }
// }

#advertisement_guest_bottom {
    color: rgb(255,255,255);
    font-size: 2rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    bottom: 38vh;
    width: 100%;
    float: none;
    z-index: 3;
	border-radius: 1px;
    min-height: 40vh;
    text-align: center;
    padding: 0 100px;

    h3 {
        font-size: 3rem;
    }

    .btn {
        font-size: 1rem !important;
        margin-top: -13px !important;
        width: 150px;

        &.btnLogin {
            background-color: transparent !important;
            color: #ffffff !important;
            border: none !important;
        }

        &.btnOffer:hover {
            background-color: #fba00b !important;
            color: #ffffff !important;
        }
    }
}

#advertisement_playlist .btn {
    font-size: x-large !important;
    margin-top: -13px !important;
}

@media ( min-width: 992px ) and ( max-width: 1200px ) {
	#advertisement_playlist {
        font-size: 14px;
        padding: 0 5vw 5px 5vw;
    }
}

@media ( max-width: 991px ) {
	#advertisement_playlist {
        flex-direction: column;
        padding: 0 2vw 5px 2vw;
        h3 { font-size: 3vw !important; margin-bottom: 26px !important; }
    }

    #advertisement_guest .btn, #advertisement_discarded .btn { height: 35px; }
    #advertisement_guest_mobile .btn, #advertisement_discarded .btn { height: 35px; }
}

@media ( max-width: 767px ) {
	#advertisement_playlist {
        h3 { font-size: 4vw !important; }
    }

    #advertisement_guest_bottom {
        font-size: 18px !important;
        padding: 0 23px;

        h3 { font-size: 24px !important; }
    }
}

#advertisement_guest { display: flex; }
#advertisement_guest_mobile { display: none; padding: 7px 0; }
#advertisement_guest_mobile .advertisement_guest_mobile_btns { display: flex; }

@media ( max-width: 1024px ) {
    #advertisement_guest, #advertisement_guest_mobile, #advertisement_discarded { bottom: 60px; }   
    #advertisement_guest { display: none; }
    #advertisement_guest_mobile { display: flex; }
}

