// HomeList
@media ( min-width: 1025px ) {
    #chasse_Homelist {
      margin: 0 0 0 80px;
    }   
  }
  
  @media ( max-width: 1024px ) {
    #chasse_Homelist {
      margin: 0 13px;
    }
  }
  
  @media ( min-width: 1025px ) {
    #peche_Homelist {
      margin: 0 0 0 80px;
    }   
  }
  
  @media ( max-width: 1024px ) {
    #peche_Homelist {
      margin: 0 13px;
    }
  }
   
  /* tiles CSS transition and transform effects */
  .GBListItem {
      position: relative;
      top: 82px;

      &.store-row { top: 30px; }
  }
  
  .list-container {
    max-width: 100%;
    padding: 0px;
    position: relative;
  }
  
  .list-title {
    padding-left: 40px;
    font-weight: bold;
    color:#C1C1C1;
    font-family: 'balboa', sans-serif;
  }
  
  .listRow {
    display: flex;
    width: 100%;
    transition: 0.3s transform;
    &:hover {
      transform: translate3d(-120px, 0, 0);
    }
  }
  
  @include listingTiles;
  .long-title {
    font-size: 0.9em;
    @media ( max-width: 1200px ) { font-size: 0.8em; }
    @media ( max-width: 1024px ) { font-size: 0.5em; }
  }
  .longer-title {
    font-size: 0.75em;
    @media ( max-width: 1200px ) { font-size: 0.65em; }
    @media ( max-width: 1024px ) { font-size: 0.35em; }
  }
  
  /* netflix-like carousel effects */
  
  html {
    overflow: scroll;
    overflow-x: hidden;
  }
  
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  .left-controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 4%;
    height: 280px;
    z-index: 40;
    background: $netflixBlack;
    opacity: 0.5;
    cursor: pointer;
    text-align: center;
    display: flex;
    color: #fff;
    justify-content: center;
  }
  
  .right-controls {
    position: absolute;
    top: 0;
    right: 0;
    width: 4%;
    height: 280px;
    z-index: 40;
    background: $netflixBlack;
    opacity: 0.5;
    display: flex;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    color: #fff;
  }
  
  .module-section {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    top:-40px;
  }
  
  #content {
    width: 600em;
    list-style-type: none;
    padding: 50px 0 50px 0;
  }
  
  /* MQ */
  @include maxMediaQueries(1400px) {
    .tile {
      width: $tileWidth * 0.95;
      height: $tileHeight * 0.95;
      &:hover {
        transform: scale(1.7);
        @media ( max-width: 1024px ) { transform: none; }
      }
      &:hover ~ .tile {
        transform: translate3d(190px, 0, 0);
      }
    }
    .listRow {
      &:hover {
        transform: translate3d(-110px, 0, 0);
      }
    }
  }
  
  @include maxMediaQueries(750px) {
    .playbtn-container {
      margin: 18px 0px 5px 0px;
    }
    .tile {
      width: $tileWidth * 0.8;
      height: $tileHeight * 0.8;
      &:hover {
        transform: scale(1.4);
        @media ( max-width: 1024px ) { transform: none; }
      }
      &:hover ~ .tile {
        transform: translate3d(90px, 0, 0);
      }
    }
    .tile-title {
      font-size: 0.8em;
    }
    .listRow {
      &:hover {
        transform: translate3d(-70px, 0, 0);
      }
    }
  }
  @include maxMediaQueries(445px) {
    .playbtn-container {
      margin: 15px 0px 0px 0px;
    }
    .tile {
      width: $tileWidth * 0.75;
      height: $tileHeight * 0.75;
      &:hover {
        transform: scale(1.3);
        @media ( max-width: 1024px ) { transform: none; }
      }
      &:hover ~ .tile {
        transform: translate3d(65px, 0, 0);
      }
    }
    .tile-title {
      font-size: 0.7em;
    }
    .listRow {
      &:hover {
        transform: translate3d(-60px, 0, 0);
      }
    }
  }

// index-vx  
.hide { display: none !important; }

.disabled { opacity: 0.4; }

body.no-scroll {
    position: fixed;
    overflow: hidden;
}

@media ( min-width: 1025px ) {
    // #home_Homelist { margin: 0 50px 0 80px; }   
    #home_Homelist { margin: 0 0 0 50px; }   
}
  
@media ( max-width: 1024px ) {
    #home_Homelist { margin: 0 13px 150px 13px !important; }
}
  
#home_Homelist.storeList .GBSlider {
    margin-left: 30px;
    @media ( max-width: 767px ) { margin-left: 0; }
    margin-top: 26px;
}

@media ( min-width: 1025px ) {
    #home_GBSlider {
        // height: 300px;
        margin: 0 50px 0 80px;
        box-shadow: 10px 20px 15px -10px #000000BF;

        .slider-wrapper.axis-horizontal {
            // height: 300px !important;
            border: 5px inset transparent;
            border-radius: 10px;
        }

        &:hover {
            // margin: 0 20px 0 50px;
            .slider-wrapper.axis-horizontal {
                // height: 300px !important;
                border: 5px inset #FBA00B;
            }
        }

        .carousel .control-dots {
            transform: translateY( 50px );
        }
    }   
}

.carousel-img { 
    position: relative;
    z-index: 1;

    .carousel-info {
        align-items: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
        left: 10%;
        position: absolute;
        top: 0;
        z-index: 1;

        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .carousel-img-title {
            height: 30%;
            margin-top: 5%;  
            
            img { height: 100%; }
        }
        .carousel-title { color: #fff; font-size: 2rem; font-weight: 700; }
        .carousel-btn-addon-box {
            align-items: center;
            display: flex;
            justify-content: space-around;
            margin: 0 auto;

            .carousel-btn-addon {
                background-color: #7c7c7c;
                border-radius: 3px;
                color: #fff;
                font-size: 1.3rem;
                padding: 5px;
                margin: 0 7px;
                width: 120px;

                &.linked { cursor: pointer; }
            }
        }
        .carousel-btn {
            background-color: #fba00b;
            border-radius: 3px;
            color: #fff;
            cursor: pointer;
            font-size: 1.5rem;
            margin: 0 auto;
            margin-bottom: 5%;
            padding: 5px;
            width: 200px;

            &.btn-tall { width: 300px; padding: 13px 5px; }
        }

        @media ( max-width: 1024px ) {
            left: 50%;
            transform: translateX(-50%);
            .carousel-btn-addon-box .carousel-btn-addon { width: 80px; }
        }

        i { font-size: 18px; margin-right: 13px; }
    }
}

#home_GBSlider {
    transition: all .5s;

    /* hover on image : changing because play button is removed */
    .carousel-img {
        transition: filter 0.5s, transform 0.5s;
    }

    .carousel-img button {
        opacity: 0;
        transition: opacity 1s;
    }

    .carousel-img:hover {
        cursor: pointer;
        filter: brightness( 1.2 );
        // transform: scale( 1.2 );
    }

    .carousel-img.with-btn:hover { cursor: initial; }

    .carousel.carousel-slider {
        overflow: visible;
    }
    
    .carousel .control-dots {
        transform: translateY( 50px );
        z-index: 1;

        .dot { height: 13px; width: 13px; }
        .dot:hover { background-color: #FBA00B; }
        // .dot:focus { outline: -webkit-focus-ring-color auto 1px; }
        .dot:focus { outline: none; }
        .dot.selected { background-color: #FBA00B; }
    }

    // .carousel .control-arrow {
    //     display: none;
    // }
}

.GBSlider.no-dots .control-dots { display: none; }

.grid .CB9vby5YRbJWpv7hRrZ0x {
    padding-bottom: 30px;
    @media ( max-width: 1024px ) { padding-bottom: 10px; }
}

.list-title { padding-top: 0; }

.list-item:first-child .list-title {
    padding-top: 50px;
    @media ( max-width: 767px ) { padding-top: 0; }
}

.movie_popup {
    color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;

    .movie_popup_mask {
        background-color: #000000b0;
        height: 100%;
        width: 100%;
    }
}

.movie_popup_info {
    height: 100vh;
    margin: auto;
    padding: 50px 0;
    position: relative;
    width: 960px;
    z-index: 1;

    &.movie_page { position: fixed; top: 0; left: 50%; transform: translate( -50% ); }

    @media ( max-width: 1200px ) { width: 800px; }
    @media ( max-width: 1024px ) { width: 80vw; }

    .movie_popup_close {
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 10;
    }

    .movie_popup_left {
        align-items: flex-end;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        left: -150px;
        position: absolute;
        top: 0;
        width: 150px;
        z-index: 2;

        .movie_popup_previous {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            height: 100px;
            padding: 13px;
            // transition: transform 0.5s ease;
            width: 100px;

            .movie_icon {
                // filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
                filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                height: 50px;
                @media ( max-width: 1024px ) { height: 30px; }
            }

            &:hover {
                cursor: pointer;
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                // transform: scale(1.1);
            }
        }
    }

    .movie_popup_right {
        align-items: flex-start;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        right: -150px;
        position: absolute;
        top: 0;
        width: 150px;
        z-index: 2;

        .movie_popup_next {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            height: 100px;
            padding: 13px;
            width: 100px;

            .movie_icon {
                // filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
                filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                height: 50px;
                transform: rotate(180deg);
                @media ( max-width: 1024px ) { height: 30px; }
            }

            &:hover {
                cursor: pointer;
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
            }
        }
    }

    .movie_popup_down {
        align-items: center;
        display: flex;
        height: 50px;
        flex-direction: column;
        justify-content: center;
        opacity: 0.5;
        position: absolute;
        bottom: 30px;
        width: 100%;
        z-index: 20;

        .movie_popup_scroll1 {
            .movie_icon {
                height: 20px;
                filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
                transform: rotate(270deg);
            }    
        }

        .movie_popup_scroll2 {
            margin-top: -13px;

            .movie_icon {
                height: 30px;
                filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
                transform: rotate(270deg);
            }    
        }
    }
    
    .close-button {
        border: 0px;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        position: absolute;
        padding: 24px;
        right: 0;
        top: 0;
        z-index: 9;
        background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background-position: 50% -50%;	

        &:hover {
            color: #FBA00B;
            cursor: pointer;
        }
    }
}

.movie_info {
    margin: -100px auto 0 auto;
    width: 960px;

    @media ( max-width: 1200px ) { width: 800px; }
    @media ( max-width: 1024px ) { width: 80vw; }
}

.movie_popup_content {
    align-items: center;
    background-color: #121212;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    // height: 80vh;
    height: 100%;
    overflow: scroll;
    padding-bottom: 23px;
    position: relative;
    text-align: left;
    width: 100%;
    z-index: 9;

    &::-webkit-scrollbar { display: none; }
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.serie_popup_content {
        .movie_popup_img_box {
            position: fixed;
            z-index: 0;

            .popup_img_play_btn { display: none; }
        }

        .movie_popup_img_title {
            margin-top: 150px;
            position: relative;
            z-index: 1;
        }

        .movie_btns {
            position: relative;
            z-index: 1;

            .movie_btn_box .icon_box {
                background-color: #111111CC;

                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#11111199; cursor: pointer;
                    } 
                }
            }
        }

        .episode-serie { padding-top: 26px; }
    }

    .movie_popup_img_box {
        position: relative;
        z-index: 9;

        .popup_img_play_btn {
            align-items: center;
            background-color: #000000;
            border-radius: 50%;
            display: flex;
            height: 60px;
            justify-content: center;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate( -50%, -50% );
            width: 60px;
            transition: opacity 0.5s ease;
            z-index: 10;
        }

        &:hover {
            cursor: pointer;
            .popup_img_play_btn { opacity: 0.65; }
        }
    }

    .movie_popup_img {
        height: 365px;
        width: 960px;
        object-fit: cover;

        @media ( max-width: 1200px ) { width: 800px; height: 304px; }
        @media ( max-width: 1024px ) { width: 100vw; height: 47.4vw; }
    }
    
    .movie_popup_img_title
    {
        align-self: flex-start;
        left: 0px;
        // padding: 26px 26px 10px;
        max-height: 130px;
        max-width: 460px;
        padding: 26px 26px;

        @media ( max-width: 767px ) { padding: 18px 18px; max-width: 100%; }
        @media ( max-width: 1024px ) { align-self: center; }
    }

    h2 {
        font-size: 32px;
        padding: 26px 0 26px 26px;
        width: 100%;

        @media ( max-width: 1024px ) { font-size: 1.8rem; }
    }

    .movie_popup_categories {
        align-self: flex-start;
        padding: 10px 26px;
        position: relative;
        width: 100%;
        z-index: 10;

        @media ( max-width: 1024px ) { box-shadow: none; padding-top: 15px; }
        @media ( min-width: 1025px ) { .catSpanLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
        
        .catSpan {
            background-color: #1f1f1f;
            border-radius: 20px;
            color: #aeaeae;
            display: inline-block;
            height: 25px;
            margin-bottom: 7px;
            margin-right: 13px;
            padding: 0 13px;

            &.catSpan-mobile { display: none; }
            &.catSpan-web { display: inline-block; }

            @media ( max-width: 767px ) {
                &.catSpan-mobile { display: inline-block; }
                &.catSpan-web { display: none; }
            }
        }
    }

    .movie_popup_synopsis {
        align-self: flex-start;
        padding: 10px 26px;
    }

    .movie_btns {
        align-items: center;
        align-self: flex-start;
        bottom: 0;
        color: #aeaeae;
        display: flex;
        padding-left: 26px;
        width: 100%;

        @media ( max-width: 1024px ) {
            flex-direction: column;
            padding-left: 0;

            & > * { margin: 13px 0; }
        }

        .icons_box { 
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .movie_btn_box {
            align-items: center;
            display: flex;
            color: #FBA00B;
            flex-direction: column;
            justify-content: center;
            margin-right: 13px;
            
            .icon_box {
                background-color: #FFFFFF1A;
                border-radius: 50%;
                height: 55px;
                width: 55px;

                img.movie_icon { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }

                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#FFFFFF4A; cursor: pointer;
                    } 
                }
                .icon_revert { transform: rotate( 180deg ); }
            }
        }

        i { font-size: 30px; }

        .btn-movie {
            background-color:#FBA00B;
            border: none;
            border-radius: 3px;
            color: #FFFFFF;
            display: inline-block;
            font-size: 20px;
            font-weight: 100;
            height: 56px;
            letter-spacing: 2px;
            margin: 0 8px 8px 0;
            padding: 7px;
            width: 200px;

            @media ( max-width: 1024px ) { width: 300px; margin: 0 0 18px 0; }
            @media ( max-width: 767px ) { width: 70vw; margin: 0 0 18px 0; }
    
            i { font-size: 18px; margin-right: 13px; }
            &:hover { background-color: #FBA00B; cursor: pointer; opacity: 0.89; }
        }

        .btn-trailer {
            background-color: #2C2C2C;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }

        .btn-bonus {
            background-color: #2C2C2C;
            color: #fba00b;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }
    }

    img.movie_icon {
        filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
        // filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        height: 30px;
        width: 30px;
        @media ( max-width: 767px ) { height: 20px; }

        &.selected {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;

        .bubble {
            // background-color: #FFF;
            background-color: #2a2a2a;
            border-radius: 3px;
            // color: #222;
            color: #fff;
            display: none;
            left: -7px;
            padding: 7px 12px;
            position: absolute;
            top: -60px;
            white-space: nowrap;
            z-index: 2;
        }

        // .bubble-bottom:before {
        //     content: "";
        //     border-left: 10px solid transparent;
        //     border-right: 10px solid transparent;
        //     border-top: 20px solid#FFF;
        //     bottom: -20px;
        //     height: 0px; 
        //     left: 25px;
        //     position: absolute;
        //     width: 0px; 
        // }

        &:hover .bubble {
            display: block;
            @media ( max-width: 1024px ) { display: none; }
        } 
    }
}

.movie_info_content {
    align-items: center;
    background-color: #121212;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    // height: 80vh;
    height: 100%;
    margin-top: -150px;
    overflow: scroll;
    position: relative;
    width: 100%;
    z-index: 1;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar { display: none; }

    .movie_info_img_box {
        position: relative;
        z-index: 1;
    }

    .movie_info_img { width: 100%; }

    h2 {
        font-size: 32px;
        padding: 50px 50px 20px 50px;

        @media ( max-width: 1024px ) { font-size: 1.8rem; }
    }

    .movie_info_categories {
        align-self: flex-start;
        padding: 26px;
        text-align: left;

        @media ( min-width: 1025px ) { .catSpanLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
        
        .catSpan {
            border: 1px solid #aeaeae;
            border-radius: 20px;
            color: #aeaeae;
            display: inline-block;
            height: 25px;
            margin: 0 7px;
            padding: 0 13px;

            &.catSpan-mobile { display: none; }
            &.catSpan-web { display: inline-block; }

            @media ( max-width: 767px ) {
                &.catSpan-mobile { display: inline-block; }
                &.catSpan-web { display: none; }
            }
        }
    }

    .movie_info_synopsis {
        align-self: flex-start;
        padding: 26px;
        text-align: left;
    }

    .movie_btns {
        align-items: center;
        align-self: flex-start;
        bottom: 0;
        color: #aeaeae;
        display: flex;
        padding: 26px;
        width: 100%;

        @media ( max-width: 1024px ) {
            flex-direction: column;
            padding-left: 0;

            & > * { margin: 13px 0; }
        }

        .icons_box { 
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .movie_btn_box {
            align-items: center;
            display: flex;
            color: #FBA00B;
            flex-direction: column;
            justify-content: center;
            margin-right: 13px;
            
            .icon_box {
                background-color: #FFFFFF1A;
                border-radius: 50%;
                height: 55px;
                width: 55px;

                img.movie_icon { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }

                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#FFFFFF4A; cursor: pointer;
                    } 
                }
                
                .icon_revert { transform: rotate( 180deg ); }
            }
        }

        i { font-size: 30px; }

        .btn-movie {
            background-color:#FBA00B;
            border: none;
            border-radius: 3px;
            color: #FFFFFF;
            display: inline-block;
            font-size: 20px;
            font-weight: 100;
            height: 56px;
            letter-spacing: 2px;
            margin: 0 8px 8px 0;
            padding: 7px;
            width: 200px;

            @media ( max-width: 1024px ) { width: 300px; margin: 0 0 18px 0; }
            @media ( max-width: 500px ) { width: 80vw; margin: 0 0 18px 0; }
    
            i { font-size: 18px; margin-right: 13px; }
            &:hover { background-color: #FBA00B; cursor: pointer; opacity: 0.89; }
        }

        .btn-trailer {
            background-color: #2C2C2C;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }

        .btn-bonus {
            background-color: #2C2C2C;
            color: #fba00b;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }
    }

    img.movie_icon {
        filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        height: 30px;
        width: 30px;
        @media ( max-width: 767px ) { height: 20px; }

        &.selected {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}

.movie_info_back {
    left: 50px;
    position: absolute;
    top: -100px;
    z-index: 13;

    @media ( max-width: 1200px ) { left: 0;  top: -50px; }
    @media ( max-width: 1024px ) { left: 0; top: -50px; }

    img.movie_icon {
        // filter: invert( 100% ) brightness(160%);
        filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        height: 50px;

        &:hover {
            color: #FBA00B;
            cursor: pointer;
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }
}

.movie_progress {
    background-color: #ddd;
    border-radius: 5px;
    bottom: 0;
    height: 5px;
    margin: -4px 0;
    max-width: 420px;
    position: absolute;
    transform: translateX(10%);
    width: 80%;
    z-index: 2;

    &.in-figure { bottom: 13px; }
    
    .progress_bar {
        background-color: #fba00b;
        border-radius: 5px;
        height: 100%;
    }
}

.movie_progress_play {
    align-items: center;
    background-color: rgba(0,0,0,.4);
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 24px;
    height: 72px;
    justify-content: center;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .5s;
    width: 72px;
    z-index: 3;
    
    &:hover{
        opacity: 0.7;
        cursor: pointer;
    }

    i { margin-left: 3px; }
}

.movie_popup_soon_offline {
    align-self: flex-start;
    color: #fba00b;
    padding: 10px 26px;
}

.movie_popup_progress_box {
    align-self: flex-start;
    display: flex;
    width: 100%;

    .movie_popup_progress {
        background-color: #ddd;
        border-radius: 5px;
        height: 7px;
        margin: -13px 0 23px 26px;
        flex-basis: 30%;

        @media ( max-width: 767px ) { flex-basis: 40%; }
    
        .progress_bar {
            background-color: #fba00b;
            border-radius: 5px;
            height: 100%;
        }
    }

    .progress_bar_time {
        margin: -23px 0 23px 23px;
        @media ( max-width: 767px ) { margin-left: 13px; font-size: 0.8em; }
    }
}

.list-title {
    &.blur-1 { filter: blur(13px); pointer-events: none; }
    &.blur-2 { filter: blur(7px); pointer-events: none; }
    &.blur-3 { filter: blur(3px); pointer-events: none; }
    
    @media ( max-width: 1024px ) {
        margin-bottom: 0 !important;
        h3 { font-size: 1.1rem; }
        .list-infoIcon { height: 19px !important; margin-top: -3px !important; }
    } 
}

.movie_mozaic_name_box {
    display: flex;
    flex-direction: row;
    padding: 0 13px;
    width: 21vw;

    .movie_mozaic_name {
        overflow: hidden;
        white-space: nowrap;
    }
    .f-grow-1 { flex-grow: 1; }

    @media ( max-width: 1024px ) { margin-left: 2vw; width: 25vw; padding: 0 3px; }
    @media ( max-width: 767px ) { width: 39vw; }
}

.movie_mozaic {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5vh;
    margin-left: 2vw;

    .mozaic_figure_box { margin-bottom: 26px; position: relative; }
    
    @media ( max-width: 1024px ) { margin-left: 2vw !important; }

    // @media ( max-width: 1024px ) {  margin-left: 0vw !important; }
    figure {
        cursor: pointer;
        height: 12vw !important;
        position: relative;
        text-align: center;
        width: 21vw !important;

        @media ( max-width: 1024px ) { height: 14vw !important; width: 25vw !important;  }
        @media ( max-width: 767px ) { height: 22vw !important; width: 39vw !important; }

        img {
            display: block;
            min-height: 100%;
            max-width: 100%;
            position: relative;
        }

        figcaption {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            color: #fff;
            height: 100%;
            font-size: 1.25em;
            left: 0;
            position: absolute;
            text-transform: uppercase;
            top: 0;
            width: 100%;
        }

        h1 {
            position: relative;
            top: 50%;
        }
    }

    .movie_progress { max-width: 80%; }
}

.search-top {
    height: 100px;
    width: 100%;

    @media ( min-width: 1025px ) { height: 180px; }
}

.goTo_box {
    cursor: pointer;
    transition: all .3s;

    // &:hover {
    //     transform: translate( 3px, -3px );
    //     h3 { color: #fba00b; }
    // }

    &:hover .list-title .list-infoText-box {
        width: 400px;
    }

    // &:hover .list-infoIcon {
    //     filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
    // }
}

#result-container1.catAcc_result {
    margin-top: 160px !important;
}

#result-container1.catAcc_noResult {
    margin-top: -180px !important;
    padding: 400px 50px;

    @media ( max-width: 1024px ) { padding: 500px 13px; }
}

.list-title {
    .list-infoText-box {
        display: inline-block;
        font-size: 0.6em;
        margin: 0 13px;
        overflow: hidden;
        transition: width 1s ease-in-out;
        vertical-align: middle;
        white-space: nowrap;
        width: 0;
        max-width: fit-content;
    }

    .list-infoIcon {
        border-radius: 5px;
        filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
        height: 26px;
        margin-top: -3px;
        transform: rotate(180deg);
    }
}

.default-favorite {
    align-items: center;
    color: #aeaeae;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 0;
    text-align: center;
    width: 90vw;

    h3 { 
        font-size: 4rem;
        @media ( max-width: 767px ) { font-size: 2rem; }
    }
    
    .default-favorite-icon {
        background-color: #FFFFFF1C;
        border-radius: 50%; 
        padding: 40px;
        margin: 40px;
        img { height: 50px; width: 50px; }
    }
    .default-favorite-info {
        font-size: 2rem;
        @media ( max-width: 767px ) { font-size: 1.2rem; }
    }
}

.movie_additional_info {
    align-self: flex-start;
    // padding: 10px 0 0 26px;
    padding: 10px 26px;

    img.movie_icon {
        margin-left: 13px;
        margin-top: -4px;
        height: 20px;
        width: auto;
    }
}

.badge-new {
    background-color: #fba00b;
    // border: 2px solid black;
    border-radius: 10px 40px 40px 10px;
    color: #fff;
    display: inline;
    float: left;
    font-size: 55%;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    padding: 2px 10px;
    position: relative;
    text-align: left;
    text-transform: none;
    z-index: 2;

    @media ( max-width: 767px ) { font-size: 30%; padding: 1px 10px; }
}

.badge-recurrence {
    color: black;
    // border: 2px solid black;
    font-size: 55%;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    background-color: #fff;
    border-radius: 10px 40px 40px 10px;
    padding: 2px 17px;
    float: left;
    position: relative;
    left: -10px;
    display: inline;
    text-align: left;
    text-transform: none;
    z-index: 1;

    @media ( max-width: 767px ) {  font-size: 30%; padding: 1px 10px; }
    
}

.badge-soon-offline {
    background-color: #E33939;
    // border: 2px solid black;
    border-radius: 10px 40px 40px 10px;
    color: #fff;
    display: inline;
    float: left;
    font-size: 55%;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    padding: 2px 10px;
    position: relative;
    text-align: left;
    text-transform: none;
    z-index: 2;

    @media ( max-width: 767px ) { font-size: 30%; padding: 1px 10px; }

    i { color: #fff; margin-right: 13px; }
}

.badge-outOfStock {
    background-color: #E33939;
    color: #fff;
    display: inline;
    float: left;
    font-size: 55%;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    padding: 2px 10px;
    position: relative;
    text-align: center;
    text-transform: none;
    width: 100%;
    z-index: 2;

    @media ( max-width: 767px ) { font-size: 30%; padding: 1px 10px; }

    i { color: #fff; margin-right: 13px; }
}

.containerBadges div:only-child{ left: 0; }


.title-distribution {
    align-self: flex-start;
    padding-left: 26px;
    margin-top: 10px;
}

.distribution-container {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    align-self: flex-start;
    padding: 10px;
    position: relative;
    width: 100%;
    touch-action: none;

    & > div
    {
        width: 100%;
    }

    .distribution-box {
        margin: 10px 0 0;
    }

    .distribution-interraction
    {
        cursor: pointer;
        &:hover .movie_distribution_img
        {
            transition: all 0.1s;
            @media ( min-width: 1024px ) {
                outline: 3px solid #fba00b;
            }
        }
    }

    .movie_distribution_img {

        border-radius: 50%;
        transition: all 0.1s;
    }

    .distribution-thumbnails {
        text-align: center;
        padding-top: 10px;
        width: 100%;
        &:nth-child(3) {
            color: #aeaeae;
            padding: 0;
        }
    }
}

.fishing-page {
    .GBSlider {
        &:hover .slider-wrapper.axis-horizontal { border: 5px inset #149DCC; }

        .carousel .control-dots {
            .dot:hover { background-color: #149DCC; }
            .dot.selected { background-color: #149DCC; }
        }
    }

    .category-box:hover .category-img {
        @media ( min-width: 1024px ) { border: 3px solid #149DCC; }
    }
    .category-img.selected { border: 2px solid #149DCC; }

    figure.effect-thumbnail-movie:hover { border: 3px solid #149DCC; }
}

figure.effect-thumbnail-movie {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .figure-details {
        align-items: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 100%;
        text-transform: initial;
        z-index: 1;

        &.with-overlay { background: linear-gradient(#00000000, #000000d9); }
    }

    .figure-img-title { min-height: 0px; max-width: 60%; margin-top: 20%; }

    .figure-img-text {
        align-items: center;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .figure-title {
        color: #fff;
        font-size: 2rem;
        font-weight: 700;
        @media ( max-width: 500px ) { font-size: 1rem; }
    }

    .figure-badgeTop {
        background-color: #fff;
        border-bottom-right-radius: 3px;
        color: #000;
        font-size: .8rem;
        left: 0;
        padding: 1px;
        position: absolute;
        top: 0;
        min-width: 120px
    }

    .figure-badgeFree {
        background-color: #fba00b;
        border-bottom-left-radius: 3px;
        font-size: 1rem;
        padding: 1px;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 120px
    }

    .figure-badgeCenter {
        background-color: #7c7c7c;
        border-radius: 3px;
        color: #fff;
        font-size: 1.3rem;
        padding: 1px;
        min-width: 120px;
    }
    .figure-info { color: #fff; font-size: 1.3rem; padding: 3px; }
    .figure-icon { min-height: 0; display: inline-block; margin-bottom: 4px; margin-right: 8px; width: 24px; }
    .figure-price {
        color: #fff;
        font-size: 2rem;
        font-weight: 700;
        padding: 3px;

        .figure-price-rent-info {
            background-color: #7c7c7c;
            border-radius: 10px;
            display: inline-block;
            font-size: 1rem;
            margin-left: 7px;
            padding: 1px 7px;
            position: relative;
            top: -15px;
        }
    }
}


.episodes-container {
    display: flex;
    width: 900px;
    width: 95%;
    &>div button:nth-child(1){left: 0;}
    &>div ._1-Hp0TfFuBlCxtdPUf_zEF {right: 0;}
    
    &>* { width: 100%;}
    .episode-box {
        margin: 7px;
        cursor: pointer;
        // &:hover .movie_episode_img { outline: solid 2px #fba00b; }
        .movie_progress_play { left: 47%; top: 47% }

        .movie_episode_img {
            border: 3px solid transparent;
            transition: transform .5s;
            &:hover {
                border: 3px solid #FBA00B;
                transform: translate( -3px, -3px );
                & + .movie_progress_play { opacity: 0.7; }
            }

            @media ( max-width: 1024px ) {
                &:hover {
                    border: none;
                    transform: none;
                }
            }
        }
        
        .movie_progress {
            position: unset;
            transform: translateX(3%);
            width: 95%;
            margin: 3px 0 0 0;
        }

        .movie_progress_no {
            position: unset;
            transform: translateX(3%);
            width: 95%;
            height: 7px;
        }

        .movie_episode_img { border-radius: 10px; cursor: pointer; }
        .episodes-thumbnails{
            padding-top: 7px;

            .number-episode{ font-size: 17px; }

            .title-episode{ opacity: 0.8; font-size: 16px; }

            .synopsis-episode{
                font-size: 15px;
                color: rgb(196, 196, 196);
                font-weight: 90;
            }
        }
    }
}

.episodes-list {
    align-items: flex-start;
    background-color: transparent;
    color: #c1c1c1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    overflow-x: scroll;
    padding: 7px 13px 0 13px;
    -ms-overflow-style: none;    
}

.episode-serie { align-self: flex-start; padding: 26px 0 0 26px }

// index
/* MQ */
@include maxMediaQueries(750px) {
  .list-title {
    padding-left: 20px;
  }
  .left-controls {
    height: 180px;
    width: 10%;
  }
  .right-controls {
    height: 180px;
    width: 10%;
  }
  #content {
    padding: 30px 0 30px 0;
  }
}

.GBListItem {
    &.row-blue {
        .list-title { color: #eee; padding-top: 13px; }
        .list-infoIcon { filter: brightness(222%); }
        &.row-fill { background: linear-gradient(to bottom, #6699bb 95%, transparent 95%); }
        &.row-halo { 
            background: linear-gradient(to bottom, transparent 20%,#6699bb 50%, #6699bb 60%, transparent 90%);
            @media ( max-width: 767px ) { background: linear-gradient(to bottom, transparent,#6699bb 50%, #6699bb 70%, transparent); }
        }
        &.row-gradient { background: linear-gradient(to bottom, #6699bb, #6699bb44 95%, transparent 95%); }

        figure.effect-thumbnail-movie:hover { border: 3px solid #149DCC; }
    }

    &.row-orange {
        .list-title { color: #eee; padding-top: 13px; }
        .list-infoIcon { filter: brightness(222%); }
        &.row-fill { background: linear-gradient(to bottom, #cc8800 95%, transparent 95%); }
        &.row-halo {
            background: linear-gradient(to bottom, transparent 20%,#cc8800 50%, #cc8800 60%, transparent 90%);
            @media ( max-width: 767px ) { background: linear-gradient(to bottom, transparent, #cc8800 50%, #cc8800 70%, transparent); }
        }
        &.row-gradient { background: linear-gradient(to bottom, #cc8800, #cc880044 95%, transparent 95%); }

        figure.effect-thumbnail-movie:hover { border: 3px solid #fba00b; }
    }

    &.row-purple {
        .list-title { color: #eee; padding-top: 13px; }
        .list-infoIcon { filter: brightness(222%); }
        &.row-fill { background: linear-gradient(to bottom, #702261 95%, transparent 95%); }
        &.row-halo {
            background: linear-gradient(to bottom, transparent 20%,#702261 50%, #702261 60%, transparent 90%);
            @media ( max-width: 767px ) { background: linear-gradient(to bottom, transparent,#702261 50%, #702261 70%, transparent); }
        }
        &.row-gradient { background: linear-gradient(to bottom, #702261, #69165944 95%, transparent 95%); }

        figure.effect-thumbnail-movie:hover { border: 3px solid #886688; }
    }

    &.row-image {
        background-size: cover;
        margin: 30px 0 60px 0;
        padding-top: 30px;
        .list-title {
            color: #eee;
            h3 {font-size: 72px !important;}
            margin-bottom: 120px;
            padding-top: 13px;
            text-shadow: 1px 0 0 #111, 1px 1px 0 #111, 0 1px 0 #111, -1px 1px 0 #111, -1px 0 0 #111, -1px -1px 0 #111, 0 -1px 0 #111, 1px -1px 0 #111111;
        }
        .list-infoIcon { filter: brightness(222%); }

        .for-image { background: linear-gradient(to bottom, #11111144, #11111144 90%, transparent 80%); }
    }
}

.shine-container { display: flex; flex-direction: column; }

.moving-image-box {
    .mainFileName { opacity: 1; transition: opacity 1s; }
    .gallery1FileName { margin-top: -100%; opacity: 0; transition: opacity 1s; }

    @media ( min-width: 1025px ) {
        &:hover {
            .mainFileName { opacity: 0; }
            .gallery1FileName { opacity: 1; }
        }
    }
}

.iframe-container {
    .GBListItem { top: 0; }
    .iframe-logo {
        margin: 26px 0; text-align: center; width: 100%;
        height: 50px;
    }
} 

.iframe_popup {
    color: #fff;
    height: 360px;
    left: 0;
    position: fixed;
    top: 0;
    width: 360px;
    z-index: 8;

    .iframe_popup_info {
        background-color: #111;
        height: 360px;
        margin: auto;
        padding: 0;
        position: relative;
        width: 360px;
        z-index: 1;

        .iframe-logo {
            padding-top: 26px;
            margin: 0;
        }
    
        .iframe_popup_close {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }
    
        .close-button {
            border: 0px;
            border-radius: 10px;
            color: #fff;
            font-size: 20px;
            position: absolute;
            padding: 7px;
            right: 0;
            top: 0;
            z-index: 9;
            background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
            background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
            background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
            background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
            background-position: 50% -50%;	
    
            &:hover {
                color: #FBA00B;
                cursor: pointer;
            }
        }

        .iframe_popup_form {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 42px;
            width: 100%;

            .iframe_popup_input {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 7px 0;
            }

            input {
                background: transparent;
                border: 1px solid #fff;
                border-radius: 5px;
                color: #fff;
                font-size: 20px;
                font-weight: 100;
                margin: 0 auto;
                padding: 7px;
                width: 300px;

                &::placeholder { font-size: 14px; color: #fff; }
            }

            .iframe_popup_error {
                color: #E33939;
                font-weight: 700;
                height: 30px;
            }

            .iframe_popup_valid_button {
                background-color: #fba00b;
                border-radius: 5px;
                font-size: 18px;
                margin: 7px auto;
                padding: 7px;
                width: 300px;

                &:hover { cursor: pointer; }
            }
        }
    }
}

.video-iframe-container-sound {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate( -50%, -50% );
    z-index: 1;

    i { filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%); font-size: 150px; }
}

#iframe_loader {
    align-items: center;
    display: none;
    height: 360px;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 360px;
    z-index: 2;

    &.show { display: flex; }
}

