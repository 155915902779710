.f-grow-1 { flex-grow: 1; }

.popup-payment-progress-nav {
    align-items: center;
    display: flex;
    margin: 26px;
    color: #fff;
    width: 100%;

    .popup-payment-progress-nav-item {
        border-bottom: 2px solid #aeaeae;
        flex: 1 1 0;
        font-size: 16px;
        padding: 13px 26px; 
        text-align: center;

        &.selected { border-bottom: 2px solid #fba00b; font-weight: 700; }

        @media ( max-width: 767px ) {
            display: none;
            &.selected { display: block; }
        }
    }
}

.popup-payment-details {
    border: 1px solid #aeaeae;
    border-radius: 5px;
    display: flex;
    padding: 7px 0;
    position: relative;
    margin: 26px 0;
    width: 80%;
    z-index: 1;

    &.selected {
        border: 1px solid #fba00b;

        .popup-payment-details-radio-box .popup-payment-details-radio-dot {
            background-color: #fba00b; 
            border: 2px solid #121212;
            box-shadow: 0 0 0 1px #fba00b;
        }
    }

    &.selectable { cursor: pointer; }

    .popup-payment-details-bandage {
        align-items: center;
        background-color: #fba00b;
        border-radius: 5px;
        display: flex;
        left: 0;
        justify-content: center;
        min-width: 220px;
        padding: 7px 13px;
        position: absolute;
        top: 0;
        transform: rotate( -30deg ) translate( -25%, -60% );
        z-index: 1;

        .popup-payment-details-bandage-priceOverlineStg {
            color: #111;
            display: inline-block;
            font-size: 16px;
            margin-right: 13px;
            opacity: 0.6;
            text-decoration: line-through;
            vertical-align: middle;
        }

        .popup-payment-details-bandage-priceStg {
            color: #111;
            font-size: 20px;
            font-weight: 700;
        }
    }

    .popup-payment-details-radio-box {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 72px;
        
        .popup-payment-details-radio-dot {
            border: 2px solid #fba00b;
            border-radius: 50%;
            display: inline-block;
            height: 16px;
            width: 16px;
        }
    }

    .popup-payment-details-item-img-box img { border-radius: 5px; }

    .popup-payment-details-content {
        padding-right: 13px;
        width: 100%;
       
        .popup-payment-details-item {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            padding: 7px 0;

            .popup-payment-details-item-title-box {
                padding: 0 7px;

                .popup-payment-details-item-title { font-size: 16px; font-weight: 700; }

                .popup-payment-details-item-linkAddon {
                    align-items: center;
                    display: flex;
                    font-size: 12px;
                    justify-content: flex-start;
        
                    .btn-addon {
                        background-color: #7c7c7c;
                        border-radius: 3px;
                        color: #fff;
                        padding: 1px;
                        margin: 0 7px;
                        text-align: center;
                        width: 80px;
        
                        &.linked { cursor: pointer; }
                    }
                }
            }

            .popup-payment-details-item-price-box {
                text-align: end;

                .popup-payment-details-item-priceStg { font-size: 20px; font-weight: 700; }
                .popup-payment-details-item-priceOverlineStg { color: #aeaeae; display: inline-block; font-size: 16px; margin-right: 13px; text-decoration: line-through; vertical-align: middle; }
                .popup-payment-details-item-details { color: #aeaeae; font-size: 10px; }
            }
        }
    }
}

.popup-payment-details-item-details { color: #aeaeae; font-size: 10px; }

.popup-payment-recap {
    background-color: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    padding: 7px 0;
    margin: 26px 0;
    width: 80%;

    .popup-payment-recap-info-box {
        align-items: center;
        border-bottom: 1px solid #aeaeae;
        display: flex;
        padding: 7px;
        margin: 0 13px;

        .popup-payment-recap-info {
            text-align: left;

            .popup-payment-recap-title { font-size: 20px; font-weight: 700; }
            .popup-payment-recap-details { color: #aeaeae; font-style: 10px; }
        }

        .popup-payment-recap-priceStg { font-size: 20px; font-weight: 700; }
        .popup-payment-recap-priceOverlineStg  { color: #aeaeae; display: inline-block; font-size: 16px; margin-right: 13px; text-decoration: line-through; vertical-align: middle; }
    }

    .popup-payment-recap-total-box {
        padding: 7px;
        margin: 7px 13px;

        .popup-payment-recap-total-code-box {
            align-items: center;
            display: flex;

            .popup-payment-recap-total-code-label { color: #aeaeae; font-style: 12px; }
            .popup-payment-recap-total-code-badge { background-color: #aeaeae; border-radius: 5px; color: #fff; font-size: 11px; font-weight: 700;  padding: 0 7px; }
        }

        .popup-payment-recap-total-price-box {
            align-items: center;
            display: flex;
            margin-top: 7px;

            .popup-payment-recap-total-price-label { font-size: 20px; font-weight: 700; }
            .popup-payment-recap-total-price-priceStg { font-size: 24px; font-weight: 700; }
            .popup-payment-recap-total-price-priceOverlineStg { color: #aeaeae; display: inline-block; font-size: 16px; margin-right: 13px; text-decoration: line-through; vertical-align: middle; }
        }
    }
    
    .popup-payment-code-box {
        background-color: #121212;
        border: 1px solid #121212;
        border-radius: 5px;   
        padding: 13px 0;
        margin: 26px 13px;

        .popup-payment-code-label { color: #aeaeae; font-style: 10px; margin-bottom: 3px; margin-left: 26px; text-align: start; }

        .popup-payment-code-form {
            align-items: center;
            display: flex;
            justify-content: center;

            input {
                background: transparent;
                border: 1px solid #fff;
                border-radius: 5px;
                color: #fff;
                font-size: 20px;
                font-weight: 100;
                margin-right: 13px;
                padding: 3px 7px;
                text-transform: uppercase;
                width: 60%;
    
                &::placeholder { font-size: 14px; color: #fff; }
            }

            .popup-payment-code-form-valid {
                background: #3b3b3b;
                border: 1px solid #3b3b3b;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                font-size: 20px;
                font-weight: 100;
                padding: 3px 7px;
                width: 30%;
    
                &:hover { background: #404040; }
            }

            .popup-payment-code-form-remove {
                background: #626262;
                border: 1px solid #626262;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                font-size: 20px;
                font-weight: 100;
                padding: 3px 7px;
                width: 30%;
    
                &:hover { background: #6c6c6c; }
            }
        }

        .popup-payment-code-form-results {
            font-weight: 700;
            min-height: 30px;
            padding-top: 7px;
            
            .popup-payment-code-form-error { color: #E33939; }
            .popup-payment-code-form-success { color: #54C254; }
        }
    }

    .popup-payment-valid-box {
        padding: 13px 0;
        margin: 26px 13px;

        .popup-payment-valid {
            background-color: #fba00b;
            border-radius: 5px;
            cursor: pointer;
            font-size: 18px;
            padding: 13px;
            width: 100%;
        }
    }    
}

.popup-payment-success-box {
    margin: 0 20%;
    width: 100%;
    
    .popup-payment-success-image {
        background-color: #FFFFFFDE;
        height: 150px;
        border-radius: 50%;
        margin: 52px auto;
        padding: 20px;
        width: 150px;

        img { filter: brightness(.1); height: 100%; width: 100%; }
    }

    .popup-payment-success-title { font-size: 32px; font-weight: 700; margin: 26px 0; }

    .popup-payment-success-thumbnail {
        margin: 0 auto;
        max-width: 400px;
        @media ( max-width: 767px ) { max-width: 80vw; }

        img { border-radius: 5px; width: 100%; }
    }

    .popup-payment-success-text { font-size: 16px; margin: 26px 0; }

    .popup-payment-valid-box {
        margin: 0 auto;
        max-width: 400px;
        @media ( max-width: 767px ) { max-width: 80vw; }

        .popup-payment-valid {
            background-color: #fba00b;
            border-radius: 5px;
            cursor: pointer;
            font-size: 18px;
            margin: 7px;
            padding: 13px;
            width: 100%;
        }

        .popup-payment-cancel {
            border: 1px solid #aeaeae;
            border-radius: 5px;
            color: #aeaeae;
            cursor: pointer;
            font-size: 18px;
            margin: 7px;
            padding: 13px;
            width: 100%;
        }
    }  
}