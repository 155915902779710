figure {
    position: relative;
    z-index: 1;

    .shine-effect {
        &.gold-effect { background-image: linear-gradient(45deg, transparent 40%, #FFD70077 50%, transparent 60%); animation: shine-animation 5s infinite linear; }
        &.sliver-effect { background-image: linear-gradient(45deg, transparent 40%, #C0C0C077 50%, transparent 60%); animation: shine-animation 6s infinite linear; }
        &.copper-effect { background-image: linear-gradient(45deg, transparent 40%, #B8733377 50%, transparent 60%); animation: shine-animation 7s infinite linear; }
       
        background-size: 800%;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    
    @keyframes shine-animation {
        0% { background-position: 50%; } 40%, 80% { background-position: 0%; }
    }
}