.categories-list-box {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    margin-top: 40px;
    padding-top: 28px;
    padding-left: 50px;
    position: relative;
    width: 100%;
    z-index: 2;

    &.store-list {
        padding-left: 0;
        margin-top: 26px;

        .arrow-left { left: 13px; }
    }

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media ( max-width: 1024px ) {
    .categories-list-box { padding: 26px 13px 0 13px; }
    .soon-container ~ .categories-list-box { margin-top: 0; }
}

.categories-list-box + #home_Homelist .GBListItem { top: 0; }
.categories-list-box + #chasse_Homelist .GBListItem { top: 0; }
.categories-list-box + #peche_Homelist .GBListItem { top: 0; }

.categories-list {
    align-items: flex-start;
    background-color: transparent;
    color: #c1c1c1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    overflow-x: scroll;
    padding: 7px 13px 0 13px;
    -ms-overflow-style: none;
}

.categories-list::-webkit-scrollbar { height: 0 !important; width: 0 !important; }

.category-box {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 13px;
}

.category-box:hover .category-item .category-img {
    // transform: none; 
    @media ( min-width: 1024px ) {
        border: 3px solid #fba00b;
        transform: scale(1.08);
    }
}


a.category-box {
    color: #eee;
}

.category-item {
    position: relative;
    z-index: 1;

    &.store-category {
        .category-img { border: 3px solid #fba00b22; }
        .category-name { font-size: 16px; }
    }
}

.category-img {
    border-radius: 50%; 
    height: 120px;
    object-fit: cover;
    transition: transform .4s, border 0.1s;
    width: 120px; 
       
        @media ( max-width: 425px ) { 
            height: 80px;
            width: 80px; 
        }
}

.category-img.selected { border: 2px solid #fba00baa; }

.category-name {
    align-items: center;
    color: #eee;
    display: flex;
    font-size: 18px;
    font-weight: 300;
    justify-content: center;
    height: 100%;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    z-index: 1;

    @media ( max-width: 425px ) { 
        font-size: 16px;
    }
}

.category-name > div {
    max-width: 130px;
    padding: 0 3px;
}

.categories-list-title {
    color: #c1c1c1;
    font-size: 2rem;
    font-weight: 500;
    left: 90px;
    position: absolute;
    top: -13px;
    z-index: 2;
}

@media ( max-width: 1024px ) { .categories-list-title { font-size: 1.75rem; left: 53px; } }
@media ( max-width: 767px ) { .categories-list-title { font-size: 1.25rem; left : 36px; } }

.arrow {
    color: #eee;
    display: block;
    position: absolute;
    font-size: 42px;
    top: 70px;
    transition: transform .5s;
    z-index: 2;
}

.arrow:hover {
    cursor: pointer;
    transform: scale(1.2);
    @media ( max-width: 1024px ) { transform: none; }
}
.arrow.disabled { opacity: 0.5; }
.arrow.disabled:hover { cursor: default; transform: none; }

.arrow-left { left: 64px; }
.arrow-right { right: 13px; }

@media ( max-width: 1024px ) {
    .categories-list { padding: 0;}
    .arrow { display: none; }
}

.categories-list-box.distribution-list-box {
    margin: 0;
    padding: 0;

    .category-box { padding: 0 13px 0 0; }
    .arrow { top: 42px; }
    .arrow-left { left: -20px; }
    .arrow-right { right: -30px; }
    .category-name { flex-direction: column; }
    .distribution-role { color: #aeaeae; }
}