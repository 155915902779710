.hide { display: none; }

@media ( max-width: 1024px ) {
    #nav-container-web, #nav-container-web-up { display: none !important; }   
}

#nav-container-web-up {
    align-items: center;
    background:#000;
    display: flex;
    justify-content: space-between;
    height: 68px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    #nav_logo-web {
        margin-left: 100px;
        .nav_logo { height: 30px; }
    }

    .nav_item {
        display: inline-block;
        margin: 7px 26px 7px 0;
        position: relative;
        z-index: 1;

        .notif-context-menu  {
            background-color: #000000;
            border: 1px solid #AEAEAE44;
            border-radius: 5px;
            top: 30px;
            color: #eee;
            right: 30px;
            min-width: 400px;
            padding: 26px;
            position: absolute;
            z-index: 1;

            .notif-context-menu-title {
                font-size: 1.5em;
                padding: 13px 0;
                text-align: center;
                width: 100%;
            }

            .notif-context-menu-item {
                align-items: center;
                border-top: 1px solid #aeaeae55;
                display: flex;
                padding: 26px 0;

                &.linked { cursor: pointer; }

                .notif-context-menu-info {
                    color: #eee;
                    padding-left: 26px;
                }
                .tile-img-small { width: 120px; }
            }

            .notif-context-menu-close {
                button {
                    border: 0px;
                    border-radius: 10px;
                    color: #fff;
                    cursor: pointer;
                    font-size: 20px;
                    position: absolute;
                    padding: 24px;
                    right: 0;
                    top: 0;
                    z-index: 9;
                    background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                    background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                    background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                    background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                    background-position: 50% -50%;	
                }
            }
        }

        .profile-context-menu  {
            background-color: #000000;
            border: 1px solid #AEAEAE44;
            border-radius: 5px;
            top: 37px;
            color: #eee;
            right: 37px;
            min-width: 150px;
            padding: 13px;
            position: absolute;
            z-index: 1;

            .profile-context-menu-item {
                align-items: center;
                display: flex;
                padding: 7px 0;

                &.linked { cursor: pointer; }

                .notif-context-menu-info {
                    color: #eee;
                    padding-left: 26px;
                }
                .tile-img-small { width: 120px; }
            }
        }
    }

    span.icon_box { display: inline-block; position: relative; text-align: center; width: 60px; }

    span.dot {
        border-radius: 50%;
        background-color: #ff0000;
        display: inline-block;
        height: 11px;
        position: absolute;
        right: 14px;
        top: -3px;
        width: 11px;
    }
    
    img.nav_icon {
        filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        height: 30px;
    }

    .selected {
        color: #FBA00B;

        img.nav_icon {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    .nav_item.active:hover, a:hover {
        color: #fff;
        cursor: pointer;

        img.nav_icon {
            filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
        }

        &.selected {
            color: #FBA00B;

            img.nav_icon {
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
            }
        }
    }

    .user-avatar {
        border-radius: 50%;
        height: 40px;
        width: 40px;
    
        @media ( max-width: 767px ) { margin-right: 0; }
        @media ( max-width: 425px ) { height: 30px; width: 30px;}
    }
    
    .language_custom_select {
        display: inline-block;
        position: relative;
        margin: 7px 26px 7px 0;
        width: 100px;
        z-index: 1;

        &.box_center {
            padding: 26px 0;
            width: 100%;
        }

        &:hover { cursor: pointer; }

        .language_select_selected {
            background: #FFFFFF1C;
            border-bottom: 3px solid transparent;
            border-top: 3px solid transparent;
            border-radius: 2px;
            color: #fff;
            font-size: 20px;
            font-weight: 100;
            padding: 4px 13px;
            width: 100px;

            &.open {
                border-bottom: 3px solid #FFFFFF1A;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:hover { cursor: pointer; }
        }

        .language_select_option {
            background: #111;
            border-bottom: 1px solid #eaeaea;
            bottom: -45px;
            color: #fff;
            display: none;
            font-size: 20px;
            font-weight: 100;
            padding: 7px 13px;
            position: absolute;
            width: 100px;
            z-index: 1;

            &:last-child { bottom: -90px; }

            &.show { display: block; }
            &.last { border-bottom: none; border-radius: 2px; }
        }
    }
}

#nav-container-web {
    // background-color: #000;
    background: radial-gradient(circle at 50% 0, #0038385e, #00000047 70.71%),
                radial-gradient(circle at 6.7% 75%, #002420, #000000 70.71%),
                radial-gradient(circle at 93.3% 75%, #00211e, #003838 70.71%) #000000;
    
    color: #AEAEAE;
    font-size: 30px;
    height: 100%;
    padding: 60px 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    white-space: nowrap;
    width: 60px;
    transition: all .5s;
    z-index: 4;
    &:hover {
        box-shadow: 20px 0px 30px #000;
        width: 300px;
    }
  
    a {
        color: inherit;
        font-weight: unset;

        display: block;
    }

    #nav_profile-web {
        height: 45px;
        padding-bottom: 100px;
    }

    .nav_item {
        padding-bottom: 20px;
    }

    .nav_item_profile {
        margin-left: -5px;
    }

    span.dot {
        border-radius: 50%;
        background-color: red;
        display: inline-block;
        height: 13px;
        margin: 0 7px 3px 3px;
        width: 13px;
    }
    
    @keyframes blinking {  
        from { opacity: 1; }
        to { opacity: 0.2; }
    }

    span.live {
        animation: blinking 2s cubic-bezier( 0, 0, 1, 1 ) infinite alternate;  
    }

    span.icon_box {
        display: inline-block;
        margin-right: 13px;
        text-align: center;
        width: 60px;
    }

    img.nav_icon {
        filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        height: 30px;
    }

    .selected {
        color: #FBA00B;

        img.nav_icon {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    a:hover, #nav_playlist-web:hover {
        color: #fff;
        cursor: pointer;

        img.nav_icon {
            filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
        }

        &.selected {
            color: #FBA00B;

            img.nav_icon {
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
            }
        }
    }

    #nav_logo-web {
        position: absolute;
        bottom: 60px;
        left: 60px;
        z-index: 1;
    }

    .user-avatar {
        border-radius: 50%;
        height: 40px;
        margin-left: 10px;
        width: 40px;
        margin-right: 26px;
    
        @media ( max-width: 767px ) { margin-right: 0; }
        @media ( max-width: 425px ) { height: 30px; width: 30px;}
    }

    .nav_item_text
    {
        font-size: 24px;
    }

    #nav_boutique-web
    {
        .nav_item_text {
            padding-bottom: 13px;
        }
    }
    #nav_boutique-web
    {
        img{ margin-bottom: 2px; }
    }
}