.local_stripe_container {
    .local_stripe_form {
        margin-top: 26px;
        text-align: left;
        width: 400px;
        @media ( max-width: 767px ) { width: 80vw; }
    
        .local_stripe_input {
            background: transparent;
            border: 1px solid #fff;
            border-radius: 5px;
            color: #fff;
            font-size: 20px;
            font-weight: 100;
            margin-bottom: 26px;
            padding: 7px 26px;
            width: 100%;
    
            &::placeholder { font-size: 14px; color: #fff; }
        }
    
        .local_stripe_card {
            border: 1px solid #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            padding: 13px 26px;
            width: 100%;
        }
    
        .local_stripe_card_info_box {
            display: flex;
        }
    
        .local_stripe_expiry {
            border: 1px solid #fff;
            border-bottom-left-radius: 5px;
            padding: 13px 26px;
            width: 50%;
        }
    
        .local_stripe_cvc {
            border: 1px solid #fff;
            border-bottom-right-radius: 5px;
            padding: 13px 26px;
            width: 50%;
        }

        .local_stripe_results {
            font-weight: 700;
            min-height: 30px;
            padding-top: 7px;
            text-align: center;
            
            .local_stripe_error { color: #E33939; }
            .local_stripe_success { color: #54C254; }
        }
    
        .local_stripe_button {
            background-color: #fba00b;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-weight: 700;
            margin-bottom: 26px;
            padding: 13px;
            width: 100%;
    
            &:hover { cursor: pointer; }
            &:disabled {
                cursor: not-allowed;
                opacity: 0.4;
                pointer-events: all !important;
            }
        } 
    }

    .local_stripe_info {
        margin: 0 auto;

        i { margin-right: 13px; }
        .local_stripe_info_text { font-size: 14px; padding-top: 7px; }
        .local_stripe_info_img {
            align-items: center;
            display: flex;
            justify-content: center;
            padding-top: 7px;
            width: 100%;

            img { display: block; width: 200px; }
        }
    }
}

