.video-js .vjs-control-bar {
	position: fixed !important;
}
.video-js .btn{
	font-size: 1.2vw !important;
}
.video-js .vjs-tech{
	position: fixed !important;
}
.video-js .vjs-modal-dialog {
	z-index: 2000 !important;
}
.react-confirm-alert-overlay {
	z-index: 9999 !important;
	background: rgba(0,0,0,0.8);
}

.responsive-image {
	position: relative;
	width: 600px;
	max-width: 100%;
}
.responsive-image__image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  .btnPopup{
	  position:relative;
	  top: -80px;
  }
  @media only screen and (max-device-width:480px) {
	.responsive-image {
		width: 300px;
	}
	.btnPopup{
		top: -38px;
	}
}


.player-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%; 
	overflow: hidden;  
}
  
.react-player {
	/* Make video to at least 100% wide and tall */
	min-width: 100%; 
	min-height: 100%; 
	
	/* Setting width & height to auto prevents the browser from stretching or squishing the video */
	width: auto;
	height: auto;
	
	/* Center the video */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.overlayGB {
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.overlayGB-visible {
	visibility: visible;
	opacity: 1;
	transition: opacity 1s linear;  
}

.overlayGB-hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 1s, opacity 1s linear;
}

/* LOADER */
.loader {
	display: inline-block;
	position: relative;
	width: 100px;
	height: 100px;
	z-index: 1;
}

@media ( max-width: 767px ) {
	.loader {
		margin: 2vh 10vh 0 10vh;
	} 
}
.s3bubble-meta-overlay-inner-video:hover div.loader-part {
	cursor: pointer;
	align-items: center;
    background-color: #000000;
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 60px;
}

// .loader div.loader-part:nth-child(1) { animation-delay: -0.45s; }
// .loader div.loader-part:nth-child(2) { animation-delay: -0.3s; }
// .loader div.loader-part:nth-child(3) { animation-delay: -0.15s; }

// .loader div.loader-text {
// 	color: #FFF;
// 	box-sizing: border-box;
// 	font-size: 6rem;
// 	left: 45%;
// 	margin: 0;
// 	position: absolute;
// 	text-align: center;
// 	top: 48%;
// 	transform: translate( -50%, -50% );
// 	z-index: 2;
// }
div.loader-play {
	display: none;
}
.s3bubble-meta-overlay-inner-video:hover div.loader-play {
	// Intérieur du Loader
	display: block;
	color: #fff;
	box-sizing: border-box;
	font-size: 1.5rem;
	margin: 0;
	opacity: 0.5;
	position: absolute;
	left: 40%;
	z-index: 1;	
}

// @keyframes loading {
// 	0% { transform: rotate( 0deg ); }
// 	100% { transform: rotate( 360deg ); }
// }

/* LOADER MOBILE*/
.s3bubble-meta-overlay-inner-video-mobile:hover div.loader-part {
	cursor: pointer;
	align-items: center;
    background-color: #000000;
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 60px;
}

.s3bubble-meta-overlay-inner-video-mobile:hover div.loader-play {
	// Intérieur du Loader
	display: block;
	color: #fff;
	box-sizing: border-box;
	font-size: 1.5rem;
	margin: 0;
	opacity: 0.5;				
	position: absolute;
	left: 40%;
	z-index: 1;	
}
.player {
    &-overlay {
        background: rgba(#1D1D1B, .5);
        color:  #FBFBFB;
        bottom: 0;
        cursor: pointer;
        height: 100vh;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 10;

        &-content {
            height: 100%;
            padding: 32px;

            &.free-movie-content { padding-top: 120px; }
        }

        &-title {
            font-size: 2rem;
            font-weight: 700;
            margin-top: 52px;
        }

        &-product-related {
            position: absolute;
            font-size: 20px;
            bottom: 30px;
            right: 30px;
            text-align: center;
            width: 30vw;

            &-img {
                background-color: #aeaeae22;
                border-radius: 5px;
                font-size: 16px;
                margin-top: 7px;
                padding: 13px;
            }

            @media (min-width: 768px) { bottom: 100px; right: 50px; width: 250px; }

            & img {
                margin-bottom: 13px;
                width: 100%;
            }
        }

        &-back {
            align-items: center;
            display: flex;

            &-icon {
                margin-right: 12px;
            }
            &-arrow {
                margin-right: 12px;
            }
        }

        &.--end {
            & .player-overlay {
                &-content {
                    position: relative;
                    text-align: left;
                }

                &-title {
                    margin-bottom: 12px;
                }

                &-back {
                    margin-bottom: 12px;
                }

                &-thumbs {
                    margin-left: 26px;

                    & > *:first-child {
                        margin-right: 6px;
                    }

                    & .up, & .down {
                        background: #323232;
                        border-radius: 50%;
                        fill: #5eb0b0;
                        height: 50px;
                        padding: 10px;
                        width: 50px;

                        &:hover {
                            fill: #aed7d7;
                        }
                    }

                    & .down {
                        transform: rotate( 180deg );
                    }
                }

                &-next {
                    position: absolute;
                    bottom: 32px;
                    right: 32px;

                    & img {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        width: 60vw;

                        @media (min-width: 768px) {
                            width: 500px;
                        }
                    }

                    &-btn {
                        background: #202020;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding: 12px 26px;
                        text-align: center;
                        width: 60vw;
						transition: 0.3s;

                        @media (min-width: 768px) { width: 500px; }
                    }

					&:hover > div { 
						background: #7e7e7e;
					}
                }
            }
        }
    }
}

.btnRet{
	font-size: 1.6rem !important;
	padding: 0.4rem 1.5rem;
	border: 1px solid transparent !important;
}
.btnRet:hover{
	border: 1px solid #fba00b  !important;
}

$font-stack: 'Helvetica Neue',Helvetica,Arial,sans-serif;

.video-js .vjs-big-play-button {
    font-size: 6em;
    left: 50%;
    top: 50%;
    width: 90px;
    height: 90px;
    margin-left: -45px;
    margin-top: -45px;
    border: none;
    background-color: rgba(43,51,63,.7);
    line-height: 90px;
    border-radius: 50%;
    border: none;
    z-index: 2;
}

.video-js .vjs-control-bar {
    z-index: 2;
}

.video-js .vjs-tech{
	position: fixed !important;
}

.s3bubble-meta-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483647;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container {
    margin: 2%;
    width: 40%;
    padding: 0;
    text-align: left
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container:-webkit-full-screen {
	display:block !important;
	z-index:2147483647;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5 {
    font-family: 'Helvetica', sans-serif;
    font-size: 1.3vw;
    font-weight: 400;
    text-shadow: 0 1px 4px rgb(0, 0, 0);    
    color: #fff;
    line-height: 1.3;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    float: left;
    width: 100%;
}
.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5:-webkit-full-screen{
	display : block !important
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h1, .s3bubble-meta-overlay .s3bubble-meta-overlay-container h1:-webkit-full-screen {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 4vw;
    font-weight: 700;
    text-shadow: 0 1px 4px rgb(0, 0, 0);
    margin: 1vw 0;
    padding: 0;
    float: left;
    width: 100%;
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container p, .s3bubble-meta-overlay .s3bubble-meta-overlay-container p:-webkit-full-screen {
    font-family: 'Helvetica', sans-serif;
    text-shadow: 0 1px 4px rgb(0, 0, 0);
    font-weight: normal;
    font-weight: 200;
    font-size: 1.8vw;
    line-height: 1.3em;
    color: #fff;
    width: 100%;
    margin: 0;
    padding: 0;
}

.s3bubble-meta-overlay-visible,.s3bubble-meta-overlay-visible:-webkit-full-screen {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}

.s3bubble-meta-overlay-hidden, .s3bubble-meta-overlay-hidden:-webkit-full-screen {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}

.popupFinPlay-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0
}

.s3bubble-meta-overlay-container-mobile {
    // Container Gauche_Mobile
	text-align: center;
	color:#fff;
	padding-top:10px;
}

.s3bubble-meta-overlay-container-mobile, .s3bubble-meta-overlay-container {
    .ret-nav{
        color: #fff;
        display: flex;
        // flex-basis: 18%;
        font-weight: 100;
        padding: 5px;
    
        img {
            height: 15px;
            margin-top: 5px;
            margin-right: 10px;
        }
    }
    
    .ret-nav:hover{
        transform: scale(1.1);
    }
}

.s3bubble-meta-overlay.next-overlay {
    display: flex;
    width: 100%;
}

@media ( max-width: 767px ) {
    .s3bubble-meta-overlay.next-overlay {
        flex-direction: column;
        height: auto;
        width: 80%;
    }
}

.s3bubble-meta-overlay .s3bubble-meta-overlay-container-video {
    // Partie droite
    margin: 2%;
    width: 52%;
    padding: 0 10%;
    text-align: left
}

.s3bubble-meta-overlay figure {
    border-style: none !important;
}

.s3bubble-meta-overlay figure h1.short-title {
// Titre du milieu
    font-size: 1em !important;       
}

.s3bubble-meta-overlay figure h1.long-title {
    font-size: 0.8em !important;
}

.s3bubble-meta-overlay figure h1.longer-title {
    font-size: 0.5em !important;
}

.s3bubble-meta-overlay figure div.title-info {
// Texte dans prochaine vidéo
    display: none;
}

.s3bubble-meta-overlay figure:hover h1.short-title {
    display: none !important;
}

.s3bubble-meta-overlay figure:hover h1.long-title {
    display: none !important;
}

.s3bubble-meta-overlay figure:hover h1.longer-title {
    display: none !important;
}

.s3bubble-meta-overlay figure img {
    transition: all .5s;
}

.s3bubble-meta-overlay figure:hover img {
    opacity: 1;
    filter: brightness(0.8);
}

.s3bubble-meta-overlay  figure:hover div.title-info {
    // Hover texte dans prochaine vidéo
    margin: 15%;
    display: block;
}

.s3bubble-meta-overlay figure:hover div.title-info .title-info-title {
// Titre dans prochaine vidéo
    font-size: 1em !important;
    font-weight: bold; 
    margin-top: -26px;
    text-shadow: 1px 1px #111;    
}

.s3bubble-meta-overlay figure:hover div.title-info .title-info-details {
    // Titre dans prochaine vidéo Hover
    font-size: 0.7em !important;
    text-shadow: 1px 1px #111;
    text-transform: none;
}

#next-overlay-title {
    background-color: rgb(32, 32, 32) !important;
    color: #fff;
    padding: 15px;
}

#next-overlay-title-mobile {
    background-color: rgb(32, 32, 32) !important;
    color: #fff;
    padding: 15px;
}

#s3bubble-meta-overlay-inner-video {
    border-radius: 3% !important;
    position: absolute;
    bottom: 5%;
    right: 5%;
}

#s3bubble-meta-overlay-inner-video-mobile {
    border-radius: 3% !important;
    size: 30%;
    width: 50%;
}

.player-center {
	height: 50px;
    left:50%;
	opacity: 80%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    @media ( max-width: 767px ) { height: 30px; }
    @media ( max-width: 425px ) { height: 20px; }    
}

.s3bubble-meta-overlay-navigation {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 50px 0 20px;

    .ret-nav{
        color: #fff;
        display: flex;
        flex-basis: 18%;
        font-weight: 100;
        padding: 5px;
    
        img {
            height: 15px;
            margin-top: 5px;
            margin-right: 10px;
        }

        #return {
            display: none;
            @media ( min-width: 769px ) { display: block; }
        }    
    }
    
    .ret-nav:hover{
        transform: scale(1.1);
    }
       
    .title-wrap {
        color: #fff;
        flex-grow: 1;
        font-size: 22px;
        font-weight: 100;
        padding: 5px 20% 5px 0;
        text-align: center;
        text-shadow: 0.2px 0.2px 0.2px;
    }
}

.s3bubble-meta-overlay-navigation-mobile {
    display: flex;
    // position: absolute;
    // width: 100%;
    padding: 0 20px 0 10px;

    .ret-nav-mobile{
        color: #fff;
        display: flex;
        flex-basis: 18%;
        font-weight: 100;
        padding: 5px;
    
        img {
            height: 15px;
            margin-top: 5px;
            margin-right: 10px;
        }
    }
    
    .ret-nav-mobile:hover{
        transform: scale(1.1);
    }
    
    .title-wrap-mobile {
        color: #fff;
        flex-grow: 1;
        font-size: 22px;
        font-weight: 100;
        padding: 5px;
        text-align: center;
        text-shadow: 0.2px 0.2px 0.2px;
    }
}


.s3bubble-meta-overlay-container {
    .icons_box { 
        align-items: center;
        display: flex;
        // justify-content: center;
        max-width: 247.58px;
        margin: 5px 0 5px 11.2px;

        // @media ( min-width: 500px ) { width: 80vw; }
    }

    .movie_btn_box {
        align-items: center;
        display: flex;
        color: #FBA00B;
        flex-direction: column;
        justify-content: center;
        margin-right: 13px;
        &:nth-child(2) {
            .icon_box img {
                transform: rotate( 180deg );
            }
        }

        .icon_box {
            background-color: #FFFFFF1A;
            border-radius: 50%;
            height: 55px;
            width: 55px;
            img { 
                border-style: none;
                height: 30px;
                // filter: brightness(0.8);
                vertical-align: middle;
                width: 30px;
        
            }

            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#FFFFFF4A; cursor: pointer;
                } 
            }
            
        }
    }
    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;

        .bubble {
            // background-color: #FFF;
            background-color: #2a2a2a;
            border-radius: 3px;
            // color: #222;
            color: #fff;
            display: none;
            left: -7px;
            padding: 7px 12px;
            position: absolute;
            top: -60px;
            white-space: nowrap;
            z-index: 2;
        }

        &:hover .bubble {
            display: block;
            @media ( max-width: 1024px ) { display: none; }
        } 
    }
}

// #s3bubble-meta-overlay-container-video-mobile {
//     margin-top: 130px;
// }
.s3bubble-meta-overlay-container-mobile {

    .icons_box { 
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 15px auto 5px auto;
        max-width: 247.58px;

        // @media ( min-width: 500px ) { width: 80vw; }
    }

    .movie_btn_box {
        align-items: center;
        display: flex;
        color: #FBA00B;
        flex-direction: column;
        justify-content: center;
        margin-right: 13px;
        &:nth-child(2) {
            .icon_box img {
                transform: rotate( 180deg );
            }
        }

        .icon_box {
            background-color: #FFFFFF1A;
            border-radius: 50%;
            height: 55px;
            width: 55px;
            img { 
                border-style: none;
                height: 30px;
                filter: none;
                vertical-align: middle;
                width: 30px;
                
                @media ( max-width: 767px ) { height: 20px; }
            }

            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#FFFFFF4A; cursor: pointer;
                } 
            }
            
        }
    }
    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;

        .bubble {
            // background-color: #FFF;
            background-color: #2a2a2a;
            border-radius: 3px;
            // color: #222;
            color: #fff;
            display: none;
            left: -7px;
            padding: 7px 12px;
            position: absolute;
            top: -60px;
            white-space: nowrap;
            z-index: 2;
        }

        &:hover .bubble {
            display: block;
            @media ( max-width: 1024px ) { display: none; }
        } 
    }
}

.title-wrap-next-overlay {
    padding-top: 50px;
}

.title-wrap-next-overlay-mobile {
    padding-top: 20px;
}

.icons_box_overlay { 
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 23px;
    max-width: 250px;

    .movie_btn_box {
        align-items: center;
        display: flex;
        color: #FBA00B;
        flex-direction: column;
        justify-content: center;
        margin-right: 13px;
        &:nth-child(2) {
            .icon_box img {
                transform: rotate( 180deg );
            }
        }

        .icon_box {
            background-color: #FFFFFF1A;
            border-radius: 50%;
            height: 55px;
            width: 55px;
            img { 
                border-style: none;
                height: 30px;
                filter: none;
                vertical-align: middle;
                width: 30px;
                
                @media ( max-width: 767px ) { height: 20px; }
            }

            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#FFFFFF4A; cursor: pointer;
                } 
            }
            
        }
    }
    
    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;

        .bubble {
            // background-color: #FFF;
            background-color: #2a2a2a;
            border-radius: 3px;
            // color: #222;
            color: #fff;
            display: none;
            left: -7px;
            padding: 7px 12px;
            position: absolute;
            top: -60px;
            white-space: nowrap;
            z-index: 2;
        }

        &:hover .bubble {
            display: block;
            @media ( max-width: 1024px ) { display: none; }
        } 
    }
}