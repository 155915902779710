.main-des-container {
    background-color: #000;
    position: relative;
    overflow: hidden;
    z-index: 1;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .main-des-go-back {
        color: #aeaeae;
        cursor: pointer;
        left: 100px;
        padding: 13px 0;
        position: absolute;
        transition: transform .2s;
        top: 7px;
        z-index: 2;

        .icon_box {
            margin-right: 13px;
            img { vertical-align: text-top; }
        }

        &:hover { transform: scale( 1.04 ); }
    }

    .main-des-image {
        right: 0;
        position: absolute;
        top: 0;
        z-index: 0;

        .main-des-image-overlay {
            background: linear-gradient(90deg, #000000 0%, #00000033 50%, #00000000 100%), linear-gradient(0deg, #000000 0%, #00000000 50%, #00000000 100%);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
    }

    .main-des-content {
        padding: 80px 0 0 120px;
        position: relative;
        z-index: 1;

        .main-des-img-title {
            margin-left: 26px;
            max-height: 260px;
            max-width: 260px;

            img { height: 100%; width: 100%; }
        }

        .main-des-title { color: #fff; font-size: 2.5rem; margin: 26px 0 13px 0; font-weight: 700; }
        
        .main-des-btn-addon-box {
            align-items: center;
            display: flex;
            justify-content: flex-start;

            .main-des-btn-addon {
                background-color: #7c7c7c;
                border-radius: 3px;
                color: #fff;
                font-size: 1.3rem;
                padding: 5px;
                margin: 0 7px;
                text-align: center;
                width: 120px;

                &.linked { cursor: pointer; }
            }
        }

        .main-des-details { color: #fff; font-size: 1.3rem; margin: 26px 0 13px 0; }

        .main-des-price {
            margin: 26px 0 13px 0;

            .main-des-priceOverlineStg { color: #aeaeae; display: inline-block; font-size: 1.2rem; margin-right: 13px; text-decoration: line-through; vertical-align: middle; }
            .main-des-priceStg { color: #fff; font-size: 2rem; font-weight: 700; }
            .main-des-price-rent-info {
                background-color: #7c7c7c;
                border-radius: 10px;
                color: #fff;
                display: inline-block;
                font-size: 1rem;
                margin-left: 7px;
                padding: 1px 7px;
                position: relative;
                top: -15px;
            }
            .main-des-free {
                background-color: #E33939;
                border-radius: 10px;
                color: #fff;
                font-size: 18px;
                margin: 26px 0;
                padding: 3px;
                text-align: center;
                width: 200px;
    
                @media ( max-width: 1024px ) { margin: 0 auto 42px; }
            }
        }

        .movie_btns {
            align-items: center;
            align-self: flex-start;
            bottom: 0;
            color: #aeaeae;
            display: flex;
            width: 100%;
    
            @media ( max-width: 1024px ) {
                flex-direction: column;
                padding-left: 0;
    
                & > * { margin: 13px 0; }
            }
    
            .icons_box { 
                align-items: center;
                display: flex;
                justify-content: center;
            }
    
            .movie_btn_box {
                align-items: center;
                display: flex;
                color: #FBA00B;
                flex-direction: column;
                justify-content: center;
                margin-right: 13px;
                
                .icon_box {
                    background-color: #FFFFFF1A;
                    border-radius: 50%;
                    height: 55px;
                    width: 55px;
    
                    img.movie_icon { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
    
                    @media ( min-width: 1025px ) {
                        &:hover {
                            background-color:#FFFFFF4A; cursor: pointer;
                        } 
                    }
                    .icon_revert { transform: rotate( 180deg ); }
                }
            }
    
            i { font-size: 30px; }
    
            .btn-movie {
                background-color:#FBA00B;
                border: none;
                border-radius: 3px;
                color: #FFFFFF;
                display: inline-block;
                font-size: 20px;
                font-weight: 100;
                height: 56px;
                letter-spacing: 2px;
                margin: 0 8px 8px 0;
                padding: 7px;
                width: 240px;
    
                @media ( max-width: 1024px ) { width: 300px; margin: 0 0 18px 0; }
                @media ( max-width: 500px ) { width: 80vw; margin: 0 0 18px 0; }
        
                i { font-size: 18px; margin-right: 13px; }
                &:hover { background-color: #FBA00B; cursor: pointer; opacity: 0.89; }
            }
    
            .btn-trailer {
                background-color: #2C2C2C;
                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#353535; cursor: pointer;
                    } 
                }
            }

            .btn-bonus {
                background-color: #2C2C2C;
                color: #fba00b;
                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#353535; cursor: pointer;
                    } 
                }
            }
        }

        img.movie_icon {
            filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
            height: 30px;
            width: 30px;
            @media ( max-width: 767px ) { height: 20px; }
    
            &.selected {
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
            }
        }

        .icon_box {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 7px;
            position: relative;
            width: 100%;
            z-index: 1;
    
            .bubble {
                background-color: #2a2a2a;
                border-radius: 3px;
                color: #fff;
                display: none;
                left: -7px;
                padding: 7px 12px;
                position: absolute;
                top: -60px;
                white-space: nowrap;
                z-index: 2;
            }
    
            &:hover .bubble {
                display: block;
                @media ( max-width: 1024px ) { display: none; }
            } 
        }

        .main-des-description { color: #fff; font-size: 1.3rem; margin: 26px 0 13px 0; max-width: 50%; }
        .main-des-additional-info { color: #fff; font-size: 1.2rem; font-weight: 700; margin-top: 26px; max-width: 50%; }
    }
}

.main-des-happy-ending { background: linear-gradient(to top, #00000000, #000); height: 50px; width: 100%; }
.main-des-happy-ending-revert { background: linear-gradient(to bottom, #00000000, #000); height: 50px; position: relative; width: 100%; z-index: 1; }

.training-container #chasse_Homelist .GBListItem { top: 0; }

.GBListItem.with-description ul { margin-top: -13px; } 
.with-description-info {
    color: #eee;
    margin-left: 10px;
    margin-right: 15px;
    text-align: justify;

    .with-description-title {
        font-size: 1.3rem;
        font-weight: 700;
        margin-top: 13px;

        .with-description-pretitle { font-size: 1.2rem; color: #aeaeae; display: inline-block; margin-right: 7px; vertical-align: middle; } 
        &.product-item { margin-top: 3px; min-height: 60px; } 
    }

    .with-description-colors {
        display: flex;
        margin-top: 7px;
        min-height: 30px;

        .with-description-colors-dots {
            border: 3px solid #fba00b22;
            border-radius: 50%;
            height: 30px;
            margin: 0 7px;
            width: 30px;
        }
    }

    .with-description-synopsis { font-size: 1.1rem; }
    .with-description-price { color: #fba00b; font-size: 2rem; margin-top: 3px; }
    .with-description-price-initial { color: #aeaeae; display: inline-block; font-size: 1.5rem; margin-right: 7px; margin-top: 13px; text-decoration: line-through; }
}

.with-description-info .with-description-synopsis { margin-top: 3px; }

@media ( max-width: 1024px ) {
    .carousel-img .carousel-info .carousel-img-title { margin-top: 13px; }
    .carousel-img .carousel-info .carousel-title { font-size: 1.5rem; }
    .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon { font-size: 1rem; }
    .carousel-img .carousel-info .carousel-btn { font-size: 1.2rem; }

    figure.effect-thumbnail-movie .figure-icon { height: 18px; width: 18px; }
    figure.effect-thumbnail-movie .figure-badgeTop { font-size: .6rem; }
    figure.effect-thumbnail-movie .figure-title { font-size: 2rem; }
    figure.effect-thumbnail-movie .figure-badgeCenter  { font-size: 1.6rem; }
    figure.effect-thumbnail-movie .figure-info { font-size: 1.6rem; }
    figure.effect-thumbnail-movie .figure-price { font-size: 2rem; }

    .main-des-container .main-des-go-back { left: 26px; top: 26px; }
    .main-des-container .main-des-content { padding-left: 26px; }
    .main-des-container .main-des-content .main-des-title { font-size: 1.5rem; padding-left: 0; }
    .main-des-container .main-des-content .main-des-btn-addon-box .main-des-btn-addon { font-size: 1rem; margin-left: 0; width: 80px; }
    .main-des-container .main-des-content .main-des-details { font-size: 1rem; }
    .main-des-container .main-des-content .main-des-image { max-height: 525px; }
    .main-des-container .main-des-image .main-des-image-overlay {
        background: linear-gradient(90deg,#000,#00000080 50%,transparent),linear-gradient(0deg,#000,#00000080 50%,transparent);
        img { max-height: 525px; }
    }
    .main-des-container .main-des-content .movie_btns { margin-left: -13px; }
    .main-des-container .main-des-content .main-des-description { max-width: 90%; }
    .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box { background-color: #000000bb; }

    .GBListItem h3 .list-title-prename { margin-right: 13px; }

    .with-description-info .with-description-title {
        font-size: 1.3rem;
        margin-top: 0;
        text-align: left;
        &.product-item { font-size: 1rem; }
    }
    .with-description-info .with-description-title .with-description-pretitle { font-size: 1rem; margin-right: 7px; }
    .with-description-info .with-description-synopsis { font-size: 1rem; text-align: left; }
}

@media ( max-width: 767px ) {
    .carousel-img .carousel-info .carousel-title { font-size: 1.2rem; }
    .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon { font-size: .8rem; }

    figure.effect-thumbnail-movie .figure-title { font-size: 1.8rem; }
    figure.effect-thumbnail-movie .figure-badgeCenter  { font-size: 1.4rem; }
    figure.effect-thumbnail-movie .figure-info { font-size: 1.4rem; }
    figure.effect-thumbnail-movie .figure-price { font-size: 1.8rem; }
}

.with-description figure.effect-thumbnail-movie { margin-top: 23px; }