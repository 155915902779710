.soon-container {
    background-color: #000;
    position: relative;
    overflow: hidden;
    z-index: 1;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.with-trailer {
        .soon-content { transition: margin-top 1s linear; }
        .soon-flex-container { background: #000; }

        @media ( max-width: 1024px ) {
            // .soon-img-title, .soon-img-title-up { display: none; }
            .soon-content { margin-top: 320px; }
        }
    }

    .soon-image {
        left: 0;
        position: absolute;
        top: 0;
        transition: width 2s ease-in-out, top 1s linear;
        width: 100%;
        z-index: 0;

        &.with-trailer {
            width: 60%;
            
            @media ( max-width: 1024px ) {
                display: none;
            }
        }

        img { width: 100%; }

        .soon-image-overlay {
            background: linear-gradient(90deg, #000000 0%, #00000033 50%, #00000000 100%), linear-gradient(0deg, #000000 0%, #00000000 50%, #00000000 100%);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
    }

    .soon-image-overlay-outside {
        background: linear-gradient(to bottom, #00000000, #000);
        bottom: 0;
        height: 50px;
        width: 100%;
        left: 0;
        position: absolute;
        z-index: 1;
    }

    .soon-content {
        padding: 13px 0 0 120px;
        position: relative;
        z-index: 1;
        
        animation: 1s ease-out 0s 1 slideInFromBottom;
        @keyframes slideInFromBottom {
            0% { transform: translateY( 100% ); }
            100% { transform: translateY( 0 ); }
        }
          

        @media ( max-width: 1024px ) {
            padding: 13px 0 0 0;
        }

        .soon-free-movie-badge {
            background-color: #E33939;
            border-radius: 10px;
            color: #fff;
            font-size: 18px;
            margin: 26px 0;
            padding: 3px;
            text-align: center;
            width: 200px;

            @media ( max-width: 1024px ) { margin: 0 auto 42px; }
        }

        .soon-img-title {
            margin-left: 26px;
            max-height: 320px;
            max-width: 320px;

            @media ( max-width: 1024px ) { margin: 26px auto; }

            img { height: 100%; width: 100%; }

            @media ( max-width: 767px ) {
                max-height: 240px;
                max-width: 240px;
            }
        }

        .soon-img-title-up, .soon-title-up {
            margin-left: 26px;
            max-height: 120px;
            max-width: 120px;
            margin: 13px auto;
           
            &.orange-title { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
            img { height: 100%; width: 100%; }
        }

        .soon-title {
            color: #eee;
            font-size: 2.3rem;
            line-height: 4rem;
            max-width: 40%;

            @media ( max-width: 1024px ) {
                margin: 0 5% 0 5%;
                max-width: 90%;
            }

            .soon-title-up { margin: 13px; }
        }

        .soon-description, .soon-date {
            color: #fff;
            font-size: 1.3rem;
            
            max-width: 40%;

            @media ( max-width: 1024px ) {
                margin: 0 5% 0 5%;
                max-width: 90%;
            }
        }

        .soon-date {
            margin: 42px 0 7px 0;

            @media ( max-width: 1024px ) {
                margin: 42px 5% 7px 5%;
                max-width: 90%;
            }
        }
    }

    .movie_btns {
        align-items: center;
        align-self: flex-start;
        bottom: 0;
        color: #aeaeae;
        display: flex;
        margin: 13px 0;
        width: 100%;

        @media ( max-width: 1024px ) {
            flex-direction: column;
            padding-left: 0;

            & > * { margin: 13px 0; }
        }

        .icons_box { 
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .movie_btn_box {
            align-items: center;
            display: flex;
            color: #FBA00B;
            flex-direction: column;
            justify-content: center;
            margin-right: 13px;
            
            .icon_box {
                background-color: #2C2C2C;
                border-radius: 50%;
                height: 55px;
                width: 55px;

                img.movie_icon { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }

                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#353535; cursor: pointer;
                    } 
                }
                .icon_revert { transform: rotate( 180deg ); }
            }
        }

        .icon_box {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 7px;
            position: relative;
            width: 100%;
            z-index: 1;

            img { height: 30px; width: 30px; }

            .bubble {
                background-color: #2a2a2a;
                border-radius: 3px;
                color: #fff;
                display: none;
                left: -7px;
                padding: 7px 12px;
                position: absolute;
                top: -60px;
                white-space: nowrap;
                z-index: 2;
            }
            
            &:hover .bubble {
                display: block;
                @media ( max-width: 1024px ) { display: none; }
            } 
        }

        i { font-size: 30px; }

        .btn-movie {
            background-color:#FBA00B;
            border: none;
            border-radius: 3px;
            color: #FFFFFF;
            display: inline-block;
            font-size: 20px;
            font-weight: 100;
            height: 56px;
            letter-spacing: 2px;
            margin: 0 8px 8px 0;
            padding: 7px;
            width: 240px;

            &.btn-tall  { margin: 26px 8px 26px 0; width: 300px; }
            @media ( max-width: 1024px ) { width: 300px; margin: 0 0 18px 0; }
            @media ( max-width: 500px ) { width: 80vw; margin: 0 0 18px 0; }
    
            i { font-size: 18px; margin-right: 13px; }
            &:hover { background-color: #FBA00B; cursor: pointer; opacity: 0.89; }
        }

        .btn-trailer {
            background-color: #2C2C2C;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }

        .btn-bonus {
            background-color: #2C2C2C;
            color: #fba00b;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }
    }
}

.soon-happy-ending { background: linear-gradient(to top, #00000000, #000); height: 50px; width: 100%; }

.soon-clock {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    font-family: sans-serif;
    display: flex;
    padding: 13px;
    overflow: hidden;
    position: relative;

    @media ( max-width: 1024px ) { font-size: 36px; }

    .soon-box {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .soon-number-box {
            align-items: center;
            display: flex;
            justify-content: center;
            overflow: hidden;
            position: relative;
            width: 80px;
            z-index: 1;

            @media ( max-width: 1024px ) { width: 60px; }
        }

        .soon-label-box {
            font-size: 14px;
            text-align: center;

            @media ( max-width: 1024px ) { font-size: 10px; }
        }
    }
}

.soon-flex-container {
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    z-index: 9;

    @media ( max-width: 1024px ) {
        width: 100%;
        z-index: 0;

        .react-player { top: 180px; }
    }

    .soon-container-relative {
        position: relative;
        flex-basis: 100%;
      
        #z3pOverlay { position: relative; }
        .z3PlayOverlay { background-color: transparent !important; }
        .soon-container-overlay {
            background:
                linear-gradient(to left, #000000 0%, #00000022 30%, #00000000 100%),
                linear-gradient(to right, #000000 0%, #00000022 30%, #00000000 100%),
                linear-gradient(to top, #000000 0%, #00000022 30%, #00000000 100%),
                linear-gradient(to bottom, #000000 0%, #00000022 35%, #00000000 100%);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 999991;
        }

        .soon-container-sound, .soon-container-reload {
            color: #fff;
            cursor: pointer;
            filter: drop-shadow( 0 0 10px #000 ); 
            position: absolute;
            transition: transform .5s linear;
            z-index: 999992;
        }

        .soon-container-sound {
            font-size: 24px;
            
            @media ( min-width: 1025px ) {
                bottom: 20px;
                right: 80px;
                &:hover { transform: scale(1.2); }
            }
            @media ( max-width: 1024px ) {
                left: 40px;
                top: 20px;
            }
        }
            
        .soon-container-reload {
            font-size: 20px;
            transform: scaleX(-1);

            @media ( min-width: 1025px ) {
                bottom: 23px;
                right: 40px;
                &:hover { transform: scaleX(-1) scale(1.2); }
            }
            @media ( max-width: 1024px ) {
                left: 80px;
                top: 23px;
            }
        }
    }

    .soon-happy-ending-left {  
        background: linear-gradient(to right, #00000000, #000);
        height: 100%;
        left: -50px;
        position: absolute;
        top: 0;
        width: 50px;
    }

    .player-overlay  { display: none; }
}

.soon-rate-star-box { 
    display: flex;

    .soon-rate-average { margin-right: 13px; }
    .soon-rate-count { margin-left: 17px; }

    .soon-rate-star {
        color: #FBA00B;
        margin: 0 1px;
        position: relative;
        z-index: 1;

        span {
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 2;
        }
    }
}

.movie_popup_content .soon-rate-star-box { align-self: flex-start; padding: 0 26px;  }