body {
    margin: 0;
    padding: 0;
    /*
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  @media screen and (min-width: 1024px){
      .list-title{
          padding-top:40px;
          padding-bottom:10px;
      }
      .list-title h3{
          font-size:2rem ;

        .list-title-prename { font-size: 1.5rem; color: #ddd; display: inline-block; margin-right: 13px; vertical-align: middle; }  
        .list-title-prepng { display: inline-block; margin-right: 13px; }
      }
  
  }

.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  