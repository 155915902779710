.homepage-container {
    margin-top: 66px;
    @media ( max-width: 1024px ) { margin-top: 88px }
}

.special-nav-menu {
    #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
        @media ( max-width: 1024px ) { display: none; }
    }
}

#profile_container, #profile_container_ {
    color: #fff;
    margin: auto;
    // min-height: 80vh;
    width: 960px;
    position: relative;
    z-index: 1;

    @media ( min-width: 1025px ) { margin: 0 auto; padding: 120px 120px 0; }
    @media ( max-width: 1024px ) { margin: 0 auto; padding: 120px 0 0; }
    @media ( max-width: 1024px ) { width: 80vw; }

    #profile_loader {
        align-items: center;
        display: none;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 1;
        &.bottom-loader { bottom: 0; top: inherit; }
        &.show { display: flex; }
    }

    #profile_confirmation {
        align-items: center;
        background-color: #00000088;
        display: none;
        height: 100vh;
        justify-content: center;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 2;

        #profile_confirmation_box {
            align-items: center;
            background-color: #111;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 250px;
            width: 450px;

            button {
                background: #FBA00B;
                border: none;
                border-radius: 5px;
                color: #fff;
                font-weight: 100;
                height: 40px;
                margin: 23px;
                width: 80px;

                &.gray { background-color: #AEAEAE; }

                &:hover { cursor: pointer; }
            }
        }

        &.show { display: flex; }
    }

    input {
        background: #FFFFFF1C;
        border: none;
        border-radius: 2px;
        color: #fff;
        font-size: 20px;
        font-weight: 100;
        padding: 10px 13px;
        width: 300px;
        @media ( max-width: 767px ) { width: 80vw; }

        &:focus { outline: none; }
    }

    .profile_edit_custom_select {
        padding: 13px 0;
        width: 400px;

        &.box_center {
            padding: 26px 0;
            width: 100%;
        }

        @media ( max-width: 767px ) { width: 80vw; }

        &:hover { cursor: pointer; }

        .profile_edit_custom_select_box {
            align-items: center;
            display: flex;
            justify-content: center;

            button {
                background: #FFFFFF1C;
                border: none;
                border-radius: 5px;
                color: #fff;
                font-weight: 100;
                height: 40px;
                margin: 0 13px;
                width: 120px;

                &.selected { background-color: #FBA00B; opacity: 0.8; }
    
                &:hover {
                    cursor: pointer;
                }
            }  
        }

        .profile_select_selected {
            background: #FFFFFF1C;
            border: none;
            border-radius: 2px;
            color: #fff;
            font-size: 20px;
            font-weight: 100;
            padding: 10px 13px;
            width: 400px;
            @media ( max-width: 767px ) { width: 80vw; }

            &.open {
                border-bottom: 3px solid #FFFFFF1A;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &:hover { cursor: pointer; }
        }

        .profile_select_option {
            background: #FFFFFF1C;
            border-bottom: 1px solid #FFFFFF1A;
            color: #fff;
            display: none;
            font-size: 20px;
            font-weight: 100;
            padding: 10px 13px;
            width: 400px;
            @media ( max-width: 767px ) { width: 80vw; }

            &.show { display: block; }
            &.last { border-bottom: none; border-radius: 2px; }
        }
    }

    .profile_icon {
        height: 18px;
        margin: -3px 13px 0 0;

        &.hover_icon:hover { cursor: pointer; }
        &.mirror_icon { transform: rotate( 180deg ); }
        &.small_icon { height: 15px; margin: -2px 0 0 7px; }
        &.store_icon { filter: invert( 100% ); }
        &.title_icon { height: 30px; }
        &.white { height: 26px; filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%); }
    }

    .profile_nav_return {
        color: rgb(112, 112, 112);
        margin-bottom: 52px;

        &:hover {
            cursor: pointer;
            display: inline-block;
            transform: scale(1.1);
            @media ( max-width: 1024px ) { transform: none; }
        }

    }

    .profile_success_icon {
        background-color: #FFFFFF1C;
        height: 150px;
        border-radius: 50%;
        padding: 20px;
        width: 150px;
    }

    .profile_info_bubble_box {
        position: relative;
        z-index: 1;

        .profile_info_bubble {
            background: #ffffff44;
            border-radius: 4px;
            box-shadow: 2px 2px 10px #858585bf;
            color: #fff;
            position: absolute;
            display: none;
            padding: 13px;
            left: 100%;
            top: 20px;
            width: 200px;
            z-index: 2;

            @media ( max-width: 767px ) { left: -100%; }
            
            &.show { display: block; }
            &:hover { cursor: pointer; }
        }
    
    }

    .profile_box_center {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .profile_edit_usual_error {
        color: #E33939;
        display: flex;
        font-weight: 700;
        height: 30px;
        justify-content: center;
    }

    .profile_edit_usual_success {
        color:#54C254;
        display: flex;
        font-weight: 700;
        height: 30px;
        justify-content: center;
    }

    .profile_edit_usual {
        width: 100%;

        &.profileManager {
            .profile_edit_usual_current { align-items: flex-start; flex-wrap: wrap; }

            .user-avatar-box {
                flex-direction: column;
                justify-content: flex-start;
                margin-top: 13px;

                .avatar_box .addon_icon_box {
                    right: -15px;
                    top: 60px;
                }

                .avatar_box.avatar_box_add {
                    align-items: center;
                    border: 3px solid #AEAEAE;
                    border-radius: 50%;
                    cursor: pointer;
                    display: flex;
                    height: 100px;
                    justify-content: center;
                    margin-bottom: 7px;
                    width: 100px;

                    img {
                        display: inline-block;
                        filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                        height: 50px;
                        width: 50px;
                    }
                }

                .user-avatar {
                    margin: 0 0 7px 0;

                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                        @media ( max-width: 1024px ) { transform: none; }
                    }

                    &.selected { border: 3px solid #fba00b; }
                    &.user-avatar-default {
                        opacity: 0.3;
                        &:hover { cursor: default; transform: none; }
                    }
                }

                .profile_edit_usual_btn {
                    padding: 7px;
                    button { height: 26px; width: 100px; }
                    &.tall button { height: 40px; width: 300px; }
                    .red { background-color: rgb(218, 5, 5); }
                    .preferences_button { background-color: transparent; border: 2px solid #fba00b; margin: 13px 0; }
                }
            }
        }

        .profile_edit_usual_current {
            align-items: center;
            display: flex;
            justify-content: space-around;
            padding: 30px;
        }
        
        .profile_edit_usual_form {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .profile_edit_usual_input, .profile_edit_usual_textarea {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 13px 0;
            }

            .profile_edit_usual_input_info {
                color: #AEAEAE;
                font-size: 12px;
                margin-top: 7px;
            }

            .profile_edit_usual_textarea textarea {
                background: #FFFFFF1C;
                border: none;
                border-radius: 2px;
                color: #fff;
                display: block;
                font-size: 20px;
                font-weight: 100;
                height: 150px;
                padding: 10px 13px;
                resize: none;
            }

            label { font-size: 14px; }
            input, textarea, select {
                width: 400px;
                @media ( max-width: 767px ) { width: 80vw; }
            }
        }
    }

    .profile_emoji {
        height: 36px;
        margin-left: 13px;
        margin-top: -7px;
        opacity: 0.8;
        width: 36px;
    }

    .f-grow-1 { flex-grow: 1; }

    .avatar-list-box {
        align-items: center;
        display: flex; 
        height: 170px;
        padding-left: 50px;
        position: relative;
        width: 100vw;
        z-index: 2;
    
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .avatar-list-title {
            color: #c1c1c1;
            font-size: 2rem;
            font-weight: 500;
            left: 90px;
            position: absolute;
            top: -26px;
            z-index: 2;

            @media ( max-width: 1024px ) { font-size: 1.75rem; left: 53px; }
            @media ( max-width: 767px ) { font-size: 1.25rem; left : 36px; }
        }
        
        // @media ( max-width: 1024px ) { .categories-list-box { padding: 26px 13px 0 13px; } }
        
        // .categories-list-box + #home_Homelist .GBListItem { top: 0; }
        // .categories-list-box + #chasse_Homelist .GBListItem { top: 0; }
        // .categories-list-box + #peche_Homelist .GBListItem { top: 0; }
        
        .avatar-list {
            align-items: center;
            background-color: transparent;
            color: #c1c1c1;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            height: 100%;
            overflow-x: scroll;
            padding: 0 40px;
            -ms-overflow-style: none;

            @media ( max-width: 1024px ) { padding: 0; }

            &::-webkit-scrollbar { height: 0 !important; width: 0 !important; }

            .avatar-box {
                align-items: center;
                display: flex;
                justify-content: center;
                padding: 0 13px;
                
                .user-avatar { 
                    border-radius: 50%; 
                    height: 100px;
                    transition: transform .5s;
                    width: 100px;
                    
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                        @media ( max-width: 1024px ) { transform: none; }
                    }

                    &.selected { border: 3px solid #fba00b; }
                    &.user-avatar-default {
                        opacity: 0.3;
                        &:hover { cursor: default; transform: none; }
                    }
                }
            }
        }

        .arrow {
            color: #eee;
            display: block;
            position: absolute;
            font-size: 42px;
            top: 50px;
            transition: transform .5s;
            z-index: 2;

            @media ( max-width: 1024px ) { display: none; }

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
                @media ( max-width: 1024px ) { transform: none; }
            }
            &.disabled { opacity: 0.5; }
            &.disabled:hover { cursor: default; transform: none; }

            &.arrow-left { left: 64px; }
            &.arrow-right { right: 13px; }
        }
        
        // .category-item {
        //     position: relative;
        //     z-index: 1;
        // }
        
        
        // .category-img.selected { border: 2px solid #fba00baa; }
        
        // .category-name {
        //     align-items: center;
        //     color: #eee;
        //     display: flex;
        //     font-size: 16px;
        //     font-weight: 300;
        //     justify-content: center;
        //     height: 100%;
        //     text-align: center;
        //     width: 100%;
        //     z-index: 1;
        // }
        
        // .category-name > div {
        //     padding: 0 3px;
        // }
    
    }

    // .avatar-list-title { 
    //     padding-left: 20vw;
    //     font-size: 26px;

    //     @media ( max-width: 1024px ) { padding-left: 26px; }
    // }

    .user-avatar-box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 26px;

        .avatar_box {
            position: relative;
            z-index: 1;

            .addon_icon_box {
                background-color: #fff;
                border-radius: 50%;
                height: 40px;
                position: absolute;
                right: 40px;
                top: 40px;
                width: 40px;
                z-index: 1;

                @media ( max-width: 767px ) { right: -10px; }
                
                &:hover {
                    cursor: pointer;
                    .addon_icon {
                        transform: scale(1.1);
                        @media ( max-width: 1024px ) { transform: none; }
                    }
                }
    
                .addon_icon {
                    height: 20px;
                    filter: invert(81%) sepia(0%) saturate(2501%) hue-rotate(184deg) brightness(89%) contrast(83%);
                    opacity: 0.8;
                    margin-left: 10px;
                    margin-top: 9px;
                    width: 20px;
                }
            }

            .user-avatar { border-radius: 50%; height: 100px; width: 100px; }
        }

        @media ( max-width: 767px ) {flex-direction: column; }
    }

    .user-avatar {
        border-radius: 50%;
        height: 80px;
        margin-right: 50px;
        margin-top: -20px;
        width: 80px;

        @media ( max-width: 767px ) { margin-right: 0; }
    }

    .user-avatar-first_name {
        display: inline-block;
        font-size: 30px;
        width: 140px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    #profile_nav {
        margin: 0 0 50px -50px;

        @media ( max-width: 767px ) { margin: 0 0 50px -20px; }

        .profile_nav:hover {
            cursor: pointer;
            display: inline-block;
            transform: scale(1.1);
            @media ( max-width: 1024px ) { transform: none; }
        }

        .profile_icon {  
            margin-left: 13px;
        }
    }

    #profile_header {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        @media ( max-width: 767px ) {
            flex-direction: column;
            h1 { margin: 26px 0; }
        }

        h1 { white-space: nowrap; }

        &.profile_header_title {
            justify-content: flex-start;
            @media ( max-width: 767px ) {
                flex-direction: row;
                margin-bottom: 0;
            }
        }

        #profile_menu_details {
            align-items: center;
            background-color: #FFFFFF1C;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            font-size: 16px;
            height: 40px;
            justify-content: center;
            width: 180px;

            &:hover {
                cursor: pointer;

                & .profile_scale {
                    display: inline-block;
                    transform: scale( 1.05 );
                    @media ( max-width: 1024px ) { transform: none; }
                }
            }
        }
    }
    input[type=checkbox]
    {
        width: 0;
        display: none;
    }
    #profile_link {
        background-color: transparent;
        border: none;
        font-size: 16px;
        resize: none;
        overflow: hidden;
        padding: 13px 7px 0px;
        pointer-events: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.profile_link_full {
            margin: 0 100px;
            width: 400px;

            @media ( max-width: 767px ) { margin: 0 64px; width: 220px; }
            @media ( max-width: 500px ) { margin: 0; }
        }

        &::selection {
            background-color: transparent;
            color: #fff;
        }
    }

    #profile_link_step3, #profile_link_dashboard { margin-top: 7px; }

    .profile_card_box {
        align-items: center;
        display: flex;
        justify-content: center;

        @media ( max-width: 767px ) { flex-direction: column; }
    }

    .profile_card {
        background-color: #FFFFFF12;
        border: 1px solid #FFFFFF1A;
        border-radius: 10px;
        font-size: 18px;
        margin-bottom: 50px;
        padding: 13px;

        .text-center {
            text-align: center;
        }

        &.full {
            margin: 26px 2%;
            width: 96%; 

            &.only_btn {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 26px 13px;

                .profile_card_button { width: 100%; }

                button { margin: 13px 0; }
            }

            &.with_margin {
                .profile_card_tall { margin: 42px 0; }

                @media ( max-width: 767px ) {
                    .profile_edit_usual_input { margin: 0 10%; width: 100%; }
                    input { width: 100%; }
                }
            }
        }

        &.half {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 400px;
            width: 48%;
            margin: 0 2%;

            @media ( max-width: 767px ) { margin: 26px 2%; width: 96%; }
        }

        .profile_card_button {
            text-align: center;

            button {
                background-color: #FBA00B;
                border: none;
                border-radius: 5px;
                color: #fff;
                font-weight: 100;
                height: 40px;
                margin-top: 26px;
                padding: 7px 26px;
                min-width: 220px;

                &.tall {
                    min-width: 320px;
                    @media ( max-width: 500px ) { min-width: 80%; max-width: 80%;  height: auto; }
                }
                &.gray { background-color: #AEAEAE; }

                &:hover { cursor: pointer; }
                &.disabled { opacity: 0.6; pointer-events: none; }
            }
        }

        .profile_card_title {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 13px;
            text-align: center;
        }

        .profile_card_tall {
            font-size: 24px;
            margin-bottom: 7px;
        }

        .profile_card_small {
            font-size: 14px;
            margin-bottom: 7px;

            &.special-margin { margin: 7px 0 0 0; }
        }

        .profile_card_list {
            align-items: center;
            border-bottom: 1px solid #FFFFFF1A;
            display: flex;
            font-size: 18px;
            padding: 7px;
            margin: 13px 0;

            @media ( max-width: 767px ) { align-items: flex-start; flex-direction: column; }

            &.first { margin-top: 50px; }
            &.last { margin-bottom: 50px; }
        }
    }

    #profile_body {
        border: 1px solid #FFFFFF1A;
        border-radius: 10px;
        font-size: 18px;
        margin-bottom: 50px;

        .profile_menu {
            align-items: center;
            background-color: #FFFFFF12;
            border-bottom: 1px solid #FFFFFF1A;
            display: flex;
            padding: 26px;

            &.profile_sub { min-height: 100px; }
            .sub_current { display: none; }

            &.selected {
                background-color: #FFFFFF44;
                border: 2px solid #fba00b88 !important;
                &:first-child { border-top-left-radius: 10px; border-top-right-radius: 10px; }
                &:last-child { border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }

                .sub_label { color: #fba00b; font-weight: 700; }
            }

            &.current_offer {
                .sub_current { display: block; }
            }

            .sub_title {
                width: 150px;
            }

            &.profile_hover:hover {
                cursor: pointer;
                &.current_offer { cursor: default; }
        
                & .profile_scale {
                    transform: scale( 1.1 );
                    @media ( max-width: 1024px ) { transform: none; }
                }
                &.current_offer .profile_scale { transform: none; }
            }

            &:last-child {
                border-bottom: none;
            }

            &.menu_card {
                flex-direction: column;
                align-items: flex-start !important;
                font-size: 1.5rem;
                padding: 0;

                .menu_card_title {
                    font-size: 2rem;
                    margin: 13px 0 13px 26px;
                }

                .menu_card_details {
                    margin: 0 0 0 26px;
                }

                .local_stripe_container {
                    font-size: initial;
                    width: 100%;

                    .local_stripe_form {
                        text-align: center;
                        margin: 13px auto;
                        
                        .local_stripe_button {
                            margin: 26px 0;

                            &.gray { background-color: #AEAEAE; }
                        }
                    }
                }
            }
        }

        #profile_menu_reactivate { background: #fba00b88; }

        a.profile_menu_small {
            font-weight: 300;

            &:hover { color: #fff; }
        }

        .profile_menu_small {
            align-items: center;
            border-bottom: 1px solid #FFFFFF1A;
            display: flex;
            padding: 13px 26px;

            &.profile_hover:hover {
                cursor: pointer;

                & .profile_scale {
                    transform: scale( 1.1 );
                    @media ( max-width: 1024px ) { transform: none; }
                }
            }

            &:last-child {
                border-bottom: none;
            }

            .profile_scale_billing_date { display: inline-block; width: 100px; }
            .profile_scale_billing_error { color: #E33939; font-weight: 700; }
        }

        .profile_menu_title {
            align-items: center;
            background-color: #FFFFFF12;
            border-bottom: 1px solid #FFFFFF1A;
            color: #AEAEAE;
            display: flex;
            padding: 13px 26px;
        }
    }

    #profile_footer {
        align-items: center;
        color: #AEAEAE;
        display: flex;
        font-size: 16px;
        justify-content: center;

        #profile_logout {
            cursor: pointer;
        }
    }
    .unsub_form_confirmation
    {
        input
        {
            width: 100%;
        }
    }
}  

.profile_edit_usual_btn {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;

    button {
        background: #FBA00B;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-weight: 100;;
        height: 40px;
        width: 300px;

        &:hover {
            cursor: pointer;
        }
    }
}

.progress {
    height: 150px !important;
    line-height: 150px;
    margin: 26px auto;
    position: relative;
    width: 150px;

    @media ( max-width: 1024px ) { height: 140px !important; margin: 0; width: 140px; }
}
.cat-retour
{
    cursor: pointer;
}
.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 20px solid #00000029;
    position: absolute;
    top: 0;
    left: 0;
}

.progress > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 20px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress.orange {
    background-color: transparent;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left
}

.progress .progress-right {
    right: 0
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards
}

.progress .progress-value {
    color: #fff;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    line-height: 135px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%
}

.progress.orange .progress-bar {
    border-color: #fba00b;
}

.progress.orange .progress-left .progress-bar {
    animation: loading-l 1s linear forwards 2s
}

.progress.orange .progress-right .progress-bar {
    animation: loading-r 2s linear forwards
}

.profile_movieList {
    margin: 13px 0 13px 50px;
}

#profile_container.with_movie {
    min-height: 0;
    padding-bottom: 26px;

    @media ( max-width: 1024px ) { padding-bottom: 26px; margin-bottom: 0; }
}

#profile_container_.with_movie {
    min-height: 0;
    padding-bottom: 0;
    padding-top: 0;

    @media ( max-width: 1024px ) { 
        margin-top: 0;
    }
}

.profile_unsub_patharrow
{
    transform: rotate(180deg);
    margin-right: 5px;
    height: 10px;
}

.profile_unsubStep1_container
{
    max-width: 960px;
    color: white;    
    padding: 120px 0 0;
    // min-width: 200px;
    margin: auto;
}

.profile_intro_texte {
    width: 50%;
    margin: 0 auto 20px;
    @media (max-width: 770px) {
        width: fit-content;
        padding: 5%;
    }

    h1 {
        font-size: 25px;
    }

    p {
        color: rgb(219, 219, 219);
        margin-top: 10px;
    }   
}

#profile_nav2 {
    margin: 0 0 40px;
    display: flex;
    justify-content: space-between;
    
    // @media ( max-width: 767px ) { margin: 0 0 50px -20px; }
    .profile_nav
    {
        color: rgb(112, 112, 112);
    }

    .profile_nav:hover {
        cursor: pointer;
        display: inline-block;
        transform: scale(1.1);
        @media ( max-width: 1024px ) { transform: none; }
    }

    .profile_icon {  
        margin-left: 13px;
    }
}

.profile_unsub_nav_btns {
    margin: 20px auto 0;
    width: 80%;
    color: white;

    &.with_btn-return { margin-top: -13px; }
    &.without_btn-return { margin-top: 0; }

    @media (min-width:370px) {
        display: flex;
        justify-content: center;
        width: 80%;
        max-width: 400px;
        &.with_btn-return {
            align-items: center; flex-direction: column; margin-top: 0;
            .profile_btn_return { margin: 0 0 26px 0; padding: 7px; width: 200px;  }
        }
    }

    @media ( min-width: 770px) {
        display: block;
        width: 200px;
    }

    div {
        width: 100%;
        margin: 0 auto 8px;
        @media( max-width: 770px)
        {
            margin-right: 5px;
        }
    }
}

.profile-unsub-form
{
    max-width: 480px;
    margin: auto;

    ul
    {
        list-style-type: none;
        margin-bottom: 10%;
        
        li
        {
            margin-top: 10px;

            &:nth-child(1)
            {
                position: relative;
            }
        }
    }

    div:nth-child(2)
    {
        h1
        {
            font-size: 27px;
            font-weight: 400;
            line-height: 1.1;
        }
        p
        {
            color: #dcdcdc;
        }
    }

    .unsub_form_confirmation ~ div
    {
        margin-top: 20px;
        text-align: center;
    }

    .profile_icons
    {
        position: absolute;
        left: -30px;
        top: 5px;
    }

    #info
    {
        fill: #fba00b;
    }
}

.checkbox-section-container
{
    margin: 0 auto 20px;
    &>div:nth-child(1)
    {
        margin-bottom: 10px;
    }
    .input-groups
    {
        label{
            color: rgb(161, 161, 161);
            font-size: 16px;
        }

        input::placeholder
        {
            color: white;
        }
    }
    .fade
    {
        margin-left: 35px;

        input {
            @media ( max-width: 767px ) { width: 200px; }
        }
    }
}

.checkbox-wrapper
{
    display: flex;
    padding: 7px 0;

    > div 
    {
        cursor: pointer;
        &:nth-child(2)
        {
            font-weight: 100;
            font-size: 16px;
        }
    }

    .checkBox {
        margin-right: 20px;    
        width: 20px;    
        position: relative;
    
        
        label {
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            border-radius: 4px;
            background: transparent;
            border: 1px solid white;

            &:after {
                opacity: 0;
                content: '';
                position: absolute;
                width: 10px;    
                height: 7px;
                top: 5px;
                left: 5px;
                border: 3px solid #fcfff4;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
            }

            @media(min-width: 1025px) { 
                &:hover { opacity: 0.6; }
            }
        }
        
        input[type=checkbox]:checked + label:after {
            opacity: 1;
        }
        
        input[type=checkbox]:checked + label {
            background: #fba00b;
            border: none;
            &:hover { opacity: 1; }
        }
            
    }
}

#result-container1 > .GBListItem
{
    top: 0;
    .movie_mozaic
    {
        justify-content: center;
        margin: 0;
    }
    
    .effect-thumbnail-movie 
    {
        border-radius: 7px;
    }
}
.profile_unsubStep1_container
+ #result-container1
{
    @media(max-width: 1024px) { 
        
        margin: 25px 13px 0 13px  !important;
    }
}
.btn
{
    font-size: 16px;
    padding: 0.5rem 0.2rem;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
        display: block;
    }

    &:hover > *{
        transform: scale(1.1)
    }

}

.profile_btn_return {
    @extend .btn;
    background-color: #fba00b;
}

.profile_btn_continuer {
    @extend .btn;
    background-color: rgb(175, 175, 175);
}

.profile_btn_unsub {
    @extend .btn;
    background-color: rgb(218, 5, 5);
}

.profile_btn_save {
    @extend .btn;
    background-color: #fba00b;
    // width: 300px;
    &:hover
    {
        background-color: lighten($color: #fba00b, $amount: 10);
    }
}

.cat-selects-container
{
    display: flex;
    flex-wrap: wrap;
    .catValidated
    {
        background: #FBA00B !important;
    }
    .cat-select
    {
        border-radius: 50px;
        background: #FFFFFF1C;
        padding: 4px 12px;
        width: fit-content;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
        font-size: 16px;
        margin-top: 10px;
        transition: 0.4s;
        &:hover
        {
            opacity: 0.8;
        }
    }
}
.title-wrap-next-overlay
{
    color: white;
}
.form-textarea
{
    textarea
    {
        display: block;
        width: 100%;
        resize: none;
        background: #FFFFFF1C;
        color: #fff;
        border: none;
        border-radius: 6px;
    }
    span 
    {
        color: rgb(161, 161, 161);
    }
}
.fade
{
    animation: fade 1s;
    opacity: 1;
}

.selected-cat
{   
    border-radius: 50px;
    background: #FFFFFF1C;
    display: flex;
    padding: 4px 12px;
    width: fit-content;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
    margin-top: 10px;
    transition: 0.4s;
    &:hover{
        transform: scale(1.05);
        svg
        {
            fill: #979797;
        }
    }
    svg
    {
        transition: 0.2s;
    }
}
.cat-selects-title
{
    padding: 4px 12px;
}
@keyframes fade {

    from{
        opacity: 0;
    }
    
    to{
        opacity: 1;
    }
}

#profile_unsub_clock {
    color: #fba00b;
    display: inline-block;
    font-size: 32px;
    margin: 13px 0;
}

.profile_unsub_list {
    margin-top: 52px;
    padding-left: 0 !important;
}

.profile_unsub_title { text-align: center; }

#profile_container #profile_header.orders_header {
    margin-bottom: 26px;

    @media ( max-width: 767px ) {
        flex-direction: row;
        h1 { margin: 26px 0; }
    }

    .profile_icon { height: 26px;  margin: 0; width: 26px; }

    .orders_btn_inactive {
        pointer-events: none;
        .profile_icon { opacity: .4; }
    }

    .orders_btn_active {
        cursor: pointer;
        .profile_icon { filter: invert( 100% ); }
    } 
}

.purchase_general {
    div { margin: 7px 0; }

    .purchase_status_delivered {
        margin: 13px 0;

        span {
            border-radius: 5px;
            background: #FFFFFF1C;
            display: inline-block;
            color: #54C254;
            font-weight: 700;
            padding: 1px 26px;
        }
    }

    .purchase_status_deliveryProcess {
        margin: 13px 0;

        span {
            border-radius: 5px;
            background: #FFFFFF1C;
            display: inline-block;
            color: #eee;
            font-weight: 700;
            padding: 1px 26px;
        }
    }

    .purchase_status_paid {
        margin: 13px 0;

        span {
            border-radius: 5px;
            background: #FFFFFF1C;
            display: inline-block;
            color: #fba00b;
            font-weight: 700;
            padding: 1px 26px;
        }
    }

    .purchase_general_title { font-size: 1.5rem; font-weight: 700; margin-bottom: 26px; }
    .purchase_general_phone { margin-top: 26px; }
}

.profile_remove_info { margin-top: 13px; text-align: center; }