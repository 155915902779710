@charset "UTF-8";
/* vars */
/* mixins */
/* responsive layout */
/* button styles */
/* CSS effects and styles of tiles */
body {
  margin: 0;
  padding: 0;
  /*
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@media screen and (min-width: 1024px) {
  .list-title {
    padding-top: 40px;
    padding-bottom: 10px; }
  .list-title h3 {
    font-size: 2rem; }
    .list-title h3 .list-title-prename {
      font-size: 1.5rem;
      color: #ddd;
      display: inline-block;
      margin-right: 13px;
      vertical-align: middle; }
    .list-title h3 .list-title-prepng {
      display: inline-block;
      margin-right: 13px; } }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

footer {
  height: 100px;
  margin-top: 300px; }
  @media (max-width: 1024px) {
    footer {
      height: 160px; } }
  @media (max-width: 800px) {
    footer {
      height: 170px;
      margin-top: 280px; } }
  footer p {
    padding-top: 12px;
    font-size: 0.7em !important; }
  footer .navbar-desabo {
    cursor: pointer;
    color: rgba(177, 163, 116, 0.57) !important; }
  footer nav.navbar {
    justify-content: flex-end; }

#advertisement {
  color: white;
  font-size: 14px;
  text-align: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.87);
  display: block;
  width: 100%;
  float: none;
  bottom: 0;
  z-index: 88888;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 1px;
  padding-bottom: 5px; }

#advertisement .btn, #advertisement .profile_btn_return, #advertisement .profile_btn_continuer, #advertisement .profile_btn_unsub, #advertisement .profile_btn_save {
  font-size: x-large !important; }

#advertisement_playlist {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 1px;
  bottom: 0;
  color: white;
  display: flex;
  float: none;
  font-size: 14px;
  justify-content: space-around;
  min-height: 12vh;
  padding: 0 10vw 5px 10vw;
  position: fixed;
  width: 100%;
  z-index: 88888; }

#advertisement_guest, #advertisement_guest_mobile, #advertisement_discarded {
  align-items: center;
  background-color: #fba00b;
  color: #fff;
  font-size: 14px;
  justify-content: space-around;
  padding: 13px 0;
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  z-index: 10; }
  @media (max-width: 767px) {
    #advertisement_guest, #advertisement_guest_mobile, #advertisement_discarded {
      flex-direction: column; } }
  #advertisement_guest .btn, #advertisement_guest .profile_btn_return, #advertisement_guest .profile_btn_continuer, #advertisement_guest .profile_btn_unsub, #advertisement_guest .profile_btn_save, #advertisement_guest_mobile .btn, #advertisement_guest_mobile .profile_btn_return, #advertisement_guest_mobile .profile_btn_continuer, #advertisement_guest_mobile .profile_btn_unsub, #advertisement_guest_mobile .profile_btn_save, #advertisement_discarded .btn, #advertisement_discarded .profile_btn_return, #advertisement_discarded .profile_btn_continuer, #advertisement_discarded .profile_btn_unsub, #advertisement_discarded .profile_btn_save {
    background-color: #fff !important;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #000 !important;
    width: 150px; }
    #advertisement_guest .btn:hover, #advertisement_guest .profile_btn_return:hover, #advertisement_guest .profile_btn_continuer:hover, #advertisement_guest .profile_btn_unsub:hover, #advertisement_guest .profile_btn_save:hover, #advertisement_guest_mobile .btn:hover, #advertisement_guest_mobile .profile_btn_return:hover, #advertisement_guest_mobile .profile_btn_continuer:hover, #advertisement_guest_mobile .profile_btn_unsub:hover, #advertisement_guest_mobile .profile_btn_save:hover, #advertisement_discarded .btn:hover, #advertisement_discarded .profile_btn_return:hover, #advertisement_discarded .profile_btn_continuer:hover, #advertisement_discarded .profile_btn_unsub:hover, #advertisement_discarded .profile_btn_save:hover {
      border: 2px solid #000 !important; }

#advertisement_discarded .btn, #advertisement_discarded .profile_btn_return, #advertisement_discarded .profile_btn_continuer, #advertisement_discarded .profile_btn_unsub, #advertisement_discarded .profile_btn_save {
  width: 280px; }

#advertisement_guest_bottom {
  color: white;
  font-size: 2rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  bottom: 38vh;
  width: 100%;
  float: none;
  z-index: 3;
  border-radius: 1px;
  min-height: 40vh;
  text-align: center;
  padding: 0 100px; }
  #advertisement_guest_bottom h3 {
    font-size: 3rem; }
  #advertisement_guest_bottom .btn, #advertisement_guest_bottom .profile_btn_return, #advertisement_guest_bottom .profile_btn_continuer, #advertisement_guest_bottom .profile_btn_unsub, #advertisement_guest_bottom .profile_btn_save {
    font-size: 1rem !important;
    margin-top: -13px !important;
    width: 150px; }
    #advertisement_guest_bottom .btn.btnLogin, #advertisement_guest_bottom .btnLogin.profile_btn_return, #advertisement_guest_bottom .btnLogin.profile_btn_continuer, #advertisement_guest_bottom .btnLogin.profile_btn_unsub, #advertisement_guest_bottom .btnLogin.profile_btn_save {
      background-color: transparent !important;
      color: #ffffff !important;
      border: none !important; }
    #advertisement_guest_bottom .btn.btnOffer:hover, #advertisement_guest_bottom .btnOffer.profile_btn_return:hover, #advertisement_guest_bottom .btnOffer.profile_btn_continuer:hover, #advertisement_guest_bottom .btnOffer.profile_btn_unsub:hover, #advertisement_guest_bottom .btnOffer.profile_btn_save:hover {
      background-color: #fba00b !important;
      color: #ffffff !important; }

#advertisement_playlist .btn, #advertisement_playlist .profile_btn_return, #advertisement_playlist .profile_btn_continuer, #advertisement_playlist .profile_btn_unsub, #advertisement_playlist .profile_btn_save {
  font-size: x-large !important;
  margin-top: -13px !important; }

@media (min-width: 992px) and (max-width: 1200px) {
  #advertisement_playlist {
    font-size: 14px;
    padding: 0 5vw 5px 5vw; } }

@media (max-width: 991px) {
  #advertisement_playlist {
    flex-direction: column;
    padding: 0 2vw 5px 2vw; }
    #advertisement_playlist h3 {
      font-size: 3vw !important;
      margin-bottom: 26px !important; }
  #advertisement_guest .btn, #advertisement_guest .profile_btn_return, #advertisement_guest .profile_btn_continuer, #advertisement_guest .profile_btn_unsub, #advertisement_guest .profile_btn_save, #advertisement_discarded .btn, #advertisement_discarded .profile_btn_return, #advertisement_discarded .profile_btn_continuer, #advertisement_discarded .profile_btn_unsub, #advertisement_discarded .profile_btn_save {
    height: 35px; }
  #advertisement_guest_mobile .btn, #advertisement_guest_mobile .profile_btn_return, #advertisement_guest_mobile .profile_btn_continuer, #advertisement_guest_mobile .profile_btn_unsub, #advertisement_guest_mobile .profile_btn_save, #advertisement_discarded .btn, #advertisement_discarded .profile_btn_return, #advertisement_discarded .profile_btn_continuer, #advertisement_discarded .profile_btn_unsub, #advertisement_discarded .profile_btn_save {
    height: 35px; } }

@media (max-width: 767px) {
  #advertisement_playlist h3 {
    font-size: 4vw !important; }
  #advertisement_guest_bottom {
    font-size: 18px !important;
    padding: 0 23px; }
    #advertisement_guest_bottom h3 {
      font-size: 24px !important; } }

#advertisement_guest {
  display: flex; }

#advertisement_guest_mobile {
  display: none;
  padding: 7px 0; }

#advertisement_guest_mobile .advertisement_guest_mobile_btns {
  display: flex; }

@media (max-width: 1024px) {
  #advertisement_guest, #advertisement_guest_mobile, #advertisement_discarded {
    bottom: 60px; }
  #advertisement_guest {
    display: none; }
  #advertisement_guest_mobile {
    display: flex; } }

.hide {
  display: none; }

@media (min-width: 1025px) {
  #nav-container-mobile {
    display: none; } }

#nav-container-mobile {
  color: #AEAEAE;
  font-size: 16px; }
  @media (max-width: 767px) {
    #nav-container-mobile {
      font-size: 12px; } }
  #nav-container-mobile #nav-container-mobile-up {
    background: #111111e6;
    color: #AEAEAE;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4; }
    #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right {
      align-items: center;
      display: flex;
      padding-right: 13px; }
      #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item {
        display: inline-block;
        margin: 7px 26px 7px 0;
        position: relative;
        z-index: 2; }
        #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu {
          background-color: #000000;
          border-radius: 5px;
          top: 40px;
          color: #eee;
          right: -50px;
          min-width: 300px;
          padding: 26px 13px;
          position: absolute;
          z-index: 1; }
          #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-title {
            font-size: 1.5em;
            padding: 13px 0;
            text-align: center;
            width: 100%; }
          #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-item {
            align-items: center;
            border-top: 1px solid #aeaeae55;
            display: flex;
            padding: 26px 0; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-item.linked {
              cursor: pointer; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-item .notif-context-menu-info {
              color: #eee;
              padding-left: 26px; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-item .tile-img-small {
              width: 120px; }
          #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .notif-context-menu .notif-context-menu-close button {
            border: 0px;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            font-size: 20px;
            position: absolute;
            padding: 24px;
            right: 0;
            top: 0;
            z-index: 9;
            background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
            background-position: 50% -50%; }
        #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .profile-context-menu {
          background-color: #000000;
          border: 1px solid #AEAEAE44;
          border-radius: 5px;
          top: 43px;
          color: #eee;
          right: 37px;
          min-width: 150px;
          padding: 13px;
          position: absolute;
          z-index: 1; }
          #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .profile-context-menu .profile-context-menu-item {
            align-items: center;
            display: flex;
            padding: 7px 0; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .profile-context-menu .profile-context-menu-item.linked {
              cursor: pointer; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .profile-context-menu .profile-context-menu-item .notif-context-menu-info {
              color: #eee;
              padding-left: 26px; }
            #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right .nav_item .profile-context-menu .profile-context-menu-item .tile-img-small {
              width: 120px; }
      #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right span.icon_box {
        display: inline-block;
        position: relative;
        text-align: center;
        width: 60px; }
      #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right span.dot {
        border-radius: 50%;
        background-color: #ff0000;
        display: inline-block;
        height: 11px;
        position: absolute;
        right: 14px;
        top: -3px;
        width: 11px; }
      #nav-container-mobile #nav-container-mobile-up .nav-container-mobile-up-right img.nav_icon {
        height: 30px; }
    #nav-container-mobile #nav-container-mobile-up img.nav_icon {
      -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
              filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
      height: 30px; }
      @media (max-width: 767px) {
        #nav-container-mobile #nav-container-mobile-up img.nav_icon {
          height: 20px; } }
    #nav-container-mobile #nav-container-mobile-up a {
      color: inherit;
      display: block; }
      #nav-container-mobile #nav-container-mobile-up a.selected {
        color: #FBA00B; }
        #nav-container-mobile #nav-container-mobile-up a.selected img.nav_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
    #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-profile {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 7px 10px; }
      #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-profile #nav_profile-mobile {
        font-size: 30px; }
        @media (max-width: 767px) {
          #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-profile #nav_profile-mobile {
            font-size: 20px; } }
    #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
      align-items: center;
      display: flex;
      font-size: 24px;
      justify-content: space-around;
      padding: 26px; }
      @media (max-width: 767px) {
        #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
          font-size: 16px; } }
      @media (max-width: 767px) {
        #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
          padding: 26px 13px; } }
      #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns a {
        align-items: center;
        background-color: #333333;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 7px 0;
        width: 150px; }
        @media (max-width: 767px) {
          #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns a {
            width: 90px; } }
        #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns a span.icon_box {
          padding-right: 26px; }
          @media (max-width: 767px) {
            #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns a span.icon_box {
              padding-right: 7px; } }
  #nav-container-mobile .hidden-nav {
    display: none !important; }
  #nav-container-mobile #nav-container-mobile-down {
    align-items: center;
    background: #111;
    bottom: 0;
    color: #AEAEAE;
    display: flex;
    justify-content: space-around;
    height: 60px;
    padding: 13px 26px 13px 26px;
    position: fixed;
    width: 100%;
    z-index: 4; }
    #nav-container-mobile #nav-container-mobile-down img.nav_icon {
      -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
              filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
      height: 30px; }
      @media (max-width: 767px) {
        #nav-container-mobile #nav-container-mobile-down img.nav_icon {
          height: 20px; } }
    #nav-container-mobile #nav-container-mobile-down a {
      color: inherit;
      display: block; }
      #nav-container-mobile #nav-container-mobile-down a.selected {
        color: #FBA00B; }
        #nav-container-mobile #nav-container-mobile-down a.selected img.nav_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
      #nav-container-mobile #nav-container-mobile-down a span {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%; }
        #nav-container-mobile #nav-container-mobile-down a span.icon_box {
          align-items: center;
          display: flex;
          justify-content: center;
          margin-bottom: 7px;
          width: 100%; }
        #nav-container-mobile #nav-container-mobile-down a span.dot {
          border-radius: 50%;
          background-color: red;
          display: inline-block;
          height: 13px;
          margin: 7px 0 10px 0;
          width: 13px; }
          @media (max-width: 767px) {
            #nav-container-mobile #nav-container-mobile-down a span.dot {
              margin: 3px 0 4px 0; } }

@-webkit-keyframes blinking {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }

@keyframes blinking {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }
        #nav-container-mobile #nav-container-mobile-down a span.live {
          -webkit-animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate;
                  animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate; }
  #nav-container-mobile #nav-container-mobile-up-profile .user-avatar {
    border-radius: 50%;
    height: 60px;
    margin-right: 50px;
    width: 60px; }
    @media (max-width: 767px) {
      #nav-container-mobile #nav-container-mobile-up-profile .user-avatar {
        margin-right: 0; } }
    @media (max-width: 425px) {
      #nav-container-mobile #nav-container-mobile-up-profile .user-avatar {
        height: 40px;
        width: 40px; } }
  #nav-container-mobile #nav_logo-mobile img {
    max-width: 100px;
    height: auto; }
    @media (max-width: 425px) {
      #nav-container-mobile #nav_logo-mobile img {
        max-width: 80px;
        height: auto; } }
  #nav-container-mobile .scrollingMenu {
    -webkit-animation: scrollingMenu 0.5s;
            animation: scrollingMenu 0.5s;
    bottom: 60px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 11; }
    @media (min-width: 767px) {
      #nav-container-mobile .scrollingMenu {
        bottom: 55px; } }
    #nav-container-mobile .scrollingMenu a {
      color: inherit;
      display: block; }
      #nav-container-mobile .scrollingMenu a.selected {
        color: #FBA00B; }
        #nav-container-mobile .scrollingMenu a.selected img.nav_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
        #nav-container-mobile .scrollingMenu a.selected .nav_item_text {
          color: #FBA00B; }
  #nav-container-mobile .menuElement {
    background: #0e0d0d;
    display: flex;
    padding: 20px 26px; }
    #nav-container-mobile .menuElement:hover {
      background: #252424; }
    #nav-container-mobile .menuElement .icon_box {
      width: 30px; }
    #nav-container-mobile .menuElement img.nav_icon {
      -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
              filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
      height: 30px; }
      @media (max-width: 767px) {
        #nav-container-mobile .menuElement img.nav_icon {
          height: 20px; } }
  #nav-container-mobile .nav_item_text {
    color: white;
    font-size: 18px;
    padding-left: 20px; }
    @media (max-width: 425px) {
      #nav-container-mobile .nav_item_text {
        font-size: 16px; } }
  #nav-container-mobile .dot {
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    height: 13px;
    margin: 7px 0 10px 0;
    width: 13px; }
    @media (max-width: 767px) {
      #nav-container-mobile .dot {
        margin: 3px 0 4px 0; } }
  #nav-container-mobile .live {
    -webkit-animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate;
            animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate; }

.icon_box_more {
  margin-bottom: 6px;
  height: 22px !important; }
  @media (min-width: 767px) {
    .icon_box_more {
      height: 32px !important; } }

@-webkit-keyframes scrollingMenu {
  from {
    bottom: -600px; }
  to {
    bottom: 60px; } }

@keyframes scrollingMenu {
  from {
    bottom: -600px; }
  to {
    bottom: 60px; } }

.hide {
  display: none; }

@media (max-width: 1024px) {
  #nav-container-web, #nav-container-web-up {
    display: none !important; } }

#nav-container-web-up {
  align-items: center;
  background: #000;
  display: flex;
  justify-content: space-between;
  height: 68px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3; }
  #nav-container-web-up #nav_logo-web {
    margin-left: 100px; }
    #nav-container-web-up #nav_logo-web .nav_logo {
      height: 30px; }
  #nav-container-web-up .nav_item {
    display: inline-block;
    margin: 7px 26px 7px 0;
    position: relative;
    z-index: 1; }
    #nav-container-web-up .nav_item .notif-context-menu {
      background-color: #000000;
      border: 1px solid #AEAEAE44;
      border-radius: 5px;
      top: 30px;
      color: #eee;
      right: 30px;
      min-width: 400px;
      padding: 26px;
      position: absolute;
      z-index: 1; }
      #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-title {
        font-size: 1.5em;
        padding: 13px 0;
        text-align: center;
        width: 100%; }
      #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-item {
        align-items: center;
        border-top: 1px solid #aeaeae55;
        display: flex;
        padding: 26px 0; }
        #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-item.linked {
          cursor: pointer; }
        #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-item .notif-context-menu-info {
          color: #eee;
          padding-left: 26px; }
        #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-item .tile-img-small {
          width: 120px; }
      #nav-container-web-up .nav_item .notif-context-menu .notif-context-menu-close button {
        border: 0px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        padding: 24px;
        right: 0;
        top: 0;
        z-index: 9;
        background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background-position: 50% -50%; }
    #nav-container-web-up .nav_item .profile-context-menu {
      background-color: #000000;
      border: 1px solid #AEAEAE44;
      border-radius: 5px;
      top: 37px;
      color: #eee;
      right: 37px;
      min-width: 150px;
      padding: 13px;
      position: absolute;
      z-index: 1; }
      #nav-container-web-up .nav_item .profile-context-menu .profile-context-menu-item {
        align-items: center;
        display: flex;
        padding: 7px 0; }
        #nav-container-web-up .nav_item .profile-context-menu .profile-context-menu-item.linked {
          cursor: pointer; }
        #nav-container-web-up .nav_item .profile-context-menu .profile-context-menu-item .notif-context-menu-info {
          color: #eee;
          padding-left: 26px; }
        #nav-container-web-up .nav_item .profile-context-menu .profile-context-menu-item .tile-img-small {
          width: 120px; }
  #nav-container-web-up span.icon_box {
    display: inline-block;
    position: relative;
    text-align: center;
    width: 60px; }
  #nav-container-web-up span.dot {
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    height: 11px;
    position: absolute;
    right: 14px;
    top: -3px;
    width: 11px; }
  #nav-container-web-up img.nav_icon {
    -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
    height: 30px; }
  #nav-container-web-up .selected {
    color: #FBA00B; }
    #nav-container-web-up .selected img.nav_icon {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  #nav-container-web-up .nav_item.active:hover, #nav-container-web-up a:hover {
    color: #fff;
    cursor: pointer; }
    #nav-container-web-up .nav_item.active:hover img.nav_icon, #nav-container-web-up a:hover img.nav_icon {
      -webkit-filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
              filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%); }
    #nav-container-web-up .nav_item.active:hover.selected, #nav-container-web-up a:hover.selected {
      color: #FBA00B; }
      #nav-container-web-up .nav_item.active:hover.selected img.nav_icon, #nav-container-web-up a:hover.selected img.nav_icon {
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  #nav-container-web-up .user-avatar {
    border-radius: 50%;
    height: 40px;
    width: 40px; }
    @media (max-width: 767px) {
      #nav-container-web-up .user-avatar {
        margin-right: 0; } }
    @media (max-width: 425px) {
      #nav-container-web-up .user-avatar {
        height: 30px;
        width: 30px; } }
  #nav-container-web-up .language_custom_select {
    display: inline-block;
    position: relative;
    margin: 7px 26px 7px 0;
    width: 100px;
    z-index: 1; }
    #nav-container-web-up .language_custom_select.box_center {
      padding: 26px 0;
      width: 100%; }
    #nav-container-web-up .language_custom_select:hover {
      cursor: pointer; }
    #nav-container-web-up .language_custom_select .language_select_selected {
      background: #FFFFFF1C;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      border-radius: 2px;
      color: #fff;
      font-size: 20px;
      font-weight: 100;
      padding: 4px 13px;
      width: 100px; }
      #nav-container-web-up .language_custom_select .language_select_selected.open {
        border-bottom: 3px solid #FFFFFF1A;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      #nav-container-web-up .language_custom_select .language_select_selected:hover {
        cursor: pointer; }
    #nav-container-web-up .language_custom_select .language_select_option {
      background: #111;
      border-bottom: 1px solid #eaeaea;
      bottom: -45px;
      color: #fff;
      display: none;
      font-size: 20px;
      font-weight: 100;
      padding: 7px 13px;
      position: absolute;
      width: 100px;
      z-index: 1; }
      #nav-container-web-up .language_custom_select .language_select_option:last-child {
        bottom: -90px; }
      #nav-container-web-up .language_custom_select .language_select_option.show {
        display: block; }
      #nav-container-web-up .language_custom_select .language_select_option.last {
        border-bottom: none;
        border-radius: 2px; }

#nav-container-web {
  background: radial-gradient(circle at 50% 0, #0038385e, #00000047 70.71%), radial-gradient(circle at 6.7% 75%, #002420, #000000 70.71%), radial-gradient(circle at 93.3% 75%, #00211e, #003838 70.71%) #000000;
  color: #AEAEAE;
  font-size: 30px;
  height: 100%;
  padding: 60px 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  white-space: nowrap;
  width: 60px;
  transition: all .5s;
  z-index: 4; }
  #nav-container-web:hover {
    box-shadow: 20px 0px 30px #000;
    width: 300px; }
  #nav-container-web a {
    color: inherit;
    font-weight: unset;
    display: block; }
  #nav-container-web #nav_profile-web {
    height: 45px;
    padding-bottom: 100px; }
  #nav-container-web .nav_item {
    padding-bottom: 20px; }
  #nav-container-web .nav_item_profile {
    margin-left: -5px; }
  #nav-container-web span.dot {
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    height: 13px;
    margin: 0 7px 3px 3px;
    width: 13px; }

@keyframes blinking {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }
  #nav-container-web span.live {
    -webkit-animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate;
            animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate; }
  #nav-container-web span.icon_box {
    display: inline-block;
    margin-right: 13px;
    text-align: center;
    width: 60px; }
  #nav-container-web img.nav_icon {
    -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
    height: 30px; }
  #nav-container-web .selected {
    color: #FBA00B; }
    #nav-container-web .selected img.nav_icon {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  #nav-container-web a:hover, #nav-container-web #nav_playlist-web:hover {
    color: #fff;
    cursor: pointer; }
    #nav-container-web a:hover img.nav_icon, #nav-container-web #nav_playlist-web:hover img.nav_icon {
      -webkit-filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
              filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%); }
    #nav-container-web a:hover.selected, #nav-container-web #nav_playlist-web:hover.selected {
      color: #FBA00B; }
      #nav-container-web a:hover.selected img.nav_icon, #nav-container-web #nav_playlist-web:hover.selected img.nav_icon {
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  #nav-container-web #nav_logo-web {
    position: absolute;
    bottom: 60px;
    left: 60px;
    z-index: 1; }
  #nav-container-web .user-avatar {
    border-radius: 50%;
    height: 40px;
    margin-left: 10px;
    width: 40px;
    margin-right: 26px; }
    @media (max-width: 767px) {
      #nav-container-web .user-avatar {
        margin-right: 0; } }
    @media (max-width: 425px) {
      #nav-container-web .user-avatar {
        height: 30px;
        width: 30px; } }
  #nav-container-web .nav_item_text {
    font-size: 24px; }
  #nav-container-web #nav_boutique-web .nav_item_text {
    padding-bottom: 13px; }
  #nav-container-web #nav_boutique-web img {
    margin-bottom: 2px; }

.categories-list-box {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  margin-top: 40px;
  padding-top: 28px;
  padding-left: 50px;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none; }
  .categories-list-box.store-list {
    padding-left: 0;
    margin-top: 26px; }
    .categories-list-box.store-list .arrow-left {
      left: 13px; }

@media (max-width: 1024px) {
  .categories-list-box {
    padding: 26px 13px 0 13px; }
  .soon-container ~ .categories-list-box {
    margin-top: 0; } }

.categories-list-box + #home_Homelist .GBListItem {
  top: 0; }

.categories-list-box + #chasse_Homelist .GBListItem {
  top: 0; }

.categories-list-box + #peche_Homelist .GBListItem {
  top: 0; }

.categories-list {
  align-items: flex-start;
  background-color: transparent;
  color: #c1c1c1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow-x: scroll;
  padding: 7px 13px 0 13px;
  -ms-overflow-style: none; }

.categories-list::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important; }

.category-box {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 13px; }

@media (min-width: 1024px) {
  .category-box:hover .category-item .category-img {
    border: 3px solid #fba00b;
    -webkit-transform: scale(1.08);
            transform: scale(1.08); } }

a.category-box {
  color: #eee; }

.category-item {
  position: relative;
  z-index: 1; }
  .category-item.store-category .category-img {
    border: 3px solid #fba00b22; }
  .category-item.store-category .category-name {
    font-size: 16px; }

.category-img {
  border-radius: 50%;
  height: 120px;
  object-fit: cover;
  transition: border 0.1s, -webkit-transform .4s;
  transition: transform .4s, border 0.1s;
  transition: transform .4s, border 0.1s, -webkit-transform .4s;
  width: 120px; }
  @media (max-width: 425px) {
    .category-img {
      height: 80px;
      width: 80px; } }

.category-img.selected {
  border: 2px solid #fba00baa; }

.category-name {
  align-items: center;
  color: #eee;
  display: flex;
  font-size: 18px;
  font-weight: 300;
  justify-content: center;
  height: 100%;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  z-index: 1; }
  @media (max-width: 425px) {
    .category-name {
      font-size: 16px; } }

.category-name > div {
  max-width: 130px;
  padding: 0 3px; }

.categories-list-title {
  color: #c1c1c1;
  font-size: 2rem;
  font-weight: 500;
  left: 90px;
  position: absolute;
  top: -13px;
  z-index: 2; }

@media (max-width: 1024px) {
  .categories-list-title {
    font-size: 1.75rem;
    left: 53px; } }

@media (max-width: 767px) {
  .categories-list-title {
    font-size: 1.25rem;
    left: 36px; } }

.arrow {
  color: #eee;
  display: block;
  position: absolute;
  font-size: 42px;
  top: 70px;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
  z-index: 2; }

.arrow:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }
  @media (max-width: 1024px) {
    .arrow:hover {
      -webkit-transform: none;
              transform: none; } }

.arrow.disabled {
  opacity: 0.5; }

.arrow.disabled:hover {
  cursor: default;
  -webkit-transform: none;
          transform: none; }

.arrow-left {
  left: 64px; }

.arrow-right {
  right: 13px; }

@media (max-width: 1024px) {
  .categories-list {
    padding: 0; }
  .arrow {
    display: none; } }

.categories-list-box.distribution-list-box {
  margin: 0;
  padding: 0; }
  .categories-list-box.distribution-list-box .category-box {
    padding: 0 13px 0 0; }
  .categories-list-box.distribution-list-box .arrow {
    top: 42px; }
  .categories-list-box.distribution-list-box .arrow-left {
    left: -20px; }
  .categories-list-box.distribution-list-box .arrow-right {
    right: -30px; }
  .categories-list-box.distribution-list-box .category-name {
    flex-direction: column; }
  .categories-list-box.distribution-list-box .distribution-role {
    color: #aeaeae; }

@media (min-width: 1025px) {
  #chasse_Homelist {
    margin: 0 0 0 80px; } }

@media (max-width: 1024px) {
  #chasse_Homelist {
    margin: 0 13px; } }

@media (min-width: 1025px) {
  #peche_Homelist {
    margin: 0 0 0 80px; } }

@media (max-width: 1024px) {
  #peche_Homelist {
    margin: 0 13px; } }

/* tiles CSS transition and transform effects */
.GBListItem {
  position: relative;
  top: 82px; }
  .GBListItem.store-row {
    top: 30px; }

.list-container {
  max-width: 100%;
  padding: 0px;
  position: relative; }

.list-title {
  padding-left: 40px;
  font-weight: bold;
  color: #C1C1C1;
  font-family: 'balboa', sans-serif; }

.listRow {
  display: flex;
  width: 100%;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform; }
  .listRow:hover {
    -webkit-transform: translate3d(-120px, 0, 0);
            transform: translate3d(-120px, 0, 0); }

.tile-title {
  padding-top: 5px;
  color: #7d8877; }

.tile-overflow {
  overflow: hidden; }

.tile {
  position: relative;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  padding: 5px;
  /*    overflow: hidden; */
  width: 280px;
  height: 160px;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%; }
  .tile:hover {
    -webkit-transform: scale(1.7);
            transform: scale(1.7); }
  .tile:hover ~ .tile {
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0); }
  .tile .tile-img {
    transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
    object-fit: cover;
    width: 280px;
    height: 160px; }

.photo-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  /*centering text inside .photo-overlay */
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s; }

.photo-overlay:hover {
  opacity: 1; }

.tile-desc {
  font-size: 0.6em;
  line-height: 1.3;
  margin: 0 0; }

.tile-btn-container {
  margin-top: 130px; }
  .tile-btn-container .addListBtn {
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid #a3a3a1;
    display: inline-block;
    cursor: pointer;
    color: #ecf0f1;
    font-size: 24px;
    padding: 2px 12px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
    margin-right: 10px;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
    .tile-btn-container .addListBtn:focus {
      outline: 0; }
    .tile-btn-container .addListBtn:hover {
      border: 2px solid #ecf0f1;
      background-color: transparent;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .tile-btn-container .addListBtn:active {
      position: relative;
      top: 1px; }
  .tile-btn-container .addListBtn {
    font-size: 22px;
    border: 1px solid #a3a3a1;
    padding: 10px 4px; }
    .tile-btn-container .addListBtn:hover {
      border: 1px solid #ecf0f1; }

.playbtn-container {
  margin: 35px 0px 10px 0px;
  text-align: center; }
  .playbtn-container .playBtn {
    content: "\25B6";
    background-color: transparent;
    color: #ecf0f1;
    border-radius: 100%;
    border: 2px solid #ecf0f1;
    display: inline-block;
    cursor: pointer;
    font-size: 24px;
    padding: 22px 10px 22px 15px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    opacity: 0.3; }
    .playbtn-container .playBtn:focus {
      outline: 0; }
    .playbtn-container .playBtn:hover {
      color: #b9090b;
      border: 2px solid #ecf0f1;
      background-color: transparent;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.long-title {
  font-size: 0.9em; }
  @media (max-width: 1200px) {
    .long-title {
      font-size: 0.8em; } }
  @media (max-width: 1024px) {
    .long-title {
      font-size: 0.5em; } }

.longer-title {
  font-size: 0.75em; }
  @media (max-width: 1200px) {
    .longer-title {
      font-size: 0.65em; } }
  @media (max-width: 1024px) {
    .longer-title {
      font-size: 0.35em; } }

/* netflix-like carousel effects */
html {
  overflow: scroll;
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 0px;
  background: transparent; }

.left-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 4%;
  height: 280px;
  z-index: 40;
  background: #0f0f0f;
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
  display: flex;
  color: #fff;
  justify-content: center; }

.right-controls {
  position: absolute;
  top: 0;
  right: 0;
  width: 4%;
  height: 280px;
  z-index: 40;
  background: #0f0f0f;
  opacity: 0.5;
  display: flex;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  color: #fff; }

.module-section {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  top: -40px; }

#content {
  width: 600em;
  list-style-type: none;
  padding: 50px 0 50px 0; }

/* MQ */
@media screen and (max-width: 1400px) {
  .tile {
    width: 266px;
    height: 152px; }
    .tile:hover {
      -webkit-transform: scale(1.7);
              transform: scale(1.7); } }
    @media screen and (max-width: 1400px) and (max-width: 1024px) {
      .tile:hover {
        -webkit-transform: none;
                transform: none; } }

@media screen and (max-width: 1400px) {
    .tile:hover ~ .tile {
      -webkit-transform: translate3d(190px, 0, 0);
              transform: translate3d(190px, 0, 0); }
  .listRow:hover {
    -webkit-transform: translate3d(-110px, 0, 0);
            transform: translate3d(-110px, 0, 0); } }

@media screen and (max-width: 750px) {
  .playbtn-container {
    margin: 18px 0px 5px 0px; }
  .tile {
    width: 224px;
    height: 128px; }
    .tile:hover {
      -webkit-transform: scale(1.4);
              transform: scale(1.4); } }
    @media screen and (max-width: 750px) and (max-width: 1024px) {
      .tile:hover {
        -webkit-transform: none;
                transform: none; } }

@media screen and (max-width: 750px) {
    .tile:hover ~ .tile {
      -webkit-transform: translate3d(90px, 0, 0);
              transform: translate3d(90px, 0, 0); }
  .tile-title {
    font-size: 0.8em; }
  .listRow:hover {
    -webkit-transform: translate3d(-70px, 0, 0);
            transform: translate3d(-70px, 0, 0); } }

@media screen and (max-width: 445px) {
  .playbtn-container {
    margin: 15px 0px 0px 0px; }
  .tile {
    width: 210px;
    height: 120px; }
    .tile:hover {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); } }
    @media screen and (max-width: 445px) and (max-width: 1024px) {
      .tile:hover {
        -webkit-transform: none;
                transform: none; } }

@media screen and (max-width: 445px) {
    .tile:hover ~ .tile {
      -webkit-transform: translate3d(65px, 0, 0);
              transform: translate3d(65px, 0, 0); }
  .tile-title {
    font-size: 0.7em; }
  .listRow:hover {
    -webkit-transform: translate3d(-60px, 0, 0);
            transform: translate3d(-60px, 0, 0); } }

.hide {
  display: none !important; }

.disabled {
  opacity: 0.4; }

body.no-scroll {
  position: fixed;
  overflow: hidden; }

@media (min-width: 1025px) {
  #home_Homelist {
    margin: 0 0 0 50px; } }

@media (max-width: 1024px) {
  #home_Homelist {
    margin: 0 13px 150px 13px !important; } }

#home_Homelist.storeList .GBSlider {
  margin-left: 30px;
  margin-top: 26px; }
  @media (max-width: 767px) {
    #home_Homelist.storeList .GBSlider {
      margin-left: 0; } }

@media (min-width: 1025px) {
  #home_GBSlider {
    margin: 0 50px 0 80px;
    box-shadow: 10px 20px 15px -10px #000000BF; }
    #home_GBSlider .slider-wrapper.axis-horizontal {
      border: 5px inset transparent;
      border-radius: 10px; }
    #home_GBSlider:hover .slider-wrapper.axis-horizontal {
      border: 5px inset #FBA00B; }
    #home_GBSlider .carousel .control-dots {
      -webkit-transform: translateY(50px);
              transform: translateY(50px); } }

.carousel-img {
  position: relative;
  z-index: 1; }
  .carousel-img .carousel-info {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    left: 10%;
    position: absolute;
    top: 0;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none; }
    .carousel-img .carousel-info .carousel-img-title {
      height: 30%;
      margin-top: 5%; }
      .carousel-img .carousel-info .carousel-img-title img {
        height: 100%; }
    .carousel-img .carousel-info .carousel-title {
      color: #fff;
      font-size: 2rem;
      font-weight: 700; }
    .carousel-img .carousel-info .carousel-btn-addon-box {
      align-items: center;
      display: flex;
      justify-content: space-around;
      margin: 0 auto; }
      .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon {
        background-color: #7c7c7c;
        border-radius: 3px;
        color: #fff;
        font-size: 1.3rem;
        padding: 5px;
        margin: 0 7px;
        width: 120px; }
        .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon.linked {
          cursor: pointer; }
    .carousel-img .carousel-info .carousel-btn {
      background-color: #fba00b;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 1.5rem;
      margin: 0 auto;
      margin-bottom: 5%;
      padding: 5px;
      width: 200px; }
      .carousel-img .carousel-info .carousel-btn.btn-tall {
        width: 300px;
        padding: 13px 5px; }
    @media (max-width: 1024px) {
      .carousel-img .carousel-info {
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
        .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon {
          width: 80px; } }
    .carousel-img .carousel-info i {
      font-size: 18px;
      margin-right: 13px; }

#home_GBSlider {
  transition: all .5s;
  /* hover on image : changing because play button is removed */ }
  #home_GBSlider .carousel-img {
    transition: -webkit-filter 0.5s, -webkit-transform 0.5s;
    transition: filter 0.5s, transform 0.5s;
    transition: filter 0.5s, transform 0.5s, -webkit-filter 0.5s, -webkit-transform 0.5s; }
  #home_GBSlider .carousel-img button {
    opacity: 0;
    transition: opacity 1s; }
  #home_GBSlider .carousel-img:hover {
    cursor: pointer;
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2); }
  #home_GBSlider .carousel-img.with-btn:hover {
    cursor: initial; }
  #home_GBSlider .carousel.carousel-slider {
    overflow: visible; }
  #home_GBSlider .carousel .control-dots {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    z-index: 1; }
    #home_GBSlider .carousel .control-dots .dot {
      height: 13px;
      width: 13px; }
    #home_GBSlider .carousel .control-dots .dot:hover {
      background-color: #FBA00B; }
    #home_GBSlider .carousel .control-dots .dot:focus {
      outline: none; }
    #home_GBSlider .carousel .control-dots .dot.selected {
      background-color: #FBA00B; }

.GBSlider.no-dots .control-dots {
  display: none; }

.grid .CB9vby5YRbJWpv7hRrZ0x {
  padding-bottom: 30px; }
  @media (max-width: 1024px) {
    .grid .CB9vby5YRbJWpv7hRrZ0x {
      padding-bottom: 10px; } }

.list-title {
  padding-top: 0; }

.list-item:first-child .list-title {
  padding-top: 50px; }
  @media (max-width: 767px) {
    .list-item:first-child .list-title {
      padding-top: 0; } }

.movie_popup {
  color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8; }
  .movie_popup .movie_popup_mask {
    background-color: #000000b0;
    height: 100%;
    width: 100%; }

.movie_popup_info {
  height: 100vh;
  margin: auto;
  padding: 50px 0;
  position: relative;
  width: 960px;
  z-index: 1; }
  .movie_popup_info.movie_page {
    position: fixed;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%); }
  @media (max-width: 1200px) {
    .movie_popup_info {
      width: 800px; } }
  @media (max-width: 1024px) {
    .movie_popup_info {
      width: 80vw; } }
  .movie_popup_info .movie_popup_close {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 10; }
  .movie_popup_info .movie_popup_left {
    align-items: flex-end;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    left: -150px;
    position: absolute;
    top: 0;
    width: 150px;
    z-index: 2; }
    .movie_popup_info .movie_popup_left .movie_popup_previous {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      height: 100px;
      padding: 13px;
      width: 100px; }
      .movie_popup_info .movie_popup_left .movie_popup_previous .movie_icon {
        -webkit-filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
        height: 50px; }
        @media (max-width: 1024px) {
          .movie_popup_info .movie_popup_left .movie_popup_previous .movie_icon {
            height: 30px; } }
      .movie_popup_info .movie_popup_left .movie_popup_previous:hover {
        cursor: pointer;
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  .movie_popup_info .movie_popup_right {
    align-items: flex-start;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    right: -150px;
    position: absolute;
    top: 0;
    width: 150px;
    z-index: 2; }
    .movie_popup_info .movie_popup_right .movie_popup_next {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      height: 100px;
      padding: 13px;
      width: 100px; }
      .movie_popup_info .movie_popup_right .movie_popup_next .movie_icon {
        -webkit-filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
        height: 50px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
        @media (max-width: 1024px) {
          .movie_popup_info .movie_popup_right .movie_popup_next .movie_icon {
            height: 30px; } }
      .movie_popup_info .movie_popup_right .movie_popup_next:hover {
        cursor: pointer;
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  .movie_popup_info .movie_popup_down {
    align-items: center;
    display: flex;
    height: 50px;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    bottom: 30px;
    width: 100%;
    z-index: 20; }
    .movie_popup_info .movie_popup_down .movie_popup_scroll1 .movie_icon {
      height: 20px;
      -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
              filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg); }
    .movie_popup_info .movie_popup_down .movie_popup_scroll2 {
      margin-top: -13px; }
      .movie_popup_info .movie_popup_down .movie_popup_scroll2 .movie_icon {
        height: 30px;
        -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
                filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg); }
  .movie_popup_info .close-button {
    border: 0px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    position: absolute;
    padding: 24px;
    right: 0;
    top: 0;
    z-index: 9;
    background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
    background-position: 50% -50%; }
    .movie_popup_info .close-button:hover {
      color: #FBA00B;
      cursor: pointer; }

.movie_info {
  margin: -100px auto 0 auto;
  width: 960px; }
  @media (max-width: 1200px) {
    .movie_info {
      width: 800px; } }
  @media (max-width: 1024px) {
    .movie_info {
      width: 80vw; } }

.movie_popup_content {
  align-items: center;
  background-color: #121212;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding-bottom: 23px;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 9;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .movie_popup_content::-webkit-scrollbar {
    display: none; }
  .movie_popup_content.serie_popup_content .movie_popup_img_box {
    position: fixed;
    z-index: 0; }
    .movie_popup_content.serie_popup_content .movie_popup_img_box .popup_img_play_btn {
      display: none; }
  .movie_popup_content.serie_popup_content .movie_popup_img_title {
    margin-top: 150px;
    position: relative;
    z-index: 1; }
  .movie_popup_content.serie_popup_content .movie_btns {
    position: relative;
    z-index: 1; }
    .movie_popup_content.serie_popup_content .movie_btns .movie_btn_box .icon_box {
      background-color: #111111CC; }
      @media (min-width: 1025px) {
        .movie_popup_content.serie_popup_content .movie_btns .movie_btn_box .icon_box:hover {
          background-color: #11111199;
          cursor: pointer; } }
  .movie_popup_content.serie_popup_content .episode-serie {
    padding-top: 26px; }
  .movie_popup_content .movie_popup_img_box {
    position: relative;
    z-index: 9; }
    .movie_popup_content .movie_popup_img_box .popup_img_play_btn {
      align-items: center;
      background-color: #000000;
      border-radius: 50%;
      display: flex;
      height: 60px;
      justify-content: center;
      left: 50%;
      opacity: 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 60px;
      transition: opacity 0.5s ease;
      z-index: 10; }
    .movie_popup_content .movie_popup_img_box:hover {
      cursor: pointer; }
      .movie_popup_content .movie_popup_img_box:hover .popup_img_play_btn {
        opacity: 0.65; }
  .movie_popup_content .movie_popup_img {
    height: 365px;
    width: 960px;
    object-fit: cover; }
    @media (max-width: 1200px) {
      .movie_popup_content .movie_popup_img {
        width: 800px;
        height: 304px; } }
    @media (max-width: 1024px) {
      .movie_popup_content .movie_popup_img {
        width: 100vw;
        height: 47.4vw; } }
  .movie_popup_content .movie_popup_img_title {
    align-self: flex-start;
    left: 0px;
    max-height: 130px;
    max-width: 460px;
    padding: 26px 26px; }
    @media (max-width: 767px) {
      .movie_popup_content .movie_popup_img_title {
        padding: 18px 18px;
        max-width: 100%; } }
    @media (max-width: 1024px) {
      .movie_popup_content .movie_popup_img_title {
        align-self: center; } }
  .movie_popup_content h2 {
    font-size: 32px;
    padding: 26px 0 26px 26px;
    width: 100%; }
    @media (max-width: 1024px) {
      .movie_popup_content h2 {
        font-size: 1.8rem; } }
  .movie_popup_content .movie_popup_categories {
    align-self: flex-start;
    padding: 10px 26px;
    position: relative;
    width: 100%;
    z-index: 10; }
    @media (max-width: 1024px) {
      .movie_popup_content .movie_popup_categories {
        box-shadow: none;
        padding-top: 15px; } }
    @media (min-width: 1025px) {
      .movie_popup_content .movie_popup_categories .catSpanLinked:hover {
        background-color: #FFFFFF4A;
        cursor: pointer; } }
    .movie_popup_content .movie_popup_categories .catSpan {
      background-color: #1f1f1f;
      border-radius: 20px;
      color: #aeaeae;
      display: inline-block;
      height: 25px;
      margin-bottom: 7px;
      margin-right: 13px;
      padding: 0 13px; }
      .movie_popup_content .movie_popup_categories .catSpan.catSpan-mobile {
        display: none; }
      .movie_popup_content .movie_popup_categories .catSpan.catSpan-web {
        display: inline-block; }
      @media (max-width: 767px) {
        .movie_popup_content .movie_popup_categories .catSpan.catSpan-mobile {
          display: inline-block; }
        .movie_popup_content .movie_popup_categories .catSpan.catSpan-web {
          display: none; } }
  .movie_popup_content .movie_popup_synopsis {
    align-self: flex-start;
    padding: 10px 26px; }
  .movie_popup_content .movie_btns {
    align-items: center;
    align-self: flex-start;
    bottom: 0;
    color: #aeaeae;
    display: flex;
    padding-left: 26px;
    width: 100%; }
    @media (max-width: 1024px) {
      .movie_popup_content .movie_btns {
        flex-direction: column;
        padding-left: 0; }
        .movie_popup_content .movie_btns > * {
          margin: 13px 0; } }
    .movie_popup_content .movie_btns .icons_box {
      align-items: center;
      display: flex;
      justify-content: center; }
    .movie_popup_content .movie_btns .movie_btn_box {
      align-items: center;
      display: flex;
      color: #FBA00B;
      flex-direction: column;
      justify-content: center;
      margin-right: 13px; }
      .movie_popup_content .movie_btns .movie_btn_box .icon_box {
        background-color: #FFFFFF1A;
        border-radius: 50%;
        height: 55px;
        width: 55px; }
        .movie_popup_content .movie_btns .movie_btn_box .icon_box img.movie_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
        @media (min-width: 1025px) {
          .movie_popup_content .movie_btns .movie_btn_box .icon_box:hover {
            background-color: #FFFFFF4A;
            cursor: pointer; } }
        .movie_popup_content .movie_btns .movie_btn_box .icon_box .icon_revert {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .movie_popup_content .movie_btns i {
      font-size: 30px; }
    .movie_popup_content .movie_btns .btn-movie {
      background-color: #FBA00B;
      border: none;
      border-radius: 3px;
      color: #FFFFFF;
      display: inline-block;
      font-size: 20px;
      font-weight: 100;
      height: 56px;
      letter-spacing: 2px;
      margin: 0 8px 8px 0;
      padding: 7px;
      width: 200px; }
      @media (max-width: 1024px) {
        .movie_popup_content .movie_btns .btn-movie {
          width: 300px;
          margin: 0 0 18px 0; } }
      @media (max-width: 767px) {
        .movie_popup_content .movie_btns .btn-movie {
          width: 70vw;
          margin: 0 0 18px 0; } }
      .movie_popup_content .movie_btns .btn-movie i {
        font-size: 18px;
        margin-right: 13px; }
      .movie_popup_content .movie_btns .btn-movie:hover {
        background-color: #FBA00B;
        cursor: pointer;
        opacity: 0.89; }
    .movie_popup_content .movie_btns .btn-trailer {
      background-color: #2C2C2C; }
      @media (min-width: 1025px) {
        .movie_popup_content .movie_btns .btn-trailer:hover {
          background-color: #353535;
          cursor: pointer; } }
    .movie_popup_content .movie_btns .btn-bonus {
      background-color: #2C2C2C;
      color: #fba00b; }
      @media (min-width: 1025px) {
        .movie_popup_content .movie_btns .btn-bonus:hover {
          background-color: #353535;
          cursor: pointer; } }
  .movie_popup_content img.movie_icon {
    -webkit-filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
            filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
    height: 30px;
    width: 30px; }
    @media (max-width: 767px) {
      .movie_popup_content img.movie_icon {
        height: 20px; } }
    .movie_popup_content img.movie_icon.selected {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  .movie_popup_content .icon_box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
    position: relative;
    width: 100%;
    z-index: 1; }
    .movie_popup_content .icon_box .bubble {
      background-color: #2a2a2a;
      border-radius: 3px;
      color: #fff;
      display: none;
      left: -7px;
      padding: 7px 12px;
      position: absolute;
      top: -60px;
      white-space: nowrap;
      z-index: 2; }
    .movie_popup_content .icon_box:hover .bubble {
      display: block; }
      @media (max-width: 1024px) {
        .movie_popup_content .icon_box:hover .bubble {
          display: none; } }

.movie_info_content {
  align-items: center;
  background-color: #121212;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: -150px;
  overflow: scroll;
  position: relative;
  width: 100%;
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .movie_info_content::-webkit-scrollbar {
    display: none; }
  .movie_info_content .movie_info_img_box {
    position: relative;
    z-index: 1; }
  .movie_info_content .movie_info_img {
    width: 100%; }
  .movie_info_content h2 {
    font-size: 32px;
    padding: 50px 50px 20px 50px; }
    @media (max-width: 1024px) {
      .movie_info_content h2 {
        font-size: 1.8rem; } }
  .movie_info_content .movie_info_categories {
    align-self: flex-start;
    padding: 26px;
    text-align: left; }
    @media (min-width: 1025px) {
      .movie_info_content .movie_info_categories .catSpanLinked:hover {
        background-color: #FFFFFF4A;
        cursor: pointer; } }
    .movie_info_content .movie_info_categories .catSpan {
      border: 1px solid #aeaeae;
      border-radius: 20px;
      color: #aeaeae;
      display: inline-block;
      height: 25px;
      margin: 0 7px;
      padding: 0 13px; }
      .movie_info_content .movie_info_categories .catSpan.catSpan-mobile {
        display: none; }
      .movie_info_content .movie_info_categories .catSpan.catSpan-web {
        display: inline-block; }
      @media (max-width: 767px) {
        .movie_info_content .movie_info_categories .catSpan.catSpan-mobile {
          display: inline-block; }
        .movie_info_content .movie_info_categories .catSpan.catSpan-web {
          display: none; } }
  .movie_info_content .movie_info_synopsis {
    align-self: flex-start;
    padding: 26px;
    text-align: left; }
  .movie_info_content .movie_btns {
    align-items: center;
    align-self: flex-start;
    bottom: 0;
    color: #aeaeae;
    display: flex;
    padding: 26px;
    width: 100%; }
    @media (max-width: 1024px) {
      .movie_info_content .movie_btns {
        flex-direction: column;
        padding-left: 0; }
        .movie_info_content .movie_btns > * {
          margin: 13px 0; } }
    .movie_info_content .movie_btns .icons_box {
      align-items: center;
      display: flex;
      justify-content: center; }
    .movie_info_content .movie_btns .movie_btn_box {
      align-items: center;
      display: flex;
      color: #FBA00B;
      flex-direction: column;
      justify-content: center;
      margin-right: 13px; }
      .movie_info_content .movie_btns .movie_btn_box .icon_box {
        background-color: #FFFFFF1A;
        border-radius: 50%;
        height: 55px;
        width: 55px; }
        .movie_info_content .movie_btns .movie_btn_box .icon_box img.movie_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
        @media (min-width: 1025px) {
          .movie_info_content .movie_btns .movie_btn_box .icon_box:hover {
            background-color: #FFFFFF4A;
            cursor: pointer; } }
        .movie_info_content .movie_btns .movie_btn_box .icon_box .icon_revert {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .movie_info_content .movie_btns i {
      font-size: 30px; }
    .movie_info_content .movie_btns .btn-movie {
      background-color: #FBA00B;
      border: none;
      border-radius: 3px;
      color: #FFFFFF;
      display: inline-block;
      font-size: 20px;
      font-weight: 100;
      height: 56px;
      letter-spacing: 2px;
      margin: 0 8px 8px 0;
      padding: 7px;
      width: 200px; }
      @media (max-width: 1024px) {
        .movie_info_content .movie_btns .btn-movie {
          width: 300px;
          margin: 0 0 18px 0; } }
      @media (max-width: 500px) {
        .movie_info_content .movie_btns .btn-movie {
          width: 80vw;
          margin: 0 0 18px 0; } }
      .movie_info_content .movie_btns .btn-movie i {
        font-size: 18px;
        margin-right: 13px; }
      .movie_info_content .movie_btns .btn-movie:hover {
        background-color: #FBA00B;
        cursor: pointer;
        opacity: 0.89; }
    .movie_info_content .movie_btns .btn-trailer {
      background-color: #2C2C2C; }
      @media (min-width: 1025px) {
        .movie_info_content .movie_btns .btn-trailer:hover {
          background-color: #353535;
          cursor: pointer; } }
    .movie_info_content .movie_btns .btn-bonus {
      background-color: #2C2C2C;
      color: #fba00b; }
      @media (min-width: 1025px) {
        .movie_info_content .movie_btns .btn-bonus:hover {
          background-color: #353535;
          cursor: pointer; } }
  .movie_info_content img.movie_icon {
    -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
    height: 30px;
    width: 30px; }
    @media (max-width: 767px) {
      .movie_info_content img.movie_icon {
        height: 20px; } }
    .movie_info_content img.movie_icon.selected {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  .movie_info_content .icon_box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
    position: relative;
    width: 100%;
    z-index: 1; }

.movie_info_back {
  left: 50px;
  position: absolute;
  top: -100px;
  z-index: 13; }
  @media (max-width: 1200px) {
    .movie_info_back {
      left: 0;
      top: -50px; } }
  @media (max-width: 1024px) {
    .movie_info_back {
      left: 0;
      top: -50px; } }
  .movie_info_back img.movie_icon {
    -webkit-filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
            filter: invert(60%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(84%);
    height: 50px; }
    .movie_info_back img.movie_icon:hover {
      color: #FBA00B;
      cursor: pointer;
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }

.movie_progress {
  background-color: #ddd;
  border-radius: 5px;
  bottom: 0;
  height: 5px;
  margin: -4px 0;
  max-width: 420px;
  position: absolute;
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
  width: 80%;
  z-index: 2; }
  .movie_progress.in-figure {
    bottom: 13px; }
  .movie_progress .progress_bar {
    background-color: #fba00b;
    border-radius: 5px;
    height: 100%; }

.movie_progress_play {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 24px;
  height: 72px;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity .5s;
  width: 72px;
  z-index: 3; }
  .movie_progress_play:hover {
    opacity: 0.7;
    cursor: pointer; }
  .movie_progress_play i {
    margin-left: 3px; }

.movie_popup_soon_offline {
  align-self: flex-start;
  color: #fba00b;
  padding: 10px 26px; }

.movie_popup_progress_box {
  align-self: flex-start;
  display: flex;
  width: 100%; }
  .movie_popup_progress_box .movie_popup_progress {
    background-color: #ddd;
    border-radius: 5px;
    height: 7px;
    margin: -13px 0 23px 26px;
    flex-basis: 30%; }
    @media (max-width: 767px) {
      .movie_popup_progress_box .movie_popup_progress {
        flex-basis: 40%; } }
    .movie_popup_progress_box .movie_popup_progress .progress_bar {
      background-color: #fba00b;
      border-radius: 5px;
      height: 100%; }
  .movie_popup_progress_box .progress_bar_time {
    margin: -23px 0 23px 23px; }
    @media (max-width: 767px) {
      .movie_popup_progress_box .progress_bar_time {
        margin-left: 13px;
        font-size: 0.8em; } }

.list-title.blur-1 {
  -webkit-filter: blur(13px);
          filter: blur(13px);
  pointer-events: none; }

.list-title.blur-2 {
  -webkit-filter: blur(7px);
          filter: blur(7px);
  pointer-events: none; }

.list-title.blur-3 {
  -webkit-filter: blur(3px);
          filter: blur(3px);
  pointer-events: none; }

@media (max-width: 1024px) {
  .list-title {
    margin-bottom: 0 !important; }
    .list-title h3 {
      font-size: 1.1rem; }
    .list-title .list-infoIcon {
      height: 19px !important;
      margin-top: -3px !important; } }

.movie_mozaic_name_box {
  display: flex;
  flex-direction: row;
  padding: 0 13px;
  width: 21vw; }
  .movie_mozaic_name_box .movie_mozaic_name {
    overflow: hidden;
    white-space: nowrap; }
  .movie_mozaic_name_box .f-grow-1 {
    flex-grow: 1; }
  @media (max-width: 1024px) {
    .movie_mozaic_name_box {
      margin-left: 2vw;
      width: 25vw;
      padding: 0 3px; } }
  @media (max-width: 767px) {
    .movie_mozaic_name_box {
      width: 39vw; } }

.movie_mozaic {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5vh;
  margin-left: 2vw; }
  .movie_mozaic .mozaic_figure_box {
    margin-bottom: 26px;
    position: relative; }
  @media (max-width: 1024px) {
    .movie_mozaic {
      margin-left: 2vw !important; } }
  .movie_mozaic figure {
    cursor: pointer;
    height: 12vw !important;
    position: relative;
    text-align: center;
    width: 21vw !important; }
    @media (max-width: 1024px) {
      .movie_mozaic figure {
        height: 14vw !important;
        width: 25vw !important; } }
    @media (max-width: 767px) {
      .movie_mozaic figure {
        height: 22vw !important;
        width: 39vw !important; } }
    .movie_mozaic figure img {
      display: block;
      min-height: 100%;
      max-width: 100%;
      position: relative; }
    .movie_mozaic figure figcaption {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      color: #fff;
      height: 100%;
      font-size: 1.25em;
      left: 0;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      width: 100%; }
    .movie_mozaic figure h1 {
      position: relative;
      top: 50%; }
  .movie_mozaic .movie_progress {
    max-width: 80%; }

.search-top {
  height: 100px;
  width: 100%; }
  @media (min-width: 1025px) {
    .search-top {
      height: 180px; } }

.goTo_box {
  cursor: pointer;
  transition: all .3s; }
  .goTo_box:hover .list-title .list-infoText-box {
    width: 400px; }

#result-container1.catAcc_result {
  margin-top: 160px !important; }

#result-container1.catAcc_noResult {
  margin-top: -180px !important;
  padding: 400px 50px; }
  @media (max-width: 1024px) {
    #result-container1.catAcc_noResult {
      padding: 500px 13px; } }

.list-title .list-infoText-box {
  display: inline-block;
  font-size: 0.6em;
  margin: 0 13px;
  overflow: hidden;
  transition: width 1s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
  width: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

.list-title .list-infoIcon {
  border-radius: 5px;
  -webkit-filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
          filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
  height: 26px;
  margin-top: -3px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.default-favorite {
  align-items: center;
  color: #aeaeae;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 0;
  text-align: center;
  width: 90vw; }
  .default-favorite h3 {
    font-size: 4rem; }
    @media (max-width: 767px) {
      .default-favorite h3 {
        font-size: 2rem; } }
  .default-favorite .default-favorite-icon {
    background-color: #FFFFFF1C;
    border-radius: 50%;
    padding: 40px;
    margin: 40px; }
    .default-favorite .default-favorite-icon img {
      height: 50px;
      width: 50px; }
  .default-favorite .default-favorite-info {
    font-size: 2rem; }
    @media (max-width: 767px) {
      .default-favorite .default-favorite-info {
        font-size: 1.2rem; } }

.movie_additional_info {
  align-self: flex-start;
  padding: 10px 26px; }
  .movie_additional_info img.movie_icon {
    margin-left: 13px;
    margin-top: -4px;
    height: 20px;
    width: auto; }

.badge-new {
  background-color: #fba00b;
  border-radius: 10px 40px 40px 10px;
  color: #fff;
  display: inline;
  float: left;
  font-size: 55%;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  padding: 2px 10px;
  position: relative;
  text-align: left;
  text-transform: none;
  z-index: 2; }
  @media (max-width: 767px) {
    .badge-new {
      font-size: 30%;
      padding: 1px 10px; } }

.badge-recurrence {
  color: black;
  font-size: 55%;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  background-color: #fff;
  border-radius: 10px 40px 40px 10px;
  padding: 2px 17px;
  float: left;
  position: relative;
  left: -10px;
  display: inline;
  text-align: left;
  text-transform: none;
  z-index: 1; }
  @media (max-width: 767px) {
    .badge-recurrence {
      font-size: 30%;
      padding: 1px 10px; } }

.badge-soon-offline {
  background-color: #E33939;
  border-radius: 10px 40px 40px 10px;
  color: #fff;
  display: inline;
  float: left;
  font-size: 55%;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  padding: 2px 10px;
  position: relative;
  text-align: left;
  text-transform: none;
  z-index: 2; }
  @media (max-width: 767px) {
    .badge-soon-offline {
      font-size: 30%;
      padding: 1px 10px; } }
  .badge-soon-offline i {
    color: #fff;
    margin-right: 13px; }

.badge-outOfStock {
  background-color: #E33939;
  color: #fff;
  display: inline;
  float: left;
  font-size: 55%;
  font-family: 'Helvetica', sans-serif;
  font-weight: bold;
  padding: 2px 10px;
  position: relative;
  text-align: center;
  text-transform: none;
  width: 100%;
  z-index: 2; }
  @media (max-width: 767px) {
    .badge-outOfStock {
      font-size: 30%;
      padding: 1px 10px; } }
  .badge-outOfStock i {
    color: #fff;
    margin-right: 13px; }

.containerBadges div:only-child {
  left: 0; }

.title-distribution {
  align-self: flex-start;
  padding-left: 26px;
  margin-top: 10px; }

.distribution-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  padding: 10px;
  position: relative;
  width: 100%;
  touch-action: none; }
  .distribution-container > div {
    width: 100%; }
  .distribution-container .distribution-box {
    margin: 10px 0 0; }
  .distribution-container .distribution-interraction {
    cursor: pointer; }
    .distribution-container .distribution-interraction:hover .movie_distribution_img {
      transition: all 0.1s; }
      @media (min-width: 1024px) {
        .distribution-container .distribution-interraction:hover .movie_distribution_img {
          outline: 3px solid #fba00b; } }
  .distribution-container .movie_distribution_img {
    border-radius: 50%;
    transition: all 0.1s; }
  .distribution-container .distribution-thumbnails {
    text-align: center;
    padding-top: 10px;
    width: 100%; }
    .distribution-container .distribution-thumbnails:nth-child(3) {
      color: #aeaeae;
      padding: 0; }

.fishing-page .GBSlider:hover .slider-wrapper.axis-horizontal {
  border: 5px inset #149DCC; }

.fishing-page .GBSlider .carousel .control-dots .dot:hover {
  background-color: #149DCC; }

.fishing-page .GBSlider .carousel .control-dots .dot.selected {
  background-color: #149DCC; }

@media (min-width: 1024px) {
  .fishing-page .category-box:hover .category-img {
    border: 3px solid #149DCC; } }

.fishing-page .category-img.selected {
  border: 2px solid #149DCC; }

.fishing-page figure.effect-thumbnail-movie:hover {
  border: 3px solid #149DCC; }

figure.effect-thumbnail-movie {
  -webkit-user-select: none;
  user-select: none; }
  figure.effect-thumbnail-movie .figure-details {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    text-transform: initial;
    z-index: 1; }
    figure.effect-thumbnail-movie .figure-details.with-overlay {
      background: linear-gradient(#00000000, #000000d9); }
  figure.effect-thumbnail-movie .figure-img-title {
    min-height: 0px;
    max-width: 60%;
    margin-top: 20%; }
  figure.effect-thumbnail-movie .figure-img-text {
    align-items: center;
    display: flex;
    justify-content: space-around;
    flex-direction: column; }
  figure.effect-thumbnail-movie .figure-title {
    color: #fff;
    font-size: 2rem;
    font-weight: 700; }
    @media (max-width: 500px) {
      figure.effect-thumbnail-movie .figure-title {
        font-size: 1rem; } }
  figure.effect-thumbnail-movie .figure-badgeTop {
    background-color: #fff;
    border-bottom-right-radius: 3px;
    color: #000;
    font-size: .8rem;
    left: 0;
    padding: 1px;
    position: absolute;
    top: 0;
    min-width: 120px; }
  figure.effect-thumbnail-movie .figure-badgeFree {
    background-color: #fba00b;
    border-bottom-left-radius: 3px;
    font-size: 1rem;
    padding: 1px;
    position: absolute;
    right: 0;
    top: 0;
    min-width: 120px; }
  figure.effect-thumbnail-movie .figure-badgeCenter {
    background-color: #7c7c7c;
    border-radius: 3px;
    color: #fff;
    font-size: 1.3rem;
    padding: 1px;
    min-width: 120px; }
  figure.effect-thumbnail-movie .figure-info {
    color: #fff;
    font-size: 1.3rem;
    padding: 3px; }
  figure.effect-thumbnail-movie .figure-icon {
    min-height: 0;
    display: inline-block;
    margin-bottom: 4px;
    margin-right: 8px;
    width: 24px; }
  figure.effect-thumbnail-movie .figure-price {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    padding: 3px; }
    figure.effect-thumbnail-movie .figure-price .figure-price-rent-info {
      background-color: #7c7c7c;
      border-radius: 10px;
      display: inline-block;
      font-size: 1rem;
      margin-left: 7px;
      padding: 1px 7px;
      position: relative;
      top: -15px; }

.episodes-container {
  display: flex;
  width: 900px;
  width: 95%; }
  .episodes-container > div button:nth-child(1) {
    left: 0; }
  .episodes-container > div ._1-Hp0TfFuBlCxtdPUf_zEF {
    right: 0; }
  .episodes-container > * {
    width: 100%; }
  .episodes-container .episode-box {
    margin: 7px;
    cursor: pointer; }
    .episodes-container .episode-box .movie_progress_play {
      left: 47%;
      top: 47%; }
    .episodes-container .episode-box .movie_episode_img {
      border: 3px solid transparent;
      transition: -webkit-transform .5s;
      transition: transform .5s;
      transition: transform .5s, -webkit-transform .5s; }
      .episodes-container .episode-box .movie_episode_img:hover {
        border: 3px solid #FBA00B;
        -webkit-transform: translate(-3px, -3px);
                transform: translate(-3px, -3px); }
        .episodes-container .episode-box .movie_episode_img:hover + .movie_progress_play {
          opacity: 0.7; }
      @media (max-width: 1024px) {
        .episodes-container .episode-box .movie_episode_img:hover {
          border: none;
          -webkit-transform: none;
                  transform: none; } }
    .episodes-container .episode-box .movie_progress {
      position: unset;
      -webkit-transform: translateX(3%);
              transform: translateX(3%);
      width: 95%;
      margin: 3px 0 0 0; }
    .episodes-container .episode-box .movie_progress_no {
      position: unset;
      -webkit-transform: translateX(3%);
              transform: translateX(3%);
      width: 95%;
      height: 7px; }
    .episodes-container .episode-box .movie_episode_img {
      border-radius: 10px;
      cursor: pointer; }
    .episodes-container .episode-box .episodes-thumbnails {
      padding-top: 7px; }
      .episodes-container .episode-box .episodes-thumbnails .number-episode {
        font-size: 17px; }
      .episodes-container .episode-box .episodes-thumbnails .title-episode {
        opacity: 0.8;
        font-size: 16px; }
      .episodes-container .episode-box .episodes-thumbnails .synopsis-episode {
        font-size: 15px;
        color: #c4c4c4;
        font-weight: 90; }

.episodes-list {
  align-items: flex-start;
  background-color: transparent;
  color: #c1c1c1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  overflow-x: scroll;
  padding: 7px 13px 0 13px;
  -ms-overflow-style: none; }

.episode-serie {
  align-self: flex-start;
  padding: 26px 0 0 26px; }

/* MQ */
@media screen and (max-width: 750px) {
  .list-title {
    padding-left: 20px; }
  .left-controls {
    height: 180px;
    width: 10%; }
  .right-controls {
    height: 180px;
    width: 10%; }
  #content {
    padding: 30px 0 30px 0; } }

.GBListItem.row-blue .list-title {
  color: #eee;
  padding-top: 13px; }

.GBListItem.row-blue .list-infoIcon {
  -webkit-filter: brightness(222%);
          filter: brightness(222%); }

.GBListItem.row-blue.row-fill {
  background: linear-gradient(to bottom, #6699bb 95%, transparent 95%); }

.GBListItem.row-blue.row-halo {
  background: linear-gradient(to bottom, transparent 20%, #6699bb 50%, #6699bb 60%, transparent 90%); }
  @media (max-width: 767px) {
    .GBListItem.row-blue.row-halo {
      background: linear-gradient(to bottom, transparent, #6699bb 50%, #6699bb 70%, transparent); } }

.GBListItem.row-blue.row-gradient {
  background: linear-gradient(to bottom, #6699bb, #6699bb44 95%, transparent 95%); }

.GBListItem.row-blue figure.effect-thumbnail-movie:hover {
  border: 3px solid #149DCC; }

.GBListItem.row-orange .list-title {
  color: #eee;
  padding-top: 13px; }

.GBListItem.row-orange .list-infoIcon {
  -webkit-filter: brightness(222%);
          filter: brightness(222%); }

.GBListItem.row-orange.row-fill {
  background: linear-gradient(to bottom, #cc8800 95%, transparent 95%); }

.GBListItem.row-orange.row-halo {
  background: linear-gradient(to bottom, transparent 20%, #cc8800 50%, #cc8800 60%, transparent 90%); }
  @media (max-width: 767px) {
    .GBListItem.row-orange.row-halo {
      background: linear-gradient(to bottom, transparent, #cc8800 50%, #cc8800 70%, transparent); } }

.GBListItem.row-orange.row-gradient {
  background: linear-gradient(to bottom, #cc8800, #cc880044 95%, transparent 95%); }

.GBListItem.row-orange figure.effect-thumbnail-movie:hover {
  border: 3px solid #fba00b; }

.GBListItem.row-purple .list-title {
  color: #eee;
  padding-top: 13px; }

.GBListItem.row-purple .list-infoIcon {
  -webkit-filter: brightness(222%);
          filter: brightness(222%); }

.GBListItem.row-purple.row-fill {
  background: linear-gradient(to bottom, #702261 95%, transparent 95%); }

.GBListItem.row-purple.row-halo {
  background: linear-gradient(to bottom, transparent 20%, #702261 50%, #702261 60%, transparent 90%); }
  @media (max-width: 767px) {
    .GBListItem.row-purple.row-halo {
      background: linear-gradient(to bottom, transparent, #702261 50%, #702261 70%, transparent); } }

.GBListItem.row-purple.row-gradient {
  background: linear-gradient(to bottom, #702261, #69165944 95%, transparent 95%); }

.GBListItem.row-purple figure.effect-thumbnail-movie:hover {
  border: 3px solid #886688; }

.GBListItem.row-image {
  background-size: cover;
  margin: 30px 0 60px 0;
  padding-top: 30px; }
  .GBListItem.row-image .list-title {
    color: #eee;
    margin-bottom: 120px;
    padding-top: 13px;
    text-shadow: 1px 0 0 #111, 1px 1px 0 #111, 0 1px 0 #111, -1px 1px 0 #111, -1px 0 0 #111, -1px -1px 0 #111, 0 -1px 0 #111, 1px -1px 0 #111111; }
    .GBListItem.row-image .list-title h3 {
      font-size: 72px !important; }
  .GBListItem.row-image .list-infoIcon {
    -webkit-filter: brightness(222%);
            filter: brightness(222%); }
  .GBListItem.row-image .for-image {
    background: linear-gradient(to bottom, #11111144, #11111144 90%, transparent 80%); }

.shine-container {
  display: flex;
  flex-direction: column; }

.moving-image-box .mainFileName {
  opacity: 1;
  transition: opacity 1s; }

.moving-image-box .gallery1FileName {
  margin-top: -100%;
  opacity: 0;
  transition: opacity 1s; }

@media (min-width: 1025px) {
  .moving-image-box:hover .mainFileName {
    opacity: 0; }
  .moving-image-box:hover .gallery1FileName {
    opacity: 1; } }

.iframe-container .GBListItem {
  top: 0; }

.iframe-container .iframe-logo {
  margin: 26px 0;
  text-align: center;
  width: 100%;
  height: 50px; }

.iframe_popup {
  color: #fff;
  height: 360px;
  left: 0;
  position: fixed;
  top: 0;
  width: 360px;
  z-index: 8; }
  .iframe_popup .iframe_popup_info {
    background-color: #111;
    height: 360px;
    margin: auto;
    padding: 0;
    position: relative;
    width: 360px;
    z-index: 1; }
    .iframe_popup .iframe_popup_info .iframe-logo {
      padding-top: 26px;
      margin: 0; }
    .iframe_popup .iframe_popup_info .iframe_popup_close {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10; }
    .iframe_popup .iframe_popup_info .close-button {
      border: 0px;
      border-radius: 10px;
      color: #fff;
      font-size: 20px;
      position: absolute;
      padding: 7px;
      right: 0;
      top: 0;
      z-index: 9;
      background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
      background-position: 50% -50%; }
      .iframe_popup .iframe_popup_info .close-button:hover {
        color: #FBA00B;
        cursor: pointer; }
    .iframe_popup .iframe_popup_info .iframe_popup_form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 42px;
      width: 100%; }
      .iframe_popup .iframe_popup_info .iframe_popup_form .iframe_popup_input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 7px 0; }
      .iframe_popup .iframe_popup_info .iframe_popup_form input {
        background: transparent;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        font-weight: 100;
        margin: 0 auto;
        padding: 7px;
        width: 300px; }
        .iframe_popup .iframe_popup_info .iframe_popup_form input::-webkit-input-placeholder {
          font-size: 14px;
          color: #fff; }
        .iframe_popup .iframe_popup_info .iframe_popup_form input::placeholder {
          font-size: 14px;
          color: #fff; }
      .iframe_popup .iframe_popup_info .iframe_popup_form .iframe_popup_error {
        color: #E33939;
        font-weight: 700;
        height: 30px; }
      .iframe_popup .iframe_popup_info .iframe_popup_form .iframe_popup_valid_button {
        background-color: #fba00b;
        border-radius: 5px;
        font-size: 18px;
        margin: 7px auto;
        padding: 7px;
        width: 300px; }
        .iframe_popup .iframe_popup_info .iframe_popup_form .iframe_popup_valid_button:hover {
          cursor: pointer; }

.video-iframe-container-sound {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1; }
  .video-iframe-container-sound i {
    -webkit-filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
            filter: invert(100%) sepia(7%) saturate(780%) hue-rotate(289deg) brightness(200%) contrast(100%);
    font-size: 150px; }

#iframe_loader {
  align-items: center;
  display: none;
  height: 360px;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 360px;
  z-index: 2; }
  #iframe_loader.show {
    display: flex; }

/* tiles CSS transition and transform effects */
#playlist_title {
  color: #fff;
  font-size: 30px;
  padding: 50px 0 0 26px;
  text-align: center;
  width: 75vw; }

#playlist_info_container {
  display: flex;
  margin: 0 0 0 13px; }
  #playlist_info_container #playlist_info {
    color: #fff;
    flex-basis: 75%;
    font-size: 20px;
    text-align: center;
    padding-top: 7px; }
  #playlist_info_container #btns_choice {
    display: flex;
    padding: 7px; }
    #playlist_info_container #btns_choice .btns_choice_ico {
      height: 20px; }
    #playlist_info_container #btns_choice #chasse_choice .btns_choice_ico {
      margin-right: 13px; }
    #playlist_info_container #btns_choice #peche_choice .btns_choice_ico {
      margin-left: 13px; }
    #playlist_info_container #btns_choice #chasse_choice, #playlist_info_container #btns_choice #peche_choice {
      align-items: center;
      background-color: #5f5d5d;
      border-radius: 13px;
      color: #111;
      display: flex;
      justify-content: center;
      height: 40px;
      opacity: 0.6;
      width: 140px;
      transition: opacity 0.5s; }
      #playlist_info_container #btns_choice #chasse_choice:hover, #playlist_info_container #btns_choice #peche_choice:hover {
        cursor: pointer;
        opacity: 0.8; }
    #playlist_info_container #btns_choice #chasse_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-right: -13px;
      opacity: 1;
      z-index: 2; }
    #playlist_info_container #btns_choice #peche_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-left: -13px;
      opacity: 1;
      z-index: 2; }
    #playlist_info_container #btns_choice .selected .btns_choice_ico {
      -webkit-filter: invert(100%);
              filter: invert(100%); }

@media (min-width: 992px) and (max-width: 1200px) {
  #playlist_info_container #playlist_info {
    font-size: 18px; } }

@media (max-width: 767px) {
  #playlist_title {
    margin: 26px;
    text-align: initial; }
  #playlist_info_container {
    flex-direction: column;
    padding-right: 13px; }
    #playlist_info_container #playlist_info {
      text-align: start; }
    #playlist_info_container #btns_choice {
      justify-content: center; } }

@media (orientation: landscape) and (max-width: 850px) {
  #playlist_title {
    margin: 60vh 0 0 26px;
    text-align: initial; } }

@media (min-width: 768px) {
  #playlist_title {
    margin: 50px; } }

@media (min-width: 1024px) {
  #playlist_title {
    margin: 0 50px 0 80px; } }

.playlist_movie_details {
  color: #fff;
  flex-basis: 40%;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px; }
  .playlist_movie_details img {
    border-radius: 10px;
    width: 100%; }
  .playlist_movie_details .playlist_movie_intro {
    align-self: flex-start;
    padding: 5vh 0 2vh 5vw; }
  .playlist_movie_details .playlist_movie_title {
    align-self: flex-start;
    padding: 3vh 0 1vh 5vw;
    font-size: 30px; }
  .playlist_movie_details .playlist_movie_synopsis {
    align-self: flex-start;
    padding: 0 5vw 0 5vw;
    font-size: 18px;
    text-align: justify; }

@media (min-width: 1200px) {
  .playlist_movie_details {
    margin-left: 4vw;
    width: 36vw; }
    .playlist_movie_details img {
      max-width: 30vw; }
    .playlist_movie_details .playlist_movie_title {
      font-size: 24px; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .playlist_movie_details {
    margin-left: 4vw;
    width: 36vw; }
    .playlist_movie_details img {
      max-width: 30vw; }
    .playlist_movie_details .playlist_movie_title {
      font-size: 20px; }
    .playlist_movie_details .playlist_movie_synopsis {
      padding: 0 2vw 0 5vw;
      font-size: 16px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .playlist_movie_details {
    margin-left: 4vw;
    width: 36vw; }
    .playlist_movie_details img {
      max-width: 30vw; }
    .playlist_movie_details .playlist_movie_title {
      font-size: 18px; }
    .playlist_movie_details .playlist_movie_synopsis {
      padding: 0 2vw 0 5vw;
      font-size: 16px; } }

@media (max-width: 767px) {
  .playlist_movie_details {
    width: 100vw; } }

.playlist_movie_categories {
  align-self: flex-start;
  font-size: 18px;
  padding: 26px 5vw 0 5vw; }
  @media (min-width: 1025px) {
    .playlist_movie_categories .catSpanLinked:hover {
      background-color: #FFFFFF4A;
      cursor: pointer; } }
  .playlist_movie_categories .catSpan {
    border: 1px solid #aeaeae;
    border-radius: 20px;
    color: #aeaeae;
    display: inline-block;
    margin-right: 13px;
    margin-bottom: 7px;
    padding: 0 13px; }
    .playlist_movie_categories .catSpan.catSpan-mobile {
      display: none; }
    .playlist_movie_categories .catSpan.catSpan-web {
      display: inline-block; }
    @media (max-width: 767px) {
      .playlist_movie_categories .catSpan.catSpan-mobile {
        display: inline-block; }
      .playlist_movie_categories .catSpan.catSpan-web {
        display: none; } }

@media (min-width: 992px) and (max-width: 1200px) {
  .playlist_movie_categories {
    padding: 26px 2vw 0 5vw;
    font-size: 16px; } }

@media (min-width: 768px) and (max-width: 992px) {
  .playlist_movie_categories {
    padding: 26px 2vw 0 5vw;
    font-size: 16px; } }

.flex-container {
  display: flex; }

.player-container-relative {
  position: relative;
  flex-basis: 60%; }
  .player-container-relative #z3pOverlay {
    position: relative; }
  .player-container-relative .z3PlayOverlay {
    background-color: transparent !important; }

@media (max-width: 767px) {
  .flex-container {
    flex-direction: column-reverse; }
    .flex-container .playlist_movie_details {
      flex-basis: auto; }
    .flex-container .player-container-relative {
      flex-basis: auto;
      width: 100vw;
      height: 50vh; }
      .flex-container .player-container-relative #z3pOverlay {
        top: 5% !important;
        height: 100% !important;
        width: 100% !important; } }

@media (min-width: 1025px) {
  #peche_Homelist {
    margin: 0 0 0 50px !important; } }

@media (max-width: 1024px) {
  #peche_Homelist {
    margin: 0 13px 150px 13px !important; } }

@media (max-width: 1024px) {
  .list-title.in-rows {
    margin-bottom: 13px !important; }
    .list-title.in-rows h3 {
      font-size: 1.1rem; }
    .list-title.in-rows .list-infoIcon {
      height: 19px !important;
      margin-top: -3px !important; } }

.list-title span.icon_box {
  display: inline-block;
  position: relative;
  width: 37px; }
  @media (max-width: 1025px) {
    .list-title span.icon_box {
      width: 23px; } }

.list-title span.dot {
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  height: 13px;
  margin: 0 7px 3px 3px;
  width: 13px; }
  @media (max-width: 1025px) {
    .list-title span.dot {
      margin: 0 7px -1px 3px; } }

@keyframes blinking {
  from {
    opacity: 1; }
  to {
    opacity: 0.2; } }

.list-title span.live {
  -webkit-animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate;
          animation: blinking 2s cubic-bezier(0, 0, 1, 1) infinite alternate; }

.GBSlider {
  margin-top: 80px;
  transition: all .5s;
  /* hover on image : changing because play button is removed */ }
  .GBSlider.in-rows {
    margin-top: 0;
    margin-bottom: 26px; }
  .GBSlider .carousel-img {
    transition: -webkit-filter 0.5s, -webkit-transform 0.5s;
    transition: filter 0.5s, transform 0.5s;
    transition: filter 0.5s, transform 0.5s, -webkit-filter 0.5s, -webkit-transform 0.5s; }
  .GBSlider .carousel-img button {
    opacity: 0;
    transition: opacity 1s; }
  .GBSlider .carousel-img:hover {
    cursor: pointer;
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2); }
  .GBSlider .carousel-img.with-btn:hover {
    cursor: initial; }
  .GBSlider .carousel.carousel-slider {
    overflow: visible; }
  .GBSlider .carousel .control-dots {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    z-index: 1; }
    .GBSlider .carousel .control-dots .dot {
      height: 13px;
      width: 13px; }
    .GBSlider .carousel .control-dots .dot:hover {
      background-color: #FBA00B; }
    .GBSlider .carousel .control-dots .dot:focus {
      outline: none; }
    .GBSlider .carousel .control-dots .dot.selected {
      background-color: #FBA00B; }

@media (min-width: 1025px) {
  .GBSlider {
    margin: 0 50px 0 80px;
    box-shadow: 10px 20px 15px -10px #000000BF; }
    .GBSlider.in-rows {
      margin: 0 13px 68px 13px; }
    .GBSlider .slider-wrapper.axis-horizontal {
      border: 5px inset transparent;
      border-radius: 10px; }
    .GBSlider:hover .slider-wrapper.axis-horizontal {
      border: 5px inset #FBA00B; }
    .GBSlider .carousel .control-dots {
      -webkit-transform: translateY(50px);
              transform: translateY(50px); } }

@media (max-width: 1025px) {
  .GBSlider.in-rows.with-dots {
    margin-bottom: 55px; } }

/* MQ */
@media screen and (max-width: 750px) {
  .list-title {
    padding-left: 20px;
    margin-bottom: 13px; }
  .left-controls {
    height: 180px;
    width: 10%; }
  .right-controls {
    height: 180px;
    width: 10%; }
  #content {
    padding: 30px 0 30px 0; } }

@media (min-width: 1025px) {
  #result-container1 {
    margin: 0 0 0 50px !important; } }

@media (max-width: 1024px) {
  #result-container1 {
    margin: 25px 13px 150px 13px !important; } }

@media (max-width: 1024px) {
  .SearchPage-container #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
    display: none; } }

.SearchPage-container .GBListItem {
  top: 30px; }

@media (max-width: 1024px) {
  .SearchPage-container .GBListItem {
    top: 180px; } }

@media (max-width: 1024px) {
  .SearchPage-container .GBListItem.withSSR {
    margin-top: 160px; } }

@media (max-width: 767px) {
  .SearchPage-container .GBListItem.withSSR.circleDisplay {
    margin-top: 0;
    top: 80px; } }

.SearchPage-container.movie-page .search-top {
  height: 0 !important; }

.SearchPage-container.movie-page #result-container1.catAcc_result {
  margin-top: 0 !important; }

#search_input {
  background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #696969;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-size: 40px;
  padding: 19px 26px;
  width: 40vw; }
  #search_input.with-proposals {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  @media (max-width: 767px) {
    #search_input {
      font-size: 1.8rem;
      width: 80vw; } }
  #search_input:focus {
    outline: none; }

#search_btn {
  align-items: center;
  background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #696969;
  border-radius: 5px;
  color: #C1C1C1;
  display: flex;
  font-size: 20px;
  justify-content: center;
  height: 50px;
  margin: 20px 0 50px 0;
  width: 20vw; }
  @media (max-width: 767px) {
    #search_btn {
      font-size: 16px; } }
  @media (max-width: 767px) {
    #search_btn {
      width: 40vw; } }
  #search_btn:hover {
    cursor: pointer; }

/* tiles style */
.tile-title {
  padding-top: 5px;
  color: #7d8877; }

.tile-overflow {
  overflow: hidden; }

.tile {
  position: relative;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  padding: 5px;
  /*    overflow: hidden; */
  width: 280px;
  height: 160px;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%; }
  .tile:hover {
    -webkit-transform: scale(1.7);
            transform: scale(1.7); }
  .tile:hover ~ .tile {
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0); }
  .tile .tile-img {
    transition: -webkit-transform 0.6s ease-out;
    transition: transform 0.6s ease-out;
    transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
    object-fit: cover;
    width: 280px;
    height: 160px; }

.photo-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  /*centering text inside .photo-overlay */
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s; }

.photo-overlay:hover {
  opacity: 1; }

.tile-desc {
  font-size: 0.6em;
  line-height: 1.3;
  margin: 0 0; }

.tile-btn-container {
  margin-top: 130px; }
  .tile-btn-container .addListBtn {
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid #a3a3a1;
    display: inline-block;
    cursor: pointer;
    color: #ecf0f1;
    font-size: 24px;
    padding: 2px 12px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
    margin-right: 10px;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
    .tile-btn-container .addListBtn:focus {
      outline: 0; }
    .tile-btn-container .addListBtn:hover {
      border: 2px solid #ecf0f1;
      background-color: transparent;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .tile-btn-container .addListBtn:active {
      position: relative;
      top: 1px; }
  .tile-btn-container .addListBtn {
    font-size: 22px;
    border: 1px solid #a3a3a1;
    padding: 10px 4px; }
    .tile-btn-container .addListBtn:hover {
      border: 1px solid #ecf0f1; }

.playbtn-container {
  margin: 35px 0px 10px 0px;
  text-align: center; }
  .playbtn-container .playBtn {
    content: "\25B6";
    background-color: transparent;
    color: #ecf0f1;
    border-radius: 100%;
    border: 2px solid #ecf0f1;
    display: inline-block;
    cursor: pointer;
    font-size: 24px;
    padding: 22px 10px 22px 15px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    opacity: 0.3; }
    .playbtn-container .playBtn:focus {
      outline: 0; }
    .playbtn-container .playBtn:hover {
      color: #b9090b;
      border: 2px solid #ecf0f1;
      background-color: transparent;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

.search-tile {
  position: relative;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  padding: 5px;
  overflow: hidden;
  width: 308px;
  height: 176px;
  transition: 0.3s -webkit-transform;
  transition: 0.3s transform;
  transition: 0.3s transform, 0.3s -webkit-transform;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }
  .search-tile:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  .search-tile .tile-img {
    object-fit: cover;
    width: 308px;
    height: 176px; }

.search-tile-desc {
  font-size: 0.65em;
  line-height: 1.3;
  margin: 0 0; }

.search-playbtn-container {
  margin: 45px 0px 10px 0px;
  text-align: center; }
  .search-playbtn-container .playBtn {
    content: "\25B6";
    background-color: transparent;
    color: #ecf0f1;
    border-radius: 100%;
    border: 2px solid #ecf0f1;
    display: inline-block;
    cursor: pointer;
    font-size: 24px;
    padding: 22px 10px 22px 15px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    opacity: 0.3; }
    .search-playbtn-container .playBtn:focus {
      outline: 0; }
    .search-playbtn-container .playBtn:hover {
      color: #b9090b;
      border: 2px solid #ecf0f1;
      background-color: transparent;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 1; }

/* grid layout */
#result-container {
  margin-top: 4%; }
  #result-container li {
    float: left;
    text-align: center;
    margin-right: 5%;
    margin-bottom: 5%;
    width: 20.25%; }
    #result-container li:nth-child(4n) {
      margin-right: 4%; }
    #result-container li img {
      width: 100%; }

/* MQ */
@media screen and (max-width: 1840px) {
  .search-tile {
    padding: 2px;
    width: 280px;
    height: 160px; }
  .search-playbtn-container {
    margin: 35px 0px 5px 0px; } }

@media screen and (max-width: 1590px) {
  .search-tile {
    width: 238px;
    height: 136px; }
  .search-playbtn-container {
    margin: 15px 0px 5px 0px; }
  .search-tile-title {
    font-size: 0.8em; }
  .search-tile-desc {
    font-size: 0.6em; } }

@media screen and (max-width: 1370px) {
  .search-tile:hover {
    -webkit-transform: scale(1.4);
            transform: scale(1.4); }
  .search-playbtn-container {
    margin: 10px 0px 5px 0px; }
  .search-tile-title {
    font-size: 0.8em; }
  .search-tile-desc {
    font-size: 0.6em; } }

@media screen and (max-width: 1175px) {
  #result-container li {
    float: left;
    text-align: center;
    margin-right: 4.5%;
    margin-bottom: 4.5%;
    width: 45.75%; }
    #result-container li:nth-child(2n) {
      margin-right: 4%; }
    #result-container li img {
      width: 100%; }
  .search-tile {
    width: 308px;
    height: 176px; }
    .search-tile:hover {
      -webkit-transform: scale(1.5);
              transform: scale(1.5); }
  .search-playbtn-container {
    margin: 30px 0px 5px 0px; }
  .search-tile-title {
    font-size: 1em; }
  .search-tile-desc {
    font-size: 0.8em; } }

@media screen and (max-width: 880px) {
  .search-playbtn-container {
    margin: 15px 0px 5px 0px; }
  .search-tile {
    width: 246.4px;
    height: 140.8px; }
  .search-tile-title {
    font-size: 0.8em; }
  .search-tile-desc {
    font-size: 0.65em; } }

@media screen and (max-width: 760px) {
  #result-container li {
    float: left;
    text-align: center;
    margin-right: 12%;
    margin-bottom: 12%;
    width: 96%; }
    #result-container li:nth-child(1n) {
      margin-right: 4%; }
    #result-container li img {
      width: 100%; }
  .search-playbtn-container {
    margin: 45px 0px 5px 0px; }
  .search-tile {
    width: 336px;
    height: 192px; }
  .search-tile-title {
    font-size: 1em; }
  .search-tile-desc {
    font-size: 0.8em; } }

@media screen and (max-width: 665px) {
  .search-tile {
    width: 280px;
    height: 160px; }
  .search-tile-title {
    font-size: 0.8em; }
  .search-tile-desc {
    font-size: 0.65em; } }

@media screen and (max-width: 545px) {
  .search-tile {
    width: 252px;
    height: 144px; }
  .search-playbtn-container {
    margin: 15px 0px 3px 0px; } }

@media screen and (max-width: 44px) {
  .search-playbtn-container {
    margin: 10px 0px 5px 0px; }
  .search-tile {
    width: 224px;
    height: 128px; }
  .search-tile-title {
    font-size: 0.75em; }
  .search-tile-desc {
    font-size: 0.55em; } }

.search_box {
  position: relative;
  z-index: 2; }
  .search_box #search_btn_small {
    align-items: center;
    border: 1px solid #696969;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    z-index: 3; }
    @media (max-width: 767px) {
      .search_box #search_btn_small {
        height: 78px; } }
    .search_box #search_btn_small.with-proposals {
      border-bottom-right-radius: 0; }
    .search_box #search_btn_small img {
      display: inline-block;
      height: 40px; }
  .search_box .search_proposals_box {
    background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #696969;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    width: 40vw;
    display: none;
    position: absolute;
    z-index: 1; }
    @media (max-width: 767px) {
      .search_box .search_proposals_box {
        width: 80vw; } }
    .search_box .search_proposals_box.show {
      display: block; }
    .search_box .search_proposals_box .search_proposals_row {
      align-items: center;
      display: flex;
      padding: 7px;
      margin: 7px 0; }
      .search_box .search_proposals_box .search_proposals_row.selected {
        background: transparent linear-gradient(90deg, #003732 0%, #002d2d 100%) 0% 0% no-repeat padding-box;
        cursor: pointer; }
      .search_box .search_proposals_box .search_proposals_row .completed img {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%); }
      @media (min-width: 1025px) {
        .search_box .search_proposals_box .search_proposals_row {
          padding: 7px 26px; }
          .search_box .search_proposals_box .search_proposals_row:hover {
            background: transparent linear-gradient(90deg, #003732 0%, #002d2d 100%) 0% 0% no-repeat padding-box;
            cursor: pointer; }
            .search_box .search_proposals_box .search_proposals_row:hover .completed img {
              -webkit-filter: grayscale(0);
                      filter: grayscale(0); } }
      .search_box .search_proposals_box .search_proposals_row img {
        border-radius: 5px;
        margin-right: 13px;
        width: 60px; }
      .search_box .search_proposals_box .search_proposals_row .search_proposals_title {
        margin: 0 13px;
        overflow: hidden;
        text-align: left;
        white-space: nowrap; }
      .search_box .search_proposals_box .search_proposals_row .search_proposals_type {
        color: #fba006;
        font-style: italic; }

#preference_container {
  color: #AEAEAE;
  font-size: 1.5rem;
  margin: 0 0 120px 50px; }
  @media (max-width: 1024px) {
    #preference_container {
      margin: 240px auto; } }
  #preference_container h1 {
    font-size: 4rem;
    padding-top: 120px;
    margin-left: 60px;
    margin-bottom: 60px; }
    @media (max-width: 1024px) {
      #preference_container h1 {
        padding-top: 0;
        margin-left: 0;
        font-size: 3rem;
        text-align: center; } }
  #preference_container #btn_submit {
    align-items: center;
    background-color: #fba00b;
    border-radius: 13px;
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    height: 40px;
    opacity: 1;
    width: 140px;
    transition: opacity 0.5s; }
    #preference_container #btn_submit:hover {
      cursor: pointer;
      opacity: 0.8; }
  #preference_container #btns_choice {
    display: flex;
    font-size: 1.3rem;
    margin: 0 26px;
    padding: 7px; }
    #preference_container #btns_choice .btns_choice_ico {
      height: 20px; }
    #preference_container #btns_choice #chasse_choice .btns_choice_ico {
      margin-right: 13px; }
    #preference_container #btns_choice #peche_choice .btns_choice_ico {
      margin-left: 13px; }
    #preference_container #btns_choice #chasse_choice, #preference_container #btns_choice #peche_choice {
      align-items: center;
      background-color: #5f5d5d;
      border-radius: 13px;
      color: #111;
      display: flex;
      justify-content: center;
      height: 40px;
      opacity: 0.6;
      width: 140px;
      transition: opacity 0.5s; }
      #preference_container #btns_choice #chasse_choice:hover, #preference_container #btns_choice #peche_choice:hover {
        cursor: pointer;
        opacity: 0.8; }
    #preference_container #btns_choice #chasse_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-right: -13px;
      opacity: 1;
      z-index: 2; }
    #preference_container #btns_choice #peche_choice.selected {
      background-color: #fba00b;
      color: #fff;
      margin-left: -13px;
      opacity: 1;
      z-index: 2; }
    #preference_container #btns_choice .selected .btns_choice_ico {
      -webkit-filter: invert(100%);
              filter: invert(100%); }
  #preference_container .preference_option {
    align-items: center;
    display: flex;
    margin: 50px 50px 26px 50px; }
    #preference_container .preference_option .f-grow {
      flex-grow: 1; }
    @media (max-width: 1024px) {
      #preference_container .preference_option {
        flex-direction: column;
        height: 200px; } }
    @media (max-width: 767px) {
      #preference_container .preference_option {
        margin: 50px 0 26px 0;
        font-size: 1.5rem; } }
  #preference_container .preference_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center; }
  #preference_container figure:hover .icon_box {
    opacity: 0.6; }
  #preference_container .categ_box .movie_mozaic {
    margin-left: 0; }
  @media (max-width: 767px) {
    #preference_container .categ_box {
      font-size: 1.2rem;
      max-width: 50vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      #preference_container .categ_box .icon_add {
        height: 25px;
        width: 25px; } }
  #preference_container .categ_box:hover {
    cursor: pointer; }
  #preference_container .icon_add {
    height: 30px;
    margin-left: 13px;
    margin-top: -3px;
    width: 30px;
    -webkit-filter: invert(100%) sepia(2%) saturate(710%) hue-rotate(232deg) brightness(117%) contrast(87%);
            filter: invert(100%) sepia(2%) saturate(710%) hue-rotate(232deg) brightness(117%) contrast(87%); }
    #preference_container .icon_add.selected {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  #preference_container .effect-thumbnail-movie.selected {
    border: 3px solid #FBA00B; }
  #preference_container .preference_error {
    color: #E33939;
    display: flex;
    font-weight: 700;
    height: 30px;
    justify-content: center;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      #preference_container .preference_error {
        font-size: 1.5rem; } }

.homepage-container {
  margin-top: 66px; }
  @media (max-width: 1024px) {
    .homepage-container {
      margin-top: 88px; } }

@media (max-width: 1024px) {
  .special-nav-menu #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
    display: none; } }

#profile_container, #profile_container_ {
  color: #fff;
  margin: auto;
  width: 960px;
  position: relative;
  z-index: 1; }
  @media (min-width: 1025px) {
    #profile_container, #profile_container_ {
      margin: 0 auto;
      padding: 120px 120px 0; } }
  @media (max-width: 1024px) {
    #profile_container, #profile_container_ {
      margin: 0 auto;
      padding: 120px 0 0; } }
  @media (max-width: 1024px) {
    #profile_container, #profile_container_ {
      width: 80vw; } }
  #profile_container #profile_loader, #profile_container_ #profile_loader {
    align-items: center;
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1; }
    #profile_container #profile_loader.bottom-loader, #profile_container_ #profile_loader.bottom-loader {
      bottom: 0;
      top: inherit; }
    #profile_container #profile_loader.show, #profile_container_ #profile_loader.show {
      display: flex; }
  #profile_container #profile_confirmation, #profile_container_ #profile_confirmation {
    align-items: center;
    background-color: #00000088;
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2; }
    #profile_container #profile_confirmation #profile_confirmation_box, #profile_container_ #profile_confirmation #profile_confirmation_box {
      align-items: center;
      background-color: #111;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 250px;
      width: 450px; }
      #profile_container #profile_confirmation #profile_confirmation_box button, #profile_container_ #profile_confirmation #profile_confirmation_box button {
        background: #FBA00B;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-weight: 100;
        height: 40px;
        margin: 23px;
        width: 80px; }
        #profile_container #profile_confirmation #profile_confirmation_box button.gray, #profile_container_ #profile_confirmation #profile_confirmation_box button.gray {
          background-color: #AEAEAE; }
        #profile_container #profile_confirmation #profile_confirmation_box button:hover, #profile_container_ #profile_confirmation #profile_confirmation_box button:hover {
          cursor: pointer; }
    #profile_container #profile_confirmation.show, #profile_container_ #profile_confirmation.show {
      display: flex; }
  #profile_container input, #profile_container_ input {
    background: #FFFFFF1C;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 20px;
    font-weight: 100;
    padding: 10px 13px;
    width: 300px; }
    @media (max-width: 767px) {
      #profile_container input, #profile_container_ input {
        width: 80vw; } }
    #profile_container input:focus, #profile_container_ input:focus {
      outline: none; }
  #profile_container .profile_edit_custom_select, #profile_container_ .profile_edit_custom_select {
    padding: 13px 0;
    width: 400px; }
    #profile_container .profile_edit_custom_select.box_center, #profile_container_ .profile_edit_custom_select.box_center {
      padding: 26px 0;
      width: 100%; }
    @media (max-width: 767px) {
      #profile_container .profile_edit_custom_select, #profile_container_ .profile_edit_custom_select {
        width: 80vw; } }
    #profile_container .profile_edit_custom_select:hover, #profile_container_ .profile_edit_custom_select:hover {
      cursor: pointer; }
    #profile_container .profile_edit_custom_select .profile_edit_custom_select_box, #profile_container_ .profile_edit_custom_select .profile_edit_custom_select_box {
      align-items: center;
      display: flex;
      justify-content: center; }
      #profile_container .profile_edit_custom_select .profile_edit_custom_select_box button, #profile_container_ .profile_edit_custom_select .profile_edit_custom_select_box button {
        background: #FFFFFF1C;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-weight: 100;
        height: 40px;
        margin: 0 13px;
        width: 120px; }
        #profile_container .profile_edit_custom_select .profile_edit_custom_select_box button.selected, #profile_container_ .profile_edit_custom_select .profile_edit_custom_select_box button.selected {
          background-color: #FBA00B;
          opacity: 0.8; }
        #profile_container .profile_edit_custom_select .profile_edit_custom_select_box button:hover, #profile_container_ .profile_edit_custom_select .profile_edit_custom_select_box button:hover {
          cursor: pointer; }
    #profile_container .profile_edit_custom_select .profile_select_selected, #profile_container_ .profile_edit_custom_select .profile_select_selected {
      background: #FFFFFF1C;
      border: none;
      border-radius: 2px;
      color: #fff;
      font-size: 20px;
      font-weight: 100;
      padding: 10px 13px;
      width: 400px; }
      @media (max-width: 767px) {
        #profile_container .profile_edit_custom_select .profile_select_selected, #profile_container_ .profile_edit_custom_select .profile_select_selected {
          width: 80vw; } }
      #profile_container .profile_edit_custom_select .profile_select_selected.open, #profile_container_ .profile_edit_custom_select .profile_select_selected.open {
        border-bottom: 3px solid #FFFFFF1A;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
      #profile_container .profile_edit_custom_select .profile_select_selected:hover, #profile_container_ .profile_edit_custom_select .profile_select_selected:hover {
        cursor: pointer; }
    #profile_container .profile_edit_custom_select .profile_select_option, #profile_container_ .profile_edit_custom_select .profile_select_option {
      background: #FFFFFF1C;
      border-bottom: 1px solid #FFFFFF1A;
      color: #fff;
      display: none;
      font-size: 20px;
      font-weight: 100;
      padding: 10px 13px;
      width: 400px; }
      @media (max-width: 767px) {
        #profile_container .profile_edit_custom_select .profile_select_option, #profile_container_ .profile_edit_custom_select .profile_select_option {
          width: 80vw; } }
      #profile_container .profile_edit_custom_select .profile_select_option.show, #profile_container_ .profile_edit_custom_select .profile_select_option.show {
        display: block; }
      #profile_container .profile_edit_custom_select .profile_select_option.last, #profile_container_ .profile_edit_custom_select .profile_select_option.last {
        border-bottom: none;
        border-radius: 2px; }
  #profile_container .profile_icon, #profile_container_ .profile_icon {
    height: 18px;
    margin: -3px 13px 0 0; }
    #profile_container .profile_icon.hover_icon:hover, #profile_container_ .profile_icon.hover_icon:hover {
      cursor: pointer; }
    #profile_container .profile_icon.mirror_icon, #profile_container_ .profile_icon.mirror_icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    #profile_container .profile_icon.small_icon, #profile_container_ .profile_icon.small_icon {
      height: 15px;
      margin: -2px 0 0 7px; }
    #profile_container .profile_icon.store_icon, #profile_container_ .profile_icon.store_icon {
      -webkit-filter: invert(100%);
              filter: invert(100%); }
    #profile_container .profile_icon.title_icon, #profile_container_ .profile_icon.title_icon {
      height: 30px; }
    #profile_container .profile_icon.white, #profile_container_ .profile_icon.white {
      height: 26px;
      -webkit-filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
              filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%); }
  #profile_container .profile_nav_return, #profile_container_ .profile_nav_return {
    color: #707070;
    margin-bottom: 52px; }
    #profile_container .profile_nav_return:hover, #profile_container_ .profile_nav_return:hover {
      cursor: pointer;
      display: inline-block;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
      @media (max-width: 1024px) {
        #profile_container .profile_nav_return:hover, #profile_container_ .profile_nav_return:hover {
          -webkit-transform: none;
                  transform: none; } }
  #profile_container .profile_success_icon, #profile_container_ .profile_success_icon {
    background-color: #FFFFFF1C;
    height: 150px;
    border-radius: 50%;
    padding: 20px;
    width: 150px; }
  #profile_container .profile_info_bubble_box, #profile_container_ .profile_info_bubble_box {
    position: relative;
    z-index: 1; }
    #profile_container .profile_info_bubble_box .profile_info_bubble, #profile_container_ .profile_info_bubble_box .profile_info_bubble {
      background: #ffffff44;
      border-radius: 4px;
      box-shadow: 2px 2px 10px #858585bf;
      color: #fff;
      position: absolute;
      display: none;
      padding: 13px;
      left: 100%;
      top: 20px;
      width: 200px;
      z-index: 2; }
      @media (max-width: 767px) {
        #profile_container .profile_info_bubble_box .profile_info_bubble, #profile_container_ .profile_info_bubble_box .profile_info_bubble {
          left: -100%; } }
      #profile_container .profile_info_bubble_box .profile_info_bubble.show, #profile_container_ .profile_info_bubble_box .profile_info_bubble.show {
        display: block; }
      #profile_container .profile_info_bubble_box .profile_info_bubble:hover, #profile_container_ .profile_info_bubble_box .profile_info_bubble:hover {
        cursor: pointer; }
  #profile_container .profile_box_center, #profile_container_ .profile_box_center {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; }
  #profile_container .profile_edit_usual_error, #profile_container_ .profile_edit_usual_error {
    color: #E33939;
    display: flex;
    font-weight: 700;
    height: 30px;
    justify-content: center; }
  #profile_container .profile_edit_usual_success, #profile_container_ .profile_edit_usual_success {
    color: #54C254;
    display: flex;
    font-weight: 700;
    height: 30px;
    justify-content: center; }
  #profile_container .profile_edit_usual, #profile_container_ .profile_edit_usual {
    width: 100%; }
    #profile_container .profile_edit_usual.profileManager .profile_edit_usual_current, #profile_container_ .profile_edit_usual.profileManager .profile_edit_usual_current {
      align-items: flex-start;
      flex-wrap: wrap; }
    #profile_container .profile_edit_usual.profileManager .user-avatar-box, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 13px; }
      #profile_container .profile_edit_usual.profileManager .user-avatar-box .avatar_box .addon_icon_box, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .avatar_box .addon_icon_box {
        right: -15px;
        top: 60px; }
      #profile_container .profile_edit_usual.profileManager .user-avatar-box .avatar_box.avatar_box_add, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .avatar_box.avatar_box_add {
        align-items: center;
        border: 3px solid #AEAEAE;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 100px;
        justify-content: center;
        margin-bottom: 7px;
        width: 100px; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .avatar_box.avatar_box_add img, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .avatar_box.avatar_box_add img {
          display: inline-block;
          -webkit-filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
                  filter: invert(100%) sepia(0%) saturate(7481%) hue-rotate(29deg) brightness(224%) contrast(60%);
          height: 50px;
          width: 50px; }
      #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar {
        margin: 0 0 7px 0; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar:hover, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar:hover {
          cursor: pointer;
          -webkit-transform: scale(1.05);
                  transform: scale(1.05); }
          @media (max-width: 1024px) {
            #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar:hover, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar:hover {
              -webkit-transform: none;
                      transform: none; } }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar.selected, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar.selected {
          border: 3px solid #fba00b; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar.user-avatar-default, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar.user-avatar-default {
          opacity: 0.3; }
          #profile_container .profile_edit_usual.profileManager .user-avatar-box .user-avatar.user-avatar-default:hover, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .user-avatar.user-avatar-default:hover {
            cursor: default;
            -webkit-transform: none;
                    transform: none; }
      #profile_container .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn {
        padding: 7px; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn button, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn button {
          height: 26px;
          width: 100px; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn.tall button, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn.tall button {
          height: 40px;
          width: 300px; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn .red, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn .red {
          background-color: #da0505; }
        #profile_container .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn .preferences_button, #profile_container_ .profile_edit_usual.profileManager .user-avatar-box .profile_edit_usual_btn .preferences_button {
          background-color: transparent;
          border: 2px solid #fba00b;
          margin: 13px 0; }
    #profile_container .profile_edit_usual .profile_edit_usual_current, #profile_container_ .profile_edit_usual .profile_edit_usual_current {
      align-items: center;
      display: flex;
      justify-content: space-around;
      padding: 30px; }
    #profile_container .profile_edit_usual .profile_edit_usual_form, #profile_container_ .profile_edit_usual .profile_edit_usual_form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      #profile_container .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_input, #profile_container .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_textarea, #profile_container_ .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_input, #profile_container_ .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_textarea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 13px 0; }
      #profile_container .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_input_info, #profile_container_ .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_input_info {
        color: #AEAEAE;
        font-size: 12px;
        margin-top: 7px; }
      #profile_container .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_textarea textarea, #profile_container_ .profile_edit_usual .profile_edit_usual_form .profile_edit_usual_textarea textarea {
        background: #FFFFFF1C;
        border: none;
        border-radius: 2px;
        color: #fff;
        display: block;
        font-size: 20px;
        font-weight: 100;
        height: 150px;
        padding: 10px 13px;
        resize: none; }
      #profile_container .profile_edit_usual .profile_edit_usual_form label, #profile_container_ .profile_edit_usual .profile_edit_usual_form label {
        font-size: 14px; }
      #profile_container .profile_edit_usual .profile_edit_usual_form input, #profile_container .profile_edit_usual .profile_edit_usual_form textarea, #profile_container .profile_edit_usual .profile_edit_usual_form select, #profile_container_ .profile_edit_usual .profile_edit_usual_form input, #profile_container_ .profile_edit_usual .profile_edit_usual_form textarea, #profile_container_ .profile_edit_usual .profile_edit_usual_form select {
        width: 400px; }
        @media (max-width: 767px) {
          #profile_container .profile_edit_usual .profile_edit_usual_form input, #profile_container .profile_edit_usual .profile_edit_usual_form textarea, #profile_container .profile_edit_usual .profile_edit_usual_form select, #profile_container_ .profile_edit_usual .profile_edit_usual_form input, #profile_container_ .profile_edit_usual .profile_edit_usual_form textarea, #profile_container_ .profile_edit_usual .profile_edit_usual_form select {
            width: 80vw; } }
  #profile_container .profile_emoji, #profile_container_ .profile_emoji {
    height: 36px;
    margin-left: 13px;
    margin-top: -7px;
    opacity: 0.8;
    width: 36px; }
  #profile_container .f-grow-1, #profile_container_ .f-grow-1 {
    flex-grow: 1; }
  #profile_container .avatar-list-box, #profile_container_ .avatar-list-box {
    align-items: center;
    display: flex;
    height: 170px;
    padding-left: 50px;
    position: relative;
    width: 100vw;
    z-index: 2;
    -webkit-user-select: none;
    user-select: none; }
    #profile_container .avatar-list-box .avatar-list-title, #profile_container_ .avatar-list-box .avatar-list-title {
      color: #c1c1c1;
      font-size: 2rem;
      font-weight: 500;
      left: 90px;
      position: absolute;
      top: -26px;
      z-index: 2; }
      @media (max-width: 1024px) {
        #profile_container .avatar-list-box .avatar-list-title, #profile_container_ .avatar-list-box .avatar-list-title {
          font-size: 1.75rem;
          left: 53px; } }
      @media (max-width: 767px) {
        #profile_container .avatar-list-box .avatar-list-title, #profile_container_ .avatar-list-box .avatar-list-title {
          font-size: 1.25rem;
          left: 36px; } }
    #profile_container .avatar-list-box .avatar-list, #profile_container_ .avatar-list-box .avatar-list {
      align-items: center;
      background-color: transparent;
      color: #c1c1c1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 100%;
      overflow-x: scroll;
      padding: 0 40px;
      -ms-overflow-style: none; }
      @media (max-width: 1024px) {
        #profile_container .avatar-list-box .avatar-list, #profile_container_ .avatar-list-box .avatar-list {
          padding: 0; } }
      #profile_container .avatar-list-box .avatar-list::-webkit-scrollbar, #profile_container_ .avatar-list-box .avatar-list::-webkit-scrollbar {
        height: 0 !important;
        width: 0 !important; }
      #profile_container .avatar-list-box .avatar-list .avatar-box, #profile_container_ .avatar-list-box .avatar-list .avatar-box {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0 13px; }
        #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar {
          border-radius: 50%;
          height: 100px;
          transition: -webkit-transform .5s;
          transition: transform .5s;
          transition: transform .5s, -webkit-transform .5s;
          width: 100px; }
          #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar:hover, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar:hover {
            cursor: pointer;
            -webkit-transform: scale(1.05);
                    transform: scale(1.05); }
            @media (max-width: 1024px) {
              #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar:hover, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar:hover {
                -webkit-transform: none;
                        transform: none; } }
          #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar.selected, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar.selected {
            border: 3px solid #fba00b; }
          #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar.user-avatar-default, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar.user-avatar-default {
            opacity: 0.3; }
            #profile_container .avatar-list-box .avatar-list .avatar-box .user-avatar.user-avatar-default:hover, #profile_container_ .avatar-list-box .avatar-list .avatar-box .user-avatar.user-avatar-default:hover {
              cursor: default;
              -webkit-transform: none;
                      transform: none; }
    #profile_container .avatar-list-box .arrow, #profile_container_ .avatar-list-box .arrow {
      color: #eee;
      display: block;
      position: absolute;
      font-size: 42px;
      top: 50px;
      transition: -webkit-transform .5s;
      transition: transform .5s;
      transition: transform .5s, -webkit-transform .5s;
      z-index: 2; }
      @media (max-width: 1024px) {
        #profile_container .avatar-list-box .arrow, #profile_container_ .avatar-list-box .arrow {
          display: none; } }
      #profile_container .avatar-list-box .arrow:hover, #profile_container_ .avatar-list-box .arrow:hover {
        cursor: pointer;
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
        @media (max-width: 1024px) {
          #profile_container .avatar-list-box .arrow:hover, #profile_container_ .avatar-list-box .arrow:hover {
            -webkit-transform: none;
                    transform: none; } }
      #profile_container .avatar-list-box .arrow.disabled, #profile_container_ .avatar-list-box .arrow.disabled {
        opacity: 0.5; }
      #profile_container .avatar-list-box .arrow.disabled:hover, #profile_container_ .avatar-list-box .arrow.disabled:hover {
        cursor: default;
        -webkit-transform: none;
                transform: none; }
      #profile_container .avatar-list-box .arrow.arrow-left, #profile_container_ .avatar-list-box .arrow.arrow-left {
        left: 64px; }
      #profile_container .avatar-list-box .arrow.arrow-right, #profile_container_ .avatar-list-box .arrow.arrow-right {
        right: 13px; }
  #profile_container .user-avatar-box, #profile_container_ .user-avatar-box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 26px; }
    #profile_container .user-avatar-box .avatar_box, #profile_container_ .user-avatar-box .avatar_box {
      position: relative;
      z-index: 1; }
      #profile_container .user-avatar-box .avatar_box .addon_icon_box, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box {
        background-color: #fff;
        border-radius: 50%;
        height: 40px;
        position: absolute;
        right: 40px;
        top: 40px;
        width: 40px;
        z-index: 1; }
        @media (max-width: 767px) {
          #profile_container .user-avatar-box .avatar_box .addon_icon_box, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box {
            right: -10px; } }
        #profile_container .user-avatar-box .avatar_box .addon_icon_box:hover, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box:hover {
          cursor: pointer; }
          #profile_container .user-avatar-box .avatar_box .addon_icon_box:hover .addon_icon, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box:hover .addon_icon {
            -webkit-transform: scale(1.1);
                    transform: scale(1.1); }
            @media (max-width: 1024px) {
              #profile_container .user-avatar-box .avatar_box .addon_icon_box:hover .addon_icon, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box:hover .addon_icon {
                -webkit-transform: none;
                        transform: none; } }
        #profile_container .user-avatar-box .avatar_box .addon_icon_box .addon_icon, #profile_container_ .user-avatar-box .avatar_box .addon_icon_box .addon_icon {
          height: 20px;
          -webkit-filter: invert(81%) sepia(0%) saturate(2501%) hue-rotate(184deg) brightness(89%) contrast(83%);
                  filter: invert(81%) sepia(0%) saturate(2501%) hue-rotate(184deg) brightness(89%) contrast(83%);
          opacity: 0.8;
          margin-left: 10px;
          margin-top: 9px;
          width: 20px; }
      #profile_container .user-avatar-box .avatar_box .user-avatar, #profile_container_ .user-avatar-box .avatar_box .user-avatar {
        border-radius: 50%;
        height: 100px;
        width: 100px; }
    @media (max-width: 767px) {
      #profile_container .user-avatar-box, #profile_container_ .user-avatar-box {
        flex-direction: column; } }
  #profile_container .user-avatar, #profile_container_ .user-avatar {
    border-radius: 50%;
    height: 80px;
    margin-right: 50px;
    margin-top: -20px;
    width: 80px; }
    @media (max-width: 767px) {
      #profile_container .user-avatar, #profile_container_ .user-avatar {
        margin-right: 0; } }
  #profile_container .user-avatar-first_name, #profile_container_ .user-avatar-first_name {
    display: inline-block;
    font-size: 30px;
    width: 140px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #profile_container #profile_nav, #profile_container_ #profile_nav {
    margin: 0 0 50px -50px; }
    @media (max-width: 767px) {
      #profile_container #profile_nav, #profile_container_ #profile_nav {
        margin: 0 0 50px -20px; } }
    #profile_container #profile_nav .profile_nav:hover, #profile_container_ #profile_nav .profile_nav:hover {
      cursor: pointer;
      display: inline-block;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
      @media (max-width: 1024px) {
        #profile_container #profile_nav .profile_nav:hover, #profile_container_ #profile_nav .profile_nav:hover {
          -webkit-transform: none;
                  transform: none; } }
    #profile_container #profile_nav .profile_icon, #profile_container_ #profile_nav .profile_icon {
      margin-left: 13px; }
  #profile_container #profile_header, #profile_container_ #profile_header {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      #profile_container #profile_header, #profile_container_ #profile_header {
        flex-direction: column; }
        #profile_container #profile_header h1, #profile_container_ #profile_header h1 {
          margin: 26px 0; } }
    #profile_container #profile_header h1, #profile_container_ #profile_header h1 {
      white-space: nowrap; }
    #profile_container #profile_header.profile_header_title, #profile_container_ #profile_header.profile_header_title {
      justify-content: flex-start; }
      @media (max-width: 767px) {
        #profile_container #profile_header.profile_header_title, #profile_container_ #profile_header.profile_header_title {
          flex-direction: row;
          margin-bottom: 0; } }
    #profile_container #profile_header #profile_menu_details, #profile_container_ #profile_header #profile_menu_details {
      align-items: center;
      background-color: #FFFFFF1C;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      height: 40px;
      justify-content: center;
      width: 180px; }
      #profile_container #profile_header #profile_menu_details:hover, #profile_container_ #profile_header #profile_menu_details:hover {
        cursor: pointer; }
        #profile_container #profile_header #profile_menu_details:hover .profile_scale, #profile_container_ #profile_header #profile_menu_details:hover .profile_scale {
          display: inline-block;
          -webkit-transform: scale(1.05);
                  transform: scale(1.05); }
          @media (max-width: 1024px) {
            #profile_container #profile_header #profile_menu_details:hover .profile_scale, #profile_container_ #profile_header #profile_menu_details:hover .profile_scale {
              -webkit-transform: none;
                      transform: none; } }
  #profile_container input[type=checkbox], #profile_container_ input[type=checkbox] {
    width: 0;
    display: none; }
  #profile_container #profile_link, #profile_container_ #profile_link {
    background-color: transparent;
    border: none;
    font-size: 16px;
    resize: none;
    overflow: hidden;
    padding: 13px 7px 0px;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
    #profile_container #profile_link.profile_link_full, #profile_container_ #profile_link.profile_link_full {
      margin: 0 100px;
      width: 400px; }
      @media (max-width: 767px) {
        #profile_container #profile_link.profile_link_full, #profile_container_ #profile_link.profile_link_full {
          margin: 0 64px;
          width: 220px; } }
      @media (max-width: 500px) {
        #profile_container #profile_link.profile_link_full, #profile_container_ #profile_link.profile_link_full {
          margin: 0; } }
    #profile_container #profile_link::selection, #profile_container_ #profile_link::selection {
      background-color: transparent;
      color: #fff; }
  #profile_container #profile_link_step3, #profile_container #profile_link_dashboard, #profile_container_ #profile_link_step3, #profile_container_ #profile_link_dashboard {
    margin-top: 7px; }
  #profile_container .profile_card_box, #profile_container_ .profile_card_box {
    align-items: center;
    display: flex;
    justify-content: center; }
    @media (max-width: 767px) {
      #profile_container .profile_card_box, #profile_container_ .profile_card_box {
        flex-direction: column; } }
  #profile_container .profile_card, #profile_container_ .profile_card {
    background-color: #FFFFFF12;
    border: 1px solid #FFFFFF1A;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 50px;
    padding: 13px; }
    #profile_container .profile_card .text-center, #profile_container_ .profile_card .text-center {
      text-align: center; }
    #profile_container .profile_card.full, #profile_container_ .profile_card.full {
      margin: 26px 2%;
      width: 96%; }
      #profile_container .profile_card.full.only_btn, #profile_container_ .profile_card.full.only_btn {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 26px 13px; }
        #profile_container .profile_card.full.only_btn .profile_card_button, #profile_container_ .profile_card.full.only_btn .profile_card_button {
          width: 100%; }
        #profile_container .profile_card.full.only_btn button, #profile_container_ .profile_card.full.only_btn button {
          margin: 13px 0; }
      #profile_container .profile_card.full.with_margin .profile_card_tall, #profile_container_ .profile_card.full.with_margin .profile_card_tall {
        margin: 42px 0; }
      @media (max-width: 767px) {
        #profile_container .profile_card.full.with_margin .profile_edit_usual_input, #profile_container_ .profile_card.full.with_margin .profile_edit_usual_input {
          margin: 0 10%;
          width: 100%; }
        #profile_container .profile_card.full.with_margin input, #profile_container_ .profile_card.full.with_margin input {
          width: 100%; } }
    #profile_container .profile_card.half, #profile_container_ .profile_card.half {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 400px;
      width: 48%;
      margin: 0 2%; }
      @media (max-width: 767px) {
        #profile_container .profile_card.half, #profile_container_ .profile_card.half {
          margin: 26px 2%;
          width: 96%; } }
    #profile_container .profile_card .profile_card_button, #profile_container_ .profile_card .profile_card_button {
      text-align: center; }
      #profile_container .profile_card .profile_card_button button, #profile_container_ .profile_card .profile_card_button button {
        background-color: #FBA00B;
        border: none;
        border-radius: 5px;
        color: #fff;
        font-weight: 100;
        height: 40px;
        margin-top: 26px;
        padding: 7px 26px;
        min-width: 220px; }
        #profile_container .profile_card .profile_card_button button.tall, #profile_container_ .profile_card .profile_card_button button.tall {
          min-width: 320px; }
          @media (max-width: 500px) {
            #profile_container .profile_card .profile_card_button button.tall, #profile_container_ .profile_card .profile_card_button button.tall {
              min-width: 80%;
              max-width: 80%;
              height: auto; } }
        #profile_container .profile_card .profile_card_button button.gray, #profile_container_ .profile_card .profile_card_button button.gray {
          background-color: #AEAEAE; }
        #profile_container .profile_card .profile_card_button button:hover, #profile_container_ .profile_card .profile_card_button button:hover {
          cursor: pointer; }
        #profile_container .profile_card .profile_card_button button.disabled, #profile_container_ .profile_card .profile_card_button button.disabled {
          opacity: 0.6;
          pointer-events: none; }
    #profile_container .profile_card .profile_card_title, #profile_container_ .profile_card .profile_card_title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 13px;
      text-align: center; }
    #profile_container .profile_card .profile_card_tall, #profile_container_ .profile_card .profile_card_tall {
      font-size: 24px;
      margin-bottom: 7px; }
    #profile_container .profile_card .profile_card_small, #profile_container_ .profile_card .profile_card_small {
      font-size: 14px;
      margin-bottom: 7px; }
      #profile_container .profile_card .profile_card_small.special-margin, #profile_container_ .profile_card .profile_card_small.special-margin {
        margin: 7px 0 0 0; }
    #profile_container .profile_card .profile_card_list, #profile_container_ .profile_card .profile_card_list {
      align-items: center;
      border-bottom: 1px solid #FFFFFF1A;
      display: flex;
      font-size: 18px;
      padding: 7px;
      margin: 13px 0; }
      @media (max-width: 767px) {
        #profile_container .profile_card .profile_card_list, #profile_container_ .profile_card .profile_card_list {
          align-items: flex-start;
          flex-direction: column; } }
      #profile_container .profile_card .profile_card_list.first, #profile_container_ .profile_card .profile_card_list.first {
        margin-top: 50px; }
      #profile_container .profile_card .profile_card_list.last, #profile_container_ .profile_card .profile_card_list.last {
        margin-bottom: 50px; }
  #profile_container #profile_body, #profile_container_ #profile_body {
    border: 1px solid #FFFFFF1A;
    border-radius: 10px;
    font-size: 18px;
    margin-bottom: 50px; }
    #profile_container #profile_body .profile_menu, #profile_container_ #profile_body .profile_menu {
      align-items: center;
      background-color: #FFFFFF12;
      border-bottom: 1px solid #FFFFFF1A;
      display: flex;
      padding: 26px; }
      #profile_container #profile_body .profile_menu.profile_sub, #profile_container_ #profile_body .profile_menu.profile_sub {
        min-height: 100px; }
      #profile_container #profile_body .profile_menu .sub_current, #profile_container_ #profile_body .profile_menu .sub_current {
        display: none; }
      #profile_container #profile_body .profile_menu.selected, #profile_container_ #profile_body .profile_menu.selected {
        background-color: #FFFFFF44;
        border: 2px solid #fba00b88 !important; }
        #profile_container #profile_body .profile_menu.selected:first-child, #profile_container_ #profile_body .profile_menu.selected:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px; }
        #profile_container #profile_body .profile_menu.selected:last-child, #profile_container_ #profile_body .profile_menu.selected:last-child {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px; }
        #profile_container #profile_body .profile_menu.selected .sub_label, #profile_container_ #profile_body .profile_menu.selected .sub_label {
          color: #fba00b;
          font-weight: 700; }
      #profile_container #profile_body .profile_menu.current_offer .sub_current, #profile_container_ #profile_body .profile_menu.current_offer .sub_current {
        display: block; }
      #profile_container #profile_body .profile_menu .sub_title, #profile_container_ #profile_body .profile_menu .sub_title {
        width: 150px; }
      #profile_container #profile_body .profile_menu.profile_hover:hover, #profile_container_ #profile_body .profile_menu.profile_hover:hover {
        cursor: pointer; }
        #profile_container #profile_body .profile_menu.profile_hover:hover.current_offer, #profile_container_ #profile_body .profile_menu.profile_hover:hover.current_offer {
          cursor: default; }
        #profile_container #profile_body .profile_menu.profile_hover:hover .profile_scale, #profile_container_ #profile_body .profile_menu.profile_hover:hover .profile_scale {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
          @media (max-width: 1024px) {
            #profile_container #profile_body .profile_menu.profile_hover:hover .profile_scale, #profile_container_ #profile_body .profile_menu.profile_hover:hover .profile_scale {
              -webkit-transform: none;
                      transform: none; } }
        #profile_container #profile_body .profile_menu.profile_hover:hover.current_offer .profile_scale, #profile_container_ #profile_body .profile_menu.profile_hover:hover.current_offer .profile_scale {
          -webkit-transform: none;
                  transform: none; }
      #profile_container #profile_body .profile_menu:last-child, #profile_container_ #profile_body .profile_menu:last-child {
        border-bottom: none; }
      #profile_container #profile_body .profile_menu.menu_card, #profile_container_ #profile_body .profile_menu.menu_card {
        flex-direction: column;
        align-items: flex-start !important;
        font-size: 1.5rem;
        padding: 0; }
        #profile_container #profile_body .profile_menu.menu_card .menu_card_title, #profile_container_ #profile_body .profile_menu.menu_card .menu_card_title {
          font-size: 2rem;
          margin: 13px 0 13px 26px; }
        #profile_container #profile_body .profile_menu.menu_card .menu_card_details, #profile_container_ #profile_body .profile_menu.menu_card .menu_card_details {
          margin: 0 0 0 26px; }
        #profile_container #profile_body .profile_menu.menu_card .local_stripe_container, #profile_container_ #profile_body .profile_menu.menu_card .local_stripe_container {
          font-size: initial;
          width: 100%; }
          #profile_container #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form, #profile_container_ #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form {
            text-align: center;
            margin: 13px auto; }
            #profile_container #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form .local_stripe_button, #profile_container_ #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form .local_stripe_button {
              margin: 26px 0; }
              #profile_container #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form .local_stripe_button.gray, #profile_container_ #profile_body .profile_menu.menu_card .local_stripe_container .local_stripe_form .local_stripe_button.gray {
                background-color: #AEAEAE; }
    #profile_container #profile_body #profile_menu_reactivate, #profile_container_ #profile_body #profile_menu_reactivate {
      background: #fba00b88; }
    #profile_container #profile_body a.profile_menu_small, #profile_container_ #profile_body a.profile_menu_small {
      font-weight: 300; }
      #profile_container #profile_body a.profile_menu_small:hover, #profile_container_ #profile_body a.profile_menu_small:hover {
        color: #fff; }
    #profile_container #profile_body .profile_menu_small, #profile_container_ #profile_body .profile_menu_small {
      align-items: center;
      border-bottom: 1px solid #FFFFFF1A;
      display: flex;
      padding: 13px 26px; }
      #profile_container #profile_body .profile_menu_small.profile_hover:hover, #profile_container_ #profile_body .profile_menu_small.profile_hover:hover {
        cursor: pointer; }
        #profile_container #profile_body .profile_menu_small.profile_hover:hover .profile_scale, #profile_container_ #profile_body .profile_menu_small.profile_hover:hover .profile_scale {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1); }
          @media (max-width: 1024px) {
            #profile_container #profile_body .profile_menu_small.profile_hover:hover .profile_scale, #profile_container_ #profile_body .profile_menu_small.profile_hover:hover .profile_scale {
              -webkit-transform: none;
                      transform: none; } }
      #profile_container #profile_body .profile_menu_small:last-child, #profile_container_ #profile_body .profile_menu_small:last-child {
        border-bottom: none; }
      #profile_container #profile_body .profile_menu_small .profile_scale_billing_date, #profile_container_ #profile_body .profile_menu_small .profile_scale_billing_date {
        display: inline-block;
        width: 100px; }
      #profile_container #profile_body .profile_menu_small .profile_scale_billing_error, #profile_container_ #profile_body .profile_menu_small .profile_scale_billing_error {
        color: #E33939;
        font-weight: 700; }
    #profile_container #profile_body .profile_menu_title, #profile_container_ #profile_body .profile_menu_title {
      align-items: center;
      background-color: #FFFFFF12;
      border-bottom: 1px solid #FFFFFF1A;
      color: #AEAEAE;
      display: flex;
      padding: 13px 26px; }
  #profile_container #profile_footer, #profile_container_ #profile_footer {
    align-items: center;
    color: #AEAEAE;
    display: flex;
    font-size: 16px;
    justify-content: center; }
    #profile_container #profile_footer #profile_logout, #profile_container_ #profile_footer #profile_logout {
      cursor: pointer; }
  #profile_container .unsub_form_confirmation input, #profile_container_ .unsub_form_confirmation input {
    width: 100%; }

.profile_edit_usual_btn {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px; }
  .profile_edit_usual_btn button {
    background: #FBA00B;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 100;
    height: 40px;
    width: 300px; }
    .profile_edit_usual_btn button:hover {
      cursor: pointer; }

.progress {
  height: 150px !important;
  line-height: 150px;
  margin: 26px auto;
  position: relative;
  width: 150px; }
  @media (max-width: 1024px) {
    .progress {
      height: 140px !important;
      margin: 0;
      width: 140px; } }

.cat-retour {
  cursor: pointer; }

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 20px solid #00000029;
  position: absolute;
  top: 0;
  left: 0; }

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1; }

.progress .progress-left {
  left: 0; }

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 20px;
  border-style: solid;
  position: absolute;
  top: 0; }

.progress.orange {
  background-color: transparent; }

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left; }

.progress .progress-right {
  right: 0; }

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  -webkit-animation: loading-1 1.8s linear forwards;
          animation: loading-1 1.8s linear forwards; }

.progress .progress-value {
  color: #fff;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%; }

.progress.orange .progress-bar {
  border-color: #fba00b; }

.progress.orange .progress-left .progress-bar {
  -webkit-animation: loading-l 1s linear forwards 2s;
          animation: loading-l 1s linear forwards 2s; }

.progress.orange .progress-right .progress-bar {
  -webkit-animation: loading-r 2s linear forwards;
          animation: loading-r 2s linear forwards; }

.profile_movieList {
  margin: 13px 0 13px 50px; }

#profile_container.with_movie {
  min-height: 0;
  padding-bottom: 26px; }
  @media (max-width: 1024px) {
    #profile_container.with_movie {
      padding-bottom: 26px;
      margin-bottom: 0; } }

#profile_container_.with_movie {
  min-height: 0;
  padding-bottom: 0;
  padding-top: 0; }
  @media (max-width: 1024px) {
    #profile_container_.with_movie {
      margin-top: 0; } }

.profile_unsub_patharrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 5px;
  height: 10px; }

.profile_unsubStep1_container {
  max-width: 960px;
  color: white;
  padding: 120px 0 0;
  margin: auto; }

.profile_intro_texte {
  width: 50%;
  margin: 0 auto 20px; }
  @media (max-width: 770px) {
    .profile_intro_texte {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 5%; } }
  .profile_intro_texte h1 {
    font-size: 25px; }
  .profile_intro_texte p {
    color: #dbdbdb;
    margin-top: 10px; }

#profile_nav2 {
  margin: 0 0 40px;
  display: flex;
  justify-content: space-between; }
  #profile_nav2 .profile_nav {
    color: #707070; }
  #profile_nav2 .profile_nav:hover {
    cursor: pointer;
    display: inline-block;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
    @media (max-width: 1024px) {
      #profile_nav2 .profile_nav:hover {
        -webkit-transform: none;
                transform: none; } }
  #profile_nav2 .profile_icon {
    margin-left: 13px; }

.profile_unsub_nav_btns {
  margin: 20px auto 0;
  width: 80%;
  color: white; }
  .profile_unsub_nav_btns.with_btn-return {
    margin-top: -13px; }
  .profile_unsub_nav_btns.without_btn-return {
    margin-top: 0; }
  @media (min-width: 370px) {
    .profile_unsub_nav_btns {
      display: flex;
      justify-content: center;
      width: 80%;
      max-width: 400px; }
      .profile_unsub_nav_btns.with_btn-return {
        align-items: center;
        flex-direction: column;
        margin-top: 0; }
        .profile_unsub_nav_btns.with_btn-return .profile_btn_return {
          margin: 0 0 26px 0;
          padding: 7px;
          width: 200px; } }
  @media (min-width: 770px) {
    .profile_unsub_nav_btns {
      display: block;
      width: 200px; } }
  .profile_unsub_nav_btns div {
    width: 100%;
    margin: 0 auto 8px; }
    @media (max-width: 770px) {
      .profile_unsub_nav_btns div {
        margin-right: 5px; } }

.profile-unsub-form {
  max-width: 480px;
  margin: auto; }
  .profile-unsub-form ul {
    list-style-type: none;
    margin-bottom: 10%; }
    .profile-unsub-form ul li {
      margin-top: 10px; }
      .profile-unsub-form ul li:nth-child(1) {
        position: relative; }
  .profile-unsub-form div:nth-child(2) h1 {
    font-size: 27px;
    font-weight: 400;
    line-height: 1.1; }
  .profile-unsub-form div:nth-child(2) p {
    color: #dcdcdc; }
  .profile-unsub-form .unsub_form_confirmation ~ div {
    margin-top: 20px;
    text-align: center; }
  .profile-unsub-form .profile_icons {
    position: absolute;
    left: -30px;
    top: 5px; }
  .profile-unsub-form #info {
    fill: #fba00b; }

.checkbox-section-container {
  margin: 0 auto 20px; }
  .checkbox-section-container > div:nth-child(1) {
    margin-bottom: 10px; }
  .checkbox-section-container .input-groups label {
    color: #a1a1a1;
    font-size: 16px; }
  .checkbox-section-container .input-groups input::-webkit-input-placeholder {
    color: white; }
  .checkbox-section-container .input-groups input::placeholder {
    color: white; }
  .checkbox-section-container .fade {
    margin-left: 35px; }
    @media (max-width: 767px) {
      .checkbox-section-container .fade input {
        width: 200px; } }

.checkbox-wrapper {
  display: flex;
  padding: 7px 0; }
  .checkbox-wrapper > div {
    cursor: pointer; }
    .checkbox-wrapper > div:nth-child(2) {
      font-weight: 100;
      font-size: 16px; }
  .checkbox-wrapper .checkBox {
    margin-right: 20px;
    width: 20px;
    position: relative; }
    .checkbox-wrapper .checkBox label {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      border-radius: 4px;
      background: transparent;
      border: 1px solid white; }
      .checkbox-wrapper .checkBox label:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 10px;
        height: 7px;
        top: 5px;
        left: 5px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      @media (min-width: 1025px) {
        .checkbox-wrapper .checkBox label:hover {
          opacity: 0.6; } }
    .checkbox-wrapper .checkBox input[type=checkbox]:checked + label:after {
      opacity: 1; }
    .checkbox-wrapper .checkBox input[type=checkbox]:checked + label {
      background: #fba00b;
      border: none; }
      .checkbox-wrapper .checkBox input[type=checkbox]:checked + label:hover {
        opacity: 1; }

#result-container1 > .GBListItem {
  top: 0; }
  #result-container1 > .GBListItem .movie_mozaic {
    justify-content: center;
    margin: 0; }
  #result-container1 > .GBListItem .effect-thumbnail-movie {
    border-radius: 7px; }

@media (max-width: 1024px) {
  .profile_unsubStep1_container + #result-container1 {
    margin: 25px 13px 0 13px  !important; } }

.btn, .profile_btn_return, .profile_btn_continuer, .profile_btn_unsub, .profile_btn_save {
  font-size: 16px;
  padding: 0.5rem 0.2rem;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn *, .profile_btn_return *, .profile_btn_continuer *, .profile_btn_unsub *, .profile_btn_save * {
    display: block; }
  .btn:hover > *, .profile_btn_return:hover > *, .profile_btn_continuer:hover > *, .profile_btn_unsub:hover > *, .profile_btn_save:hover > * {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.profile_btn_return {
  background-color: #fba00b; }

.profile_btn_continuer {
  background-color: #afafaf; }

.profile_btn_unsub {
  background-color: #da0505; }

.profile_btn_save {
  background-color: #fba00b; }
  .profile_btn_save:hover {
    background-color: #fcb43d; }

.cat-selects-container {
  display: flex;
  flex-wrap: wrap; }
  .cat-selects-container .catValidated {
    background: #FBA00B !important; }
  .cat-selects-container .cat-select {
    border-radius: 50px;
    background: #FFFFFF1C;
    padding: 4px 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
    margin-top: 10px;
    transition: 0.4s; }
    .cat-selects-container .cat-select:hover {
      opacity: 0.8; }

.title-wrap-next-overlay {
  color: white; }

.form-textarea textarea {
  display: block;
  width: 100%;
  resize: none;
  background: #FFFFFF1C;
  color: #fff;
  border: none;
  border-radius: 6px; }

.form-textarea span {
  color: #a1a1a1; }

.fade {
  -webkit-animation: fade 1s;
          animation: fade 1s;
  opacity: 1; }

.selected-cat {
  border-radius: 50px;
  background: #FFFFFF1C;
  display: flex;
  padding: 4px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
  margin-top: 10px;
  transition: 0.4s; }
  .selected-cat:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
    .selected-cat:hover svg {
      fill: #979797; }
  .selected-cat svg {
    transition: 0.2s; }

.cat-selects-title {
  padding: 4px 12px; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#profile_unsub_clock {
  color: #fba00b;
  display: inline-block;
  font-size: 32px;
  margin: 13px 0; }

.profile_unsub_list {
  margin-top: 52px;
  padding-left: 0 !important; }

.profile_unsub_title {
  text-align: center; }

#profile_container #profile_header.orders_header {
  margin-bottom: 26px; }
  @media (max-width: 767px) {
    #profile_container #profile_header.orders_header {
      flex-direction: row; }
      #profile_container #profile_header.orders_header h1 {
        margin: 26px 0; } }
  #profile_container #profile_header.orders_header .profile_icon {
    height: 26px;
    margin: 0;
    width: 26px; }
  #profile_container #profile_header.orders_header .orders_btn_inactive {
    pointer-events: none; }
    #profile_container #profile_header.orders_header .orders_btn_inactive .profile_icon {
      opacity: .4; }
  #profile_container #profile_header.orders_header .orders_btn_active {
    cursor: pointer; }
    #profile_container #profile_header.orders_header .orders_btn_active .profile_icon {
      -webkit-filter: invert(100%);
              filter: invert(100%); }

.purchase_general div {
  margin: 7px 0; }

.purchase_general .purchase_status_delivered {
  margin: 13px 0; }
  .purchase_general .purchase_status_delivered span {
    border-radius: 5px;
    background: #FFFFFF1C;
    display: inline-block;
    color: #54C254;
    font-weight: 700;
    padding: 1px 26px; }

.purchase_general .purchase_status_deliveryProcess {
  margin: 13px 0; }
  .purchase_general .purchase_status_deliveryProcess span {
    border-radius: 5px;
    background: #FFFFFF1C;
    display: inline-block;
    color: #eee;
    font-weight: 700;
    padding: 1px 26px; }

.purchase_general .purchase_status_paid {
  margin: 13px 0; }
  .purchase_general .purchase_status_paid span {
    border-radius: 5px;
    background: #FFFFFF1C;
    display: inline-block;
    color: #fba00b;
    font-weight: 700;
    padding: 1px 26px; }

.purchase_general .purchase_general_title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 26px; }

.purchase_general .purchase_general_phone {
  margin-top: 26px; }

.profile_remove_info {
  margin-top: 13px;
  text-align: center; }

#ils_loader {
  align-items: center;
  display: none;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2; }
  #ils_loader.show {
    display: flex; }

.ils_popup {
  color: #fff;
  font-size: 14px;
  height: 100vh;
  left: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 11; }
  .ils_popup_mask {
    background-color: #1C0A2Ab0;
    height: 100%;
    width: 100%; }
    .ils_popup_mask .ils_popup_info {
      height: 100vh;
      margin: auto;
      padding: 50px 0;
      position: relative;
      width: 750px;
      z-index: 1; }
      @media (max-width: 767px) {
        .ils_popup_mask .ils_popup_info {
          width: 100vw; } }
      .ils_popup_mask .ils_popup_info .close-button {
        border: 0px;
        border-radius: 10px;
        color: #fff;
        font-size: 20px;
        position: absolute;
        padding: 24px;
        right: 0;
        top: 0;
        z-index: 12;
        background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        background-position: 50% -50%; }
        .ils_popup_mask .ils_popup_info .close-button:hover {
          cursor: pointer; }
      .ils_popup_mask .ils_popup_info .forgotten_password {
        -webkit-transform: translateX(100%);
                transform: translateX(100%); }
      .ils_popup_mask .ils_popup_info .redirect-button {
        display: inline-block;
        text-decoration: underline; }
        .ils_popup_mask .ils_popup_info .redirect-button:hover {
          cursor: pointer; }
      .ils_popup_mask .ils_popup_info .horizontal-separator {
        border-bottom: 2px solid #fff;
        height: 1px;
        margin: 26px 0;
        width: 90%; }
      .ils_popup_mask .ils_popup_info .ils_popup_close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 13; }
      .ils_popup_mask .ils_popup_info .ils_popup_content {
        align-items: center;
        background-color: #121212;
        border-radius: 10px;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: scroll;
        padding-bottom: 52px;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 12;
        -ms-overflow-style: none;
        scrollbar-width: none; }
        .ils_popup_mask .ils_popup_info .ils_popup_content::-webkit-scrollbar {
          display: none; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_logo_box {
          margin-top: 26px;
          width: 175px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_logo_box .ils_popup_logo_small {
            height: 60px;
            width: 60px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_logo_box .ils_popup_logo_tall {
            width: 175px; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_product_box {
          align-items: center;
          display: flex;
          font-size: 18px;
          font-weight: 700;
          margin-top: 100px;
          width: 90%; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_product_quantity {
          align-self: flex-start;
          margin-left: 5%; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_payment_info {
          background-color: #FBA00B;
          border-radius: 20px;
          padding: 5px;
          width: 240px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_payment_info i {
            margin-right: 13px; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_title {
          margin: 40px 60px;
          font-size: 32px;
          font-weight: 700; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_title.no_top_margin {
            margin-top: 0; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_text {
          margin: 13px 26px;
          font-size: 20px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_text .ils_popup_block_span {
            display: block; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_blank_space {
          padding: 2rem; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_thumbnail {
          margin-top: 52px;
          width: 400px; }
          @media (max-width: 767px) {
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_thumbnail {
              width: 80vw; } }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_thumbnail img {
            width: 100%; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_link {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          text-decoration: underline; }
        .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 26px;
          width: 100%; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_form_part {
            margin: 7px 0; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container {
            display: flex;
            padding-bottom: 13px;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox {
              position: relative; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox input[type=checkbox] {
                width: 0;
                display: none; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox input[type=checkbox]:checked + span:after {
                opacity: 1; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox input[type=checkbox]:checked + span {
                background: #fba00b;
                border: none; }
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox input[type=checkbox]:checked + span:hover {
                  opacity: 1; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox span {
                cursor: pointer;
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                border-radius: 4px;
                background: transparent;
                border: 1px solid white; }
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox span:after {
                  opacity: 0;
                  content: '';
                  position: absolute;
                  width: 10px;
                  height: 7px;
                  top: 5px;
                  left: 5px;
                  border: 3px solid #fcfff4;
                  border-top: none;
                  border-right: none;
                  -webkit-transform: rotate(-45deg);
                          transform: rotate(-45deg); }
                @media (min-width: 1025px) {
                  .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox span:hover {
                    opacity: 0.6; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_checkbox_container .ils_popup_checkbox_label {
              padding-left: 27px;
              padding-top: 2px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_input {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 7px 0; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form input {
            background: transparent;
            border: 1px solid #fff;
            border-radius: 5px;
            color: #fff;
            font-size: 20px;
            font-weight: 100;
            padding: 7px 26px;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form input {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form input::-webkit-input-placeholder {
              font-size: 14px;
              color: #fff; }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form input::placeholder {
              font-size: 14px;
              color: #fff; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 13px 3px;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios:hover {
              cursor: pointer; }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios .ils_popup_radio_box {
              position: relative;
              text-align: end;
              width: 100%;
              z-index: 1; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios .ils_popup_radio_box .radio-dot {
                position: absolute;
                border: 1px solid #aeaeae;
                border-radius: 50%;
                display: inline-block;
                height: 16px;
                right: 66px;
                top: 2px;
                width: 16px; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_radios .ils_popup_radio_box.selected .radio-dot {
                background-color: #fba00b;
                border: 2px solid #121212;
                box-shadow: 0 0 0 1px #fba00b; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 13px 3px;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select {
              border: 1px solid #fff;
              border-radius: 5px;
              margin-right: 7px;
              padding: 7px;
              position: relative;
              width: 160px;
              z-index: 2; }
              @media (max-width: 767px) {
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select {
                  width: 100px; } }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select:hover {
                cursor: pointer; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select:last-child {
                margin: 0; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select i {
                float: right;
                margin-right: 7px;
                margin-top: 3px; }
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options {
                background-color: #121212;
                border: 1px solid #fff;
                border-radius: 5px;
                display: none;
                height: 120px;
                left: 12px;
                overflow: scroll;
                position: absolute;
                text-align: center;
                top: 36px;
                width: 120px;
                z-index: 1;
                -ms-overflow-style: none;
                scrollbar-width: none; }
                @media (max-width: 767px) {
                  .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options {
                    width: 80px; } }
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options::-webkit-scrollbar {
                  display: none; }
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options.opened {
                  display: block; }
                .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options div {
                  padding: 3px; }
                  .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options div.selected {
                    background-color: #aeaeae; }
                  .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_selects .ils_popup_select .ils_popup_options div:hover {
                    cursor: pointer;
                    background-color: #aeaeae; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_cgu {
            margin: 26px 0 26px 0;
            padding-left: 40px;
            text-align: start;
            position: relative;
            width: 400px;
            z-index: 1; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_cgu {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_cgu a {
              text-decoration: underline; }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_cgu .radio-dot {
              position: absolute;
              border: 1px solid #aeaeae;
              border-radius: 50%;
              display: inline-block;
              height: 16px;
              left: 0;
              top: 50%;
              -webkit-transform: translate(0, -50%);
                      transform: translate(0, -50%);
              width: 16px; }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_cgu.selected .radio-dot {
              background-color: #fba00b;
              border: 2px solid #121212;
              box-shadow: 0 0 0 1px #fba00b; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_required_fields {
            color: #aeaeae;
            font-style: italic;
            margin-bottom: 13px;
            margin-top: 3px;
            text-align: left;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_required_fields {
                width: 80vw; } }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_error {
            color: #E33939;
            font-weight: 700;
            height: 30px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_success {
            color: #54C254;
            font-weight: 700;
            height: 30px; }
          .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_valid_button {
            background-color: #fba00b;
            border-radius: 5px;
            font-size: 18px;
            margin-bottom: 52px;
            margin-top: 13px;
            padding: 13px;
            width: 400px; }
            @media (max-width: 767px) {
              .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_valid_button {
                width: 80vw; } }
            .ils_popup_mask .ils_popup_info .ils_popup_content .ils_popup_form .ils_popup_valid_button:hover {
              cursor: pointer; }

.f-grow-1 {
  flex-grow: 1; }

.popup-payment-progress-nav {
  align-items: center;
  display: flex;
  margin: 26px;
  color: #fff;
  width: 100%; }
  .popup-payment-progress-nav .popup-payment-progress-nav-item {
    border-bottom: 2px solid #aeaeae;
    flex: 1 1;
    font-size: 16px;
    padding: 13px 26px;
    text-align: center; }
    .popup-payment-progress-nav .popup-payment-progress-nav-item.selected {
      border-bottom: 2px solid #fba00b;
      font-weight: 700; }
    @media (max-width: 767px) {
      .popup-payment-progress-nav .popup-payment-progress-nav-item {
        display: none; }
        .popup-payment-progress-nav .popup-payment-progress-nav-item.selected {
          display: block; } }

.popup-payment-details {
  border: 1px solid #aeaeae;
  border-radius: 5px;
  display: flex;
  padding: 7px 0;
  position: relative;
  margin: 26px 0;
  width: 80%;
  z-index: 1; }
  .popup-payment-details.selected {
    border: 1px solid #fba00b; }
    .popup-payment-details.selected .popup-payment-details-radio-box .popup-payment-details-radio-dot {
      background-color: #fba00b;
      border: 2px solid #121212;
      box-shadow: 0 0 0 1px #fba00b; }
  .popup-payment-details.selectable {
    cursor: pointer; }
  .popup-payment-details .popup-payment-details-bandage {
    align-items: center;
    background-color: #fba00b;
    border-radius: 5px;
    display: flex;
    left: 0;
    justify-content: center;
    min-width: 220px;
    padding: 7px 13px;
    position: absolute;
    top: 0;
    -webkit-transform: rotate(-30deg) translate(-25%, -60%);
            transform: rotate(-30deg) translate(-25%, -60%);
    z-index: 1; }
    .popup-payment-details .popup-payment-details-bandage .popup-payment-details-bandage-priceOverlineStg {
      color: #111;
      display: inline-block;
      font-size: 16px;
      margin-right: 13px;
      opacity: 0.6;
      text-decoration: line-through;
      vertical-align: middle; }
    .popup-payment-details .popup-payment-details-bandage .popup-payment-details-bandage-priceStg {
      color: #111;
      font-size: 20px;
      font-weight: 700; }
  .popup-payment-details .popup-payment-details-radio-box {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 72px; }
    .popup-payment-details .popup-payment-details-radio-box .popup-payment-details-radio-dot {
      border: 2px solid #fba00b;
      border-radius: 50%;
      display: inline-block;
      height: 16px;
      width: 16px; }
  .popup-payment-details .popup-payment-details-item-img-box img {
    border-radius: 5px; }
  .popup-payment-details .popup-payment-details-content {
    padding-right: 13px;
    width: 100%; }
    .popup-payment-details .popup-payment-details-content .popup-payment-details-item {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: 7px 0; }
      .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-title-box {
        padding: 0 7px; }
        .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-title-box .popup-payment-details-item-title {
          font-size: 16px;
          font-weight: 700; }
        .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-title-box .popup-payment-details-item-linkAddon {
          align-items: center;
          display: flex;
          font-size: 12px;
          justify-content: flex-start; }
          .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-title-box .popup-payment-details-item-linkAddon .btn-addon {
            background-color: #7c7c7c;
            border-radius: 3px;
            color: #fff;
            padding: 1px;
            margin: 0 7px;
            text-align: center;
            width: 80px; }
            .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-title-box .popup-payment-details-item-linkAddon .btn-addon.linked {
              cursor: pointer; }
      .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-price-box {
        text-align: end; }
        .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-price-box .popup-payment-details-item-priceStg {
          font-size: 20px;
          font-weight: 700; }
        .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-price-box .popup-payment-details-item-priceOverlineStg {
          color: #aeaeae;
          display: inline-block;
          font-size: 16px;
          margin-right: 13px;
          text-decoration: line-through;
          vertical-align: middle; }
        .popup-payment-details .popup-payment-details-content .popup-payment-details-item .popup-payment-details-item-price-box .popup-payment-details-item-details {
          color: #aeaeae;
          font-size: 10px; }

.popup-payment-details-item-details {
  color: #aeaeae;
  font-size: 10px; }

.popup-payment-recap {
  background-color: #3b3b3b;
  border: 1px solid #3b3b3b;
  border-radius: 5px;
  padding: 7px 0;
  margin: 26px 0;
  width: 80%; }
  .popup-payment-recap .popup-payment-recap-info-box {
    align-items: center;
    border-bottom: 1px solid #aeaeae;
    display: flex;
    padding: 7px;
    margin: 0 13px; }
    .popup-payment-recap .popup-payment-recap-info-box .popup-payment-recap-info {
      text-align: left; }
      .popup-payment-recap .popup-payment-recap-info-box .popup-payment-recap-info .popup-payment-recap-title {
        font-size: 20px;
        font-weight: 700; }
      .popup-payment-recap .popup-payment-recap-info-box .popup-payment-recap-info .popup-payment-recap-details {
        color: #aeaeae;
        font-style: 10px; }
    .popup-payment-recap .popup-payment-recap-info-box .popup-payment-recap-priceStg {
      font-size: 20px;
      font-weight: 700; }
    .popup-payment-recap .popup-payment-recap-info-box .popup-payment-recap-priceOverlineStg {
      color: #aeaeae;
      display: inline-block;
      font-size: 16px;
      margin-right: 13px;
      text-decoration: line-through;
      vertical-align: middle; }
  .popup-payment-recap .popup-payment-recap-total-box {
    padding: 7px;
    margin: 7px 13px; }
    .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-code-box {
      align-items: center;
      display: flex; }
      .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-code-box .popup-payment-recap-total-code-label {
        color: #aeaeae;
        font-style: 12px; }
      .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-code-box .popup-payment-recap-total-code-badge {
        background-color: #aeaeae;
        border-radius: 5px;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        padding: 0 7px; }
    .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-price-box {
      align-items: center;
      display: flex;
      margin-top: 7px; }
      .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-price-box .popup-payment-recap-total-price-label {
        font-size: 20px;
        font-weight: 700; }
      .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-price-box .popup-payment-recap-total-price-priceStg {
        font-size: 24px;
        font-weight: 700; }
      .popup-payment-recap .popup-payment-recap-total-box .popup-payment-recap-total-price-box .popup-payment-recap-total-price-priceOverlineStg {
        color: #aeaeae;
        display: inline-block;
        font-size: 16px;
        margin-right: 13px;
        text-decoration: line-through;
        vertical-align: middle; }
  .popup-payment-recap .popup-payment-code-box {
    background-color: #121212;
    border: 1px solid #121212;
    border-radius: 5px;
    padding: 13px 0;
    margin: 26px 13px; }
    .popup-payment-recap .popup-payment-code-box .popup-payment-code-label {
      color: #aeaeae;
      font-style: 10px;
      margin-bottom: 3px;
      margin-left: 26px;
      text-align: start; }
    .popup-payment-recap .popup-payment-code-box .popup-payment-code-form {
      align-items: center;
      display: flex;
      justify-content: center; }
      .popup-payment-recap .popup-payment-code-box .popup-payment-code-form input {
        background: transparent;
        border: 1px solid #fff;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        font-weight: 100;
        margin-right: 13px;
        padding: 3px 7px;
        text-transform: uppercase;
        width: 60%; }
        .popup-payment-recap .popup-payment-code-box .popup-payment-code-form input::-webkit-input-placeholder {
          font-size: 14px;
          color: #fff; }
        .popup-payment-recap .popup-payment-code-box .popup-payment-code-form input::placeholder {
          font-size: 14px;
          color: #fff; }
      .popup-payment-recap .popup-payment-code-box .popup-payment-code-form .popup-payment-code-form-valid {
        background: #3b3b3b;
        border: 1px solid #3b3b3b;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 100;
        padding: 3px 7px;
        width: 30%; }
        .popup-payment-recap .popup-payment-code-box .popup-payment-code-form .popup-payment-code-form-valid:hover {
          background: #404040; }
      .popup-payment-recap .popup-payment-code-box .popup-payment-code-form .popup-payment-code-form-remove {
        background: #626262;
        border: 1px solid #626262;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 100;
        padding: 3px 7px;
        width: 30%; }
        .popup-payment-recap .popup-payment-code-box .popup-payment-code-form .popup-payment-code-form-remove:hover {
          background: #6c6c6c; }
    .popup-payment-recap .popup-payment-code-box .popup-payment-code-form-results {
      font-weight: 700;
      min-height: 30px;
      padding-top: 7px; }
      .popup-payment-recap .popup-payment-code-box .popup-payment-code-form-results .popup-payment-code-form-error {
        color: #E33939; }
      .popup-payment-recap .popup-payment-code-box .popup-payment-code-form-results .popup-payment-code-form-success {
        color: #54C254; }
  .popup-payment-recap .popup-payment-valid-box {
    padding: 13px 0;
    margin: 26px 13px; }
    .popup-payment-recap .popup-payment-valid-box .popup-payment-valid {
      background-color: #fba00b;
      border-radius: 5px;
      cursor: pointer;
      font-size: 18px;
      padding: 13px;
      width: 100%; }

.popup-payment-success-box {
  margin: 0 20%;
  width: 100%; }
  .popup-payment-success-box .popup-payment-success-image {
    background-color: #FFFFFFDE;
    height: 150px;
    border-radius: 50%;
    margin: 52px auto;
    padding: 20px;
    width: 150px; }
    .popup-payment-success-box .popup-payment-success-image img {
      -webkit-filter: brightness(0.1);
              filter: brightness(0.1);
      height: 100%;
      width: 100%; }
  .popup-payment-success-box .popup-payment-success-title {
    font-size: 32px;
    font-weight: 700;
    margin: 26px 0; }
  .popup-payment-success-box .popup-payment-success-thumbnail {
    margin: 0 auto;
    max-width: 400px; }
    @media (max-width: 767px) {
      .popup-payment-success-box .popup-payment-success-thumbnail {
        max-width: 80vw; } }
    .popup-payment-success-box .popup-payment-success-thumbnail img {
      border-radius: 5px;
      width: 100%; }
  .popup-payment-success-box .popup-payment-success-text {
    font-size: 16px;
    margin: 26px 0; }
  .popup-payment-success-box .popup-payment-valid-box {
    margin: 0 auto;
    max-width: 400px; }
    @media (max-width: 767px) {
      .popup-payment-success-box .popup-payment-valid-box {
        max-width: 80vw; } }
    .popup-payment-success-box .popup-payment-valid-box .popup-payment-valid {
      background-color: #fba00b;
      border-radius: 5px;
      cursor: pointer;
      font-size: 18px;
      margin: 7px;
      padding: 13px;
      width: 100%; }
    .popup-payment-success-box .popup-payment-valid-box .popup-payment-cancel {
      border: 1px solid #aeaeae;
      border-radius: 5px;
      color: #aeaeae;
      cursor: pointer;
      font-size: 18px;
      margin: 7px;
      padding: 13px;
      width: 100%; }

.local_stripe_container .local_stripe_form {
  margin-top: 26px;
  text-align: left;
  width: 400px; }
  @media (max-width: 767px) {
    .local_stripe_container .local_stripe_form {
      width: 80vw; } }
  .local_stripe_container .local_stripe_form .local_stripe_input {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 100;
    margin-bottom: 26px;
    padding: 7px 26px;
    width: 100%; }
    .local_stripe_container .local_stripe_form .local_stripe_input::-webkit-input-placeholder {
      font-size: 14px;
      color: #fff; }
    .local_stripe_container .local_stripe_form .local_stripe_input::placeholder {
      font-size: 14px;
      color: #fff; }
  .local_stripe_container .local_stripe_form .local_stripe_card {
    border: 1px solid #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 13px 26px;
    width: 100%; }
  .local_stripe_container .local_stripe_form .local_stripe_card_info_box {
    display: flex; }
  .local_stripe_container .local_stripe_form .local_stripe_expiry {
    border: 1px solid #fff;
    border-bottom-left-radius: 5px;
    padding: 13px 26px;
    width: 50%; }
  .local_stripe_container .local_stripe_form .local_stripe_cvc {
    border: 1px solid #fff;
    border-bottom-right-radius: 5px;
    padding: 13px 26px;
    width: 50%; }
  .local_stripe_container .local_stripe_form .local_stripe_results {
    font-weight: 700;
    min-height: 30px;
    padding-top: 7px;
    text-align: center; }
    .local_stripe_container .local_stripe_form .local_stripe_results .local_stripe_error {
      color: #E33939; }
    .local_stripe_container .local_stripe_form .local_stripe_results .local_stripe_success {
      color: #54C254; }
  .local_stripe_container .local_stripe_form .local_stripe_button {
    background-color: #fba00b;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 26px;
    padding: 13px;
    width: 100%; }
    .local_stripe_container .local_stripe_form .local_stripe_button:hover {
      cursor: pointer; }
    .local_stripe_container .local_stripe_form .local_stripe_button:disabled {
      cursor: not-allowed;
      opacity: 0.4;
      pointer-events: all !important; }

.local_stripe_container .local_stripe_info {
  margin: 0 auto; }
  .local_stripe_container .local_stripe_info i {
    margin-right: 13px; }
  .local_stripe_container .local_stripe_info .local_stripe_info_text {
    font-size: 14px;
    padding-top: 7px; }
  .local_stripe_container .local_stripe_info .local_stripe_info_img {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 7px;
    width: 100%; }
    .local_stripe_container .local_stripe_info .local_stripe_info_img img {
      display: block;
      width: 200px; }

figure {
  position: relative;
  z-index: 1; }
  figure .shine-effect {
    background-size: 800%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2; }
    figure .shine-effect.gold-effect {
      background-image: linear-gradient(45deg, transparent 40%, #FFD70077 50%, transparent 60%);
      -webkit-animation: shine-animation 5s infinite linear;
              animation: shine-animation 5s infinite linear; }
    figure .shine-effect.sliver-effect {
      background-image: linear-gradient(45deg, transparent 40%, #C0C0C077 50%, transparent 60%);
      -webkit-animation: shine-animation 6s infinite linear;
              animation: shine-animation 6s infinite linear; }
    figure .shine-effect.copper-effect {
      background-image: linear-gradient(45deg, transparent 40%, #B8733377 50%, transparent 60%);
      -webkit-animation: shine-animation 7s infinite linear;
              animation: shine-animation 7s infinite linear; }

@-webkit-keyframes shine-animation {
  0% {
    background-position: 50%; }
  40%, 80% {
    background-position: 0%; } }

@keyframes shine-animation {
  0% {
    background-position: 50%; }
  40%, 80% {
    background-position: 0%; } }

.main-des-container {
  background-color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none; }
  .main-des-container .main-des-go-back {
    color: #aeaeae;
    cursor: pointer;
    left: 100px;
    padding: 13px 0;
    position: absolute;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    top: 7px;
    z-index: 2; }
    .main-des-container .main-des-go-back .icon_box {
      margin-right: 13px; }
      .main-des-container .main-des-go-back .icon_box img {
        vertical-align: text-top; }
    .main-des-container .main-des-go-back:hover {
      -webkit-transform: scale(1.04);
              transform: scale(1.04); }
  .main-des-container .main-des-image {
    right: 0;
    position: absolute;
    top: 0;
    z-index: 0; }
    .main-des-container .main-des-image .main-des-image-overlay {
      background: linear-gradient(90deg, #000000 0%, #00000033 50%, #00000000 100%), linear-gradient(0deg, #000000 0%, #00000000 50%, #00000000 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0; }
  .main-des-container .main-des-content {
    padding: 80px 0 0 120px;
    position: relative;
    z-index: 1; }
    .main-des-container .main-des-content .main-des-img-title {
      margin-left: 26px;
      max-height: 260px;
      max-width: 260px; }
      .main-des-container .main-des-content .main-des-img-title img {
        height: 100%;
        width: 100%; }
    .main-des-container .main-des-content .main-des-title {
      color: #fff;
      font-size: 2.5rem;
      margin: 26px 0 13px 0;
      font-weight: 700; }
    .main-des-container .main-des-content .main-des-btn-addon-box {
      align-items: center;
      display: flex;
      justify-content: flex-start; }
      .main-des-container .main-des-content .main-des-btn-addon-box .main-des-btn-addon {
        background-color: #7c7c7c;
        border-radius: 3px;
        color: #fff;
        font-size: 1.3rem;
        padding: 5px;
        margin: 0 7px;
        text-align: center;
        width: 120px; }
        .main-des-container .main-des-content .main-des-btn-addon-box .main-des-btn-addon.linked {
          cursor: pointer; }
    .main-des-container .main-des-content .main-des-details {
      color: #fff;
      font-size: 1.3rem;
      margin: 26px 0 13px 0; }
    .main-des-container .main-des-content .main-des-price {
      margin: 26px 0 13px 0; }
      .main-des-container .main-des-content .main-des-price .main-des-priceOverlineStg {
        color: #aeaeae;
        display: inline-block;
        font-size: 1.2rem;
        margin-right: 13px;
        text-decoration: line-through;
        vertical-align: middle; }
      .main-des-container .main-des-content .main-des-price .main-des-priceStg {
        color: #fff;
        font-size: 2rem;
        font-weight: 700; }
      .main-des-container .main-des-content .main-des-price .main-des-price-rent-info {
        background-color: #7c7c7c;
        border-radius: 10px;
        color: #fff;
        display: inline-block;
        font-size: 1rem;
        margin-left: 7px;
        padding: 1px 7px;
        position: relative;
        top: -15px; }
      .main-des-container .main-des-content .main-des-price .main-des-free {
        background-color: #E33939;
        border-radius: 10px;
        color: #fff;
        font-size: 18px;
        margin: 26px 0;
        padding: 3px;
        text-align: center;
        width: 200px; }
        @media (max-width: 1024px) {
          .main-des-container .main-des-content .main-des-price .main-des-free {
            margin: 0 auto 42px; } }
    .main-des-container .main-des-content .movie_btns {
      align-items: center;
      align-self: flex-start;
      bottom: 0;
      color: #aeaeae;
      display: flex;
      width: 100%; }
      @media (max-width: 1024px) {
        .main-des-container .main-des-content .movie_btns {
          flex-direction: column;
          padding-left: 0; }
          .main-des-container .main-des-content .movie_btns > * {
            margin: 13px 0; } }
      .main-des-container .main-des-content .movie_btns .icons_box {
        align-items: center;
        display: flex;
        justify-content: center; }
      .main-des-container .main-des-content .movie_btns .movie_btn_box {
        align-items: center;
        display: flex;
        color: #FBA00B;
        flex-direction: column;
        justify-content: center;
        margin-right: 13px; }
        .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box {
          background-color: #FFFFFF1A;
          border-radius: 50%;
          height: 55px;
          width: 55px; }
          .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box img.movie_icon {
            -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                    filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
          @media (min-width: 1025px) {
            .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box:hover {
              background-color: #FFFFFF4A;
              cursor: pointer; } }
          .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box .icon_revert {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg); }
      .main-des-container .main-des-content .movie_btns i {
        font-size: 30px; }
      .main-des-container .main-des-content .movie_btns .btn-movie {
        background-color: #FBA00B;
        border: none;
        border-radius: 3px;
        color: #FFFFFF;
        display: inline-block;
        font-size: 20px;
        font-weight: 100;
        height: 56px;
        letter-spacing: 2px;
        margin: 0 8px 8px 0;
        padding: 7px;
        width: 240px; }
        @media (max-width: 1024px) {
          .main-des-container .main-des-content .movie_btns .btn-movie {
            width: 300px;
            margin: 0 0 18px 0; } }
        @media (max-width: 500px) {
          .main-des-container .main-des-content .movie_btns .btn-movie {
            width: 80vw;
            margin: 0 0 18px 0; } }
        .main-des-container .main-des-content .movie_btns .btn-movie i {
          font-size: 18px;
          margin-right: 13px; }
        .main-des-container .main-des-content .movie_btns .btn-movie:hover {
          background-color: #FBA00B;
          cursor: pointer;
          opacity: 0.89; }
      .main-des-container .main-des-content .movie_btns .btn-trailer {
        background-color: #2C2C2C; }
        @media (min-width: 1025px) {
          .main-des-container .main-des-content .movie_btns .btn-trailer:hover {
            background-color: #353535;
            cursor: pointer; } }
      .main-des-container .main-des-content .movie_btns .btn-bonus {
        background-color: #2C2C2C;
        color: #fba00b; }
        @media (min-width: 1025px) {
          .main-des-container .main-des-content .movie_btns .btn-bonus:hover {
            background-color: #353535;
            cursor: pointer; } }
    .main-des-container .main-des-content img.movie_icon {
      -webkit-filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
              filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
      height: 30px;
      width: 30px; }
      @media (max-width: 767px) {
        .main-des-container .main-des-content img.movie_icon {
          height: 20px; } }
      .main-des-container .main-des-content img.movie_icon.selected {
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
    .main-des-container .main-des-content .icon_box {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 7px;
      position: relative;
      width: 100%;
      z-index: 1; }
      .main-des-container .main-des-content .icon_box .bubble {
        background-color: #2a2a2a;
        border-radius: 3px;
        color: #fff;
        display: none;
        left: -7px;
        padding: 7px 12px;
        position: absolute;
        top: -60px;
        white-space: nowrap;
        z-index: 2; }
      .main-des-container .main-des-content .icon_box:hover .bubble {
        display: block; }
        @media (max-width: 1024px) {
          .main-des-container .main-des-content .icon_box:hover .bubble {
            display: none; } }
    .main-des-container .main-des-content .main-des-description {
      color: #fff;
      font-size: 1.3rem;
      margin: 26px 0 13px 0;
      max-width: 50%; }
    .main-des-container .main-des-content .main-des-additional-info {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 26px;
      max-width: 50%; }

.main-des-happy-ending {
  background: linear-gradient(to top, #00000000, #000);
  height: 50px;
  width: 100%; }

.main-des-happy-ending-revert {
  background: linear-gradient(to bottom, #00000000, #000);
  height: 50px;
  position: relative;
  width: 100%;
  z-index: 1; }

.training-container #chasse_Homelist .GBListItem {
  top: 0; }

.GBListItem.with-description ul {
  margin-top: -13px; }

.with-description-info {
  color: #eee;
  margin-left: 10px;
  margin-right: 15px;
  text-align: justify; }
  .with-description-info .with-description-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 13px; }
    .with-description-info .with-description-title .with-description-pretitle {
      font-size: 1.2rem;
      color: #aeaeae;
      display: inline-block;
      margin-right: 7px;
      vertical-align: middle; }
    .with-description-info .with-description-title.product-item {
      margin-top: 3px;
      min-height: 60px; }
  .with-description-info .with-description-colors {
    display: flex;
    margin-top: 7px;
    min-height: 30px; }
    .with-description-info .with-description-colors .with-description-colors-dots {
      border: 3px solid #fba00b22;
      border-radius: 50%;
      height: 30px;
      margin: 0 7px;
      width: 30px; }
  .with-description-info .with-description-synopsis {
    font-size: 1.1rem; }
  .with-description-info .with-description-price {
    color: #fba00b;
    font-size: 2rem;
    margin-top: 3px; }
  .with-description-info .with-description-price-initial {
    color: #aeaeae;
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 7px;
    margin-top: 13px;
    text-decoration: line-through; }

.with-description-info .with-description-synopsis {
  margin-top: 3px; }

@media (max-width: 1024px) {
  .carousel-img .carousel-info .carousel-img-title {
    margin-top: 13px; }
  .carousel-img .carousel-info .carousel-title {
    font-size: 1.5rem; }
  .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon {
    font-size: 1rem; }
  .carousel-img .carousel-info .carousel-btn {
    font-size: 1.2rem; }
  figure.effect-thumbnail-movie .figure-icon {
    height: 18px;
    width: 18px; }
  figure.effect-thumbnail-movie .figure-badgeTop {
    font-size: .6rem; }
  figure.effect-thumbnail-movie .figure-title {
    font-size: 2rem; }
  figure.effect-thumbnail-movie .figure-badgeCenter {
    font-size: 1.6rem; }
  figure.effect-thumbnail-movie .figure-info {
    font-size: 1.6rem; }
  figure.effect-thumbnail-movie .figure-price {
    font-size: 2rem; }
  .main-des-container .main-des-go-back {
    left: 26px;
    top: 26px; }
  .main-des-container .main-des-content {
    padding-left: 26px; }
  .main-des-container .main-des-content .main-des-title {
    font-size: 1.5rem;
    padding-left: 0; }
  .main-des-container .main-des-content .main-des-btn-addon-box .main-des-btn-addon {
    font-size: 1rem;
    margin-left: 0;
    width: 80px; }
  .main-des-container .main-des-content .main-des-details {
    font-size: 1rem; }
  .main-des-container .main-des-content .main-des-image {
    max-height: 525px; }
  .main-des-container .main-des-image .main-des-image-overlay {
    background: linear-gradient(90deg, #000, #00000080 50%, transparent), linear-gradient(0deg, #000, #00000080 50%, transparent); }
    .main-des-container .main-des-image .main-des-image-overlay img {
      max-height: 525px; }
  .main-des-container .main-des-content .movie_btns {
    margin-left: -13px; }
  .main-des-container .main-des-content .main-des-description {
    max-width: 90%; }
  .main-des-container .main-des-content .movie_btns .movie_btn_box .icon_box {
    background-color: #000000bb; }
  .GBListItem h3 .list-title-prename {
    margin-right: 13px; }
  .with-description-info .with-description-title {
    font-size: 1.3rem;
    margin-top: 0;
    text-align: left; }
    .with-description-info .with-description-title.product-item {
      font-size: 1rem; }
  .with-description-info .with-description-title .with-description-pretitle {
    font-size: 1rem;
    margin-right: 7px; }
  .with-description-info .with-description-synopsis {
    font-size: 1rem;
    text-align: left; } }

@media (max-width: 767px) {
  .carousel-img .carousel-info .carousel-title {
    font-size: 1.2rem; }
  .carousel-img .carousel-info .carousel-btn-addon-box .carousel-btn-addon {
    font-size: .8rem; }
  figure.effect-thumbnail-movie .figure-title {
    font-size: 1.8rem; }
  figure.effect-thumbnail-movie .figure-badgeCenter {
    font-size: 1.4rem; }
  figure.effect-thumbnail-movie .figure-info {
    font-size: 1.4rem; }
  figure.effect-thumbnail-movie .figure-price {
    font-size: 1.8rem; } }

.with-description figure.effect-thumbnail-movie {
  margin-top: 23px; }

.soon-container {
  background-color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none; }
  .soon-container.with-trailer .soon-content {
    transition: margin-top 1s linear; }
  .soon-container.with-trailer .soon-flex-container {
    background: #000; }
  @media (max-width: 1024px) {
    .soon-container.with-trailer .soon-content {
      margin-top: 320px; } }
  .soon-container .soon-image {
    left: 0;
    position: absolute;
    top: 0;
    transition: width 2s ease-in-out, top 1s linear;
    width: 100%;
    z-index: 0; }
    .soon-container .soon-image.with-trailer {
      width: 60%; }
      @media (max-width: 1024px) {
        .soon-container .soon-image.with-trailer {
          display: none; } }
    .soon-container .soon-image img {
      width: 100%; }
    .soon-container .soon-image .soon-image-overlay {
      background: linear-gradient(90deg, #000000 0%, #00000033 50%, #00000000 100%), linear-gradient(0deg, #000000 0%, #00000000 50%, #00000000 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0; }
  .soon-container .soon-image-overlay-outside {
    background: linear-gradient(to bottom, #00000000, #000);
    bottom: 0;
    height: 50px;
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 1; }
  .soon-container .soon-content {
    padding: 13px 0 0 120px;
    position: relative;
    z-index: 1;
    -webkit-animation: 1s ease-out 0s 1 slideInFromBottom;
            animation: 1s ease-out 0s 1 slideInFromBottom; }

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
    @media (max-width: 1024px) {
      .soon-container .soon-content {
        padding: 13px 0 0 0; } }
    .soon-container .soon-content .soon-free-movie-badge {
      background-color: #E33939;
      border-radius: 10px;
      color: #fff;
      font-size: 18px;
      margin: 26px 0;
      padding: 3px;
      text-align: center;
      width: 200px; }
      @media (max-width: 1024px) {
        .soon-container .soon-content .soon-free-movie-badge {
          margin: 0 auto 42px; } }
    .soon-container .soon-content .soon-img-title {
      margin-left: 26px;
      max-height: 320px;
      max-width: 320px; }
      @media (max-width: 1024px) {
        .soon-container .soon-content .soon-img-title {
          margin: 26px auto; } }
      .soon-container .soon-content .soon-img-title img {
        height: 100%;
        width: 100%; }
      @media (max-width: 767px) {
        .soon-container .soon-content .soon-img-title {
          max-height: 240px;
          max-width: 240px; } }
    .soon-container .soon-content .soon-img-title-up, .soon-container .soon-content .soon-title-up {
      margin-left: 26px;
      max-height: 120px;
      max-width: 120px;
      margin: 13px auto; }
      .soon-container .soon-content .soon-img-title-up.orange-title, .soon-container .soon-content .soon-title-up.orange-title {
        -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
      .soon-container .soon-content .soon-img-title-up img, .soon-container .soon-content .soon-title-up img {
        height: 100%;
        width: 100%; }
    .soon-container .soon-content .soon-title {
      color: #eee;
      font-size: 2.3rem;
      line-height: 4rem;
      max-width: 40%; }
      @media (max-width: 1024px) {
        .soon-container .soon-content .soon-title {
          margin: 0 5% 0 5%;
          max-width: 90%; } }
      .soon-container .soon-content .soon-title .soon-title-up {
        margin: 13px; }
    .soon-container .soon-content .soon-description, .soon-container .soon-content .soon-date {
      color: #fff;
      font-size: 1.3rem;
      max-width: 40%; }
      @media (max-width: 1024px) {
        .soon-container .soon-content .soon-description, .soon-container .soon-content .soon-date {
          margin: 0 5% 0 5%;
          max-width: 90%; } }
    .soon-container .soon-content .soon-date {
      margin: 42px 0 7px 0; }
      @media (max-width: 1024px) {
        .soon-container .soon-content .soon-date {
          margin: 42px 5% 7px 5%;
          max-width: 90%; } }
  .soon-container .movie_btns {
    align-items: center;
    align-self: flex-start;
    bottom: 0;
    color: #aeaeae;
    display: flex;
    margin: 13px 0;
    width: 100%; }
    @media (max-width: 1024px) {
      .soon-container .movie_btns {
        flex-direction: column;
        padding-left: 0; }
        .soon-container .movie_btns > * {
          margin: 13px 0; } }
    .soon-container .movie_btns .icons_box {
      align-items: center;
      display: flex;
      justify-content: center; }
    .soon-container .movie_btns .movie_btn_box {
      align-items: center;
      display: flex;
      color: #FBA00B;
      flex-direction: column;
      justify-content: center;
      margin-right: 13px; }
      .soon-container .movie_btns .movie_btn_box .icon_box {
        background-color: #2C2C2C;
        border-radius: 50%;
        height: 55px;
        width: 55px; }
        .soon-container .movie_btns .movie_btn_box .icon_box img.movie_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
        @media (min-width: 1025px) {
          .soon-container .movie_btns .movie_btn_box .icon_box:hover {
            background-color: #353535;
            cursor: pointer; } }
        .soon-container .movie_btns .movie_btn_box .icon_box .icon_revert {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .soon-container .movie_btns .icon_box {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 7px;
      position: relative;
      width: 100%;
      z-index: 1; }
      .soon-container .movie_btns .icon_box img {
        height: 30px;
        width: 30px; }
      .soon-container .movie_btns .icon_box .bubble {
        background-color: #2a2a2a;
        border-radius: 3px;
        color: #fff;
        display: none;
        left: -7px;
        padding: 7px 12px;
        position: absolute;
        top: -60px;
        white-space: nowrap;
        z-index: 2; }
      .soon-container .movie_btns .icon_box:hover .bubble {
        display: block; }
        @media (max-width: 1024px) {
          .soon-container .movie_btns .icon_box:hover .bubble {
            display: none; } }
    .soon-container .movie_btns i {
      font-size: 30px; }
    .soon-container .movie_btns .btn-movie {
      background-color: #FBA00B;
      border: none;
      border-radius: 3px;
      color: #FFFFFF;
      display: inline-block;
      font-size: 20px;
      font-weight: 100;
      height: 56px;
      letter-spacing: 2px;
      margin: 0 8px 8px 0;
      padding: 7px;
      width: 240px; }
      .soon-container .movie_btns .btn-movie.btn-tall {
        margin: 26px 8px 26px 0;
        width: 300px; }
      @media (max-width: 1024px) {
        .soon-container .movie_btns .btn-movie {
          width: 300px;
          margin: 0 0 18px 0; } }
      @media (max-width: 500px) {
        .soon-container .movie_btns .btn-movie {
          width: 80vw;
          margin: 0 0 18px 0; } }
      .soon-container .movie_btns .btn-movie i {
        font-size: 18px;
        margin-right: 13px; }
      .soon-container .movie_btns .btn-movie:hover {
        background-color: #FBA00B;
        cursor: pointer;
        opacity: 0.89; }
    .soon-container .movie_btns .btn-trailer {
      background-color: #2C2C2C; }
      @media (min-width: 1025px) {
        .soon-container .movie_btns .btn-trailer:hover {
          background-color: #353535;
          cursor: pointer; } }
    .soon-container .movie_btns .btn-bonus {
      background-color: #2C2C2C;
      color: #fba00b; }
      @media (min-width: 1025px) {
        .soon-container .movie_btns .btn-bonus:hover {
          background-color: #353535;
          cursor: pointer; } }

.soon-happy-ending {
  background: linear-gradient(to top, #00000000, #000);
  height: 50px;
  width: 100%; }

.soon-clock {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  font-family: sans-serif;
  display: flex;
  padding: 13px;
  overflow: hidden;
  position: relative; }
  @media (max-width: 1024px) {
    .soon-clock {
      font-size: 36px; } }
  .soon-clock .soon-box {
    overflow: hidden;
    display: flex;
    flex-direction: column; }
    .soon-clock .soon-box .soon-number-box {
      align-items: center;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 80px;
      z-index: 1; }
      @media (max-width: 1024px) {
        .soon-clock .soon-box .soon-number-box {
          width: 60px; } }
    .soon-clock .soon-box .soon-label-box {
      font-size: 14px;
      text-align: center; }
      @media (max-width: 1024px) {
        .soon-clock .soon-box .soon-label-box {
          font-size: 10px; } }

.soon-flex-container {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 9; }
  @media (max-width: 1024px) {
    .soon-flex-container {
      width: 100%;
      z-index: 0; }
      .soon-flex-container .react-player {
        top: 180px; } }
  .soon-flex-container .soon-container-relative {
    position: relative;
    flex-basis: 100%; }
    .soon-flex-container .soon-container-relative #z3pOverlay {
      position: relative; }
    .soon-flex-container .soon-container-relative .z3PlayOverlay {
      background-color: transparent !important; }
    .soon-flex-container .soon-container-relative .soon-container-overlay {
      background: linear-gradient(to left, #000000 0%, #00000022 30%, #00000000 100%), linear-gradient(to right, #000000 0%, #00000022 30%, #00000000 100%), linear-gradient(to top, #000000 0%, #00000022 30%, #00000000 100%), linear-gradient(to bottom, #000000 0%, #00000022 35%, #00000000 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 999991; }
    .soon-flex-container .soon-container-relative .soon-container-sound, .soon-flex-container .soon-container-relative .soon-container-reload {
      color: #fff;
      cursor: pointer;
      -webkit-filter: drop-shadow(0 0 10px #000);
              filter: drop-shadow(0 0 10px #000);
      position: absolute;
      transition: -webkit-transform .5s linear;
      transition: transform .5s linear;
      transition: transform .5s linear, -webkit-transform .5s linear;
      z-index: 999992; }
    .soon-flex-container .soon-container-relative .soon-container-sound {
      font-size: 24px; }
      @media (min-width: 1025px) {
        .soon-flex-container .soon-container-relative .soon-container-sound {
          bottom: 20px;
          right: 80px; }
          .soon-flex-container .soon-container-relative .soon-container-sound:hover {
            -webkit-transform: scale(1.2);
                    transform: scale(1.2); } }
      @media (max-width: 1024px) {
        .soon-flex-container .soon-container-relative .soon-container-sound {
          left: 40px;
          top: 20px; } }
    .soon-flex-container .soon-container-relative .soon-container-reload {
      font-size: 20px;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      @media (min-width: 1025px) {
        .soon-flex-container .soon-container-relative .soon-container-reload {
          bottom: 23px;
          right: 40px; }
          .soon-flex-container .soon-container-relative .soon-container-reload:hover {
            -webkit-transform: scaleX(-1) scale(1.2);
                    transform: scaleX(-1) scale(1.2); } }
      @media (max-width: 1024px) {
        .soon-flex-container .soon-container-relative .soon-container-reload {
          left: 80px;
          top: 23px; } }
  .soon-flex-container .soon-happy-ending-left {
    background: linear-gradient(to right, #00000000, #000);
    height: 100%;
    left: -50px;
    position: absolute;
    top: 0;
    width: 50px; }
  .soon-flex-container .player-overlay {
    display: none; }

.soon-rate-star-box {
  display: flex; }
  .soon-rate-star-box .soon-rate-average {
    margin-right: 13px; }
  .soon-rate-star-box .soon-rate-count {
    margin-left: 17px; }
  .soon-rate-star-box .soon-rate-star {
    color: #FBA00B;
    margin: 0 1px;
    position: relative;
    z-index: 1; }
    .soon-rate-star-box .soon-rate-star span {
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 2; }

.movie_popup_content .soon-rate-star-box {
  align-self: flex-start;
  padding: 0 26px; }

.clock-container {
  display: flex;
  margin-left: 13px; }

.separator-colon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 64px; }
  @media (max-width: 1600px) {
    .separator-colon {
      font-size: 32px;
      margin-top: 7px; } }

.clock-label {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 42px; }
  @media (max-width: 1600px) {
    .clock-label {
      font-size: 9px;
      line-height: 21px; } }

.clock-container-second, .clock-container-minute, .clock-container-hour, .clock-container-day {
  /* Reset */
  /* Main */
  font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
  -webkit-user-select: none;
          user-select: none;
  color: #888;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#969696', endColorstr = '#595959', GradientType = 1);
  text-align: center;
  width: 140px;
  height: 90px;
  margin: 26px 0;
  /* Skeleton */
  /* PLAY */
  /* SHADOW */
  /*DOWN*/ }
  .clock-container-second *, .clock-container-minute *, .clock-container-hour *, .clock-container-day * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .clock-container-second .clock-cards, .clock-container-minute .clock-cards, .clock-container-hour .clock-cards, .clock-container-day .clock-cards {
    text-decoration: none;
    color: #ccc; }
  .clock-container-second ul, .clock-container-minute ul, .clock-container-hour ul, .clock-container-day ul {
    list-style: none; }
  .clock-container-second .clearfix:before,
  .clock-container-second .clearfix:after, .clock-container-minute .clearfix:before,
  .clock-container-minute .clearfix:after, .clock-container-hour .clearfix:before,
  .clock-container-hour .clearfix:after, .clock-container-day .clearfix:before,
  .clock-container-day .clearfix:after {
    content: " ";
    display: table; }
  .clock-container-second .clearfix:after, .clock-container-minute .clearfix:after, .clock-container-hour .clearfix:after, .clock-container-day .clearfix:after {
    clear: both; }
  .clock-container-second .clearfix, .clock-container-minute .clearfix, .clock-container-hour .clearfix, .clock-container-day .clearfix {
    *zoom: 1; }
  @media (max-width: 1600px) {
    .clock-container-second, .clock-container-minute, .clock-container-hour, .clock-container-day {
      height: 45px;
      width: 80px; } }
  @media (max-width: 400px) {
    .clock-container-second, .clock-container-minute, .clock-container-hour, .clock-container-day {
      height: 30px;
      width: 64px; } }
  .clock-container-second #social, .clock-container-minute #social, .clock-container-hour #social, .clock-container-day #social {
    text-align: center;
    position: absolute;
    bottom: 14%;
    width: 100%; }
  .clock-container-second #social p, .clock-container-minute #social p, .clock-container-hour #social p, .clock-container-day #social p {
    margin-bottom: 10px; }
  .clock-container-second #social ul,
  .clock-container-second #social li, .clock-container-minute #social ul,
  .clock-container-minute #social li, .clock-container-hour #social ul,
  .clock-container-hour #social li, .clock-container-day #social ul,
  .clock-container-day #social li {
    display: inline-block; }
  .clock-container-second ul.flip, .clock-container-minute ul.flip, .clock-container-hour ul.flip, .clock-container-day ul.flip {
    position: relative;
    float: left;
    margin: 5px;
    width: 60px;
    height: 90px;
    font-size: 80px;
    font-weight: bold;
    line-height: 87px;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7); }
    @media (max-width: 1600px) {
      .clock-container-second ul.flip, .clock-container-minute ul.flip, .clock-container-hour ul.flip, .clock-container-day ul.flip {
        width: 30px;
        height: 45px;
        font-size: 40px;
        line-height: 43px; } }
    @media (max-width: 400px) {
      .clock-container-second ul.flip, .clock-container-minute ul.flip, .clock-container-hour ul.flip, .clock-container-day ul.flip {
        width: 20px;
        height: 30px;
        font-size: 27px;
        line-height: 29px; } }
  .clock-container-second ul.flip li, .clock-container-minute ul.flip li, .clock-container-hour ul.flip li, .clock-container-day ul.flip li {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .clock-container-second ul.flip li:first-child, .clock-container-minute ul.flip li:first-child, .clock-container-hour ul.flip li:first-child, .clock-container-day ul.flip li:first-child {
    z-index: 2; }
  .clock-container-second ul.flip li .clock-cards, .clock-container-minute ul.flip li .clock-cards, .clock-container-hour ul.flip li .clock-cards, .clock-container-day ul.flip li .clock-cards {
    display: block;
    height: 100%;
    -webkit-perspective: 200px;
            perspective: 200px; }
  .clock-container-second ul.flip li .clock-cards div, .clock-container-minute ul.flip li .clock-cards div, .clock-container-hour ul.flip li .clock-cards div, .clock-container-day ul.flip li .clock-cards div {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden; }
  .clock-container-second ul.flip li .clock-cards div .shadow, .clock-container-minute ul.flip li .clock-cards div .shadow, .clock-container-hour ul.flip li .clock-cards div .shadow, .clock-container-day ul.flip li .clock-cards div .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .clock-container-second ul.flip li .clock-cards div.up, .clock-container-minute ul.flip li .clock-cards div.up, .clock-container-hour ul.flip li .clock-cards div.up, .clock-container-day ul.flip li .clock-cards div.up {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    top: 0; }
  .clock-container-second ul.flip li .clock-cards div.up:after, .clock-container-minute ul.flip li .clock-cards div.up:after, .clock-container-hour ul.flip li .clock-cards div.up:after, .clock-container-day ul.flip li .clock-cards div.up:after {
    content: "";
    position: absolute;
    top: 44px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.4); }
  .clock-container-second ul.flip li .clock-cards div.down, .clock-container-minute ul.flip li .clock-cards div.down, .clock-container-hour ul.flip li .clock-cards div.down, .clock-container-day ul.flip li .clock-cards div.down {
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    bottom: 0; }
  .clock-container-second ul.flip li .clock-cards div div.inn, .clock-container-minute ul.flip li .clock-cards div div.inn, .clock-container-hour ul.flip li .clock-cards div div.inn, .clock-container-day ul.flip li .clock-cards div div.inn {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 200%;
    color: #fff;
    text-shadow: 0 1px 2px #000;
    text-align: center;
    background-color: #333;
    border-radius: 6px; }
  .clock-container-second ul.flip li .clock-cards div.up div.inn, .clock-container-minute ul.flip li .clock-cards div.up div.inn, .clock-container-hour ul.flip li .clock-cards div.up div.inn, .clock-container-day ul.flip li .clock-cards div.up div.inn {
    top: 0; }
  .clock-container-second ul.flip li .clock-cards div.down div.inn, .clock-container-minute ul.flip li .clock-cards div.down div.inn, .clock-container-hour ul.flip li .clock-cards div.down div.inn, .clock-container-day ul.flip li .clock-cards div.down div.inn {
    bottom: 0; }
  .clock-container-second.play ul li.before, .clock-container-minute.play ul li.before, .clock-container-hour.play ul li.before, .clock-container-day.play ul li.before {
    z-index: 3; }
  .clock-container-second.play ul li.active, .clock-container-minute.play ul li.active, .clock-container-hour.play ul li.active, .clock-container-day.play ul li.active {
    -webkit-animation: asd .5s .5s linear both;
            animation: asd .5s .5s linear both;
    z-index: 2; }

@-webkit-keyframes asd {
  0% {
    z-index: 2; }
  5% {
    z-index: 4; }
  100% {
    z-index: 4; } }

@keyframes asd {
  0% {
    z-index: 2; }
  5% {
    z-index: 4; }
  100% {
    z-index: 4; } }
  .clock-container-second.play ul li.active .down, .clock-container-minute.play ul li.active .down, .clock-container-hour.play ul li.active .down, .clock-container-day.play ul li.active .down {
    z-index: 2;
    -webkit-animation: turn .5s .5s linear both;
            animation: turn .5s .5s linear both; }

@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }

@keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg); }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); } }
  .clock-container-second.play ul li.before .up, .clock-container-minute.play ul li.before .up, .clock-container-hour.play ul li.before .up, .clock-container-day.play ul li.before .up {
    z-index: 2;
    -webkit-animation: turn2 .5s linear both;
            animation: turn2 .5s linear both; }

@-webkit-keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg); } }

@keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg); }
  100% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg); } }
  .clock-container-second.play ul li.before .up .shadow, .clock-container-minute.play ul li.before .up .shadow, .clock-container-hour.play ul li.before .up .shadow, .clock-container-day.play ul li.before .up .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
    -webkit-animation: show .5s linear both;
            animation: show .5s linear both; }
  .clock-container-second.play ul li.active .up .shadow, .clock-container-minute.play ul li.active .up .shadow, .clock-container-hour.play ul li.active .up .shadow, .clock-container-day.play ul li.active .up .shadow {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%);
    -webkit-animation: hide .5s .3s linear both;
            animation: hide .5s .3s linear both; }
  .clock-container-second.play ul li.before .down .shadow, .clock-container-minute.play ul li.before .down .shadow, .clock-container-hour.play ul li.before .down .shadow, .clock-container-day.play ul li.before .down .shadow {
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
    -webkit-animation: show .5s linear both;
            animation: show .5s linear both; }
  .clock-container-second.play ul li.active .down .shadow, .clock-container-minute.play ul li.active .down .shadow, .clock-container-hour.play ul li.active .down .shadow, .clock-container-day.play ul li.active .down .shadow {
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%);
    -webkit-animation: hide .5s .3s linear both;
            animation: hide .5s .3s linear both; }

@-webkit-keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes hide {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.video-playlist-container {
  background-color: #000;
  height: 60vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none; }
  @media (max-width: 1025px) {
    .video-playlist-container {
      height: 54vh; } }
  @media (max-width: 767px) {
    .video-playlist-container {
      height: 48vh; } }
  @media (max-width: 500px) {
    .video-playlist-container {
      height: 36vh; } }
  .video-playlist-container .video-playlist-flex-container {
    display: flex;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9; }
    .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative {
      position: relative;
      flex-basis: 100%; }
      .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative #z3pOverlay {
        position: relative; }
      .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-sound, .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-full-screen {
        color: #fff;
        -webkit-filter: drop-shadow(0 0 10px #000);
                filter: drop-shadow(0 0 10px #000);
        position: absolute;
        transition: -webkit-transform .5s linear;
        transition: transform .5s linear;
        transition: transform .5s linear, -webkit-transform .5s linear;
        z-index: 999992; }
      .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-sound {
        font-size: 24px; }
        @media (min-width: 1025px) {
          .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-sound {
            bottom: 20px;
            right: 80px; }
            .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-sound:hover {
              cursor: pointer;
              -webkit-transform: scale(1.2);
                      transform: scale(1.2); } }
        @media (max-width: 1024px) {
          .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-sound {
            font-size: 36px;
            padding: 13px;
            left: 27px;
            top: 7px; } }
      .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-full-screen {
        font-size: 20px;
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1); }
        @media (min-width: 1025px) {
          .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-full-screen {
            bottom: 23px;
            right: 40px; }
            .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-full-screen:hover {
              cursor: pointer;
              -webkit-transform: scaleX(-1) scale(1.2);
                      transform: scaleX(-1) scale(1.2); } }
        @media (max-width: 1024px) {
          .video-playlist-container .video-playlist-flex-container .video-playlist-container-relative .video-playlist-container-full-screen {
            font-size: 32px;
            left: 77px;
            padding: 13px;
            top: 10px; } }

.video-playlist-happy-ending {
  background: linear-gradient(to top, #00000000, #000);
  height: 50px;
  width: 100%; }

.video-playlist-content {
  color: #fff;
  width: 60%;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .video-playlist-content {
      width: 90%; } }
  .video-playlist-content .video-playlist-content-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 26px; }
    @media (min-width: 1025px) {
      .video-playlist-content .video-playlist-content-header {
        height: 80px; } }
    @media (max-width: 767px) {
      .video-playlist-content .video-playlist-content-header {
        flex-direction: column; } }
    .video-playlist-content .video-playlist-content-header .video-playlist-img-title {
      height: 100%; }
      @media (max-width: 767px) {
        .video-playlist-content .video-playlist-content-header .video-playlist-img-title {
          max-width: 80%;
          margin-bottom: 26px; } }
      .video-playlist-content .video-playlist-content-header .video-playlist-img-title img {
        max-height: 100%;
        max-width: 100%; }
    .video-playlist-content .video-playlist-content-header .video-playlist-title {
      font-size: 24px; }
  .video-playlist-content .movie_btns {
    align-items: center;
    color: #aeaeae;
    display: flex; }
    @media (max-width: 1024px) {
      .video-playlist-content .movie_btns {
        flex-direction: column;
        padding-left: 0; }
        .video-playlist-content .movie_btns > * {
          margin: 13px 0; } }
    .video-playlist-content .movie_btns .icons_box {
      align-items: center;
      display: flex;
      justify-content: center; }
    .video-playlist-content .movie_btns .movie_btn_box {
      align-items: center;
      display: flex;
      color: #FBA00B;
      flex-direction: column;
      justify-content: center;
      margin-right: 13px; }
      .video-playlist-content .movie_btns .movie_btn_box .icon_box {
        background-color: #FFFFFF1A;
        border-radius: 50%;
        height: 55px;
        width: 55px; }
        .video-playlist-content .movie_btns .movie_btn_box .icon_box img.movie_icon {
          -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
                  filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
        @media (min-width: 1025px) {
          .video-playlist-content .movie_btns .movie_btn_box .icon_box:hover {
            background-color: #FFFFFF4A;
            cursor: pointer; } }
        .video-playlist-content .movie_btns .movie_btn_box .icon_box .icon_revert {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg); }
    .video-playlist-content .movie_btns i {
      font-size: 30px; }
    .video-playlist-content .movie_btns .btn-movie {
      background-color: #FBA00B;
      border: none;
      border-radius: 3px;
      color: #FFFFFF;
      display: inline-block;
      font-size: 20px;
      font-weight: 100;
      height: 56px;
      letter-spacing: 2px;
      margin: 0 8px 8px 0;
      padding: 7px;
      width: 240px; }
      @media (max-width: 1024px) {
        .video-playlist-content .movie_btns .btn-movie {
          width: 300px;
          margin: 0 0 18px 0; } }
      @media (max-width: 500px) {
        .video-playlist-content .movie_btns .btn-movie {
          width: 80vw;
          margin: 0 0 18px 0; } }
      .video-playlist-content .movie_btns .btn-movie i {
        font-size: 18px;
        margin-right: 13px; }
      .video-playlist-content .movie_btns .btn-movie:hover {
        background-color: #FBA00B;
        cursor: pointer;
        opacity: 0.89; }
    .video-playlist-content .movie_btns .btn-trailer {
      background-color: #2C2C2C; }
      @media (min-width: 1025px) {
        .video-playlist-content .movie_btns .btn-trailer:hover {
          background-color: #353535;
          cursor: pointer; } }
    .video-playlist-content .movie_btns .btn-bonus {
      background-color: #2C2C2C;
      color: #fba00b; }
      @media (min-width: 1025px) {
        .video-playlist-content .movie_btns .btn-bonus:hover {
          background-color: #353535;
          cursor: pointer; } }
  .video-playlist-content img.movie_icon {
    -webkit-filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
            filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
    height: 30px;
    width: 30px; }
    @media (max-width: 767px) {
      .video-playlist-content img.movie_icon {
        height: 20px; } }
    .video-playlist-content img.movie_icon.selected {
      -webkit-filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
              filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }
  .video-playlist-content .icon_box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
    position: relative;
    width: 100%;
    z-index: 1; }
    .video-playlist-content .icon_box .bubble {
      background-color: #2a2a2a;
      border-radius: 3px;
      color: #fff;
      display: none;
      left: -7px;
      padding: 7px 12px;
      position: absolute;
      top: -60px;
      white-space: nowrap;
      z-index: 2; }
    .video-playlist-content .icon_box:hover .bubble {
      display: block; }
      @media (max-width: 1024px) {
        .video-playlist-content .icon_box:hover .bubble {
          display: none; } }
  .video-playlist-content .movie_additional_info {
    align-self: flex-start;
    padding: 0;
    margin-top: 26px; }
    .video-playlist-content .movie_additional_info img.movie_icon {
      margin-left: 13px;
      margin-top: -4px;
      height: 20px;
      width: auto; }
  .video-playlist-content .movie_popup_categories {
    align-self: flex-start;
    margin-top: 13px;
    padding: 10px 26px;
    position: relative;
    width: 100%;
    z-index: 3; }
    @media (max-width: 1024px) {
      .video-playlist-content .movie_popup_categories {
        box-shadow: none;
        padding: 15px 0; } }
    @media (min-width: 1025px) {
      .video-playlist-content .movie_popup_categories .catSpanLinked:hover {
        background-color: #FFFFFF4A;
        cursor: pointer; } }
    .video-playlist-content .movie_popup_categories .catSpan {
      background-color: #1f1f1f;
      border-radius: 20px;
      color: #aeaeae;
      display: inline-block;
      height: 25px;
      margin-bottom: 7px;
      margin-right: 13px;
      padding: 0 13px; }
      @media (max-width: 1024px) {
        .video-playlist-content .movie_popup_categories .catSpan {
          margin: 7px 0; } }
      .video-playlist-content .movie_popup_categories .catSpan.catSpan-mobile {
        display: none; }
      .video-playlist-content .movie_popup_categories .catSpan.catSpan-web {
        display: inline-block; }
      @media (max-width: 767px) {
        .video-playlist-content .movie_popup_categories .catSpan.catSpan-mobile {
          display: inline-block; }
        .video-playlist-content .movie_popup_categories .catSpan.catSpan-web {
          display: none; } }
  .video-playlist-content .movie_popup_synopsis {
    margin: 26px 0; }
  .video-playlist-content .distribution-container {
    margin-top: 13px; }

.video-js .vjs-control-bar {
  position: fixed !important; }

.video-js .btn, .video-js .profile_btn_return, .video-js .profile_btn_continuer, .video-js .profile_btn_unsub, .video-js .profile_btn_save {
  font-size: 1.2vw !important; }

.video-js .vjs-tech {
  position: fixed !important; }

.video-js .vjs-modal-dialog {
  z-index: 2000 !important; }

.react-confirm-alert-overlay {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.8); }

.responsive-image {
  position: relative;
  width: 600px;
  max-width: 100%; }

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.btnPopup {
  position: relative;
  top: -80px; }

@media only screen and (max-device-width: 480px) {
  .responsive-image {
    width: 300px; }
  .btnPopup {
    top: -38px; } }

.player-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.react-player {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.overlayGB {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.overlayGB-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear; }

.overlayGB-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear; }

/* LOADER */
.loader {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  z-index: 1; }

@media (max-width: 767px) {
  .loader {
    margin: 2vh 10vh 0 10vh; } }

.s3bubble-meta-overlay-inner-video:hover div.loader-part {
  cursor: pointer;
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px; }

div.loader-play {
  display: none; }

.s3bubble-meta-overlay-inner-video:hover div.loader-play {
  display: block;
  color: #fff;
  box-sizing: border-box;
  font-size: 1.5rem;
  margin: 0;
  opacity: 0.5;
  position: absolute;
  left: 40%;
  z-index: 1; }

/* LOADER MOBILE*/
.s3bubble-meta-overlay-inner-video-mobile:hover div.loader-part {
  cursor: pointer;
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 45%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px; }

.s3bubble-meta-overlay-inner-video-mobile:hover div.loader-play {
  display: block;
  color: #fff;
  box-sizing: border-box;
  font-size: 1.5rem;
  margin: 0;
  opacity: 0.5;
  position: absolute;
  left: 40%;
  z-index: 1; }

.player-overlay {
  background: rgba(29, 29, 27, 0.5);
  color: #FBFBFB;
  bottom: 0;
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10; }
  .player-overlay-content {
    height: 100%;
    padding: 32px; }
    .player-overlay-content.free-movie-content {
      padding-top: 120px; }
  .player-overlay-title {
    font-size: 2rem;
    font-weight: 700;
    margin-top: 52px; }
  .player-overlay-product-related {
    position: absolute;
    font-size: 20px;
    bottom: 30px;
    right: 30px;
    text-align: center;
    width: 30vw; }
    .player-overlay-product-related-img {
      background-color: #aeaeae22;
      border-radius: 5px;
      font-size: 16px;
      margin-top: 7px;
      padding: 13px; }
    @media (min-width: 768px) {
      .player-overlay-product-related {
        bottom: 100px;
        right: 50px;
        width: 250px; } }
    .player-overlay-product-related img {
      margin-bottom: 13px;
      width: 100%; }
  .player-overlay-back {
    align-items: center;
    display: flex; }
    .player-overlay-back-icon {
      margin-right: 12px; }
    .player-overlay-back-arrow {
      margin-right: 12px; }
  .player-overlay.\--end .player-overlay-content {
    position: relative;
    text-align: left; }
  .player-overlay.\--end .player-overlay-title {
    margin-bottom: 12px; }
  .player-overlay.\--end .player-overlay-back {
    margin-bottom: 12px; }
  .player-overlay.\--end .player-overlay-thumbs {
    margin-left: 26px; }
    .player-overlay.\--end .player-overlay-thumbs > *:first-child {
      margin-right: 6px; }
    .player-overlay.\--end .player-overlay-thumbs .up, .player-overlay.\--end .player-overlay-thumbs .down {
      background: #323232;
      border-radius: 50%;
      fill: #5eb0b0;
      height: 50px;
      padding: 10px;
      width: 50px; }
      .player-overlay.\--end .player-overlay-thumbs .up:hover, .player-overlay.\--end .player-overlay-thumbs .down:hover {
        fill: #aed7d7; }
    .player-overlay.\--end .player-overlay-thumbs .down {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .player-overlay.\--end .player-overlay-next {
    position: absolute;
    bottom: 32px;
    right: 32px; }
    .player-overlay.\--end .player-overlay-next img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 60vw; }
      @media (min-width: 768px) {
        .player-overlay.\--end .player-overlay-next img {
          width: 500px; } }
    .player-overlay.\--end .player-overlay-next-btn {
      background: #202020;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 12px 26px;
      text-align: center;
      width: 60vw;
      transition: 0.3s; }
      @media (min-width: 768px) {
        .player-overlay.\--end .player-overlay-next-btn {
          width: 500px; } }
    .player-overlay.\--end .player-overlay-next:hover > div {
      background: #7e7e7e; }

.btnRet {
  font-size: 1.6rem !important;
  padding: 0.4rem 1.5rem;
  border: 1px solid transparent !important; }

.btnRet:hover {
  border: 1px solid #fba00b  !important; }

.video-js .vjs-big-play-button {
  font-size: 6em;
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  margin-left: -45px;
  margin-top: -45px;
  border: none;
  background-color: rgba(43, 51, 63, 0.7);
  line-height: 90px;
  border-radius: 50%;
  border: none;
  z-index: 2; }

.video-js .vjs-control-bar {
  z-index: 2; }

.video-js .vjs-tech {
  position: fixed !important; }

.s3bubble-meta-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2147483647; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container {
  margin: 2%;
  width: 40%;
  padding: 0;
  text-align: left; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container:-webkit-full-screen {
  display: block !important;
  z-index: 2147483647; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5 {
  font-family: 'Helvetica', sans-serif;
  font-size: 1.3vw;
  font-weight: 400;
  text-shadow: 0 1px 4px black;
  color: #fff;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  opacity: 0.9;
  float: left;
  width: 100%; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5:-webkit-full-screen {
  display: block !important; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h1, .s3bubble-meta-overlay .s3bubble-meta-overlay-container h1:-webkit-full-screen {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 4vw;
  font-weight: 700;
  text-shadow: 0 1px 4px black;
  margin: 1vw 0;
  padding: 0;
  float: left;
  width: 100%; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container p, .s3bubble-meta-overlay .s3bubble-meta-overlay-container p:-webkit-full-screen {
  font-family: 'Helvetica', sans-serif;
  text-shadow: 0 1px 4px black;
  font-weight: normal;
  font-weight: 200;
  font-size: 1.8vw;
  line-height: 1.3em;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0; }

.s3bubble-meta-overlay-visible, .s3bubble-meta-overlay-visible:-webkit-full-screen {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 1s linear;
  transition: opacity 1s linear; }

.s3bubble-meta-overlay-hidden, .s3bubble-meta-overlay-hidden:-webkit-full-screen {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s 1s, opacity 1s linear;
  transition: visibility 0s 1s, opacity 1s linear; }

.popupFinPlay-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; }

.s3bubble-meta-overlay-container-mobile {
  text-align: center;
  color: #fff;
  padding-top: 10px; }

.s3bubble-meta-overlay-container-mobile .ret-nav, .s3bubble-meta-overlay-container .ret-nav {
  color: #fff;
  display: flex;
  font-weight: 100;
  padding: 5px; }
  .s3bubble-meta-overlay-container-mobile .ret-nav img, .s3bubble-meta-overlay-container .ret-nav img {
    height: 15px;
    margin-top: 5px;
    margin-right: 10px; }

.s3bubble-meta-overlay-container-mobile .ret-nav:hover, .s3bubble-meta-overlay-container .ret-nav:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.s3bubble-meta-overlay.next-overlay {
  display: flex;
  width: 100%; }

@media (max-width: 767px) {
  .s3bubble-meta-overlay.next-overlay {
    flex-direction: column;
    height: auto;
    width: 80%; } }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container-video {
  margin: 2%;
  width: 52%;
  padding: 0 10%;
  text-align: left; }

.s3bubble-meta-overlay figure {
  border-style: none !important; }

.s3bubble-meta-overlay figure h1.short-title {
  font-size: 1em !important; }

.s3bubble-meta-overlay figure h1.long-title {
  font-size: 0.8em !important; }

.s3bubble-meta-overlay figure h1.longer-title {
  font-size: 0.5em !important; }

.s3bubble-meta-overlay figure div.title-info {
  display: none; }

.s3bubble-meta-overlay figure:hover h1.short-title {
  display: none !important; }

.s3bubble-meta-overlay figure:hover h1.long-title {
  display: none !important; }

.s3bubble-meta-overlay figure:hover h1.longer-title {
  display: none !important; }

.s3bubble-meta-overlay figure img {
  transition: all .5s; }

.s3bubble-meta-overlay figure:hover img {
  opacity: 1;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8); }

.s3bubble-meta-overlay figure:hover div.title-info {
  margin: 15%;
  display: block; }

.s3bubble-meta-overlay figure:hover div.title-info .title-info-title {
  font-size: 1em !important;
  font-weight: bold;
  margin-top: -26px;
  text-shadow: 1px 1px #111; }

.s3bubble-meta-overlay figure:hover div.title-info .title-info-details {
  font-size: 0.7em !important;
  text-shadow: 1px 1px #111;
  text-transform: none; }

#next-overlay-title {
  background-color: #202020 !important;
  color: #fff;
  padding: 15px; }

#next-overlay-title-mobile {
  background-color: #202020 !important;
  color: #fff;
  padding: 15px; }

#s3bubble-meta-overlay-inner-video {
  border-radius: 3% !important;
  position: absolute;
  bottom: 5%;
  right: 5%; }

#s3bubble-meta-overlay-inner-video-mobile {
  border-radius: 3% !important;
  size: 30%;
  width: 50%; }

.player-center {
  height: 50px;
  left: 50%;
  opacity: 80%;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  @media (max-width: 767px) {
    .player-center {
      height: 30px; } }
  @media (max-width: 425px) {
    .player-center {
      height: 20px; } }

.s3bubble-meta-overlay-navigation {
  display: flex;
  position: absolute;
  width: 100%;
  padding: 0 50px 0 20px; }
  .s3bubble-meta-overlay-navigation .ret-nav {
    color: #fff;
    display: flex;
    flex-basis: 18%;
    font-weight: 100;
    padding: 5px; }
    .s3bubble-meta-overlay-navigation .ret-nav img {
      height: 15px;
      margin-top: 5px;
      margin-right: 10px; }
    .s3bubble-meta-overlay-navigation .ret-nav #return {
      display: none; }
      @media (min-width: 769px) {
        .s3bubble-meta-overlay-navigation .ret-nav #return {
          display: block; } }
  .s3bubble-meta-overlay-navigation .ret-nav:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .s3bubble-meta-overlay-navigation .title-wrap {
    color: #fff;
    flex-grow: 1;
    font-size: 22px;
    font-weight: 100;
    padding: 5px 20% 5px 0;
    text-align: center;
    text-shadow: 0.2px 0.2px 0.2px; }

.s3bubble-meta-overlay-navigation-mobile {
  display: flex;
  padding: 0 20px 0 10px; }
  .s3bubble-meta-overlay-navigation-mobile .ret-nav-mobile {
    color: #fff;
    display: flex;
    flex-basis: 18%;
    font-weight: 100;
    padding: 5px; }
    .s3bubble-meta-overlay-navigation-mobile .ret-nav-mobile img {
      height: 15px;
      margin-top: 5px;
      margin-right: 10px; }
  .s3bubble-meta-overlay-navigation-mobile .ret-nav-mobile:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .s3bubble-meta-overlay-navigation-mobile .title-wrap-mobile {
    color: #fff;
    flex-grow: 1;
    font-size: 22px;
    font-weight: 100;
    padding: 5px;
    text-align: center;
    text-shadow: 0.2px 0.2px 0.2px; }

.s3bubble-meta-overlay-container .icons_box {
  align-items: center;
  display: flex;
  max-width: 247.58px;
  margin: 5px 0 5px 11.2px; }

.s3bubble-meta-overlay-container .movie_btn_box {
  align-items: center;
  display: flex;
  color: #FBA00B;
  flex-direction: column;
  justify-content: center;
  margin-right: 13px; }
  .s3bubble-meta-overlay-container .movie_btn_box:nth-child(2) .icon_box img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .s3bubble-meta-overlay-container .movie_btn_box .icon_box {
    background-color: #FFFFFF1A;
    border-radius: 50%;
    height: 55px;
    width: 55px; }
    .s3bubble-meta-overlay-container .movie_btn_box .icon_box img {
      border-style: none;
      height: 30px;
      vertical-align: middle;
      width: 30px; }
    @media (min-width: 1025px) {
      .s3bubble-meta-overlay-container .movie_btn_box .icon_box:hover {
        background-color: #FFFFFF4A;
        cursor: pointer; } }

.s3bubble-meta-overlay-container .icon_box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
  position: relative;
  width: 100%;
  z-index: 1; }
  .s3bubble-meta-overlay-container .icon_box .bubble {
    background-color: #2a2a2a;
    border-radius: 3px;
    color: #fff;
    display: none;
    left: -7px;
    padding: 7px 12px;
    position: absolute;
    top: -60px;
    white-space: nowrap;
    z-index: 2; }
  .s3bubble-meta-overlay-container .icon_box:hover .bubble {
    display: block; }
    @media (max-width: 1024px) {
      .s3bubble-meta-overlay-container .icon_box:hover .bubble {
        display: none; } }

.s3bubble-meta-overlay-container-mobile .icons_box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px auto 5px auto;
  max-width: 247.58px; }

.s3bubble-meta-overlay-container-mobile .movie_btn_box {
  align-items: center;
  display: flex;
  color: #FBA00B;
  flex-direction: column;
  justify-content: center;
  margin-right: 13px; }
  .s3bubble-meta-overlay-container-mobile .movie_btn_box:nth-child(2) .icon_box img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .s3bubble-meta-overlay-container-mobile .movie_btn_box .icon_box {
    background-color: #FFFFFF1A;
    border-radius: 50%;
    height: 55px;
    width: 55px; }
    .s3bubble-meta-overlay-container-mobile .movie_btn_box .icon_box img {
      border-style: none;
      height: 30px;
      -webkit-filter: none;
              filter: none;
      vertical-align: middle;
      width: 30px; }
      @media (max-width: 767px) {
        .s3bubble-meta-overlay-container-mobile .movie_btn_box .icon_box img {
          height: 20px; } }
    @media (min-width: 1025px) {
      .s3bubble-meta-overlay-container-mobile .movie_btn_box .icon_box:hover {
        background-color: #FFFFFF4A;
        cursor: pointer; } }

.s3bubble-meta-overlay-container-mobile .icon_box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
  position: relative;
  width: 100%;
  z-index: 1; }
  .s3bubble-meta-overlay-container-mobile .icon_box .bubble {
    background-color: #2a2a2a;
    border-radius: 3px;
    color: #fff;
    display: none;
    left: -7px;
    padding: 7px 12px;
    position: absolute;
    top: -60px;
    white-space: nowrap;
    z-index: 2; }
  .s3bubble-meta-overlay-container-mobile .icon_box:hover .bubble {
    display: block; }
    @media (max-width: 1024px) {
      .s3bubble-meta-overlay-container-mobile .icon_box:hover .bubble {
        display: none; } }

.title-wrap-next-overlay {
  padding-top: 50px; }

.title-wrap-next-overlay-mobile {
  padding-top: 20px; }

.icons_box_overlay {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 23px;
  max-width: 250px; }
  .icons_box_overlay .movie_btn_box {
    align-items: center;
    display: flex;
    color: #FBA00B;
    flex-direction: column;
    justify-content: center;
    margin-right: 13px; }
    .icons_box_overlay .movie_btn_box:nth-child(2) .icon_box img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .icons_box_overlay .movie_btn_box .icon_box {
      background-color: #FFFFFF1A;
      border-radius: 50%;
      height: 55px;
      width: 55px; }
      .icons_box_overlay .movie_btn_box .icon_box img {
        border-style: none;
        height: 30px;
        -webkit-filter: none;
                filter: none;
        vertical-align: middle;
        width: 30px; }
        @media (max-width: 767px) {
          .icons_box_overlay .movie_btn_box .icon_box img {
            height: 20px; } }
      @media (min-width: 1025px) {
        .icons_box_overlay .movie_btn_box .icon_box:hover {
          background-color: #FFFFFF4A;
          cursor: pointer; } }
  .icons_box_overlay .icon_box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
    position: relative;
    width: 100%;
    z-index: 1; }
    .icons_box_overlay .icon_box .bubble {
      background-color: #2a2a2a;
      border-radius: 3px;
      color: #fff;
      display: none;
      left: -7px;
      padding: 7px 12px;
      position: absolute;
      top: -60px;
      white-space: nowrap;
      z-index: 2; }
    .icons_box_overlay .icon_box:hover .bubble {
      display: block; }
      @media (max-width: 1024px) {
        .icons_box_overlay .icon_box:hover .bubble {
          display: none; } }

.video-js .vjs-control-bar {
  position: fixed !important; }

.video-js .btn, .video-js .profile_btn_return, .video-js .profile_btn_continuer, .video-js .profile_btn_unsub, .video-js .profile_btn_save {
  font-size: 1.2vw !important; }

.video-js .vjs-tech {
  position: fixed !important; }

.video-js .vjs-modal-dialog {
  z-index: 2000 !important; }

.react-confirm-alert-overlay {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.8); }

.responsive-image {
  position: relative;
  width: 600px;
  max-width: 100%; }

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.btnPopup {
  position: relative;
  top: -80px; }

@media only screen and (max-device-width: 480px) {
  .responsive-image {
    width: 300px; }
  .btnPopup {
    top: -38px; } }

.player-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.react-player {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.z3PlayOverlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #000;
  display: none;
  transition: .2s;
  z-index: 99999; }

.overlayGB {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.overlayGB-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear; }

.overlayGB-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear; }

/* LOADER */
.loader {
  display: inline-block;
  margin: 10vh;
  position: relative;
  width: 200px;
  height: 200px;
  z-index: 1; }

@media (max-width: 767px) {
  .loader {
    margin: 2vh 10vh 0 10vh; } }

.loader:hover {
  cursor: pointer; }

.loader div.loader-part {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 160px;
  height: 160px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #FBA00B transparent transparent transparent;
  z-index: 1; }

.loader div.loader-part:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.loader div.loader-part:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.loader div.loader-part:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

.loader div.loader-text {
  color: #FFF;
  box-sizing: border-box;
  font-size: 6rem;
  left: 45%;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 48%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2; }

.loader div.loader-play {
  color: #FBA00B;
  box-sizing: border-box;
  font-size: 6rem;
  left: 45%;
  margin: 0;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 48%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.btnRet {
  font-size: 1.6rem !important;
  padding: 0.4rem 1.5rem;
  border: 1px solid transparent !important; }

.btnRet:hover {
  border: 1px solid #fba00b !important; }

.video-js .vjs-big-play-button {
  font-size: 6em;
  left: 50%;
  top: 50%;
  width: 90px;
  height: 90px;
  margin-left: -45px;
  margin-top: -45px;
  border: none;
  background-color: rgba(43, 51, 63, 0.7);
  line-height: 90px;
  border-radius: 50%;
  border: none;
  z-index: 2; }

.video-js .vjs-control-bar {
  z-index: 2; }

.video-js .vjs-tech {
  position: fixed !important; }

.s3bubble-meta-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2147483647; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container {
  margin: 2%;
  width: 40%;
  padding: 0;
  text-align: left; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container:-webkit-full-screen {
  display: block !important;
  z-index: 2147483647; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5 {
  font-family: 'Helvetica', sans-serif;
  font-size: 1.3vw;
  font-weight: 400;
  text-shadow: 0 1px 4px black;
  color: #fff;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  opacity: 0.9;
  float: left;
  width: 100%; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h5:-webkit-full-screen {
  display: block !important; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container h1, .s3bubble-meta-overlay .s3bubble-meta-overlay-container h1:-webkit-full-screen {
  font-family: 'Open Sans', sans-serif;
  font-size: 4vw;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 1px 4px black;
  margin: 1vw 0;
  padding: 0;
  float: left;
  width: 100%; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container p, .s3bubble-meta-overlay .s3bubble-meta-overlay-container p:-webkit-full-screen {
  font-family: 'Helvetica', sans-serif;
  text-shadow: 0 1px 4px black;
  font-weight: normal;
  font-weight: 200;
  font-size: 1.8vw;
  line-height: 1.3em;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0; }

.s3bubble-meta-overlay-visible, .s3bubble-meta-overlay-visible:-webkit-full-screen {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 1s linear;
  transition: opacity 1s linear; }

.s3bubble-meta-overlay-hidden, .s3bubble-meta-overlay-hidden:-webkit-full-screen {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s 1s, opacity 1s linear;
  transition: visibility 0s 1s, opacity 1s linear; }

.popupFinPlay-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; }

.s3bubble-meta-overlay-container-mobile {
  text-align: center;
  color: #fff;
  padding-top: 10px; }

.s3bubble-meta-overlay-container-mobile h5 {
  padding-top: 10px; }

.s3bubble-meta-overlay.next-overlay {
  display: flex;
  width: 100%; }

@media (max-width: 767px) {
  .s3bubble-meta-overlay.next-overlay {
    flex-direction: column; } }

.s3bubble-meta-overlay:hover {
  cursor: pointer; }

.notFullScreen .s3bubble-meta-overlay {
  transition: border 0.5s; }
  .notFullScreen .s3bubble-meta-overlay .fullscreen_icon {
    bottom: 7px;
    font-size: 36px;
    opacity: 0;
    position: absolute;
    right: 7px;
    transition: opacity 0.5s;
    z-index: 2; }
  .notFullScreen .s3bubble-meta-overlay:hover .fullscreen_icon {
    opacity: 0.8; }
  .notFullScreen .s3bubble-meta-overlay.refresh {
    opacity: 0.4; }
    .notFullScreen .s3bubble-meta-overlay.refresh .play_icon {
      color: #eee;
      font-size: 96px;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 2; }

.s3bubble-meta-overlay .s3bubble-meta-overlay-container-video {
  margin: 2%;
  width: 52%;
  padding: 0 10%;
  text-align: left; }

.s3bubble-meta-overlay figure {
  border: 5px solid #fba00b; }

.s3bubble-meta-overlay figure h1.short-title {
  font-size: 1em !important; }

.s3bubble-meta-overlay figure h1.long-title {
  font-size: 0.8em !important; }

.s3bubble-meta-overlay figure h1.longer-title {
  font-size: 0.5em !important; }

.s3bubble-meta-overlay figure div.title-info {
  display: none; }

.s3bubble-meta-overlay figure:hover h1.short-title {
  display: none !important; }

.s3bubble-meta-overlay figure:hover h1.long-title {
  display: none !important; }

.s3bubble-meta-overlay figure:hover h1.longer-title {
  display: none !important; }

.s3bubble-meta-overlay figure img {
  transition: all .5s; }

.s3bubble-meta-overlay figure:hover img {
  opacity: 1;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8); }

.s3bubble-meta-overlay figure:hover div.title-info {
  margin: 15%;
  display: block; }

.s3bubble-meta-overlay figure:hover div.title-info .title-info-title {
  font-size: 1em !important;
  font-weight: bold;
  margin-top: -26px;
  text-shadow: 1px 1px #111; }

.s3bubble-meta-overlay figure:hover div.title-info .title-info-details {
  font-size: 0.7em !important;
  text-shadow: 1px 1px #111;
  text-transform: none; }

#advertisement_pub {
  bottom: 7px;
  color: #fff;
  position: absolute;
  left: 7px;
  width: 100%;
  z-index: 2; }
  #advertisement_pub h3 {
    font-size: 2rem;
    padding: 13px;
    text-align: center; }
  #advertisement_pub a.go_offer {
    margin: 0 auto;
    width: 500px; }
    @media (max-width: 767px) {
      #advertisement_pub a.go_offer {
        width: 80%; } }
    #advertisement_pub a.go_offer:hover {
      background-color: #d6880a !important;
      color: #fff !important; }
  #advertisement_pub.hide {
    display: none; }

#advertisement_pub_count {
  align-items: center;
  bottom: 26px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  z-index: 2; }
  #advertisement_pub_count h3 {
    font-size: 2rem;
    margin-top: 13px;
    padding: 0 13px; }
  #advertisement_pub_count.hide {
    display: none; }

#advertisement_pub_progress {
  height: 7px;
  bottom: 7px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 500px;
  z-index: 2; }
  @media (max-width: 767px) {
    #advertisement_pub_progress {
      width: 80%; } }
  #advertisement_pub_progress.hide {
    display: none; }
  #advertisement_pub_progress #progressBar {
    background-color: #fba00b;
    height: 100%; }

@media (min-width: 1200px) and (max-width: 1400px) {
  #advertisement_pub h3 {
    font-size: 1.8rem; } }

@media (min-width: 992px) and (max-width: 1200px) {
  #advertisement_pub h3 {
    font-size: 1.4rem; }
  #advertisement_pub_count h3 {
    font-size: 1.3rem;
    padding: 0 7px; } }

@media (min-width: 768px) and (max-width: 992px) {
  #advertisement_pub h3 {
    font-size: 1.2rem; }
  #advertisement_pub_count h3 {
    font-size: 1rem;
    padding: 0 7px; }
  #advertisement_pub_count a.go_offer {
    font-size: 1.4rem;
    width: 80%; } }

@media (max-width: 767px) {
  #advertisement_pub h3 {
    font-size: 1rem;
    padding: 0; }
  #advertisement_pub a.btn, #advertisement_pub a.profile_btn_return, #advertisement_pub a.profile_btn_continuer, #advertisement_pub a.profile_btn_unsub, #advertisement_pub a.profile_btn_save {
    width: 80%;
    margin-top: 13px; }
  #advertisement_pub_count h3 {
    font-size: 1rem;
    padding: 3px;
    margin-bottom: 0 !important; }
  #advertisement_pub_count a.go_offer {
    font-size: 1rem;
    width: 80%; } }

#z3pOverlay.playlistPlayer .s3bubble-meta-overlay {
  background-color: transparent; }

#z3pOverlay.playlistPlayer.isFullScreen .fullscreen_icon {
  display: none; }

#advertisement_pub_count a.go_offer {
  margin: 0 auto;
  width: 500px; }
  @media (max-width: 767px) {
    #advertisement_pub_count a.go_offer {
      width: 80%; } }
  #advertisement_pub_count a.go_offer:hover {
    background-color: #d6880a !important;
    color: #fff !important; }

.free-movie-container {
  align-items: center;
  background-color: #fba00b;
  color: #fff;
  font-size: 14px;
  justify-content: space-around;
  padding: 13px 0;
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  z-index: 10; }
  @media (max-width: 767px) {
    .free-movie-container {
      flex-direction: column; } }
  .free-movie-container .btn, .free-movie-container .profile_btn_return, .free-movie-container .profile_btn_continuer, .free-movie-container .profile_btn_unsub, .free-movie-container .profile_btn_save {
    background-color: #fff !important;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #000 !important;
    width: 150px; }
    .free-movie-container .btn:hover, .free-movie-container .profile_btn_return:hover, .free-movie-container .profile_btn_continuer:hover, .free-movie-container .profile_btn_unsub:hover, .free-movie-container .profile_btn_save:hover {
      border: 2px solid #000 !important; }

.movie-page .soon-container .soon-content .soon-img-title {
  margin-bottom: 26px;
  margin-top: 26px; }

.movie-page .soon-container .soon-content .soon-title {
  margin-bottom: 26px;
  margin-top: 26px; }

.movie-page .soon-container .movie_btns {
  margin: 26px 0; }

.movie-page .soon-description {
  -webkit-filter: drop-shadow(0 0 10px #000);
          filter: drop-shadow(0 0 10px #000); }
  @media (max-width: 1024px) {
    .movie-page .soon-description {
      margin-top: 7px; } }

.movie-page .soon-title {
  font-size: 32px;
  margin: 26px 0; }

.movie-page .soon-container .soon-content .soon-description, .movie-page .soon-container .soon-content .soon-date {
  margin-bottom: 26px; }

.movie-page .episodes-container {
  width: 100%; }
  .movie-page .episodes-container .episode-box .movie_episode_img:hover {
    -webkit-transform: translate(-7px, -7px);
            transform: translate(-7px, -7px); }

.movie-page .episode-serie {
  font-size: 18px;
  padding: 0;
  margin-top: 13px; }
  @media (max-width: 1024px) {
    .movie-page .episode-serie {
      margin: 13px 5%;
      max-width: 90%; } }

.movie-page .soon-container .movie_btns {
  margin: 26px 0 0 0; }

.movie-page .soon-content .movie_additional_info {
  align-self: flex-start;
  padding: 0;
  margin-top: 26px; }
  @media (max-width: 1024px) {
    .movie-page .soon-content .movie_additional_info {
      margin: 7px 5%;
      max-width: 90%; } }
  .movie-page .soon-content .movie_additional_info img.movie_icon {
    -webkit-filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
            filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
    margin-left: 13px;
    margin-top: -4px;
    height: 20px;
    width: auto; }

.movie-page .soon-content .movie_popup_categories {
  align-self: flex-start;
  margin: 13px 0;
  padding: 10px 0;
  position: relative;
  width: 100%;
  z-index: 3; }
  @media (max-width: 1024px) {
    .movie-page .soon-content .movie_popup_categories {
      box-shadow: none;
      margin: 7px 5%;
      max-width: 90%;
      padding: 0; } }
  @media (min-width: 1025px) {
    .movie-page .soon-content .movie_popup_categories .catSpanLinked:hover {
      background-color: #FFFFFF4A;
      cursor: pointer; } }
  .movie-page .soon-content .movie_popup_categories .catSpan {
    background-color: #1f1f1f;
    border-radius: 20px;
    color: #aeaeae;
    display: inline-block;
    height: 25px;
    margin-bottom: 7px;
    margin-right: 13px;
    padding: 0 13px; }
    @media (max-width: 1024px) {
      .movie-page .soon-content .movie_popup_categories .catSpan {
        margin: 7px 0; } }
    .movie-page .soon-content .movie_popup_categories .catSpan.catSpan-mobile {
      display: none; }
    .movie-page .soon-content .movie_popup_categories .catSpan.catSpan-web {
      display: inline-block; }
    @media (max-width: 767px) {
      .movie-page .soon-content .movie_popup_categories .catSpan.catSpan-mobile {
        display: inline-block; }
      .movie-page .soon-content .movie_popup_categories .catSpan.catSpan-web {
        display: none; } }

.movie-page .movie_rows_nav {
  align-items: center;
  display: flex;
  margin: 0 auto;
  color: #fff;
  width: 80%; }
  @media (max-width: 1024px) {
    .movie-page .movie_rows_nav {
      margin: 0 5%;
      width: 90%; } }
  .movie-page .movie_rows_nav .movie_rows_nav_item {
    border-bottom: 2px solid #aeaeae;
    flex: 1 1;
    font-size: 16px;
    margin: 0 13px;
    padding: 13px 26px;
    text-align: center; }
    @media (max-width: 1024px) {
      .movie-page .movie_rows_nav .movie_rows_nav_item {
        font-size: 14px;
        margin: 0 3px;
        padding: 7px 0; } }
    @media (max-width: 767px) {
      .movie-page .movie_rows_nav .movie_rows_nav_item {
        font-size: 10px; } }
    .movie-page .movie_rows_nav .movie_rows_nav_item:hover {
      cursor: pointer; }
    .movie-page .movie_rows_nav .movie_rows_nav_item.selected {
      border-bottom: 2px solid #fba00b;
      font-weight: 700; }

.movie-page .movie_rows_details {
  margin: 42px 0;
  width: 100%; }
  .movie-page .movie_rows_details .categories-list-box.distribution-list-box {
    margin: 0 auto;
    width: 80%; }
  .movie-page .movie_rows_details .GBListItem {
    top: 0 !important; }
    .movie-page .movie_rows_details .GBListItem .list-title {
      padding-top: 0 !important; }

#profile_container.movie_comment_popup {
  padding: 0 50px;
  margin: 0; }
  @media (max-width: 1024px) {
    #profile_container.movie_comment_popup {
      padding: 26px;
      margin: 0 auto; } }
  #profile_container.movie_comment_popup .profile_edit_usual {
    border-top: 2px solid #fff;
    padding-top: 26px; }
  #profile_container.movie_comment_popup .profile_edit_usual_form {
    align-items: start; }
    @media (max-width: 1024px) {
      #profile_container.movie_comment_popup .profile_edit_usual_form {
        align-items: center; }
        #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_input {
          align-self: flex-start; }
          #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_input input {
            width: 200px; } }
    #profile_container.movie_comment_popup .profile_edit_usual_form h3, #profile_container.movie_comment_popup .profile_edit_usual_form .comment_star_rating_box, #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_btn, #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_message {
      align-self: center; }
    #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_textarea {
      width: 100%; }
      #profile_container.movie_comment_popup .profile_edit_usual_form .profile_edit_usual_textarea textarea {
        width: 100%; }

.movie_comment_title {
  font-size: 2rem;
  text-align: center;
  width: 100%;
  padding-bottom: 13px; }

#profile_container.movie_comment_page {
  padding: 13px 0 0 120px;
  margin: 0; }
  @media (max-width: 1024px) {
    #profile_container.movie_comment_page {
      padding: 0;
      margin: 0 auto; } }
  #profile_container.movie_comment_page .profile_edit_usual_form {
    align-items: start; }
    @media (max-width: 1024px) {
      #profile_container.movie_comment_page .profile_edit_usual_form {
        align-items: center; } }
    #profile_container.movie_comment_page .profile_edit_usual_form h3, #profile_container.movie_comment_page .profile_edit_usual_form .comment_star_rating_box, #profile_container.movie_comment_page .profile_edit_usual_form .profile_edit_usual_btn, #profile_container.movie_comment_page .profile_edit_usual_form .profile_edit_usual_message {
      align-self: center; }
    #profile_container.movie_comment_page .profile_edit_usual_form .profile_edit_usual_textarea {
      width: 100%; }
      #profile_container.movie_comment_page .profile_edit_usual_form .profile_edit_usual_textarea textarea {
        width: 100%; }

.no-cursor {
  cursor: initial; }

.list-title.list-title-relative {
  padding: 0 13px 0 50px;
  position: relative;
  z-index: 2; }
  @media (max-width: 767px) {
    .list-title.list-title-relative {
      padding: 0 13px; } }

.list-title-product {
  font-size: 3rem;
  padding-top: 42px; }
  @media (max-width: 767px) {
    .list-title-product {
      padding-top: 84px;
      text-align: center; } }
  .list-title-product.mozaic {
    padding-top: 84px; }

.list-title-product-outOfStock {
  color: #fba00b;
  font-size: 3rem;
  font-weight: 300;
  margin-top: 26px; }
  @media (max-width: 767px) {
    .list-title-product-outOfStock {
      text-align: center; } }

.list-title-product-price {
  color: #fba00b;
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 13px;
  margin-top: 26px; }
  .list-title-product-price .list-title-product-price-initial {
    color: #aeaeae;
    display: inline-block;
    font-size: 2rem;
    margin-right: 13px;
    text-decoration: line-through; }
  .list-title-product-price .list-title-product-price-discount {
    background-color: #fba00b;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-left: 26px;
    padding: 7px 17px;
    text-align: center;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
    @media (max-width: 767px) {
      .list-title-product-price .list-title-product-price-discount {
        margin-left: 0; } }
  @media (max-width: 767px) {
    .list-title-product-price {
      text-align: center; } }

.list-title-product-tags-box {
  display: flex; }
  @media (max-width: 767px) {
    .list-title-product-tags-box {
      justify-content: center; } }
  .list-title-product-tags-box .list-title-product-tags {
    background-color: #1f1f1f;
    border-radius: 20px;
    color: #aeaeae;
    height: 25px;
    margin-bottom: 7px;
    margin-right: 13px;
    margin-top: 13px;
    padding: 0 13px; }
  @media (min-width: 1025px) {
    .list-title-product-tags-box .productTagsLinked:hover {
      background-color: #FFFFFF4A;
      cursor: pointer; } }

.description-title-product {
  color: #aeaeae;
  font-size: 2.5rem;
  margin: 26px 0 13px 50px; }
  @media (max-width: 767px) {
    .description-title-product {
      margin-left: 7px; } }

.description-product {
  color: #aeaeae;
  font-size: 1.5rem;
  margin-bottom: 50px;
  margin-left: 50px;
  padding: 0 13px; }
  @media (max-width: 767px) {
    .description-product {
      margin-left: 13px; } }

#home_Homelist.storeDetails .GBListItem {
  top: 26px; }

.product_mozaic_box {
  margin-left: 50px;
  padding-top: 50px; }
  @media (max-width: 1024px) {
    .product_mozaic_box {
      margin-left: 26px;
      margin-right: 26px; } }
  .product_mozaic_box .shine-container {
    width: 21vw !important; }
    @media (max-width: 1024px) {
      .product_mozaic_box .shine-container {
        width: 25vw !important; } }
    @media (max-width: 767px) {
      .product_mozaic_box .shine-container {
        width: 39vw !important; } }
  .product_mozaic_box .product_mozaic {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5vh;
    margin-left: 2vw; }
    .product_mozaic_box .product_mozaic.movie_mozaic figure {
      height: 21vw !important;
      width: 21vw !important; }
      @media (max-width: 1024px) {
        .product_mozaic_box .product_mozaic.movie_mozaic figure {
          height: 25vw !important;
          width: 25vw !important; } }
      @media (max-width: 767px) {
        .product_mozaic_box .product_mozaic.movie_mozaic figure {
          height: 39vw !important;
          width: 39vw !important; } }

.list-title-product-cart, #profile_container .list-title-product-cart {
  align-items: center;
  display: flex;
  justify-content: flex-start; }
  @media (max-width: 767px) {
    .list-title-product-cart, #profile_container .list-title-product-cart {
      align-items: inherit;
      flex-direction: column;
      justify-content: center; } }
  .list-title-product-cart .list-title-product-custom-select, #profile_container .list-title-product-cart .list-title-product-custom-select {
    margin-bottom: 13px;
    margin-right: 13px;
    width: 150px; }
    @media (max-width: 767px) {
      .list-title-product-cart .list-title-product-custom-select, #profile_container .list-title-product-cart .list-title-product-custom-select {
        margin-right: 0; } }
    .list-title-product-cart .list-title-product-custom-select:hover, #profile_container .list-title-product-cart .list-title-product-custom-select:hover {
      cursor: pointer; }
    .list-title-product-cart .list-title-product-custom-select label, #profile_container .list-title-product-cart .list-title-product-custom-select label {
      font-size: 16px; }
    .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option-box, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option-box {
      position: absolute;
      z-index: 1; }
    .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected {
      background: #00221f;
      border: 1px solid #aeaeae;
      border-radius: 2px;
      color: #aeaeae;
      font-size: 20px;
      font-weight: 100;
      padding: 10px 13px;
      width: 150px; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected:hover, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected:hover {
        cursor: pointer; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.disabled, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.disabled {
        opacity: .6; }
        .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.disabled:hover, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.disabled:hover {
          cursor: not-allowed; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.open, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-selected.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; }
    .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option {
      background: #00221f;
      border: 1px solid #aeaeae;
      border-bottom: none;
      color: #aeaeae;
      display: none;
      font-size: 20px;
      font-weight: 100;
      padding: 10px 13px;
      width: 150px; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option:first-child, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option:first-child {
        border-top: 4px solid #aeaeae; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option:last-child, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option:last-child {
        border-bottom: 1px solid #aeaeae;
        border-radius: 2px; }
      .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option.show, #profile_container .list-title-product-cart .list-title-product-custom-select .list-title-product-select-option.show {
        display: block; }
  .list-title-product-cart .list-title-product-quantity-box, #profile_container .list-title-product-cart .list-title-product-quantity-box {
    margin-bottom: 13px;
    margin-right: 13px;
    width: 150px; }
    @media (max-width: 767px) {
      .list-title-product-cart .list-title-product-quantity-box, #profile_container .list-title-product-cart .list-title-product-quantity-box {
        margin-right: 0; } }
    .list-title-product-cart .list-title-product-quantity-box label, #profile_container .list-title-product-cart .list-title-product-quantity-box label {
      font-size: 16px; }
    .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input {
      display: flex; }
      .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input {
        background: #00221f;
        border: 1px solid #aeaeae;
        border-radius: 2px;
        color: #aeaeae;
        font-size: 20px;
        font-weight: 100;
        padding: 10px 0;
        text-align: center;
        width: 60px;
        pointer-events: none; }
        .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input.disabled, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input.disabled {
          opacity: .6; }
          .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input.disabled:hover, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input.disabled:hover {
            cursor: not-allowed; }
      .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input::-webkit-outer-spin-button, .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input::-webkit-inner-spin-button, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input::-webkit-outer-spin-button, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus, .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus {
        background: #00221f;
        border: 1px solid #aeaeae;
        border-radius: 2px;
        color: #aeaeae;
        font-size: 20px;
        font-weight: 700;
        padding: 10px 0;
        text-align: center;
        width: 42px;
        -webkit-user-select: none;
        user-select: none; }
        .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus:hover, .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus:hover, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus:hover, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus:hover {
          cursor: pointer; }
      .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-minus {
        margin-right: 3px; }
      .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus, #profile_container .list-title-product-cart .list-title-product-quantity-box .list-title-product-quantity-input .list-title-product-quantity-input-plus {
        margin-left: 3px; }
  .list-title-product-cart .list-title-product-btn, #profile_container .list-title-product-cart .list-title-product-btn {
    margin-left: 13px;
    margin-top: 20px; }
    @media (max-width: 767px) {
      .list-title-product-cart .list-title-product-btn, #profile_container .list-title-product-cart .list-title-product-btn {
        margin-left: 0; } }
    .list-title-product-cart .list-title-product-btn button, #profile_container .list-title-product-cart .list-title-product-btn button {
      background: #fba00b;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-weight: 100;
      height: 52px;
      width: 150px; }
      .list-title-product-cart .list-title-product-btn button:hover, #profile_container .list-title-product-cart .list-title-product-btn button:hover {
        cursor: pointer; }

.list-title-product-cart-message {
  font-weight: 700;
  height: 30px; }
  @media (max-width: 767px) {
    .list-title-product-cart-message {
      margin: 13px 0;
      text-align: center; } }
  .list-title-product-cart-message .list-title-product-cart-message-error {
    color: #E33939; }
  .list-title-product-cart-message .list-title-product-cart-message-success {
    color: #54C254; }

#nav_cart-web {
  background: #fba00b;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: 100;
  padding: 3px;
  text-align: center;
  transition: all .5s;
  width: 60px; }
  @media (min-width: 1025px) {
    #nav_cart-web:hover {
      cursor: pointer;
      -webkit-transform: scale(1.1);
              transform: scale(1.1); } }
  #nav_cart-web .dot-cart {
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    height: 25px;
    padding-top: 1px;
    position: absolute;
    right: -14px;
    top: 14px;
    width: 25px; }

#nav_cart-mobile {
  background: #fba00b;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 100;
  margin-top: 5px;
  padding: 3px;
  text-align: center;
  width: 60px; }
  #nav_cart-mobile .dot-cart {
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    height: 25px;
    padding-top: 1px;
    position: absolute;
    right: 10px;
    top: 36px;
    width: 25px; }

.cart_product_delivery {
  margin: 23px 0 !important; }
  .cart_product_delivery a {
    color: #fba00b;
    cursor: pointer;
    display: inline-block;
    font-weight: 700; }

.cart_product_details .cart_product_image {
  height: 100px;
  width: 100px; }
  .cart_product_details .cart_product_image img {
    height: 100%;
    width: 100%; }

.cart_product_details .cart_product_title {
  font-size: 1.5rem;
  font-weight: 700; }

.cart_product_title a {
  color: #fba00b;
  cursor: pointer;
  font-weight: 700;
  margin-left: 3px; }

.cart_product_price {
  color: #fba00b;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 7px 0; }
  .cart_product_price.tall {
    font-size: 2.5rem; }
  .cart_product_price .cart_product_price_old {
    color: #aeaeae;
    display: inline-block;
    font-size: 2rem;
    margin: 0 7px;
    text-decoration: line-through; }
  .cart_product_price .cart_product_price_initial {
    display: inline-block;
    margin: 0 7px; }

.cart_product_remove {
  color: #E33939;
  font-weight: 700; }
  .cart_product_remove:hover {
    cursor: pointer; }

.cart_valid_btn_box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 720px; }
  .cart_valid_btn_box.cart_btn_top {
    margin-bottom: 26px; }
  @media (max-width: 1024px) {
    .cart_valid_btn_box {
      width: 80vw;
      padding: 0; }
      .cart_valid_btn_box .cart_valid_btn:first-child {
        margin-top: 26px; }
      .cart_valid_btn_box.cart_btn_top .cart_valid_btn:first-child {
        margin-top: 0; } }
  @media (max-width: 767px) {
    .cart_valid_btn_box {
      flex-direction: column-reverse; } }

.cart_valid_btn {
  background: #fba00b;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
  width: 200px; }
  .cart_valid_btn:hover {
    cursor: pointer; }

.cart_comment_btn_box {
  margin-top: 13px;
  width: 100%; }
  @media (max-width: 767px) {
    .cart_comment_btn_box {
      margin: 13px auto 0; } }

.cart_comment_btn, a.cart_comment_btn {
  background: #fba00b;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 100;
  padding: 5px;
  text-align: center;
  width: 200px; }
  .cart_comment_btn:hover, a.cart_comment_btn:hover {
    cursor: pointer; }

.address_title {
  margin-top: 42px; }

.address_checkbox_container {
  display: flex;
  padding-bottom: 13px;
  width: 400px; }
  .address_checkbox_container .address_checkbox {
    position: relative; }
    .address_checkbox_container .address_checkbox input[type=checkbox] {
      width: 0;
      display: none; }

.address_checkbox_container {
  display: flex;
  padding: 26px 0;
  width: 400px; }
  @media (max-width: 767px) {
    .address_checkbox_container {
      width: 80vw; } }
  .address_checkbox_container .address_checkbox {
    position: relative; }
    .address_checkbox_container .address_checkbox input[type=checkbox] {
      width: 0;
      display: none; }
    .address_checkbox_container .address_checkbox input[type=checkbox]:checked + span:after {
      opacity: 1; }
    .address_checkbox_container .address_checkbox input[type=checkbox]:checked + span {
      background: #fba00b;
      border: none; }
      .address_checkbox_container .address_checkbox input[type=checkbox]:checked + span:hover {
        opacity: 1; }
    .address_checkbox_container .address_checkbox span {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 4px;
      border-radius: 4px;
      background: transparent;
      border: 1px solid white; }
      .address_checkbox_container .address_checkbox span:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 10px;
        height: 7px;
        top: 5px;
        left: 5px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
      @media (min-width: 1025px) {
        .address_checkbox_container .address_checkbox span:hover {
          opacity: 0.6; } }
  .address_checkbox_container .address_checkbox_label {
    padding-left: 27px;
    padding-top: 2px; }

.inputs_disabled .profile_edit_usual_input {
  opacity: .6; }
  .inputs_disabled .profile_edit_usual_input:hover {
    cursor: not-allowed; }
  .inputs_disabled .profile_edit_usual_input input {
    pointer-events: none; }

.menu_stripe {
  justify-content: center; }
  .menu_stripe .local_stripe_info_text {
    margin-bottom: 13px;
    text-align: center; }

.product-go-back {
  color: #aeaeae;
  cursor: pointer;
  left: 50px;
  padding: 13px 0;
  position: absolute;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  top: 7px;
  z-index: 2; }
  .product-go-back .icon_box {
    margin-right: 13px;
    width: inherit !important; }
    .product-go-back .icon_box img {
      vertical-align: text-top; }
  .product-go-back:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }

.product_code_form {
  align-items: center;
  display: flex;
  justify-content: center; }
  @media (max-width: 767px) {
    .product_code_form {
      flex-direction: column; } }
  .product_code_form .product_code_form_valid {
    background: #3b3b3b;
    border: 1px solid #3b3b3b;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 100;
    height: 50px;
    margin-left: 7px;
    padding: 9px 7px;
    text-align: center;
    width: 100px; }
    @media (max-width: 767px) {
      .product_code_form .product_code_form_valid {
        margin-left: 0;
        margin-top: 13px; } }
    .product_code_form .product_code_form_valid:hover {
      background: #404040; }
  .product_code_form .product_code_form_remove {
    background: #626262;
    border: 1px solid #626262;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: 100;
    margin-left: 7px;
    padding: 9px 7px;
    text-align: center;
    width: 100px; }
    @media (max-width: 767px) {
      .product_code_form .product_code_form_remove {
        margin-left: 0;
        margin-top: 13px; } }
    .product_code_form .product_code_form_remove:hover {
      background: #6c6c6c; }

.product_code_form_results {
  font-weight: 700;
  min-height: 35px;
  padding-top: 7px; }
  .product_code_form_results .product_code_form_error {
    color: #E33939; }
  .product_code_form_results .product_code_form_success {
    color: #54C254; }

.menu_priceTotal {
  border-bottom: 6px solid #FFFFFF1A !important; }

.cart_product_discount_box {
  color: #fff; }
  .cart_product_discount_box span {
    background-color: #fba00b;
    border-radius: 5px;
    display: inline-block;
    font-weight: 700;
    margin-left: 7px;
    padding: 7px 17px;
    text-align: center; }

.cart_product_defualt_discount_box {
  background-color: #fba00b;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-left: 7px;
  padding: 7px 17px;
  text-align: center; }

.product_popup_img {
  margin-top: 80px;
  cursor: initial;
  object-fit: cover;
  width: 960px; }
  @media (max-width: 1200px) {
    .product_popup_img {
      width: 800px; } }
  @media (max-width: 1024px) {
    .product_popup_img {
      width: 80vw; } }

.comment_box {
  margin-bottom: 0 !important; }

.comment_star_rating_box {
  color: #fba00b;
  display: flex;
  font-size: 2rem;
  margin-top: 26px; }
  .comment_star_rating_box .comment_star_box {
    cursor: pointer;
    margin: 0 7px; }

.comment-box-title {
  font-size: 2rem;
  margin: 26px 0; }

.comment-box-container {
  width: 100%; }

.comment-box {
  background-color: #ffffff11;
  border-radius: 5px;
  margin: 13px 0;
  padding: 13px 0;
  width: 100%; }
  .comment-box .comment-user-box {
    display: flex;
    margin-bottom: 13px; }
    .comment-box .comment-user-box .comment-avatar-box {
      text-align: center;
      width: 60px; }
    .comment-box .comment-user-box .comment-avatar {
      border-radius: 50%;
      height: 40px;
      width: 40px; }
    .comment-box .comment-user-box .comment-pseudo {
      padding-top: 10px; }
    .comment-box .comment-user-box .comment-date {
      color: #aeaeae;
      font-style: italic;
      margin-left: 7px;
      padding-top: 10px; }
  .comment-box .comment-text {
    margin-left: 60px; }

.profile_edit_usual_message {
  height: 30px; }
  .profile_edit_usual_message .profile_edit_usual_error, .profile_edit_usual_message .profile_edit_usual_success {
    height: initial !important; }

.profile_edit_usual_btn.comment_box {
  margin-bottom: 30px;
  padding: 7px; }
  @media (max-width: 767px) {
    .profile_edit_usual_btn.comment_box {
      width: 70vw; } }

.grid {
  position: relative;
  clear: both;
  margin: 0 auto;
  /*	padding: 0 0 1em; */
  padding: 0 0 5px;
  /*	max-width: 1000px; */
  list-style: none;
  text-align: center; }

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  /*
	margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%;
	*/
  height: auto;
  text-align: center;
  cursor: pointer; }

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  /* opacity: 0.8; */ }

.grid figure figcaption {
  /*	padding: 2em; */
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* padding:5px; */ }

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none; }

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0; }

.grid figure h1 {
  position: absolute;
  top: 50%;
  width: 100%; }

.grid figure h1.search-tile-title span {
  display: inline-block;
  min-height: 80px;
  text-align: center;
  width: 100%; }

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300; }

.grid figure h2 span {
  font-weight: 800; }

.grid figure h2,
.grid figure p {
  margin: 0; }

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%; }

/* Individual effects */
/*---------------*/
/***** Julia *****/
/*---------------*/
figure.effect-julia {
  background: #2f3238; }

figure.effect-julia img {
  max-width: none;
  height: 400px;
  transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  transition: opacity 1s, transform 1s, -webkit-transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-julia figcaption {
  text-align: left; }

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0; }

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255, 255, 255, 0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0); }

figure.effect-julia p:first-child {
  transition-delay: 0.15s; }

figure.effect-julia p:nth-of-type(2) {
  transition-delay: 0.1s; }

figure.effect-julia p:nth-of-type(3) {
  transition-delay: 0.05s; }

figure.effect-julia:hover p:first-child {
  transition-delay: 0s; }

figure.effect-julia:hover p:nth-of-type(2) {
  transition-delay: 0.05s; }

figure.effect-julia:hover p:nth-of-type(3) {
  transition-delay: 0.1s; }

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1); }

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Goliath *****/
/*-----------------*/
figure.effect-goliath {
  background: #df4e4e; }

figure.effect-goliath img,
figure.effect-goliath h2 {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s; }

figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-goliath h2,
figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px; }

figure.effect-goliath p {
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

figure.effect-goliath:hover img {
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0); }

figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0); }

figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Hera *****/
/*-----------------*/
figure.effect-hera {
  background: #303fa9; }

figure.effect-hera h2 {
  font-size: 158.75%; }

figure.effect-hera h2,
figure.effect-hera p {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%; }

figure.effect-hera figcaption::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  content: '';
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%; }

figure.effect-hera p {
  width: 100px;
  text-transform: none;
  font-size: 121%;
  line-height: 2; }

figure.effect-hera p a {
  color: #fff; }

figure.effect-hera p a:hover,
figure.effect-hera p a:focus {
  opacity: 0.6; }

figure.effect-hera p a i {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-hera p a:first-child i {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0); }

figure.effect-hera p a:nth-child(2) i {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0); }

figure.effect-hera p a:nth-child(3) i {
  -webkit-transform: translate3d(-60px, 60px, 0);
  transform: translate3d(-60px, 60px, 0); }

figure.effect-hera p a:nth-child(4) i {
  -webkit-transform: translate3d(60px, 60px, 0);
  transform: translate3d(60px, 60px, 0); }

figure.effect-hera:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1); }

figure.effect-hera:hover h2 {
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1); }

figure.effect-hera:hover p i:empty {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* just because it's stronger than nth-child */
  opacity: 1; }

/*-----------------*/
/***** Winston *****/
/*-----------------*/
figure.effect-winston {
  background: #162633;
  text-align: left; }

figure.effect-winston img {
  transition: opacity 0.45s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-winston figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  content: '';
  transition: opacity 0.45s, -webkit-transform 0.45s;
  transition: opacity 0.45s, transform 0.45s;
  transition: opacity 0.45s, transform 0.45s, -webkit-transform 0.45s;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

figure.effect-winston h2 {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-winston p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 7% 0; }

figure.effect-winston a {
  margin: 0 10px;
  color: #5d504f;
  font-size: 170%; }

figure.effect-winston a:hover,
figure.effect-winston a:focus {
  color: #cc6055; }

figure.effect-winston p a i {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }

figure.effect-winston:hover img {
  opacity: 0.6; }

figure.effect-winston:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-winston:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: rotate3d(0, 0, 1, 20deg);
  transform: rotate3d(0, 0, 1, 20deg); }

figure.effect-winston:hover p i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-winston:hover p a:nth-child(3) i {
  transition-delay: 0.05s; }

figure.effect-winston:hover p a:nth-child(2) i {
  transition-delay: 0.1s; }

figure.effect-winston:hover p a:first-child i {
  transition-delay: 0.15s; }

/*-----------------*/
/***** Selena *****/
/*-----------------*/
figure.effect-selena {
  background: #fff; }

figure.effect-selena img {
  opacity: 0.95;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-selena:hover img {
  -webkit-transform: scale3d(0.95, 0.95, 1);
  transform: scale3d(0.95, 0.95, 1); }

figure.effect-selena h2 {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0); }

figure.effect-selena p {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%; }

figure.effect-selena:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-selena:hover p {
  opacity: 1;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0); }

/*-----------------*/
/***** Terry *****/
/*-----------------*/
figure.effect-terry {
  background: #34495e; }

figure.effect-terry figcaption {
  padding: 1em; }

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #101010;
  content: '';
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s; }

figure.effect-terry figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 70px 60px 0;
  -webkit-transform: translate3d(70px, 60px, 0);
  transform: translate3d(70px, 60px, 0); }

figure.effect-terry figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  -webkit-transform: translate3d(-15px, -15px, 0);
  transform: translate3d(-15px, -15px, 0); }

figure.effect-terry img,
figure.effect-terry p a {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-terry img {
  opacity: 0.85; }

figure.effect-terry h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

@media screen and (max-width: 920px) {
  figure.effect-terry h2 {
    padding: 0.75em 10px;
    font-size: 120%; } }

figure.effect-terry p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 111%; }

figure.effect-terry p a {
  display: block;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0;
  -webkit-transform: translate3d(90px, 0, 0);
  transform: translate3d(90px, 0, 0); }

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
  color: #f3cf3f; }

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-terry:hover img {
  opacity: 0.6; }

figure.effect-terry:hover h2,
figure.effect-terry:hover p a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-terry:hover p a {
  opacity: 1; }

figure.effect-terry:hover p a:first-child {
  transition-delay: 0.025s; }

figure.effect-terry:hover p a:nth-child(2) {
  transition-delay: 0.05s; }

figure.effect-terry:hover p a:nth-child(3) {
  transition-delay: 0.075s; }

figure.effect-terry:hover p a:nth-child(4) {
  transition-delay: 0.1s; }

/*-----------------*/
/***** Phoebe *****/
/*-----------------*/
figure.effect-phoebe {
  background: #675983; }

figure.effect-phoebe img {
  opacity: 0.85;
  transition: opacity 0.35s; }

figure.effect-phoebe:hover img {
  opacity: 0.6; }

figure.effect-phoebe figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  content: '';
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale3d(5, 2.5, 1);
  transform: scale3d(5, 2.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-phoebe:hover figcaption::before {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-phoebe h2 {
  margin-top: 1em;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0); }

figure.effect-phoebe:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-phoebe p a {
  color: #fff;
  font-size: 140%;
  opacity: 0;
  position: relative;
  display: inline-block;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-phoebe p a:first-child {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0); }

figure.effect-phoebe p a:nth-child(2) {
  -webkit-transform: translate3d(0, 60px, 0);
  transform: translate3d(0, 60px, 0); }

figure.effect-phoebe p a:nth-child(3) {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0); }

figure.effect-phoebe:hover p a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*-----------------*/
/***** Apollo *****/
/*-----------------*/
figure.effect-apollo {
  background: #3498db; }

figure.effect-apollo img {
  opacity: 0.95;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1); }

figure.effect-apollo figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0); }

figure.effect-apollo p {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3em;
  padding: 0 1em;
  max-width: 150px;
  border-right: 4px solid #fff;
  text-align: right;
  opacity: 0;
  transition: opacity 0.35s; }

figure.effect-apollo h2 {
  text-align: left; }

figure.effect-apollo:hover img {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-apollo:hover figcaption::before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0); }

figure.effect-apollo:hover p {
  opacity: 1;
  transition-delay: 0.1s; }

/*-----------------*/
/***** Kira *****/
/*-----------------*/
figure.effect-kira {
  background: #fff;
  text-align: left; }

figure.effect-kira img {
  transition: opacity 0.35s; }

figure.effect-kira figcaption {
  z-index: 1; }

figure.effect-kira p {
  padding: 2.25em 0.5em;
  font-weight: 600;
  font-size: 100%;
  line-height: 1.5;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

figure.effect-kira p a {
  margin: 0 0.5em;
  color: #101010; }

figure.effect-kira p a:hover,
figure.effect-kira p a:focus {
  opacity: 0.6; }

figure.effect-kira figcaption::before {
  position: absolute;
  top: 0;
  right: 2em;
  left: 2em;
  z-index: -1;
  height: 3.5em;
  background: #fff;
  content: '';
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0; }

figure.effect-kira:hover img {
  opacity: 0.5; }

figure.effect-kira:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-kira:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 5em, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 5em, 0) scale3d(1, 1, 1); }

/*-----------------*/
/***** Steve *****/
/*-----------------*/
figure.effect-steve {
  z-index: auto;
  overflow: visible;
  background: #000; }

figure.effect-steve:before,
figure.effect-steve h2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  transition: opacity 0.35s; }

figure.effect-steve:before {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
  opacity: 0; }

figure.effect-steve figcaption {
  z-index: 1; }

figure.effect-steve img {
  opacity: 1;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: perspective(1000px) translate3d(0, 0, 0);
  transform: perspective(1000px) translate3d(0, 0, 0); }

figure.effect-steve h2,
figure.effect-steve p {
  background: #fff;
  color: #2d434e; }

figure.effect-steve h2 {
  position: relative;
  margin-top: 2em;
  padding: 0.25em; }

figure.effect-steve h2:before {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5); }

figure.effect-steve p {
  margin-top: 1em;
  padding: 0.5em;
  font-weight: 800;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1); }

figure.effect-steve:hover:before {
  opacity: 1; }

figure.effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px); }

figure.effect-steve:hover h2:before {
  opacity: 0; }

figure.effect-steve:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/*-----------------*/
/***** Moses *****/
/*-----------------*/
figure.effect-moses {
  background: linear-gradient(-45deg, #EC65B7 0%, #05E0D8 100%); }

figure.effect-moses img {
  opacity: 0.85;
  transition: opacity 0.35s; }

figure.effect-moses h2,
figure.effect-moses p {
  padding: 20px;
  width: 50%;
  height: 50%;
  border: 2px solid #fff; }

figure.effect-moses h2 {
  padding: 20px;
  width: 50%;
  height: 50%;
  text-align: left;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0); }

figure.effect-moses p {
  float: right;
  padding: 20px;
  text-align: right;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

figure.effect-moses:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-moses:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-moses:hover img {
  opacity: 0.6; }

/*---------------*/
/***** Jazz *****/
/*---------------*/
figure.effect-jazz {
  background: linear-gradient(-45deg, #f3cf3f 0%, #f33f58 100%); }

figure.effect-jazz img {
  opacity: 0.9; }

figure.effect-jazz figcaption::after,
figure.effect-jazz img,
figure.effect-jazz p {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-jazz figcaption::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-jazz h2,
figure.effect-jazz p {
  opacity: 1;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1); }

figure.effect-jazz h2 {
  padding-top: 26%;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s; }

figure.effect-jazz p {
  padding: 0.5em 2em;
  text-transform: none;
  font-size: 0.85em;
  opacity: 0; }

figure.effect-jazz:hover img {
  opacity: 0.7;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1); }

figure.effect-jazz:hover figcaption::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1); }

figure.effect-jazz:hover h2,
figure.effect-jazz:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/*---------------*/
/***** Ming *****/
/*---------------*/
figure.effect-ming {
  background: #030c17; }

figure.effect-ming img {
  opacity: 0.9;
  transition: opacity 0.35s; }

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1); }

figure.effect-ming h2 {
  margin: 20% 0 10px 0;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s; }

figure.effect-ming p {
  padding: 1em;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5); }

figure.effect-ming:hover h2 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9); }

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-ming:hover figcaption {
  background-color: rgba(58, 52, 42, 0); }

figure.effect-ming:hover img {
  opacity: 0.4; }

/*---------------*/
/***** Lexi *****/
/*---------------*/
figure.effect-lexi {
  background: linear-gradient(-45deg, #000 0%, #fff 100%); }

figure.effect-lexi img {
  margin: -10px 0 0 -10px;
  max-width: none;
  width: calc(100% + 10px);
  opacity: 0.9;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

figure.effect-lexi figcaption::before,
figure.effect-lexi p {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-lexi figcaption::before {
  position: absolute;
  right: -100px;
  bottom: -100px;
  width: 300px;
  height: 300px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 900px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 1);
  transform: scale3d(0.5, 0.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

figure.effect-lexi:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

figure.effect-lexi h2 {
  text-align: left;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(5px, 5px, 0);
  transform: translate3d(5px, 5px, 0); }

figure.effect-lexi p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 1.5em 0;
  width: 140px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(20px, 20px, 0);
  transform: translate3d(20px, 20px, 0); }

figure.effect-lexi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

figure.effect-lexi:hover h2,
figure.effect-lexi:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Duke *****/
/*---------------*/
.effect-duke-chasse {
  /*
	background: -webkit-linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
	background: linear-gradient(-45deg, #34495e 0%,#cc6055 100%);
	*/
  background: #7D8877 !important; }

.effect-duke-peche-sous-marine {
  background: #6C9DBE !important; }

figure.effect-duke img,
figure.effect-duke p {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-duke:hover img {
  opacity: 0.1;
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1); }

figure.effect-duke h2,
figure.effect-duke h1 {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  width: 100%; }

figure.effect-duke p span {
  font-size: 1.2em;
  text-transform: uppercase; }

figure.effect-duke p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  width: 100%;
  /*
	margin: 20px;
	padding: 30px;
	border: 2px solid #fff;
	*/
  text-transform: none;
  font-size: 1vw;
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%; }

figure.effect-duke:hover h1 {
  opacity: 0; }

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

/* Media queries */
@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px;
    text-align: center; }
  .grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto; } }

figure.effect-thumbnail-movie {
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 10px;
  box-shadow: 10px 20px 15px -10px #000000BF;
  margin: 10px 0;
  transition: all .5s; }

.movie_mozaic figure.effect-thumbnail-movie {
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 10px;
  box-shadow: 10px 20px 15px -10px #000000BF;
  margin: 7px;
  transition: all .5s; }

@media (max-width: 1024px) {
  figure.effect-thumbnail-movie {
    border: none;
    border-radius: 2px;
    margin: 10px 0; }
  .movie_mozaic figure.effect-thumbnail-movie {
    border-radius: 2px;
    margin: 7px; } }

figure.effect-thumbnail-movie:hover {
  border: 3px solid #FBA00B;
  /* margin: 0; */
  -webkit-transform: translate(-7px, -7px);
          transform: translate(-7px, -7px); }

@media (max-width: 1024px) {
  figure.effect-thumbnail-movie:hover {
    border: none;
    -webkit-transform: none;
            transform: none; } }

/* figure.effect-thumbnail-movie:hover + figure.effect-thumbnail-movie {
	margin-left: 21px;
} */
figure.effect-thumbnail-movie:hover .movie_progress_play {
  opacity: 1; }

.carousel-img:hover .movie_progress_play {
  opacity: 1; }

/* figure.effect-thumbnail-movie img { transition: all .5s; border-radius: 5px; }
figure.effect-thumbnail-movie:hover img { transform: scale(1.2); } */
.blockingMask {
  align-items: flex-start;
  color: #fff;
  display: flex;
  height: 100%;
  font-size: 24px;
  justify-content: flex-end;
  left: 0;
  cursor: initial;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4; }
  .blockingMask i {
    -webkit-filter: drop-shadow(0 0 10px #000);
            filter: drop-shadow(0 0 10px #000);
    margin: 13px; }

/* Global */
* {
  /*	
      margin: 0;
      bottom: 0;
      color: #fff;
      list-style: none;
      font-family: "Roboto", sans-serif;
    */
  box-sizing: border-box; }

#root {
  min-height: 100vh; }

#background {
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: -1; }
  #background img {
    height: 100%;
    width: 100%; }

body {
  width: 100%; }

.orange {
  color: #fba00b; }

.btnBoxVideoDetail-sm {
  text-shadow: -1px 0 #fba00b, 0 1px #fba00b, 1px 0 #fba00b, 0 -1px #fba00b;
  position: absolute;
  left: 13px;
  top: 13px;
  z-index: 1; }

.btnBoxVideoDetail {
  text-shadow: -1px 0 #fba00b, 0 1px #fba00b, 1px 0 #fba00b, 0 -1px #fba00b;
  position: absolute;
  left: 35%;
  top: 50px;
  z-index: 1; }

.btnBoxVideoDetail:hover {
  cursor: pointer; }

.infoChasse {
  position: relative;
  color: #fff; }

.infoChasse {
  height: 30vw; }

.infoChasse .background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  transition: opacity 0.3s cubic-bezier(0.5, 0, 0.1, 1) 0s; }

.z3p {
  position: absolute;
  z-index: 2;
  width: 70%;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto; }

.infoChasse .close-button {
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.3vw;
  padding: 20px 20px 40px 40px;
  z-index: 12;
  cursor: pointer;
  color: #fff;
  -webkit-transform-origin: 60% 35%;
  transform-origin: 60% 35%;
  background: radial-gradient(ellipse at top right, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
  background-position: 50% -50%; }

.textInfo {
  position: absolute;
  padding: 20px;
  z-index: 10;
  max-width: 30%;
  height: 100%; }

.textInfo .btn, .textInfo .profile_btn_return, .textInfo .profile_btn_continuer, .textInfo .profile_btn_unsub, .textInfo .profile_btn_save {
  position: absolute;
  bottom: 20px; }

.textInfo .btnplayer, .textInfoMobile .btnplayer, .text .btnplayer {
  background-color: #fff; }

.textInfo .btnplayer:hover, .textInfoMobile .btnplayer:hover, .text .btnplayer:hover {
  background-color: #fba00b;
  color: #fff; }

.legendGB .btnplayer:hover {
  background-color: #fba00b;
  color: #fff; }

.carousel-caption .btnplayer:hover {
  background-color: #fba00b;
  color: #fff; }

.legendGB .icnBtn {
  font-size: 2em; }

.textInfo .icnBtn {
  font-size: 2em; }

.z3p-img {
  position: absolute;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.vignette {
  background: #000;
  z-index: 2;
  width: 30%;
  position: absolute;
  top: 0;
  bottom: 0; }

.vignette::before {
  content: '';
  position: absolute;
  z-index: 10;
  background-image: linear-gradient(to right, #000, transparent);
  top: 0;
  bottom: 0;
  left: 100%;
  width: 275px; }

.z3Player {
  background-color: #000; }

.btnContaint {
  display: flex;
  justify-content: center;
  bottom: 5px; }

.pInner {
  position: relative;
  display: inline-block;
  text-align: justify;
  max-height: 11em;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: "ellipsis"; }

@media screen and (max-width: 1024px) {
  .infoChasse .close-button {
    font-size: 3vw; }
  .textInfo {
    padding-top: 40px;
    max-width: 80%;
    font-size: 1em; }
  .infoChasse {
    height: 50vw; } }

@media screen and (max-width: 500px) {
  .infoChasse {
    /*height: 70vw;*/
    height: 350px !important; }
  .infoChasse .close-button {
    font-size: 4vw; }
  .textInfo {
    padding-top: 40px;
    max-width: 100%;
    font-size: 1em; }
  /*
        .infoChasse {
            height: 80vw;
        }
        */
  .textInfo .btn, .textInfo .profile_btn_return, .textInfo .profile_btn_continuer, .textInfo .profile_btn_unsub, .textInfo .profile_btn_save {
    bottom: 5px; }
  .btn, .profile_btn_return, .profile_btn_continuer, .profile_btn_unsub, .profile_btn_save {
    padding: 2px 5px; } }

.descMovie {
  padding-top: 9px; }

.img-pres-mobile {
  position: absolute;
  top: 0px;
  left: 0px; }

.vignette2:before {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(transparent 10%, #000 45%);
  content: ''; }

.textInfoMobile {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 5px 10px; }

.btnContaintMobile {
  padding-top: 10px; }

.descMovieMobile {
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-bottom: 10px solid transparent; }

@media (min-width: 1025px) {
  #favorite_Homelist {
    margin: 0 auto;
    padding: 0 0 0 50px; } }

@media (max-width: 1024px) {
  #favorite_Homelist {
    margin: 0 auto; } }

