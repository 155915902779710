// ViewSearch
@media ( min-width: 1025px ) {
    #result-container1 {
        margin: 0 0 0 50px !important;
    }   
}
  
@media ( max-width: 1024px ) {
    #result-container1 {
        margin: 25px 13px 150px 13px !important;
    }
}

.SearchPage-container {
    #nav-container-mobile #nav-container-mobile-up #nav-container-mobile-up-btns {
        @media ( max-width: 1024px ) { display: none; }
    }

    .GBListItem { top: 30px; }
    .GBListItem {
        @media ( max-width: 1024px ) { top: 180px; }

        @media ( max-width: 1024px ) { &.withSSR { margin-top: 160px; } }
        @media ( max-width: 767px ) { &.withSSR.circleDisplay { margin-top: 0; top: 80px; } }
    }

    &.movie-page {
      .search-top { height: 0 !important; }
      #result-container1.catAcc_result { margin-top: 0 !important; }
    }
}

#search_input {
    background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #696969;
    border-bottom-left-radius: 5px;  
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;  
    border-top-right-radius: 5px;
    color: #fff;
    font-size: 40px;
    padding: 19px 26px;
    // text-transform: uppercase;
    width: 40vw;

    &.with-proposals { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

    @media ( max-width: 767px ) {
        font-size: 1.8rem;
        width: 80vw;
    }

    &:focus { outline: none; }
}

#search_btn {
    align-items: center;
    background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #696969;
    border-radius: 5px;
    color: #C1C1C1;
    display: flex;
    font-size: 20px;
    @media ( max-width: 767px ) { font-size: 16px; }
    justify-content: center;
    height: 50px;
    margin: 20px 0 50px 0;
    width: 20vw;
    @media ( max-width: 767px ) { width: 40vw; }

    &:hover { cursor: pointer; }
}

// search
/* tiles style */

@include listingTiles;

.search-tile {
  position: relative;
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  padding: 5px;
  overflow: hidden;
  width: $tileWidth * 1.1;
  height: $tileHeight * 1.1;
  transition: 0.3s transform;
  transform-origin: 50% 50%;
  &:hover { transform: scale(1.5); }
  .tile-img {
    object-fit: cover;
    width: $tileWidth * 1.1;
    height: $tileHeight * 1.1;
  }
}

.search-tile-desc {
  font-size: 0.65em;
  line-height: 1.3;
  margin: 0 0;
}

.search-playbtn-container {
  margin: 45px 0px 10px 0px;
  text-align: center;
  @include playBtn;
}

/* grid layout */

#result-container {
  margin-top: 4%;
  li { @include grid(4, 5%); }
}

/* MQ */

@include maxMediaQueries(1840px) {
  .search-tile {
    padding: 2px;
    width: $tileWidth;
    height: $tileHeight;
  }
  .search-playbtn-container { margin: 35px 0px 5px 0px; }
}

@include maxMediaQueries(1590px) {
  .search-tile {
    width: $tileWidth * 0.85;
    height: $tileHeight * 0.85;
  }
  .search-playbtn-container {
    margin: 15px 0px 5px 0px;
  }
  .search-tile-title {
    font-size: 0.8em;
  }
  .search-tile-desc {
    font-size: 0.6em;
  }
}

@include maxMediaQueries(1370px) {
  .search-tile {
    &:hover {
      transform: scale(1.4);
    }
  }
  .search-playbtn-container { margin: 10px 0px 5px 0px;
  }
  .search-tile-title {
    font-size: 0.8em;
  }
  .search-tile-desc {
    font-size: 0.6em;
  }
}

@include maxMediaQueries(1175px) {
  #result-container {
    li {
      @include grid(2, 4.5%);
    }
  }
  .search-tile {
    width: $tileWidth * 1.1;
    height: $tileHeight * 1.1;
    &:hover {
      transform: scale(1.5);
    }
  }
  .search-playbtn-container {
    margin: 30px 0px 5px 0px;
  }
  .search-tile-title {
    font-size: 1em;
  }
  .search-tile-desc {
    font-size: 0.8em;
  }
}

@include maxMediaQueries(880px) {
  .search-playbtn-container {
    margin: 15px 0px 5px 0px;
  }
  .search-tile {
    width: $tileWidth * 0.88;
    height: $tileHeight * 0.88;
  }
  .search-tile-title {
    font-size: 0.8em;
  }
  .search-tile-desc {
    font-size: 0.65em;
  }
}

@include maxMediaQueries(760px) {
  #result-container {
    li {
      @include grid(1, 12%);
    }
  }
  .search-playbtn-container {
    margin: 45px 0px 5px 0px;
  }
  .search-tile {
    width: $tileWidth * 1.2;
    height: $tileHeight * 1.2;
  }
  .search-tile-title {
    font-size: 1em;
  }
  .search-tile-desc {
    font-size: 0.8em;
  }
}

@include maxMediaQueries(665px) {
  .search-tile {
    width: $tileWidth;
    height: $tileHeight;
  }
  .search-tile-title {
    font-size: 0.8em;
  }
  .search-tile-desc {
    font-size: 0.65em;
  }
}

@include maxMediaQueries(545px) {
  .search-tile {
    width: $tileWidth * 0.9;
    height: $tileHeight * 0.9;
  }
  .search-playbtn-container {
    margin: 15px 0px 3px 0px;
  }
}

@include maxMediaQueries(44px) {
  .search-playbtn-container {
    margin: 10px 0px 5px 0px;
  }
  .search-tile {
    width: $tileWidth * 0.8;
    height: $tileHeight * 0.8;
  }
  .search-tile-title {
    font-size: 0.75em;
  }
  .search-tile-desc {
    font-size: 0.55em;
  }
}

.search_box {
  position: relative;
  z-index: 2;

  #search_btn_small {
    align-items: center;
    border: 1px solid #696969;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    z-index: 3;

    @media ( max-width: 767px ) { height: 78px; }

    &.with-proposals { border-bottom-right-radius: 0; }

    img {
      display: inline-block;
      height: 40px;
    }
  }

  .search_proposals_box {
    background: transparent linear-gradient(90deg, #002521 0%, #001212 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #696969;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    width: 40vw;

    @media ( max-width: 767px ) { width: 80vw; }

    display: none;
    position: absolute;
    z-index: 1;

    &.show { display: block; }

    .search_proposals_row {
      align-items: center;
      display: flex;
      padding: 7px;
      margin: 7px 0;
      
      &.selected {
        background: transparent linear-gradient(90deg, #003732 0%, #002d2d 100%) 0% 0% no-repeat padding-box;
        cursor: pointer;
      }

      .completed img {
        filter: grayscale(100%);
      }

      @media(min-width: 1025px) { 
        padding: 7px 26px;

        &:hover {
          background: transparent linear-gradient(90deg, #003732 0%, #002d2d 100%) 0% 0% no-repeat padding-box;
          cursor: pointer;

          .completed img {
            filter: grayscale(0);
          }
        }
      }

      img {
        border-radius: 5px;
        margin-right: 13px;
        width: 60px;
      }

      .search_proposals_title {
        margin: 0 13px;
        overflow: hidden;
        text-align: left;
        white-space: nowrap;
      }

      .search_proposals_type {
        color: #fba006;
        font-style: italic;
      }
    }
  }
}