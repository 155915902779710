#ils_loader {
    align-items: center;
    display: none;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;

    &.show { display: flex; }
}

.ils_popup {
    color: #fff;
    font-size: 14px;
    height: 100vh;
    left: 0;
    position: fixed;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 11;
    
    &_mask {
        background-color: #1C0A2Ab0;
        height: 100%;
        width: 100%;

        .ils_popup_info {
            height: 100vh;
            margin: auto;
            padding: 50px 0;
            position: relative;
            width: 750px;
            z-index: 1;

            @media ( max-width: 767px ) { width: 100vw; }

            .close-button {
                border: 0px;
                border-radius: 10px;
                color: #fff;
                font-size: 20px;
                position: absolute;
                padding: 24px;
                right: 0;
                top: 0;
                z-index: 12;
                background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
                background-position: 50% -50%;	

                &:hover {
                    cursor: pointer;
                }
            }

            .forgotten_password{
                transform: translateX(100%);
            }

            .redirect-button {
                display: inline-block;
                text-decoration: underline;

                &:hover { cursor: pointer; }
            }

            .horizontal-separator {
                border-bottom: 2px solid #fff;
                height: 1px;
                margin: 26px 0;
                width: 90%;
            }

            .ils_popup_close {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 13;
            }

            .ils_popup_content {
                align-items: center;
                background-color: #121212;
                border-radius: 10px;
                color: #fff;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: scroll;
                padding-bottom: 52px;
                position: relative;
                text-align: center;
                width: 100%;
                z-index: 12;

                &::-webkit-scrollbar { display: none;}
                -ms-overflow-style: none;
                scrollbar-width: none;

                .ils_popup_logo_box {
                    margin-top: 26px;
                    width: 175px;

                    .ils_popup_logo_small { height: 60px; width: 60px; }
                    .ils_popup_logo_tall { width: 175px; }
                }

                .ils_popup_product_box {
                    align-items: center;
                    display: flex;
                    font-size: 18px;
                    font-weight: 700;
                    margin-top: 100px;
                    width: 90%;
                }

                .ils_popup_product_quantity {
                    align-self: flex-start;
                    margin-left: 5%;
                }

                .ils_popup_payment_info {
                    background-color: #FBA00B;
                    border-radius: 20px;
                    padding: 5px;
                    width: 240px;

                    i { margin-right: 13px; }
                }

                .ils_popup_title {
                    margin: 40px 60px; font-size: 32px; font-weight: 700;
                    &.no_top_margin { margin-top: 0; }
                }
                .ils_popup_text {
                     margin: 13px 26px;
                     font-size: 20px;

                     .ils_popup_block_span{
                         display: block;
                     }
                }
                .ils_popup_blank_space {
                    padding: 2rem;
                }

                .ils_popup_thumbnail {
                    margin-top: 52px;
                    width: 400px;
                    @media ( max-width: 767px ) { width: 80vw; }

                    img { width: 100%; }
                }

                .ils_popup_link {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    text-decoration: underline;
                }

                .ils_popup_form {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-top: 26px;
                    width: 100%;

                    .ils_popup_form_part { margin: 7px 0; }

                    .ils_popup_checkbox_container {
                        display: flex;
                        padding-bottom: 13px;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }

                        .ils_popup_checkbox {
                            position: relative; 

                            input[type=checkbox] { width: 0; display: none; }
                            input[type=checkbox]:checked + span:after { opacity: 1; }
                            input[type=checkbox]:checked + span {
                                background: #fba00b;
                                border: none;
                                &:hover { opacity: 1; }
                            }
                            
                            span {
                                cursor: pointer;
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                top: 0;
                                border-radius: 4px;
                                background: transparent;
                                border: 1px solid white;
                    
                                &:after {
                                    opacity: 0;
                                    content: '';
                                    position: absolute;
                                    width: 10px;    
                                    height: 7px;
                                    top: 5px;
                                    left: 5px;
                                    border: 3px solid #fcfff4;
                                    border-top: none;
                                    border-right: none;
                                    transform: rotate(-45deg);
                                }
                    
                                @media( min-width: 1025px ) { &:hover { opacity: 0.6; } }
                            }
                        }

                        .ils_popup_checkbox_label { padding-left: 27px; padding-top: 2px; }
                    }

                    .ils_popup_input {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 7px 0;
                    }

                    input {
                        background: transparent;
                        border: 1px solid #fff;
                        border-radius: 5px;
                        color: #fff;
                        font-size: 20px;
                        font-weight: 100;
                        padding: 7px 26px;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }

                        &::placeholder { font-size: 14px; color: #fff; }
                    }

                    .ils_popup_radios {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        padding: 13px 3px;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }

                        &:hover { cursor: pointer; }

                        .ils_popup_radio_box {
                            position: relative;
                            text-align: end;
                            width: 100%;
                            z-index: 1;
                            
                            .radio-dot {
                                position: absolute;
                                border: 1px solid #aeaeae;
                                border-radius: 50%;
                                display: inline-block;
                                height: 16px;
                                right: 66px;
                                top: 2px;
                                width: 16px;
                            }

                            &.selected .radio-dot {
                                background-color: #fba00b; 
                                border: 2px solid #121212;
                                box-shadow: 0 0 0 1px #fba00b;
                            }
                        }
                    }

                    .ils_popup_selects {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        padding: 13px 3px;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }

                        .ils_popup_select {
                            border: 1px solid #fff;
                            border-radius: 5px;
                            margin-right: 7px;
                            padding: 7px;
                            position: relative;
                            width: 160px;
                            z-index: 2;
                            @media ( max-width: 767px ) { width: 100px; }

                            &:hover { cursor: pointer; }
                            &:last-child { margin: 0; }
                            
                            i {  
                                float: right;
                                margin-right: 7px;
                                margin-top: 3px;
                            }

                            .ils_popup_options {
                                background-color: #121212;
                                border: 1px solid #fff;
                                border-radius: 5px;
                                display: none;
                                height: 120px;
                                left: 12px;
                                overflow: scroll;
                                position: absolute;
                                text-align: center;
                                top: 36px;
                                width: 120px;
                                z-index: 1;
                                @media ( max-width: 767px ) { width: 80px; }

                                &::-webkit-scrollbar { display: none;}
                                -ms-overflow-style: none;
                                scrollbar-width: none;

                                &.opened { display: block; }

                                div {
                                    padding: 3px;

                                    &.selected { background-color: #aeaeae; }

                                    &:hover {
                                        cursor: pointer;
                                        background-color: #aeaeae;
                                    }
                                }
                            }
                        }
                    }

                    .ils_popup_cgu {
                        margin: 26px 0 26px 0;
                        padding-left: 40px;
                        text-align: start;
                        position: relative;
                        width: 400px;
                        z-index: 1;
                        @media ( max-width: 767px ) { width: 80vw; }

                        a { text-decoration: underline; }

                        .radio-dot {
                            position: absolute;
                            border: 1px solid #aeaeae;
                            border-radius: 50%;
                            display: inline-block;
                            height: 16px;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                            width: 16px;
                        }

                        &.selected .radio-dot {
                            background-color: #fba00b; 
                            border: 2px solid #121212;
                            box-shadow: 0 0 0 1px #fba00b;
                        }
                    }

                    .ils_popup_required_fields {
                        color: #aeaeae;
                        font-style: italic;
                        margin-bottom: 13px;
                        margin-top: 3px;
                        text-align: left;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }
                    }

                    .ils_popup_error {
                        color: #E33939;
                        font-weight: 700;
                        height: 30px;
                    }

                    .ils_popup_success {
                        color: #54C254;
                        font-weight: 700;
                        height: 30px;
                    }

                    .ils_popup_valid_button {
                        background-color: #fba00b;
                        border-radius: 5px;
                        font-size: 18px;
                        margin-bottom: 52px;
                        margin-top: 13px;
                        padding: 13px;
                        width: 400px;
                        @media ( max-width: 767px ) { width: 80vw; }

                        &:hover { cursor: pointer; }
                    }
                }
            }
        }
    }
}