/* Global */
* {
    /*	
      margin: 0;
      bottom: 0;
      color: #fff;
      list-style: none;
      font-family: "Roboto", sans-serif;
    */
        box-sizing: border-box;
    }
    
    #root {
        // background: $netflixBlack;
        // background-image: url( '../assets/img/bg-vx.png' );
        // background-repeat: no-repeat;
          // background-size: cover;
        min-height: 100vh;
    }
    
    #background {
        position: fixed;
        height: 100%;
        top: 0;
        width: 100%;
        z-index: -1;
    
        img {
            height: 100%;
            width: 100%;
        }
    }
    
    body {
      width: 100%;
    }
    
    .orange{
        color: #fba00b;
    }
    
    .btnBoxVideoDetail-sm{
        text-shadow: -1px 0 #fba00b, 0 1px #fba00b, 1px 0 #fba00b, 0 -1px #fba00b;
        position: absolute;
        left: 13px;
        top: 13px;
        z-index: 1;
    }
    
    .btnBoxVideoDetail{
        text-shadow: -1px 0 #fba00b, 0 1px #fba00b, 1px 0 #fba00b, 0 -1px #fba00b;
        position: absolute;
        left: 35%;
        top: 50px;
        z-index: 1;
    }
    
    .btnBoxVideoDetail:hover{
        cursor: pointer;
    }
    
    .infoChasse{
        position:relative;
        color:#fff;
    }
    .infoChasse {
        height: 30vw;
    }
    .infoChasse .background{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        overflow: hidden;
        -webkit-transition: opacity .3s cubic-bezier(.5,0,.1,1) 0s;
        -o-transition: opacity .3s cubic-bezier(.5,0,.1,1) 0s;
        -moz-transition: opacity .3s cubic-bezier(.5,0,.1,1) 0s;
        transition: opacity .3s cubic-bezier(.5,0,.1,1) 0s;
    }
    .z3p{
        position:absolute;
        z-index: 2;
        width: 70%;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;	
    }
    .infoChasse .close-button{
        border: 0;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1.3vw;
        padding: 20px 20px 40px 40px;
        z-index: 12;
        cursor: pointer;
        color: #fff;
        -webkit-transform-origin: 60% 35%;
        -moz-transform-origin: 60% 35%;
        -ms-transform-origin: 60% 35%;
        -o-transform-origin: 60% 35%;
        transform-origin: 60% 35%;
        background: -webkit-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: -moz-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: -o-radial-gradient(top right ellipse,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background: radial-gradient(ellipse at top right,rgba(0,0,0,.4) 0,rgba(0,0,0,0) 70%,rgba(0,0,0,0) 100%);
        background-position: 50% -50%;	
    }
    .textInfo{
        position:absolute;
        padding:20px;
        z-index:10;
        max-width: 30%;
        height:100%;
    }
    .textInfo .btn {
        position:absolute;
        bottom:20px;
    }
    .textInfo .btnplayer,.textInfoMobile .btnplayer,.text .btnplayer{
        background-color:#fff;
    }
    .textInfo .btnplayer:hover,.textInfoMobile .btnplayer:hover,.text .btnplayer:hover{
        background-color:#fba00b;
        color: #fff;
    }
    .legendGB .btnplayer:hover{
        background-color:#fba00b;
        color: #fff;
    }
    .carousel-caption .btnplayer:hover{
        background-color:#fba00b;
        color: #fff;
    }
    .legendGB .icnBtn{
        font-size: 2em;
    }
    .textInfo .icnBtn{
        font-size: 2em;
    }
    .z3p-img{
        position:absolute;
        background-size: cover;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;	
    }
    .vignette{
        background: #000;
        z-index: 2;
        width: 30%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .vignette::before{
        content: '';
        position: absolute;
        z-index: 10;
        background-image: -webkit-gradient(linear,left top,right top,from(#000),to(transparent));
        background-image: -webkit-linear-gradient(left,#000,transparent);
        background-image: -moz-linear-gradient(left,#000,transparent);
        background-image: -o-linear-gradient(left,#000,transparent);
        background-image: linear-gradient(to right,#000,transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px;
    }
    .z3Player{
        background-color:#000;
    }
    
    .btnContaint{
        display: flex;
        justify-content: center;
        bottom: 5px;	
    }
    .pInner {
        position: relative;
        display: inline-block;
        text-align: justify;
        max-height: 11em;
        overflow: hidden;		
        word-wrap: break-word;
        text-overflow: "ellipsis";
    }
    
      @media screen and (max-width: 1024px){
        .infoChasse .close-button{
            font-size: 3vw;
        }
        .textInfo{
            padding-top:40px;
            max-width: 80%;
            font-size: 1em;
        }
        .infoChasse {
            height: 50vw;
        }
          
    }
    @media screen and (max-width: 500px) {
        .infoChasse {
            /*height: 70vw;*/
            height:350px !important;
        }
        .infoChasse .close-button{
            font-size: 4vw;
        }
        .textInfo{
            padding-top:40px;
            max-width: 100%;
            font-size: 1em;
        }
        /*
        .infoChasse {
            height: 80vw;
        }
        */
        .textInfo .btn {
            bottom:5px;
        }
        .btn {
            padding: 2px 5px;;
        }
    }
    .descMovie{
        padding-top: 9px;
    }
    .img-pres-mobile{
        position:absolute;
        top: 0px;
        left: 0px;
    }
    .vignette2:before{
        position: absolute;
        //bottom: 50%;
        left: 0px;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(transparent 10%,#000 45%);
        content: '';
    }
    .textInfoMobile{
        position: absolute;
        bottom: 20px;
        width: 100%;
        padding: 5px 10px;
    }
    .btnContaintMobile{
        padding-top:10px;
    }
    .descMovieMobile{
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom : 10px solid transparent,
    }
    #favorite_Homelist {
        @media ( min-width: 1025px ) { margin: 0 auto; padding: 0 0 0 50px; }
        @media ( max-width: 1024px ) {
            margin: 0 auto;
        }
    }