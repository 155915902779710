.video-playlist-container {
    background-color: #000;
    height: 60vh;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;

    @media ( max-width: 1025px ) { height: 54vh; }
    @media ( max-width: 767px ) { height: 48vh; }
    @media ( max-width: 500px ) { height: 36vh; }

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .video-playlist-flex-container {
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;

        .video-playlist-container-relative {
            position: relative;
            flex-basis: 100%;

            #z3pOverlay { position: relative; }

            .video-playlist-container-sound, .video-playlist-container-full-screen {
                color: #fff;
                filter: drop-shadow( 0 0 10px #000 ); 
                position: absolute;
                transition: transform .5s linear;
                z-index: 999992;
            }
    
            .video-playlist-container-sound {
                font-size: 24px;
                
                @media ( min-width: 1025px ) {
                    bottom: 20px;
                    right: 80px;
                    &:hover { cursor: pointer; transform: scale(1.2); }
                }
                @media ( max-width: 1024px ) {
                    font-size: 36px;
                    padding: 13px;
                    left: 27px;
                    top: 7px;
                }
            }
                
            .video-playlist-container-full-screen {
                font-size: 20px;
                transform: scaleX(-1);
    
                @media ( min-width: 1025px ) {
                    bottom: 23px;
                    right: 40px;
                    &:hover { cursor: pointer; transform: scaleX(-1) scale(1.2); }
                }
                @media ( max-width: 1024px ) {
                    font-size: 32px;
                    left: 77px;
                    padding: 13px;
                    top: 10px;
                }
            }
        }
    }
}

.video-playlist-happy-ending { background: linear-gradient(to top, #00000000, #000); height: 50px; width: 100%; }

.video-playlist-content {
    color: #fff;
    width: 60%;
    margin: 0 auto;

    @media ( max-width: 1024px ) { width: 90%; }

    .video-playlist-content-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 26px;

        @media ( min-width: 1025px ) { height: 80px; }
        @media ( max-width: 767px ) { flex-direction: column; }

        .video-playlist-img-title {
            height: 100%;

            @media ( max-width: 767px ) { max-width: 80%; margin-bottom: 26px; }

            img { max-height: 100%; max-width: 100%; }
        }
        .video-playlist-title { font-size: 24px; }
    }

    .movie_btns {
        align-items: center;
        color: #aeaeae;
        display: flex;

        @media ( max-width: 1024px ) {
            flex-direction: column;
            padding-left: 0;

            & > * { margin: 13px 0; }
        }

        .icons_box { 
            align-items: center;
            display: flex;
            justify-content: center;
        }

        .movie_btn_box {
            align-items: center;
            display: flex;
            color: #FBA00B;
            flex-direction: column;
            justify-content: center;
            margin-right: 13px;
            
            .icon_box {
                background-color: #FFFFFF1A;
                border-radius: 50%;
                height: 55px;
                width: 55px;

                img.movie_icon { filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%); }

                @media ( min-width: 1025px ) {
                    &:hover {
                        background-color:#FFFFFF4A; cursor: pointer;
                    } 
                }
                .icon_revert { transform: rotate( 180deg ); }
            }
        }

        i { font-size: 30px; }

        .btn-movie {
            background-color:#FBA00B;
            border: none;
            border-radius: 3px;
            color: #FFFFFF;
            display: inline-block;
            font-size: 20px;
            font-weight: 100;
            height: 56px;
            letter-spacing: 2px;
            margin: 0 8px 8px 0;
            padding: 7px;
            width: 240px;

            @media ( max-width: 1024px ) { width: 300px; margin: 0 0 18px 0; }
            @media ( max-width: 500px ) { width: 80vw; margin: 0 0 18px 0; }
    
            i { font-size: 18px; margin-right: 13px; }
            &:hover { background-color: #FBA00B; cursor: pointer; opacity: 0.89; }
        }

        .btn-trailer {
            background-color: #2C2C2C;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }

        .btn-bonus {
            background-color: #2C2C2C;
            color: #fba00b;
            @media ( min-width: 1025px ) {
                &:hover {
                    background-color:#353535; cursor: pointer;
                } 
            }
        }
    }

    img.movie_icon {
        filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
        height: 30px;
        width: 30px;
        @media ( max-width: 767px ) { height: 20px; }

        &.selected {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    .icon_box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
        position: relative;
        width: 100%;
        z-index: 1;

        .bubble {
            background-color: #2a2a2a;
            border-radius: 3px;
            color: #fff;
            display: none;
            left: -7px;
            padding: 7px 12px;
            position: absolute;
            top: -60px;
            white-space: nowrap;
            z-index: 2;
        }

        &:hover .bubble {
            display: block;
            @media ( max-width: 1024px ) { display: none; }
        } 
    }

    .movie_additional_info {
        align-self: flex-start;
        padding: 0;
        margin-top: 26px;

        img.movie_icon {
            margin-left: 13px;
            margin-top: -4px;
            height: 20px;
            width: auto;
        }
    }

    .movie_popup_categories {
        align-self: flex-start;
        margin-top: 13px;
        padding: 10px 26px;
        position: relative;
        width: 100%;
        z-index: 3;

        @media ( max-width: 1024px ) { box-shadow: none; padding: 15px 0; }
        @media ( min-width: 1025px ) { .catSpanLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
        
        .catSpan {
            background-color: #1f1f1f;
            border-radius: 20px;
            color: #aeaeae;
            display: inline-block;
            height: 25px;
            margin-bottom: 7px;
            margin-right: 13px;
            padding: 0 13px;

            @media ( max-width: 1024px ) { margin: 7px 0; }

            &.catSpan-mobile { display: none; }
            &.catSpan-web { display: inline-block; }

            @media ( max-width: 767px ) {
                &.catSpan-mobile { display: inline-block; }
                &.catSpan-web { display: none; }
            }
        }
    }

    .movie_popup_synopsis { margin: 26px 0; }
    .distribution-container { margin-top: 13px; }
}