
.no-cursor { cursor: initial; }

.list-title.list-title-relative {
    padding: 0 13px 0 50px;
    position: relative;
    z-index: 2;

    @media ( max-width: 767px ) { padding: 0 13px; }
}

.list-title-product {
    font-size: 3rem;
    padding-top: 42px;
    @media ( max-width: 767px ) { padding-top: 84px; text-align: center; }

    &.mozaic { padding-top: 84px; }
}

.list-title-product-outOfStock {
    color: #fba00b;
    font-size: 3rem;
    font-weight: 300;
    margin-top: 26px;

    @media ( max-width: 767px ) { text-align: center; }
}

.list-title-product-price {
    color: #fba00b;
    font-size: 3rem;
    font-weight: 300;
    margin-bottom: 13px;
    margin-top: 26px;

    .list-title-product-price-initial {
        color: #aeaeae;
        display: inline-block;
        font-size: 2rem;
        margin-right: 13px;
        text-decoration: line-through;
    }

    .list-title-product-price-discount {
        background-color: #fba00b;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        margin-left: 26px;
        padding: 7px 17px;
        text-align: center;
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);

        @media ( max-width: 767px ) { margin-left: 0; }
    }

    @media ( max-width: 767px ) { text-align: center; }
}

.list-title-product-tags-box {
    display: flex;
    @media ( max-width: 767px ) { justify-content: center; }

    .list-title-product-tags {
        background-color: #1f1f1f;
        border-radius: 20px;
        color: #aeaeae;
        height: 25px;
        margin-bottom: 7px;
        margin-right: 13px;
        margin-top: 13px;
        padding: 0 13px;
    }

    @media ( min-width: 1025px ) { .productTagsLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
}

.description-title-product {
    color: #aeaeae;
    font-size: 2.5rem;
    margin: 26px 0 13px 50px;

    @media ( max-width: 767px ) { margin-left: 7px; }
}

.description-product {
    color: #aeaeae;
    font-size: 1.5rem;
    margin-bottom: 50px;
    margin-left: 50px;
    padding: 0 13px;

    @media ( max-width: 767px ) { margin-left: 13px; }
}

#home_Homelist.storeDetails .GBListItem { top: 26px; }

.product_mozaic_box {
    margin-left: 50px;
    padding-top: 50px;

    @media ( max-width: 1024px ) { margin-left: 26px; margin-right: 26px; }

    .shine-container {
        width: 21vw !important;

        @media ( max-width: 1024px ) { width: 25vw !important;  }
        @media ( max-width: 767px ) { width: 39vw !important; }
    }

    .product_mozaic {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5vh;
        margin-left: 2vw;

        &.movie_mozaic figure {
            height: 21vw !important;
            width: 21vw !important;

            @media ( max-width: 1024px ) { height: 25vw !important; width: 25vw !important;  }
            @media ( max-width: 767px ) { height: 39vw !important; width: 39vw !important; }
        }
    }
}

.list-title-product-cart, #profile_container .list-title-product-cart {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media ( max-width: 767px ) {
        align-items: inherit;
        flex-direction: column;
        justify-content: center;
    }

    .list-title-product-custom-select {
        margin-bottom: 13px;
        margin-right: 13px;
        width: 150px;
        @media ( max-width: 767px ) { margin-right: 0; }

        &:hover { cursor: pointer; }
        label { font-size: 16px; }

        .list-title-product-select-option-box { position: absolute; z-index: 1; }

        .list-title-product-select-selected {
            background: #00221f;
            border: 1px solid #aeaeae;
            border-radius: 2px;
            color: #aeaeae;
            font-size: 20px;
            font-weight: 100;
            padding: 10px 13px;
            width: 150px;

            &:hover { cursor: pointer; }
            &.disabled {
                opacity: .6;
                &:hover { cursor: not-allowed; }
            }
            &.open { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
        }

        .list-title-product-select-option {
            background: #00221f;
            border: 1px solid #aeaeae;
            border-bottom: none;
            color: #aeaeae;
            display: none;
            font-size: 20px;
            font-weight: 100;
            padding: 10px 13px;
            width: 150px;

            &:first-child() { border-top: 4px solid #aeaeae; }
            &:last-child() { border-bottom: 1px solid #aeaeae; border-radius: 2px; }
            &.show { display: block; }
        }
    }

    .list-title-product-quantity-box {
        margin-bottom: 13px;
        margin-right: 13px;
        width: 150px;
        @media ( max-width: 767px ) { margin-right: 0; }

        label { font-size: 16px; }

        .list-title-product-quantity-input {
            display: flex;

            input {
                background: #00221f;
                border: 1px solid #aeaeae;
                border-radius: 2px;
                color: #aeaeae;
                font-size: 20px;
                font-weight: 100;
                padding: 10px 0;
                text-align: center;
                width: 60px;

                pointer-events: none;

                &.disabled {
                    opacity: .6;
                    &:hover { cursor: not-allowed; }
                }
            }

            input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

            .list-title-product-quantity-input-minus, .list-title-product-quantity-input-plus {
                background: #00221f;
                border: 1px solid #aeaeae;
                border-radius: 2px;
                color: #aeaeae;
                font-size: 20px;
                font-weight: 700;
                padding: 10px 0;
                text-align: center;
                width: 42px;

                &:hover { cursor: pointer; }
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .list-title-product-quantity-input-minus { margin-right: 3px; }
            .list-title-product-quantity-input-plus { margin-left: 3px; }
        }
    }

    .list-title-product-btn {
        margin-left: 13px;
        margin-top: 20px;

        @media ( max-width: 767px ) { margin-left: 0; }

        button {
            background: #fba00b;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-weight: 100;
            height: 52px;
            width: 150px;
            &:hover { cursor: pointer; }
        }
    }
}

.list-title-product-cart-message {
    font-weight: 700;
    height: 30px;
    @media ( max-width: 767px ) { margin: 13px 0; text-align: center; }
    .list-title-product-cart-message-error { color: #E33939; }
    .list-title-product-cart-message-success { color:#54C254; }
}

#nav_cart-web {
    background: #fba00b;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: 100;
    padding: 3px;
    text-align: center;
    transition: all .5s;
    width: 60px;

    @media ( min-width: 1025px ) {
        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    .dot-cart {
        border-radius: 50%;
        background-color: #ff0000;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        height: 25px;
        padding-top: 1px;
        position: absolute;
        right: -14px;
        top: 14px;
        width: 25px;
    }
}

#nav_cart-mobile {
    background: #fba00b;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 100;
    margin-top: 5px;
    padding: 3px;
    text-align: center;
    width: 60px;

    .dot-cart {
        border-radius: 50%;
        background-color: #ff0000;
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        height: 25px;
        padding-top: 1px;
        position: absolute;
        right: 10px;
        top: 36px;
        width: 25px;
    }
}

.cart_product_delivery {
    margin: 23px 0 !important;

    a {
        color: #fba00b;
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
    }
}

.cart_product_details {
    .cart_product_image {
        height: 100px;
        width: 100px;

        img { height: 100%; width: 100%; }
    }

    .cart_product_title {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.cart_product_title  a {
    color: #fba00b;
    cursor: pointer;
    font-weight: 700;
    margin-left: 3px;
}

.cart_product_price {
    color: #fba00b;
    font-size: 1.5rem;
    font-weight: 300;
    margin: 7px 0;

    &.tall { font-size: 2.5rem; }

    .cart_product_price_old {
        color: #aeaeae;
        display: inline-block;
        font-size: 2rem;
        margin: 0 7px;
        text-decoration: line-through;
    }

    .cart_product_price_initial {
        display: inline-block;
        margin: 0 7px;
    }
}

.cart_product_remove {
    color: #E33939;
    font-weight: 700;
    &:hover { cursor: pointer; }
}

.cart_valid_btn_box {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    width: 720px;

    &.cart_btn_top { margin-bottom: 26px; }

    @media ( max-width: 1024px ) {
        width: 80vw;
        padding: 0;

        .cart_valid_btn:first-child { margin-top: 26px; }
        &.cart_btn_top .cart_valid_btn:first-child { margin-top: 0; }
    }
    @media ( max-width: 767px ) { flex-direction: column-reverse; }
}

.cart_valid_btn {
    background: #fba00b;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: 100;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
    width: 200px;
    &:hover { cursor: pointer; }
}

.cart_comment_btn_box {
    margin-top: 13px;
    width: 100%;
    @media ( max-width: 767px ) { margin: 13px auto 0; }
}

.cart_comment_btn, a.cart_comment_btn {
    background: #fba00b;
    border: none;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 100;
    padding: 5px;
    text-align: center;
    width: 200px;
    &:hover { cursor: pointer; }
}

.address_title { margin-top: 42px; }

.address_checkbox_container {
    display: flex;
    padding-bottom: 13px;
    width: 400px;

    .address_checkbox {
        position: relative;

        input[type=checkbox] { width: 0; display: none; }
    }
}

.address_checkbox_container {
    display: flex;
    padding: 26px 0;
    width: 400px;
    @media ( max-width: 767px ) { width: 80vw; }

    .address_checkbox {
        position: relative; 

        input[type=checkbox] { width: 0; display: none; }
        input[type=checkbox]:checked + span:after { opacity: 1; }
        input[type=checkbox]:checked + span {
            background: #fba00b;
            border: none;
            &:hover { opacity: 1; }
        }
        
        span {
            cursor: pointer;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 4px;
            border-radius: 4px;
            background: transparent;
            border: 1px solid white;

            &:after {
                opacity: 0;
                content: '';
                position: absolute;
                width: 10px;    
                height: 7px;
                top: 5px;
                left: 5px;
                border: 3px solid #fcfff4;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
            }

            @media( min-width: 1025px ) { &:hover { opacity: 0.6; } }
        }
    }

    .address_checkbox_label { padding-left: 27px; padding-top: 2px; }
}

.inputs_disabled .profile_edit_usual_input {
    opacity: .6;
    &:hover { cursor: not-allowed; }

    input { pointer-events: none; }
}

.menu_stripe {
    justify-content: center;

    .local_stripe_info_text {
        margin-bottom: 13px;
        text-align: center;
    }
}

.product-go-back {
    color: #aeaeae;
    cursor: pointer;
    left: 50px;
    padding: 13px 0;
    position: absolute;
    transition: transform .2s;
    top: 7px;
    z-index: 2;

    .icon_box {
        margin-right: 13px;
        width: inherit !important;
        img { vertical-align: text-top; }
    }

    &:hover { transform: scale( 1.04 ); }
}

.product_code_form {
    align-items: center;
    display: flex;
    justify-content: center;

    @media ( max-width: 767px ) { flex-direction: column; }

    .product_code_form_valid {
        background: #3b3b3b;
        border: 1px solid #3b3b3b;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 100;
        height: 50px;
        margin-left: 7px;
        padding: 9px 7px;
        text-align: center;
        width: 100px;

        @media ( max-width: 767px ) { margin-left: 0; margin-top: 13px; }

        &:hover { background: #404040; }
    }

    .product_code_form_remove {
        background: #626262;
        border: 1px solid #626262;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        font-size: 20px;
        font-weight: 100;
        margin-left: 7px;
        padding: 9px 7px;
        text-align: center;
        width: 100px;

        @media ( max-width: 767px ) { margin-left: 0; margin-top: 13px; }

        &:hover { background: #6c6c6c; }
    }
}

.product_code_form_results {
    font-weight: 700;
    min-height: 35px;
    padding-top: 7px;
    
    .product_code_form_error { color: #E33939; }
    .product_code_form_success { color: #54C254; }
}

.menu_priceTotal {
    border-bottom: 6px solid #FFFFFF1A !important;
}

.cart_product_discount_box {
    color: #fff;

    span {
        background-color: #fba00b;
        border-radius: 5px;
        display: inline-block;
        font-weight: 700;
        margin-left: 7px;
        padding: 7px 17px;
        text-align: center;
    }
}

.cart_product_defualt_discount_box {
    background-color: #fba00b;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-left: 7px;
    padding: 7px 17px;
    text-align: center;
}

.product_popup_img {
    margin-top: 80px;
    cursor: initial;
    object-fit: cover;
    width: 960px;

    @media ( max-width: 1200px ) { width: 800px; }
    @media ( max-width: 1024px ) { width: 80vw; }
}

.comment_box { margin-bottom: 0 !important; }

.comment_star_rating_box {
    color: #fba00b;
    display: flex;
    font-size: 2rem;
    margin-top: 26px;

    .comment_star_box {
        cursor: pointer;
        margin: 0 7px;
    }
}

.comment-box-title {
    font-size: 2rem;
    margin: 26px 0;
}

.comment-box-container { width: 100%; }

.comment-box {
    background-color: #ffffff11;
    border-radius: 5px;
    margin: 13px 0;
    padding: 13px 0;
    width: 100%;

    .comment-user-box {
        display: flex;
        margin-bottom: 13px;;
    
        .comment-avatar-box { text-align: center; width: 60px; }
        .comment-avatar { border-radius: 50%; height: 40px; width: 40px; }
        .comment-pseudo { padding-top: 10px; }
        .comment-date { color: #aeaeae; font-style: italic; margin-left: 7px; padding-top: 10px; }
    }

    .comment-text { margin-left: 60px; }
}

.profile_edit_usual_message {
    height: 30px;
    .profile_edit_usual_error, .profile_edit_usual_success { height: initial !important; }
}

.profile_edit_usual_btn.comment_box {
    margin-bottom: 30px;
    padding: 7px;

    @media ( max-width: 767px ) { width: 70vw; }
}