/* vars */

$netflixRed: #b9090b;
$netflixBlack: #0f0f0f;
$netflixWhite: #ecf0f1;
$whiteGrey: #a3a3a1;
$iconSize: 50px;
$tileWidth: 280px;
$tileHeight: 160px;

/* mixins */

/* responsive layout */

@mixin maxMediaQueries($arg...) {
	@if length($arg) ==1 {
		@media screen and (max-width: nth($arg, 1)) {
			@content;
		}
	}
	@if length($arg) ==2 {
		@media screen and (max-width: nth($arg, 1)) and (min-width: nth($arg, 2)) {
			@content;
		}
	}
}

@mixin minMediaQueries($arg) {
	@media screen and (min-width: $arg) {
		@content;
	}
}

@mixin grid($cols, $mgn) {
	float: left;
	text-align: center;
	margin-right: $mgn;
	margin-bottom: $mgn;
	width: ((96% - (($cols - 1) * $mgn)) / $cols);
	&:nth-child(#{$cols}n) {
		margin-right: 4%;
	}
	img {
		width: 100%;
	}
}

/* button styles */

@mixin addListBtn {
	.addListBtn {
		background-color: transparent;
		border-radius: 100%;
		border: 2px solid $whiteGrey;
		display: inline-block;
		cursor: pointer;
		color: $netflixWhite;
		font-size: 24px;
		padding: 2px 12px;
		text-decoration: none;
		box-sizing: border-box;
		transition: 0.3s transform;
		margin-right: 10px;
		transform-origin: 50% 50%;
		&:focus {
			outline: 0;
		}
		&:hover {
			border: 2px solid $netflixWhite;
			background-color: transparent;
			transform: scale(1.1);
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}

@mixin playBtn {
	.playBtn {
		content: "▶";
		background-color: transparent;
		color: $netflixWhite;
		border-radius: 100%;
		border: 2px solid $netflixWhite;
		display: inline-block;
		cursor: pointer;
		font-size: 24px;
		padding: 22px 10px 22px 15px;
		text-decoration: none;
		box-sizing: border-box;
		transition: 0.3s transform;
		transform-origin: 50% 50%;
		opacity: 0.3;
		&:focus {
			outline: 0;
		}
		&:hover {
			color: $netflixRed;
			border: 2px solid $netflixWhite;
			background-color: transparent;
			transform: scale(1.1);
			opacity: 1;
		}
	}
}

/* CSS effects and styles of tiles */

@mixin listingTiles {
	.tile-title{
		padding-top: 5px;
		color:#7d8877;
	}
	.tile-overflow{
		overflow: hidden;
	}
	.tile {
		position: relative;
		display: inline-block;
		line-height: 0;
		cursor: pointer;
		padding: 5px;
		/*    overflow: hidden; */
		width: $tileWidth;
		height: $tileHeight;
		transition: 0.3s transform;
		transform-origin: 0% 50%;
		&:hover {
			transform: scale(1.7);
		}
		&:hover ~ .tile {
			transform: translate3d(200px, 0, 0);
		}
		.tile-img {
			transition: transform 0.6s ease-out;
			object-fit: cover;
			width: $tileWidth;
			height: $tileHeight;
		}
	}
	.photo-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		/*centering text inside .photo-overlay */
		justify-content: space-between;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity 0.5s;
	}
	.photo-overlay:hover {
		opacity: 1;
	}
	.tile-desc {
		font-size: 0.6em;
		line-height: 1.3;
		margin: 0 0;
	}
	.tile-btn-container {
		margin-top: 130px;
		@include addListBtn;
		.addListBtn {
			font-size: 22px;
			border: 1px solid $whiteGrey;
			padding: 10px 4px;
			&:hover {
				border: 1px solid $netflixWhite;
			}
		}
	}
	.playbtn-container {
		margin: 35px 0px 10px 0px;
		text-align: center;
		@include playBtn;
	}
}
