#preference_container {
    color: #AEAEAE;
    font-size: 1.5rem;
    margin: 0 0 120px 50px;

    @media ( max-width: 1024px ) { margin: 240px auto; }

    h1 {
        font-size: 4rem;
        padding-top: 120px;
        margin-left: 60px;
        margin-bottom: 60px;

        @media ( max-width: 1024px ) { padding-top: 0; margin-left: 0; font-size: 3rem; text-align: center; }
    }

    #btn_submit {
        align-items: center;
        background-color: #fba00b;
        border-radius: 13px;
        color: #fff;
        display: flex;
        justify-content: center;
        font-size: 1.3rem;
        height: 40px;
        opacity: 1;
        width: 140px;
        transition: opacity 0.5s;
        
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }

    #btns_choice { 
        display: flex;
        font-size: 1.3rem;
        margin: 0 26px;
        padding: 7px;
    
        .btns_choice_ico {
          height: 20px;
        }
    
        #chasse_choice .btns_choice_ico {
          margin-right: 13px;
        }
    
        #peche_choice .btns_choice_ico {
          margin-left: 13px;
        }
    
        #chasse_choice, #peche_choice {
          align-items: center;
          background-color:rgb(95, 93, 93);
          border-radius: 13px;
          color: #111;
          display: flex;
          justify-content: center;
          height: 40px;
          opacity: 0.6;
          width: 140px;
          transition: opacity 0.5s;
    
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
    
        #chasse_choice.selected {
          background-color: #fba00b;
          color: #fff;
          margin-right: -13px;
          opacity: 1;
          z-index: 2;
        }
        
        #peche_choice.selected {
          background-color: #fba00b;
          color: #fff;
          margin-left: -13px;
          opacity: 1;
          z-index: 2;
        }
    
        .selected .btns_choice_ico {
          filter: invert( 100% );
        }
    }

    .preference_option {
        align-items: center;
        display: flex;
        margin: 50px 50px 26px 50px;

        .f-grow { flex-grow: 1; }

        @media ( max-width: 1024px ) {
            flex-direction: column;
            height: 200px;
        }
        @media ( max-width: 767px ) {
            margin: 50px 0 26px 0;
            font-size: 1.5rem;
        }
    }

    .preference_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    figure:hover {
        .icon_box { opacity: 0.6; }
    }

    .categ_box {
        .movie_mozaic { margin-left: 0; }

        @media ( max-width: 767px ) {
            font-size: 1.2rem;
            max-width: 50vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .icon_add { height: 25px; width: 25px; }
        }
    }

    .categ_box:hover { cursor: pointer; }

    .icon_add {
        height: 30px;
        margin-left: 13px;
        margin-top: -3px;
        width: 30px;
        filter: invert(100%) sepia(2%) saturate(710%) hue-rotate(232deg) brightness(117%) contrast(87%);

        &.selected {
            filter: invert(56%) sepia(92%) saturate(514%) hue-rotate(356deg) brightness(103%) contrast(97%);
        }
    }

    .effect-thumbnail-movie.selected {
        border: 3px solid #FBA00B;
    }

    .preference_error {
        color: #E33939;
        display: flex;
        font-weight: 700;
        height: 30px;
        justify-content: center;
        margin-bottom: 50px;

        @media ( max-width: 767px ) { font-size: 1.5rem; }
    }
}