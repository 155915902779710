.movie-page {
    .soon-container .soon-content .soon-img-title { margin-bottom: 26px; margin-top: 26px; }
    .soon-container .soon-content .soon-title { margin-bottom: 26px; margin-top: 26px; }
    .soon-container .movie_btns { margin: 26px 0; }
    .soon-description {
        filter: drop-shadow(0 0 10px #000);
        @media ( max-width: 1024px ) { margin-top: 7px; }
    }
    .soon-title {
        font-size: 32px;
        margin: 26px 0;
    }

    .soon-container .soon-content .soon-description, .soon-container .soon-content .soon-date { margin-bottom: 26px; }

    .episodes-container {
        width: 100%;

        .episode-box .movie_episode_img:hover { transform: translate( -7px, -7px ); }
    }
    .episode-serie {
        font-size: 18px;
        padding: 0;
        margin-top: 13px;

        @media ( max-width: 1024px ) { margin: 13px 5%; max-width: 90%; }
    }
    .soon-container .movie_btns { margin: 26px 0 0 0; }

    .soon-content .movie_additional_info {
        align-self: flex-start;
        padding: 0;
        margin-top: 26px;

        @media ( max-width: 1024px ) { margin: 7px 5%; max-width: 90%; }

        img.movie_icon {
            filter: invert(95%) sepia(94%) saturate(2%) hue-rotate(48deg) brightness(99%) contrast(95%);
            margin-left: 13px;
            margin-top: -4px;
            height: 20px;
            width: auto;
        }
    }

    .soon-content .movie_popup_categories {
        align-self: flex-start;
        margin: 13px 0;
        padding: 10px 0;
        position: relative;
        width: 100%;
        z-index: 3;

        @media ( max-width: 1024px ) { box-shadow: none; margin: 7px 5%; max-width: 90%; padding: 0; }
        @media ( min-width: 1025px ) { .catSpanLinked:hover { background-color:#FFFFFF4A; cursor: pointer; } }
        
        .catSpan {
            background-color: #1f1f1f;
            border-radius: 20px;
            color: #aeaeae;
            display: inline-block;
            height: 25px;
            margin-bottom: 7px;
            margin-right: 13px;
            padding: 0 13px;

            @media ( max-width: 1024px ) { margin: 7px 0; }

            &.catSpan-mobile { display: none; }
            &.catSpan-web { display: inline-block; }

            @media ( max-width: 767px ) {
                &.catSpan-mobile { display: inline-block; }
                &.catSpan-web { display: none; }
            }
        }
    }

    .movie_rows_nav {
        align-items: center;
        display: flex;
        margin: 0 auto;
        color: #fff;
        width: 80%;

        @media ( max-width: 1024px ) { margin: 0 5%; width: 90%; }
    
        .movie_rows_nav_item {
            border-bottom: 2px solid #aeaeae;
            flex: 1 1 0;
            font-size: 16px;
            margin: 0 13px;
            padding: 13px 26px; 
            text-align: center;

            @media ( max-width: 1024px ) {
                font-size: 14px;
                margin: 0 3px;
                padding: 7px 0;
            }
            @media ( max-width: 767px ) { font-size: 10px; }
    
            &:hover { cursor: pointer; }
            &.selected { border-bottom: 2px solid #fba00b; font-weight: 700; }
        }
    }

    .movie_rows_details {
        margin: 42px 0;
        width: 100%;

        .categories-list-box.distribution-list-box {
            margin: 0 auto;
            width: 80%;
        } 

        .GBListItem {
            top: 0 !important;
            .list-title { padding-top: 0 !important; }
        }
    }
}

#profile_container.movie_comment_popup {
    padding: 0 50px;
    margin: 0;

    @media ( max-width: 1024px ) { padding: 26px; margin: 0 auto; }

    .profile_edit_usual { border-top: 2px solid #fff; padding-top: 26px; }
    

    .profile_edit_usual_form {
        align-items: start;

        @media ( max-width: 1024px ) {
            align-items: center;

            .profile_edit_usual_input {
                align-self: flex-start; 
                input { width: 200px; }
            } 
        }

        h3, .comment_star_rating_box, .profile_edit_usual_btn, .profile_edit_usual_message { align-self: center; }
        .profile_edit_usual_textarea { width: 100%; textarea { width: 100%; } }
    }
}

.movie_comment_title { font-size: 2rem; text-align: center; width: 100%; padding-bottom: 13px; }

#profile_container.movie_comment_page {
    padding: 13px 0 0 120px;
    margin: 0;

    @media ( max-width: 1024px ) { padding: 0; margin: 0 auto; }

    .profile_edit_usual_form {
        align-items: start;

        @media ( max-width: 1024px ) { align-items: center; }

        h3, .comment_star_rating_box, .profile_edit_usual_btn, .profile_edit_usual_message { align-self: center; }
        .profile_edit_usual_textarea { width: 100%; textarea { width: 100%; } }
    }
}