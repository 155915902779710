.clock-container { display: flex; margin-left: 13px; }

.separator-colon {
  align-items: center;
  display: flex;
  justify-content: center;
  color:#fff;
  font-size: 64px;

  @media ( max-width: 1600px ) { font-size: 32px; margin-top: 7px; }
}

.clock-label {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  line-height: 42px;

  @media ( max-width: 1600px ) { font-size: 9px; line-height: 21px; }
}

.clock-container-second, .clock-container-minute, .clock-container-hour, .clock-container-day {
  /* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.clock-cards {
  text-decoration: none;
  color: #ccc;
}

ul {
  list-style: none
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/* Main */

// html, body {
//   min-height: 100%;
// }

// body {
  font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
  user-select: none;
  color: #888;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .3);
  // background: rgb(150, 150, 150);
  // background: -moz-radial-gradient(center, ellipse cover, rgba(150, 150, 150, 1) 0%, rgba(89, 89, 89, 1) 100%);
  // background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(150, 150, 150, 1)), color-stop(100%, rgba(89, 89, 89, 1)));
  // background: -webkit-radial-gradient(center, ellipse cover, rgba(150, 150, 150, 1) 0%, rgba(89, 89, 89, 1) 100%);
  // background: -o-radial-gradient(center, ellipse cover, rgba(150, 150, 150, 1) 0%, rgba(89, 89, 89, 1) 100%);
  // background: -ms-radial-gradient(center, ellipse cover, rgba(150, 150, 150, 1) 0%, rgba(89, 89, 89, 1) 100%);
  // background: radial-gradient(ellipse at center, rgba(150, 150, 150, 1) 0%, rgba(89, 89, 89, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr = '#969696', endColorstr = '#595959', GradientType = 1);

// }

// .container {
  text-align: center;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  width: 140px;
  height: 90px;
  margin: 26px 0;

  @media ( max-width: 1600px ) { height: 45px; width: 80px; }
  @media ( max-width: 400px ) { height: 30px; width: 64px; }
  // margin: -45px 0 0 -70px;
// }

#social {
  text-align: center;
  position: absolute;
  bottom: 14%;
  width: 100%;
}
#social p{
margin-bottom: 10px;
}

#social ul,
#social li {
  display: inline-block;
}

/* Skeleton */

ul.flip {
  position: relative;
  float: left;
  margin: 5px;
  width: 60px;
  height: 90px;
  font-size: 80px;
  font-weight: bold;
  line-height: 87px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .7);

  @media ( max-width: 1600px ) {
    width: 30px;
    height: 45px;
    font-size: 40px;
    line-height: 43px;
  }

  @media ( max-width: 400px ) {
    width: 20px;
    height: 30px;
    font-size: 27px;
    line-height: 29px;
  }
}

ul.flip li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

ul.flip li:first-child {
  z-index: 2;
}

ul.flip li .clock-cards {
  display: block;
  height: 100%;
  perspective: 200px;
}

ul.flip li .clock-cards div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}

ul.flip li .clock-cards div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

ul.flip li .clock-cards div.up {
  transform-origin: 50% 100%;
  top: 0;
}

ul.flip li .clock-cards div.up:after {
content: "";
position:absolute;
top:44px;
left:0;
z-index: 5;
  width: 100%;
height: 3px;
background-color: rgba(0,0,0,.4);
}

ul.flip li .clock-cards div.down {
  transform-origin: 50% 0%;
  bottom: 0;
}

ul.flip li .clock-cards div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #fff;
  text-shadow: 0 1px 2px #000;
  text-align: center;
  background-color: #333;
  border-radius: 6px;
}

ul.flip li .clock-cards div.up div.inn {
  top: 0;

}

ul.flip li .clock-cards div.down div.inn {
  bottom: 0;
}

/* PLAY */

&.play ul li.before {
  z-index: 3;
}

&.play ul li.active {
  animation: asd .5s .5s linear both;
  z-index: 2;
}

@keyframes asd {
  0% {
      z-index: 2;
  }
  5% {
      z-index: 4;
  }
  100% {
      z-index: 4;
  }
}

&.play ul li.active .down {
  z-index: 2;
  animation: turn .5s .5s linear both;
}

@keyframes turn {
  0% {
      transform: rotateX(90deg);
  }
  100% {
      transform: rotateX(0deg);
  }
}

&.play ul li.before .up {
  z-index: 2;
  animation: turn2 .5s linear both;
}

@keyframes turn2 {
  0% {
      transform: rotateX(0deg);
  }
  100% {
      transform: rotateX(-90deg);
  }
}

/* SHADOW */

&.play ul li.before .up .shadow {
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(linear, to right to bottom, to right to top, color-stop(0%, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, 1)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  animation: show .5s linear both;
}

&.play ul li.active .up .shadow {
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(linear, to right to bottom, to right to top, color-stop(0%, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, 1)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .1) 0%, rgba(0, 0, 0, 1) 100%);
  animation: hide .5s .3s linear both;
}

/*DOWN*/

&.play ul li.before .down .shadow {
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -webkit-gradient(linear, to right to bottom, to right to top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, .1)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  animation: show .5s linear both;
}

&.play ul li.active .down .shadow {
  background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -webkit-gradient(linear, to right to bottom, to right to top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, .1)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .1) 100%);
  animation: hide .5s .3s linear both;
}

@keyframes show {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes hide {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}
}